import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 519 296">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="">
					<path fill="#FFFEFE" opacity="1.000000" stroke="none" 
	d="
M520.000000,161.000000 
	C520.000000,206.305984 520.000000,251.611984 520.000000,296.947693 
	C347.000000,296.947693 174.000000,296.947693 1.000000,296.947693 
	C1.000000,273.576019 1.000000,250.256668 1.385494,226.460083 
	C3.512607,226.311050 5.254226,226.639252 7.249317,226.977570 
	C7.671868,226.984131 7.840946,226.980591 8.023846,226.987167 
	C8.037668,226.997269 8.067795,226.981003 8.191948,227.285339 
	C9.548903,228.396011 10.781703,229.202347 12.147867,230.310669 
	C13.839511,231.759705 15.397793,232.906769 17.176498,234.078064 
	C17.396923,234.102325 17.837339,234.154617 17.934601,234.505707 
	C20.343847,235.631149 22.655828,236.405472 25.422235,237.332001 
	C25.624113,237.699432 26.270227,238.875427 26.932552,240.040070 
	C26.948761,240.028717 26.983271,240.027634 27.124460,240.336456 
	C28.807043,241.452759 30.348438,242.260239 31.908920,243.071228 
	C31.928005,243.074753 31.960339,243.096237 32.067108,243.311646 
	C32.392380,243.790817 32.672741,243.930206 33.350990,243.947510 
	C34.129864,243.882706 34.572708,243.815643 35.111496,244.050232 
	C35.828907,244.895035 36.450367,245.438171 37.216911,246.309174 
	C41.280537,248.662308 45.180183,250.725494 49.127430,252.693176 
	C50.593582,253.424072 52.171284,253.931198 53.698112,254.540344 
	C53.870651,252.756973 54.043190,250.973587 54.499496,249.175171 
	C55.161739,249.134720 55.540215,249.109283 55.947464,249.092194 
	C55.976234,249.100525 55.917740,249.116241 55.945953,249.438934 
	C56.319340,250.499649 56.664509,251.237656 57.015770,252.342163 
	C58.735672,254.439331 60.449474,256.169983 62.054138,258.187653 
	C62.052746,259.266113 62.160500,260.057617 62.202866,261.165009 
	C62.490952,261.948364 62.844425,262.415802 63.345898,263.165985 
	C64.317024,263.631958 65.140152,263.815216 66.080040,264.311890 
	C67.828735,266.043854 69.460663,267.462372 71.274902,269.170471 
	C75.700523,271.636444 79.943848,273.812836 84.306053,276.050201 
	C85.049644,274.739288 85.528999,273.894196 86.420166,273.037933 
	C89.547829,272.687103 92.263687,272.347412 95.094681,272.253784 
	C95.210014,273.311951 95.210228,274.124023 94.985641,275.188354 
	C92.745773,275.737549 90.730690,276.034515 88.715614,276.331451 
	C88.451889,276.892822 88.188164,277.454163 87.924438,278.015533 
	C91.891350,280.715515 95.858261,283.415497 99.841621,286.128113 
	C99.858078,286.140778 99.890350,286.166290 99.963318,286.402527 
	C100.245071,286.926147 100.526802,287.079590 101.075012,287.292755 
	C101.602760,287.618408 101.936981,287.750427 102.433304,288.160339 
	C104.390053,289.643921 106.184715,290.849640 108.055939,292.361603 
	C110.097481,292.416321 112.062469,292.164825 114.022011,292.296326 
	C114.324562,295.875885 115.762032,296.801941 118.600258,294.981018 
	C121.727676,292.974548 124.866615,290.986023 128.353119,288.952087 
	C133.487289,286.196228 136.326599,280.528625 142.506454,279.860626 
	C143.079575,279.798706 143.539154,278.686371 144.348022,277.890991 
	C147.023575,276.110596 149.402542,274.500916 152.087921,272.716888 
	C155.918350,269.379242 159.442352,266.215942 162.984543,262.660431 
	C162.788956,258.831573 164.907074,258.066589 167.731522,258.057465 
	C168.299347,259.335510 168.841766,260.556366 169.641464,262.356354 
	C173.043304,259.696503 176.040115,257.353302 179.252045,254.898300 
	C179.732529,254.579224 179.909927,254.313278 180.338837,253.957214 
	C181.437988,252.953766 182.197662,251.981750 183.115463,250.710114 
	C184.858093,248.632156 186.442612,246.853836 188.346863,245.194000 
	C192.679642,248.443954 195.956375,247.720963 198.866165,243.623810 
	C199.654114,242.514343 201.300705,242.054016 202.300476,241.045258 
	C203.430893,239.904678 204.276718,238.482071 205.533813,237.134613 
	C206.213318,237.052505 206.605301,237.017899 207.207153,237.223785 
	C209.120499,236.606506 211.017960,235.994583 212.485077,234.837189 
	C215.135086,232.746643 217.527924,230.330124 220.450500,228.030731 
	C224.262527,228.175797 227.993546,228.390228 229.409531,224.598480 
	C230.273788,222.284180 229.765442,219.457306 229.943008,216.903290 
	C230.012436,216.945953 229.862579,216.883942 230.261414,216.853058 
	C230.932846,216.658905 231.439056,216.502502 231.444092,216.331390 
	C231.586121,211.493729 233.172913,206.524002 230.161438,201.558670 
	C230.106216,200.108047 230.111816,199.050247 230.358307,197.773376 
	C230.436890,196.371155 230.274612,195.187988 230.056458,193.594025 
	C230.108765,191.810730 230.216934,190.438263 230.666977,188.941284 
	C231.346588,182.838562 231.684326,176.860367 232.370178,171.042053 
	C235.890549,172.314987 238.951523,173.341171 237.092911,167.832306 
	C236.853073,167.121460 236.941208,166.067841 237.309937,165.418686 
	C242.589584,156.123566 249.744110,151.936981 261.122620,154.579071 
	C267.492035,156.058029 271.457245,159.588074 273.107758,166.077408 
	C275.815887,176.725143 271.958862,185.630936 265.647186,193.545776 
	C263.239655,196.564835 259.007629,198.128937 255.188324,200.230881 
	C254.482224,200.059189 254.194839,200.012711 253.815369,199.583099 
	C253.427643,197.145233 253.132019,195.090515 252.916473,192.812943 
	C252.996536,192.590103 253.042740,192.118790 253.434998,192.070526 
	C255.965607,192.569290 258.103943,193.116318 260.748474,193.792847 
	C261.743164,192.703598 263.043274,191.279877 264.808563,189.346741 
	C260.471832,188.755814 257.266785,188.319092 254.055862,187.489975 
	C255.534485,181.390915 257.361664,175.747330 258.426178,169.963364 
	C260.186554,160.398407 258.873444,158.871246 250.006699,157.615906 
	C247.899734,166.001495 245.824234,174.304443 243.723801,182.601074 
	C242.852203,186.043839 241.737335,189.023117 238.409134,191.340073 
	C234.582504,194.004013 235.157761,200.866425 238.911758,203.336853 
	C240.744949,204.543259 242.924789,205.222870 245.183960,206.497040 
	C267.569458,208.538086 277.170319,196.157425 282.670044,180.448914 
	C284.039398,176.537720 283.505554,171.984955 284.040375,167.751587 
	C285.118134,159.220856 282.027344,152.765839 274.969727,147.892944 
	C273.566986,146.924438 272.997223,144.749496 272.420502,143.071198 
	C277.509338,141.551254 277.725891,137.423538 277.921234,133.656906 
	C278.205261,128.180420 277.663116,122.657661 278.010834,117.188812 
	C278.423981,110.690163 274.424042,106.161263 267.936707,105.940987 
	C262.600159,105.759781 260.781616,109.732674 258.384521,113.153381 
	C257.403717,114.553047 256.517700,116.019142 255.052002,118.284180 
	C254.227661,115.995834 253.817383,114.992691 253.502914,113.960350 
	C252.194870,109.666351 248.884598,107.498436 244.995132,106.010727 
	C239.726028,103.995308 233.239807,109.189491 233.033676,115.734634 
	C232.861206,121.211037 233.814896,126.867653 232.724701,132.123978 
	C231.872787,136.231415 233.044449,138.198227 236.112030,140.452118 
	C239.293137,143.727921 242.559326,143.661163 245.584503,141.016556 
	C247.593033,139.260681 249.155075,139.714569 250.794647,141.441696 
	C249.993881,142.195557 249.331818,142.885071 248.627426,142.931442 
	C240.594574,143.460464 234.901184,147.823685 230.172363,153.515579 
	C230.066620,149.716217 230.032120,146.347687 230.033127,142.513992 
	C229.966095,136.733795 229.863541,131.418747 229.832855,125.807304 
	C229.865906,125.311310 229.827103,125.111725 229.901917,124.466064 
	C229.993698,116.868904 229.971893,109.717827 230.146301,102.268066 
	C230.208099,96.438835 230.073700,90.908287 230.015839,84.925934 
	C230.043015,83.867073 229.993652,83.260017 230.103653,82.541931 
	C230.194733,81.941765 230.126465,81.452629 229.889374,80.619995 
	C229.528534,80.108505 229.336487,79.940506 229.021683,79.468857 
	C226.257294,77.534515 223.615692,75.903816 220.813293,73.997223 
	C219.774719,73.140396 218.896896,72.559464 217.965881,71.653397 
	C216.985001,70.836174 216.057327,70.344086 214.932587,69.657394 
	C214.386536,69.447319 214.037537,69.431847 213.416840,69.278412 
	C212.754059,69.038383 212.363007,68.936333 211.894836,68.628212 
	C211.625092,68.195145 211.397202,68.012001 211.015381,67.563705 
	C209.955688,66.811096 209.014648,66.367523 207.956100,65.616089 
	C206.915588,64.848045 205.992599,64.387871 204.937973,63.611042 
	C200.752808,60.381973 196.715683,57.445827 192.611237,54.607010 
	C192.316910,54.403435 191.530319,54.911568 190.626434,55.039223 
	C188.563660,53.930122 186.847076,52.870590 185.039841,51.568668 
	C184.645615,51.207153 184.342056,51.088028 184.024277,50.984619 
	C184.010071,51.000336 184.021896,50.963356 183.928513,50.633682 
	C178.304184,46.773720 172.773239,43.243423 167.102692,39.411308 
	C161.995453,36.388500 157.027817,33.667515 152.030884,30.972343 
	C152.001572,30.998158 152.058014,30.948929 152.047302,30.582329 
	C150.946426,29.610292 149.856277,29.004854 148.596359,28.270662 
	C148.426605,28.141909 148.041748,27.958992 147.981842,27.625328 
	C144.659439,25.488604 141.396942,23.685541 137.879059,21.737795 
	C137.079987,21.425835 136.536316,21.258560 135.832413,20.902464 
	C135.422409,20.495325 135.202652,20.250101 134.984802,19.646833 
	C134.229645,18.990751 133.502609,18.665806 132.605362,18.228926 
	C132.435150,18.116993 132.080627,17.916222 132.015701,17.584484 
	C128.474167,14.827637 125.109413,12.218616 121.494835,10.020372 
	C116.027466,6.695336 111.552040,7.727371 106.726517,13.123970 
	C105.595184,14.037797 104.724533,14.811377 103.559998,15.592289 
	C102.789574,15.921988 102.313026,16.244352 101.524033,16.618336 
	C100.770195,17.077545 100.328804,17.485142 99.945061,17.896072 
	C100.002701,17.899406 99.889954,17.867594 99.663376,17.865784 
	C99.117256,17.964462 98.921593,18.178278 98.541885,18.570316 
	C97.839684,18.778156 97.445396,18.921103 96.788551,19.070368 
	C96.315475,19.337757 96.104958,19.598825 95.818413,20.007673 
	C95.742386,20.155453 95.712357,20.486477 95.475952,20.572620 
	C94.835075,20.948431 94.430595,21.238098 93.736832,21.393257 
	C91.956764,22.167086 90.465981,23.075420 88.883728,24.174679 
	C88.792259,24.365602 88.770378,24.788441 88.856735,24.885624 
	C88.943092,24.982805 88.798218,24.766918 88.501289,24.765678 
	C87.831909,24.897917 87.459457,25.031393 86.843391,25.194054 
	C86.284500,25.382025 86.092056,25.634468 86.003647,25.980942 
	C85.984840,25.981316 85.971870,26.016630 85.728622,26.076050 
	C85.349945,26.424057 85.214500,26.712646 84.901054,27.294098 
	C83.448372,28.694775 82.173698,29.802591 80.925446,30.959063 
	C80.951866,31.007717 80.926697,30.900240 80.595123,30.828758 
	C79.831810,30.878721 79.400055,31.000168 78.626274,31.095863 
	C76.830910,31.976051 75.377579,32.881992 73.584595,33.887707 
	C65.461349,38.946041 57.677750,43.904594 49.656044,48.894089 
	C49.102039,49.035839 48.859074,49.235420 48.450470,49.679829 
	C47.746094,50.160385 47.280285,50.484882 46.458298,50.836552 
	C43.336742,52.801746 40.571365,54.739777 37.535583,56.770050 
	C36.915131,57.012691 36.565090,57.163090 35.951180,57.294693 
	C35.495846,57.548416 35.304375,57.820938 34.861923,58.265469 
	C33.694851,59.210167 32.778759,59.982861 31.508984,60.818954 
	C25.725241,64.425835 20.295179,67.969315 14.624538,71.574326 
	C14.383955,71.635857 13.980985,71.926163 13.619906,71.934250 
	C11.709219,72.828918 10.202923,73.804916 8.600001,74.581467 
	C6.101116,75.792076 3.536667,76.867371 1.000000,78.000000 
	C1.000000,52.369831 1.000000,26.739662 1.000000,1.000000 
	C77.687531,1.000000 154.375107,1.000000 231.394257,1.330163 
	C231.552979,2.573770 231.380142,3.487213 231.186005,4.759471 
	C231.030685,6.065396 230.896652,7.012506 230.420975,8.008348 
	C229.500504,9.218626 228.171967,11.078613 228.482330,11.411759 
	C229.443039,12.443007 231.027649,12.893028 232.365601,13.607851 
	C232.361130,13.647018 232.439957,13.645395 232.313232,13.986694 
	C232.474625,15.210533 232.762756,16.093073 232.789764,17.143692 
	C232.016739,18.214687 231.504822,19.117603 230.583710,20.014683 
	C228.447601,20.668123 226.720657,21.327400 224.997162,21.993437 
	C225.000610,22.000195 224.987625,21.992868 224.598770,21.993725 
	C223.501740,23.379662 222.793579,24.764742 222.058838,26.365952 
	C222.032272,26.582081 221.807983,26.955400 221.507812,26.955402 
	C220.807327,26.976185 220.407028,26.996964 219.868927,26.698229 
	C219.169495,25.310316 218.607849,24.241919 217.478027,22.092733 
	C216.848236,24.193724 216.019699,25.442406 216.354431,26.087011 
	C218.521088,30.259377 216.949722,32.552944 212.998138,34.214664 
	C210.533997,35.250889 210.607864,40.716919 212.968979,43.277870 
	C213.259781,44.037468 213.529144,44.510773 213.906357,45.187637 
	C214.014206,45.391193 214.070999,45.848400 214.098038,46.087509 
	C214.245285,46.645004 214.462708,46.869026 214.974518,47.231056 
	C215.448410,47.760979 215.725067,48.058525 216.099304,48.517632 
	C216.373856,48.872112 216.590515,48.995277 217.139648,49.158318 
	C218.298920,50.157993 219.165405,51.048046 220.060577,52.291210 
	C221.061600,53.093353 222.033966,53.542381 223.003159,53.995705 
	C223.000000,54.000000 223.007538,53.993023 223.092712,54.230663 
	C223.455673,54.643169 223.733429,54.818035 224.250900,55.079277 
	C224.657928,55.450447 224.825256,55.735245 225.074646,56.252411 
	C225.347778,56.778191 225.615891,56.954456 226.197418,57.024010 
	C226.433792,57.034443 226.906967,57.030304 226.925690,57.269505 
	C226.944427,57.508705 227.003876,57.984879 227.096130,58.225349 
	C227.476135,58.637001 227.763901,58.808178 228.285980,59.060547 
	C228.814056,59.342354 228.977173,59.619774 229.091644,60.215061 
	C229.451065,60.636810 229.728485,60.817509 230.244507,61.078392 
	C230.656525,61.435585 230.829956,61.712597 231.018188,62.327240 
	C231.977249,63.494247 232.921478,64.323624 233.982086,65.334702 
	C234.271820,65.761871 234.502701,65.926895 234.942719,66.235764 
	C235.391388,66.645424 235.688416,66.830803 235.992706,67.007965 
	C236.000000,66.999741 235.982254,67.014137 235.979034,67.246872 
	C235.975800,67.479607 236.029556,67.942101 236.016022,68.304810 
	C236.690430,69.429146 237.378342,70.190773 238.037796,70.978745 
	C238.009323,71.005081 238.051056,70.940559 238.032745,71.302299 
	C238.693283,72.428986 239.372131,73.193932 240.027466,73.980530 
	C240.003937,74.002182 240.043884,73.954697 240.025482,74.315552 
	C240.690277,75.433640 241.373474,76.190872 242.028931,76.973900 
	C242.001221,76.999695 242.054672,76.948784 242.032532,77.341248 
	C243.008530,78.822357 244.006683,79.911003 245.002106,80.999924 
	C244.999390,81.000206 245.000351,80.994225 245.048584,81.206985 
	C245.227051,81.697357 245.434647,81.892776 245.830215,82.221985 
	C245.940826,82.437950 246.052109,82.910309 246.103531,83.139290 
	C246.302231,83.662064 246.528442,83.870003 246.987122,84.259811 
	C247.783310,85.319824 248.425964,86.112099 249.052689,87.284180 
	C249.987427,88.283844 251.131409,89.596794 251.853027,89.395523 
	C254.152435,88.754158 257.384979,88.739281 256.995575,84.727829 
	C257.118011,84.384087 257.188324,84.188148 257.617767,84.064896 
	C259.742767,86.241325 261.466919,88.366341 263.257263,90.434052 
	C265.862732,93.443169 269.066345,93.574287 271.082520,90.245621 
	C272.320740,88.201332 272.403046,85.456856 273.007050,82.762566 
	C272.999207,82.323029 272.988708,82.147720 272.983704,81.987106 
	C272.989136,82.001793 272.964172,81.974136 273.183716,81.916969 
	C273.685608,81.713287 273.881866,81.490807 274.260620,81.005814 
	C275.088226,79.889786 275.647186,78.960304 276.553345,78.028778 
	C277.594360,78.006989 278.288177,77.987251 278.993958,78.332611 
	C279.302521,80.894524 280.197876,81.896973 281.951569,79.815140 
	C282.864044,78.731972 283.334229,77.276230 284.351898,75.993423 
	C285.163788,75.980995 285.627960,75.963928 286.315765,76.037750 
	C286.793152,76.344482 286.963104,76.613312 287.074463,77.271278 
	C288.270721,78.832748 289.289917,81.427338 291.184784,77.964554 
	C291.396637,77.892067 291.843048,77.856659 292.141602,77.899857 
	C292.952850,77.688866 293.465607,77.434669 294.196838,77.094162 
	C294.415375,77.007866 294.879333,76.933289 295.133057,76.862793 
	C295.582672,76.511864 295.778534,76.231422 296.090515,75.693382 
	C296.749939,74.921219 297.293213,74.406647 298.179871,73.900345 
	C299.217438,73.356148 299.911621,72.803688 300.605804,72.251228 
	C300.046661,71.826004 299.487549,71.400772 298.952393,70.649292 
	C298.971069,69.886993 298.965820,69.450951 299.195221,68.861694 
	C299.009552,68.133926 298.589233,67.559357 298.064270,66.789795 
	C297.959625,66.594803 297.904877,66.155624 297.945984,65.763130 
	C297.287231,64.267235 296.587311,63.163830 295.666779,61.950745 
	C295.190033,61.608719 295.045502,61.320419 295.004578,60.670799 
	C294.632721,59.926228 294.268768,59.487011 293.942261,59.021954 
	C293.979675,58.996113 293.932983,59.074154 293.973511,58.805206 
	C294.029785,58.355881 294.045532,58.175499 294.383026,57.846451 
	C296.544159,56.346073 298.383606,54.994370 300.223022,53.642662 
	C299.931976,53.095493 299.640930,52.548321 299.349884,52.001148 
	C296.895691,52.001148 294.441528,52.001148 291.798889,51.748817 
	C290.806091,51.270317 290.001740,51.044147 289.107330,50.551826 
	C288.461700,49.875854 287.906189,49.466030 287.223022,48.796135 
	C287.068695,48.345058 287.042023,48.154057 286.945374,47.741924 
	C286.709778,47.241367 286.471954,47.053204 285.900940,46.819626 
	C285.409515,46.153664 285.179047,45.624378 284.975830,44.694630 
	C283.973877,43.209904 282.944672,42.125641 281.683411,41.026955 
	C281.451385,41.012527 280.987061,41.037121 280.929077,40.754631 
	C280.321899,39.957687 279.772675,39.443230 279.196228,38.621288 
	C278.775299,37.884739 278.381531,37.455673 277.882263,36.749771 
	C277.184052,35.982643 276.591309,35.492359 275.935303,34.745140 
	C275.594482,34.285034 275.316925,34.081863 275.048157,33.669582 
	C275.021454,33.157063 274.868408,32.934525 274.479523,32.461617 
	C273.978149,31.877050 273.595032,31.623739 273.144226,31.082497 
	C272.316864,29.668221 271.557220,28.541880 270.994324,27.095814 
	C272.954254,26.680840 274.717468,26.585587 276.569214,26.485550 
	C276.363190,25.857376 276.352448,25.395016 276.159607,25.299191 
	C270.863953,22.667891 266.458923,19.477341 264.125854,13.471628 
	C263.106354,10.847270 260.215424,7.514646 255.658661,9.858638 
	C253.294052,8.412075 251.235489,7.121163 249.056946,5.511755 
	C248.173218,4.928674 247.409515,4.664091 246.699524,4.290877 
	C246.753265,4.182247 246.592728,4.363853 246.710815,4.021291 
	C246.552597,2.785819 246.276291,1.892910 246.000000,1.000000 
	C337.240570,1.000000 428.481110,1.000000 520.000000,1.000000 
	C520.000000,53.020565 520.000000,105.041626 519.587769,157.516876 
	C516.862915,157.563889 514.550232,157.156708 512.202759,156.743423 
	C511.995422,158.424103 511.873871,159.409332 511.736603,160.521988 
	C507.035583,157.666153 496.553345,164.201248 492.594452,172.059448 
	C492.210876,174.162094 492.185974,176.067139 492.079102,178.191528 
	C491.997101,178.410889 491.953888,178.877258 491.615417,179.072083 
	C489.809723,184.576767 489.615631,190.654160 483.044983,192.935944 
	C483.029663,192.975143 483.110291,192.950958 483.023621,192.696350 
	C482.642822,192.254227 482.348694,192.066681 482.049561,191.923767 
	C482.044525,191.968384 482.134094,191.961563 482.078430,191.552948 
	C482.058899,189.771713 482.095032,188.399078 482.090881,187.026123 
	C482.050598,187.025787 482.067108,187.104630 482.400696,186.928528 
	C482.764008,185.513031 482.793701,184.273636 482.902527,182.813324 
	C482.981659,182.592392 483.039062,182.126602 483.359283,181.970520 
	C483.836395,180.889053 483.993286,179.963684 484.490265,178.841064 
	C485.495972,174.423660 486.161591,170.203461 486.870697,165.721588 
	C486.626221,165.270676 486.338196,165.081451 486.039062,164.930450 
	C486.027924,164.968689 486.106476,164.955475 486.028168,164.694626 
	C485.648010,164.251572 485.346130,164.069351 485.038574,163.926758 
	C485.032898,163.966370 485.110382,163.946198 485.020264,163.695770 
	C484.622009,163.350464 484.313873,163.255600 483.796082,163.076447 
	C483.586456,162.992172 483.135956,162.957047 483.036469,162.708832 
	C482.628876,162.363937 482.320740,162.267273 481.681396,162.090302 
	C480.901215,162.059204 480.452271,162.108429 479.617920,162.082428 
	C477.956390,162.007233 476.680237,162.007233 475.514435,162.007233 
	C476.099518,163.767044 476.632355,165.369797 477.094971,167.394974 
	C475.017975,175.492020 473.011230,183.166626 470.727570,190.955719 
	C469.948151,191.671097 469.445618,192.271957 468.547607,192.912262 
	C467.438110,191.346191 466.132202,189.736786 466.142731,188.136047 
	C466.160522,185.435669 466.967377,182.731369 467.524811,180.045822 
	C468.300964,176.306778 469.312439,172.609772 469.932983,168.846909 
	C470.736359,163.975525 466.752380,161.443008 460.306305,162.525040 
	C459.976013,165.116547 459.740051,167.710098 459.300598,170.268692 
	C458.233276,176.482666 457.071381,182.680374 455.696747,188.972351 
	C455.349579,189.366974 455.253235,189.673904 455.076111,190.194427 
	C454.995331,190.408035 454.952484,190.862762 454.646149,191.001801 
	C453.237274,191.814438 452.134735,192.488037 450.663788,192.950211 
	C452.203247,182.997177 454.116577,173.256638 456.016632,163.513504 
	C456.909149,158.936905 458.304779,154.361542 458.358459,149.772583 
	C458.376434,148.235916 454.928223,146.673477 453.105804,145.075592 
	C452.940796,144.930954 453.079987,144.439392 453.497345,144.070770 
	C463.900726,143.926834 467.514923,140.525253 468.039307,130.840775 
	C468.099670,129.726425 468.510071,128.629379 468.773041,127.527863 
	C471.853699,114.624123 461.995880,104.445129 448.968048,106.849228 
	C446.861847,107.237900 444.437164,106.645264 442.308929,106.007889 
	C438.419342,104.843002 431.267456,108.442307 431.114319,112.391754 
	C430.792084,120.702484 430.788208,129.044846 431.186554,137.348694 
	C431.270813,139.105972 433.318695,141.667831 435.018799,142.259979 
	C438.457153,143.457565 442.277252,143.559189 446.133057,144.461533 
	C446.867310,146.790558 448.114716,148.886536 447.812836,150.728607 
	C447.009521,155.630508 445.362457,160.387299 444.373383,165.267151 
	C442.773712,173.159439 440.867126,181.060913 440.257507,189.047607 
	C440.010986,192.276947 439.877930,197.672073 446.054749,198.383591 
	C447.864594,198.494614 449.492065,198.267731 451.340179,198.014404 
	C451.560791,197.987946 451.978424,197.836136 452.304535,197.845093 
	C453.791107,196.593307 454.951569,195.332581 456.370422,194.112701 
	C457.043823,194.448700 457.458771,194.743866 457.962738,195.297180 
	C458.353546,195.741013 458.655334,195.926727 458.962250,196.073364 
	C458.967346,196.034286 458.889374,196.045868 458.973022,196.303284 
	C459.355377,196.745819 459.654053,196.930939 459.960266,197.076141 
	C459.967773,197.036224 459.889282,197.057144 459.973785,197.317352 
	C460.376251,197.669373 460.694183,197.761185 461.215240,197.929169 
	C461.418335,198.005325 461.850403,198.043961 462.042786,198.392029 
	C464.186584,198.511612 466.138031,198.283142 468.052368,198.038239 
	C468.015289,198.021805 468.040161,198.099030 468.297028,198.016754 
	C468.737549,197.636581 468.921204,197.338669 469.065735,197.029144 
	C469.026611,197.017517 469.057281,197.093155 469.367004,196.966293 
	C470.491028,195.582443 471.305359,194.325455 472.308899,193.051971 
	C472.498108,193.035507 472.877533,193.054108 472.968811,193.310181 
	C473.358124,193.758621 473.656189,193.950989 473.971313,194.363037 
	C473.988373,194.582733 474.158020,194.989456 474.168701,195.324219 
	C475.432159,196.474548 476.684998,197.290115 477.960449,198.063843 
	C477.983093,198.021988 477.898987,198.066483 478.099365,198.414307 
	C482.693237,199.891174 486.417175,198.384872 488.531586,194.716919 
	C490.588196,191.149292 491.618317,186.989914 493.054626,183.060577 
	C493.010071,183.030777 493.061676,183.124710 493.318359,183.035812 
	C493.764282,182.648163 493.953522,182.349411 494.482666,182.192566 
	C499.250885,183.751236 503.679230,185.168045 508.447174,186.693512 
	C502.675751,191.879913 497.467133,193.140320 494.191711,190.505554 
	C491.972839,195.134079 492.238861,198.057480 494.960297,198.952011 
	C499.438324,200.423950 510.080658,196.695709 512.719238,192.730637 
	C515.368347,188.749725 514.000244,184.731247 509.434235,182.986237 
	C507.182526,182.125687 504.860779,181.369415 502.768585,180.209106 
	C501.919128,179.738022 500.868256,177.951385 501.151520,177.388275 
	C503.024048,173.665497 505.234589,170.112732 507.335052,166.504608 
	C507.885345,166.759872 508.435608,167.015137 508.985901,167.270401 
	C508.653748,169.921417 507.833801,172.638916 508.182434,175.197159 
	C508.374756,176.608566 510.355225,178.096008 511.875702,178.863068 
	C512.568359,179.212479 514.873779,178.130203 515.119080,177.303238 
	C516.340210,173.186111 517.036377,168.916656 518.102600,164.748199 
	C518.441345,163.423691 519.351379,162.245270 520.000000,161.000000 
M348.058563,196.569397 
	C348.373077,196.661606 348.687592,196.753799 349.425507,196.993271 
	C349.425507,196.993271 349.871460,197.039078 350.253174,197.734100 
	C352.204254,197.511978 354.155365,197.289856 356.021942,197.028061 
	C356.021942,197.028061 356.062531,197.112122 356.713287,197.020508 
	C357.509247,196.369064 358.305176,195.717621 359.018982,195.013138 
	C359.018982,195.013138 359.064758,195.099548 359.681152,194.865814 
	C360.159149,193.931946 360.637146,192.998077 361.036316,192.038818 
	C361.036316,192.038818 361.049408,192.120575 361.573273,191.950180 
	C361.766968,191.649185 361.960663,191.348190 362.775421,191.329468 
	C364.807343,193.570282 366.839264,195.811096 369.266571,198.739868 
	C371.225830,198.508453 373.185089,198.277039 375.575867,198.004776 
	C375.575867,198.004776 375.978943,197.845306 376.673920,197.818146 
	C379.172333,192.723511 381.753601,187.666351 384.092133,182.499344 
	C384.508942,181.578445 383.919037,180.201889 383.793976,179.035736 
	C383.115448,179.879440 382.288788,180.643463 381.784424,181.580872 
	C379.764069,185.336075 377.843658,189.145065 375.099976,192.951004 
	C374.416656,191.002747 372.931000,188.935287 373.199463,187.128464 
	C374.025269,181.571030 375.505371,176.109467 376.763367,170.618027 
	C377.918610,165.575180 373.101318,160.288559 368.077179,160.983978 
	C366.462616,161.207443 364.785095,160.975739 362.779572,160.279022 
	C361.162933,160.484085 359.546295,160.689163 357.282654,160.981354 
	C356.853882,161.042633 356.425140,161.103912 355.464386,161.079300 
	C355.274719,161.370407 355.085022,161.661499 354.972076,161.970795 
	C354.972076,161.970795 354.957611,161.893265 354.442444,162.060791 
	C354.258667,162.360275 354.074890,162.659744 353.968506,162.970001 
	C353.968506,162.970001 353.957428,162.892639 353.443054,163.061081 
	C353.257996,163.360062 353.072937,163.659042 352.965668,163.968414 
	C352.965668,163.968414 352.956085,163.890488 352.438324,164.055817 
	C352.256195,164.357117 352.074036,164.658417 351.969421,164.968918 
	C351.969421,164.968918 351.957550,164.891785 351.445953,165.065216 
	C351.261444,165.363541 351.076935,165.661850 350.969238,165.971878 
	C350.969238,165.971878 350.958466,165.894882 350.439636,166.054962 
	C350.255219,166.355011 350.070801,166.655075 349.965668,166.968719 
	C349.965668,166.968719 349.945343,166.890900 349.432159,167.063477 
	C349.337830,167.376358 349.243469,167.689240 348.984131,168.411377 
	C348.984131,168.411377 348.950409,168.851364 348.440094,169.039459 
	C348.351379,169.355530 348.262665,169.671585 348.021057,170.422791 
	C348.021057,170.422791 347.960663,170.880035 347.305542,171.197601 
	C346.834442,172.778381 346.363312,174.359177 345.974762,175.982101 
	C345.974762,175.982101 345.934570,175.898605 345.294922,176.205124 
	C345.250519,177.127411 345.206116,178.049698 345.002625,179.404694 
	C345.002625,179.404694 344.957916,179.863556 344.311951,180.181213 
	C344.264679,181.113937 344.217377,182.046661 344.007050,183.721756 
	C343.391571,185.815155 342.776062,187.908554 341.860626,190.611679 
	C340.574219,191.461212 339.287781,192.310760 337.348480,193.011337 
	C336.844391,192.667053 336.340332,192.322769 335.992706,191.122833 
	C336.699890,186.438675 337.407074,181.754517 338.026917,177.019257 
	C338.026917,177.019257 338.073883,177.108856 338.662598,176.856201 
	C338.717407,176.247513 338.772217,175.638809 338.978729,174.377350 
	C339.028015,173.933792 339.077301,173.490219 339.740692,172.748749 
	C339.864014,171.855347 339.987366,170.961929 340.023895,170.016891 
	C340.023895,170.016891 340.072540,170.105392 340.660706,169.853531 
	C340.718567,169.245560 340.776428,168.637573 340.983063,167.378220 
	C341.026886,166.935608 341.070679,166.492996 341.701782,165.817368 
	C341.744781,165.211563 341.787781,164.605743 342.006561,163.302551 
	C342.373596,162.213333 342.740601,161.124130 343.821686,159.693069 
	C345.912048,159.251053 348.002411,158.809036 351.474304,158.074905 
	C347.988708,157.064514 346.067719,156.507660 344.080444,155.549347 
	C344.080444,155.549347 344.054993,155.143234 344.757996,154.706970 
	C344.527924,152.114548 344.297852,149.522141 344.003601,147.000870 
	C344.003601,147.000870 344.078918,146.941147 343.923218,146.443863 
	C343.623383,146.350266 343.323578,146.256653 342.586761,145.998306 
	C342.586761,145.998306 342.122711,145.945831 341.803711,145.309662 
	C340.879303,145.261292 339.954865,145.212936 338.281921,145.004440 
	C337.521576,145.056717 336.761261,145.108994 335.167389,145.028809 
	C334.500031,148.352524 333.832672,151.676239 333.004639,155.421982 
	C333.004639,155.421982 332.957428,155.871094 332.064240,155.973877 
	C328.778839,155.973877 325.493469,155.973877 322.195465,155.973877 
	C321.999817,161.271530 321.999817,161.271530 330.571899,161.130356 
	C330.770203,161.753845 330.968506,162.377335 330.812531,163.694473 
	C328.833618,171.785217 326.854706,179.875961 324.235443,188.284424 
	C323.364105,189.535095 322.727386,191.183975 321.533600,191.887253 
	C320.816559,192.309647 318.213806,191.310379 318.190063,190.858994 
	C317.908600,185.507141 317.650421,180.066574 320.351501,175.138168 
	C322.252808,171.669052 320.914337,164.156097 317.685242,161.243271 
	C315.751648,161.481735 313.818054,161.720215 311.299805,161.989197 
	C310.909729,161.977951 310.519653,161.966721 309.772980,161.268021 
	C306.318481,159.213867 303.043732,159.743896 299.262543,162.203568 
	C291.622803,168.953033 288.511536,177.651718 289.127960,187.564133 
	C289.357697,191.258606 290.128723,195.170181 294.542664,197.127853 
	C299.176575,199.183105 301.950562,197.158234 304.636688,193.883148 
	C305.467438,192.870239 306.557037,192.069611 307.537354,191.163254 
	C309.536987,195.422119 311.945496,199.720520 317.154968,198.741653 
	C319.711975,198.261200 321.758911,195.066208 324.685760,193.026245 
	C325.114929,192.965927 325.544098,192.905594 326.554077,193.097626 
	C326.992645,193.747910 327.431183,194.398209 327.981812,195.831360 
	C331.277802,196.969498 334.337189,200.077942 338.577698,197.000473 
	C338.577698,197.000473 338.999207,196.851486 339.700012,196.810684 
	C340.845947,194.558578 341.991882,192.306473 343.853271,190.048981 
	C344.622253,191.363098 345.391205,192.677231 346.084137,194.526611 
	C346.372314,194.721069 346.660492,194.915527 346.966827,195.032227 
	C346.966827,195.032227 346.888000,195.045044 347.056061,195.559708 
	C347.357758,195.741486 347.659454,195.923264 347.976898,196.028503 
	C347.976898,196.028503 347.901001,196.047134 348.058563,196.569397 
M383.059113,168.560226 
	C383.358521,168.744125 383.657928,168.928024 383.977631,169.031860 
	C383.977631,169.031860 383.900574,169.061569 384.146729,169.673584 
	C385.079315,170.149734 386.011902,170.625870 386.974213,171.023788 
	C386.974213,171.023788 386.894989,171.050766 387.069153,171.553146 
	C387.376434,171.646851 387.683716,171.740570 388.631195,172.007339 
	C389.071136,172.046463 389.511108,172.085587 390.231934,172.737656 
	C391.070984,172.737656 391.910034,172.737656 392.988525,172.737656 
	C392.256653,171.642944 391.688812,170.793549 391.021179,169.112183 
	C391.318787,167.209610 391.257324,165.157257 391.977448,163.430939 
	C394.780304,156.711853 405.626343,152.301666 413.681061,154.256821 
	C418.804657,155.500458 423.137390,157.660233 425.025757,162.986267 
	C427.608582,170.271072 425.419525,175.048096 416.118225,180.961853 
	C413.169830,181.378769 410.221405,181.795685 406.770599,182.283661 
	C407.721191,179.356323 408.377014,177.376328 409.006256,175.387939 
	C409.686127,173.239700 410.480957,171.115646 410.982758,168.925400 
	C413.073944,159.797760 409.629944,156.135193 401.021057,157.333054 
	C400.813629,159.696655 400.908539,161.973511 400.376801,164.093170 
	C398.062958,173.317276 395.480408,182.473953 393.166290,191.697998 
	C391.556305,198.115356 393.242523,203.009995 397.442047,205.094223 
	C401.278992,206.998520 402.696289,206.058334 403.049255,201.854385 
	C403.465027,196.901581 404.214752,191.976822 404.699036,188.024490 
	C409.575897,186.475739 413.304688,185.291565 417.826508,184.015244 
	C426.230377,181.714935 434.602509,179.059250 436.420837,168.984344 
	C437.587646,162.519241 438.755829,155.669769 432.017120,150.855103 
	C427.427307,147.575775 423.724640,142.812546 417.330261,142.717529 
	C416.572754,142.706268 415.830536,141.668488 415.835266,141.023987 
	C420.633636,139.121201 422.754547,135.265228 422.938690,130.424606 
	C423.110657,125.902534 422.360626,121.328423 422.731506,116.836250 
	C423.026062,113.268867 420.175140,106.934029 416.966339,106.023560 
	C413.165710,104.945152 408.863220,108.083389 408.071838,113.064423 
	C407.505432,116.629463 407.915741,120.344322 407.436432,123.931686 
	C407.286560,125.053574 405.657959,125.977875 404.706268,126.992622 
	C403.818573,125.986107 402.350464,125.076630 402.162659,123.953110 
	C401.757965,121.532677 401.803802,118.982132 402.041260,116.521118 
	C402.470795,112.069344 398.818146,106.357651 395.068634,105.994522 
	C391.180206,105.617958 387.086639,110.358391 387.010223,115.391258 
	C386.964722,118.386414 387.310303,121.419952 386.949860,124.371490 
	C386.050415,131.736938 388.098633,137.521072 395.273804,141.745895 
	C395.802612,142.069077 396.331421,142.392242 396.860229,142.715424 
	C396.507080,143.129730 396.217316,143.629959 395.790741,143.944809 
	C392.192993,146.600220 388.570251,149.221725 384.442047,152.046921 
	C384.255280,152.349884 384.068512,152.652832 383.963196,152.974091 
	C383.963196,152.974091 383.933899,152.895950 383.304230,153.197266 
	C382.919373,154.469696 382.534515,155.742126 382.005707,157.434113 
	C382.005707,157.434113 381.959381,157.875244 381.235626,158.286270 
	C381.476410,160.902008 381.717163,163.517746 382.003418,166.577957 
	C382.003418,166.577957 382.159119,166.996735 382.069855,167.532944 
	C382.362183,167.726120 382.654510,167.919296 382.963989,168.033920 
	C382.963989,168.033920 382.884735,168.047348 383.059113,168.560226 
M354.007324,113.450386 
	C351.784119,108.447952 346.357117,104.985672 342.242004,105.944427 
	C336.203857,107.351234 334.009552,110.401428 333.987701,117.321899 
	C333.970917,122.642776 334.249084,127.995911 333.753326,133.274323 
	C333.336578,137.711319 338.369324,144.519562 342.101898,142.684311 
	C347.142090,140.206131 350.813141,140.495895 355.602905,142.830780 
	C360.438293,145.187897 362.358185,140.406891 364.494476,136.710312 
	C365.521576,138.370239 366.010773,139.667557 366.919983,140.513336 
	C368.337463,141.831909 370.629791,144.139069 371.508942,143.738617 
	C373.957794,142.623184 377.124023,140.670166 377.730804,138.431091 
	C378.950836,133.929016 378.795471,129.021301 378.929688,124.273117 
	C378.988159,122.203621 377.924500,120.069786 378.132996,118.043625 
	C378.642609,113.090958 376.925232,107.089653 373.618042,106.329407 
	C369.279236,105.332008 364.672028,105.113098 361.694031,109.769814 
	C359.971558,112.463249 358.124023,115.076729 356.218353,117.895470 
	C355.516785,116.704208 354.783173,115.458565 354.007324,113.450386 
M513.043274,131.278763 
	C509.608368,123.919708 506.046173,116.616547 502.781738,109.182640 
	C500.469788,103.917809 495.816284,105.734703 492.518433,106.317810 
	C489.772919,106.803261 486.544891,109.510536 485.219696,112.098579 
	C481.959137,118.466408 479.310638,125.219353 477.170319,132.053650 
	C476.317169,134.777908 476.338531,139.229553 477.984192,140.887726 
	C479.747345,142.664337 484.082428,142.947678 486.950958,142.317245 
	C491.143890,141.395752 495.030090,139.078568 498.932129,137.405518 
	C502.799011,143.842606 507.609009,145.545380 513.279236,141.972122 
	C516.766785,139.774338 516.825439,137.402451 513.043274,131.278763 
M295.233093,142.998901 
	C299.893494,141.175949 304.553925,139.352997 309.214325,137.530029 
	C309.161591,138.024277 309.108826,138.518524 309.056061,139.012772 
	C311.569153,140.625076 313.895630,142.761520 316.635468,143.736923 
	C321.811096,145.579498 326.859955,140.538528 324.969543,135.309128 
	C322.114807,127.412109 318.766418,119.686028 315.426880,111.974304 
	C313.038269,106.458351 308.410431,104.990021 302.859894,106.023102 
	C298.365723,106.859566 296.297607,109.919304 294.818787,114.176575 
	C292.782898,120.037613 289.938019,125.628365 287.281860,131.260468 
	C284.033813,138.147507 286.792542,142.797470 295.233093,142.998901 
M230.198288,66.596001 
	C230.202698,66.209213 230.207108,65.822418 230.211533,65.435631 
	C230.051071,65.434074 229.751160,65.421646 229.750320,65.432533 
	C229.720291,65.817451 229.718353,66.204544 230.198288,66.596001 
z"/>
<path fill="#FDFD0A" opacity="1.000000" stroke="none" 
	d="
M279.223419,38.928776 
	C279.772675,39.443230 280.321899,39.957687 280.975861,40.940712 
	C281.188263,41.650654 281.357941,41.834892 281.806152,42.233536 
	C282.266449,42.898838 282.510193,43.292603 282.275085,43.766708 
	C276.254181,43.882084 270.712158,43.917110 265.102600,43.949654 
	C265.035126,43.947163 265.052643,43.813206 264.976501,43.648365 
	C264.766663,43.273220 264.585083,43.119385 264.120239,42.810272 
	C263.249207,42.081249 262.613647,41.563969 261.888672,40.809925 
	C261.527222,40.384743 261.255219,40.196320 260.893494,39.679691 
	C258.546936,36.879288 256.290039,34.407089 253.907272,31.653915 
	C252.857986,30.580343 251.934540,29.787748 250.968933,28.681911 
	C250.283600,27.914503 249.640427,27.460339 248.862762,26.695406 
	C245.534851,23.898972 242.341415,21.413303 239.049271,18.602383 
	C236.780350,16.733223 234.610153,15.189311 232.439957,13.645397 
	C232.439957,13.645395 232.361130,13.647018 232.273605,13.264416 
	C231.711594,11.241079 231.237106,9.600346 230.762604,7.959614 
	C230.896652,7.012506 231.030685,6.065396 231.490845,4.622325 
	C232.544647,3.084244 233.272324,2.042123 234.000000,1.000001 
	C236.370560,1.000000 238.741104,1.000000 241.749817,1.260986 
	C243.789566,2.469264 245.191147,3.416556 246.592728,4.363851 
	C246.592728,4.363853 246.753265,4.182247 246.780533,4.583530 
	C247.190750,5.469392 247.573685,5.953971 248.020798,6.704865 
	C248.674957,7.319911 249.264938,7.668643 250.116577,8.203304 
	C251.592026,9.567566 252.805801,10.745900 254.160828,12.195731 
	C254.866837,12.644361 255.431580,12.821491 256.318451,13.105774 
	C261.359558,17.947132 266.078583,22.681341 270.797577,27.415543 
	C271.557220,28.541880 272.316864,29.668221 273.209351,31.453403 
	C273.760712,32.339119 274.179230,32.565994 274.597778,32.792870 
	C274.868408,32.934525 275.021454,33.157063 275.115082,33.925079 
	C275.448395,34.593815 275.723480,34.797943 275.998596,35.002071 
	C276.591309,35.492359 277.184052,35.982643 277.903168,37.062561 
	C278.427521,38.077721 278.825470,38.503250 279.223419,38.928776 
z"/>
<path fill="#291A68" opacity="1.000000" stroke="none" 
	d="
M491.953888,178.877258 
	C491.953888,178.877258 491.997101,178.410889 492.332764,177.900421 
	C492.763306,175.547241 492.858215,173.704544 492.953156,171.861832 
	C496.553345,164.201248 507.035583,157.666153 511.736603,160.521988 
	C511.873871,159.409332 511.995422,158.424103 512.202759,156.743423 
	C514.550232,157.156708 516.862915,157.563889 519.587769,157.985535 
	C520.000000,158.750000 520.000000,159.500000 520.000000,160.625000 
	C519.351379,162.245270 518.441345,163.423691 518.102600,164.748199 
	C517.036377,168.916656 516.340210,173.186111 515.119080,177.303238 
	C514.873779,178.130203 512.568359,179.212479 511.875702,178.863068 
	C510.355225,178.096008 508.374756,176.608566 508.182434,175.197159 
	C507.833801,172.638916 508.653748,169.921417 508.985901,167.270401 
	C508.435608,167.015137 507.885345,166.759872 507.335052,166.504608 
	C505.234589,170.112732 503.024048,173.665497 501.151520,177.388275 
	C500.868256,177.951385 501.919128,179.738022 502.768585,180.209106 
	C504.860779,181.369415 507.182526,182.125687 509.434235,182.986237 
	C514.000244,184.731247 515.368347,188.749725 512.719238,192.730637 
	C510.080658,196.695709 499.438324,200.423950 494.960297,198.952011 
	C492.238861,198.057480 491.972839,195.134079 494.191711,190.505554 
	C497.467133,193.140320 502.675751,191.879913 508.447174,186.693512 
	C503.679230,185.168045 499.250885,183.751236 494.233490,182.292084 
	C493.450165,182.541367 493.255920,182.833038 493.061676,183.124710 
	C493.061676,183.124710 493.010071,183.030777 492.765564,182.816315 
	C492.332001,181.360321 492.142944,180.118790 491.953888,178.877258 
z"/>
<path fill="#FAD809" opacity="1.000000" stroke="none" 
	d="
M1.000000,108.000000 
	C1.000000,105.957680 1.000000,103.915367 1.250330,101.436523 
	C1.667548,100.999962 1.834435,100.999916 2.194970,101.239502 
	C5.369125,99.342102 8.349631,97.205093 11.211687,95.153008 
	C8.080162,93.388359 7.911681,92.706390 10.349763,88.955215 
	C11.473486,87.273590 12.229486,85.634361 13.368521,83.995605 
	C14.516572,83.991165 15.281590,83.986244 16.043301,84.367050 
	C17.551455,89.672279 20.776920,84.915398 23.132685,86.168625 
	C21.435518,88.802856 22.930246,89.085381 25.153114,89.348389 
	C25.544744,91.463654 25.781593,93.228516 25.607719,94.995728 
	C18.579473,94.498161 14.915709,99.606575 10.370670,102.962662 
	C9.803840,103.381218 10.097158,104.964638 9.615334,106.003410 
	C7.494263,106.665977 5.749778,107.333450 3.629638,108.003922 
	C2.502655,108.004616 1.751328,108.002304 1.000000,108.000000 
z"/>
<path fill="#F0A739" opacity="1.000000" stroke="none" 
	d="
M0.999999,212.000000 
	C1.000000,208.637894 1.000000,205.275803 1.414229,201.457138 
	C4.549734,199.998306 7.271009,198.996033 10.275200,197.798492 
	C12.181807,195.833817 13.759050,194.693192 14.861217,198.147873 
	C16.141441,198.897873 17.573206,199.432281 19.142029,200.270813 
	C20.408972,202.033295 21.853144,203.313110 18.680656,204.361404 
	C17.016212,204.911377 16.219421,206.430511 19.000853,207.267517 
	C18.950136,207.942978 18.911180,208.327438 18.649807,208.800446 
	C18.427393,208.889008 18.006571,209.117355 17.685379,209.015518 
	C16.919479,208.854340 16.474773,208.794998 15.874194,208.485992 
	C14.890633,207.787292 14.062944,207.338242 13.151356,206.629242 
	C12.978596,206.016815 12.889736,205.664352 12.841946,204.985718 
	C12.511306,204.257858 12.139597,203.856155 11.767889,203.454437 
	C11.523987,203.999802 11.280084,204.545181 10.811064,205.336700 
	C9.053033,207.416321 7.520121,209.249771 5.618157,211.039856 
	C3.832737,211.330978 2.416367,211.665482 0.999999,212.000000 
M4.702723,207.841080 
	C4.962151,208.062790 5.221578,208.284500 5.481008,208.506210 
	C5.607160,208.060043 5.733313,207.613876 5.859462,207.167709 
	C5.539176,207.243347 5.218891,207.318970 4.702723,207.841080 
z"/>
<path fill="#F6B138" opacity="1.000000" stroke="none" 
	d="
M9.992283,197.993759 
	C7.271009,198.996033 4.549734,199.998306 1.414229,201.000290 
	C1.000000,194.978851 1.000000,188.957703 1.369909,182.468811 
	C2.479546,181.991348 3.219276,181.981644 4.193467,182.247467 
	C5.329502,183.361908 6.762167,184.054672 7.040235,185.065170 
	C8.211896,189.323074 9.039068,193.675751 9.992283,197.993759 
z"/>
<path fill="#F9CC38" opacity="1.000000" stroke="none" 
	d="
M1.000001,152.000000 
	C1.000000,148.307098 1.000000,144.614182 1.401054,140.458984 
	C3.882095,140.323929 5.962083,140.651169 8.373316,141.037231 
	C9.453569,142.717270 10.202577,144.338486 11.297159,146.006470 
	C15.108713,146.881256 13.046371,149.227890 12.977229,151.294159 
	C11.632257,152.379959 10.316813,153.190506 8.619007,153.994431 
	C5.824432,153.325195 3.412217,152.662598 1.000001,152.000000 
z"/>
<path fill="#FBBE2D" opacity="1.000000" stroke="none" 
	d="
M1.000001,152.468262 
	C3.412217,152.662598 5.824432,153.325195 8.418770,154.254761 
	C7.524219,155.263367 6.447545,156.004990 6.156520,156.205444 
	C7.759410,158.601486 8.891862,160.294296 10.019084,162.241592 
	C10.011627,162.665726 10.009399,162.835403 9.635330,163.012741 
	C7.842238,164.685486 6.420989,166.350555 4.660743,168.060593 
	C3.214498,169.070374 2.107248,170.035187 0.999999,171.000000 
	C1.000000,164.978851 1.000000,158.957703 1.000001,152.468262 
z"/>
<path fill="#FDCC46" opacity="1.000000" stroke="none" 
	d="
M0.999999,171.458069 
	C2.107248,170.035187 3.214498,169.070374 4.723369,168.419250 
	C5.749393,169.989258 6.765261,171.198639 6.922709,172.511002 
	C7.346814,176.045975 9.176302,174.812302 11.121447,174.253387 
	C11.829009,175.354813 12.414060,176.177444 13.201031,177.270721 
	C14.276125,177.715912 15.149300,177.890457 15.995897,178.348404 
	C15.668629,179.082565 15.367938,179.533310 14.725831,179.846619 
	C10.909278,180.463440 7.434142,181.217697 3.959005,181.971939 
	C3.219276,181.981644 2.479546,181.991348 1.369909,182.000534 
	C1.000000,178.638718 1.000000,175.277420 0.999999,171.458069 
z"/>
<path fill="#EAB915" opacity="1.000000" stroke="none" 
	d="
M1.000000,132.000000 
	C1.000000,128.969406 1.000000,125.938812 1.289626,122.334503 
	C2.364385,121.528610 3.149519,121.296425 4.053753,121.311714 
	C4.470208,122.019768 4.767562,122.480339 5.042397,123.300644 
	C3.596659,127.637032 8.607964,125.714203 8.876318,128.293900 
	C8.586961,129.352600 8.051208,130.588058 8.345453,130.835144 
	C9.776050,132.036499 11.428882,132.973221 12.999271,134.363495 
	C11.672042,136.146545 10.348570,137.569717 9.006071,138.620071 
	C7.593817,134.542267 5.792440,131.359100 1.000000,132.000000 
z"/>
<path fill="#FBEAC5" opacity="1.000000" stroke="none" 
	d="
M6.995847,226.967468 
	C5.254226,226.639252 3.512607,226.311050 1.385494,225.991425 
	C1.000000,225.555557 1.000000,225.111099 1.371490,224.317810 
	C4.196780,221.340561 8.501419,220.396362 9.342426,216.052277 
	C11.454020,218.073212 13.225725,220.032990 14.855445,222.325134 
	C13.848394,226.173996 11.150384,226.873062 8.067795,226.981003 
	C8.067795,226.981003 8.037668,226.997269 8.005714,226.738693 
	C7.816645,226.216476 7.659530,225.952835 7.502417,225.689194 
	C7.333562,226.115280 7.164706,226.541382 6.995847,226.967468 
z"/>
<path fill="#FEEE0A" opacity="1.000000" stroke="none" 
	d="
M12.985487,83.995125 
	C12.229486,85.634361 11.473486,87.273590 10.040513,88.914032 
	C8.241546,88.609947 7.119550,88.304672 5.727133,88.187912 
	C3.971140,89.584290 2.485570,90.792145 1.000000,92.000000 
	C1.000000,88.638710 1.000000,85.277428 1.255649,81.453880 
	C1.681668,80.986229 1.852038,80.980843 2.008782,80.984497 
	C1.995157,80.993553 2.014983,80.963997 2.140846,81.279221 
	C2.796919,82.642929 3.327130,83.691414 3.945587,84.914413 
	C5.185143,83.755402 6.104263,82.896004 7.398431,82.032654 
	C9.510816,82.684166 11.248151,83.339645 12.985487,83.995125 
z"/>
<path fill="#FDE22D" opacity="1.000000" stroke="none" 
	d="
M1.000000,132.444168 
	C5.792440,131.359100 7.593817,134.542267 8.763423,138.788391 
	C8.373891,139.879166 8.207981,140.428787 8.042070,140.978409 
	C5.962083,140.651169 3.882095,140.323929 1.401054,139.998352 
	C1.000000,137.629440 1.000000,135.258896 1.000000,132.444168 
z"/>
<path fill="#FADE1F" opacity="1.000000" stroke="none" 
	d="
M3.934652,121.064240 
	C3.149519,121.296425 2.364385,121.528610 1.289626,121.880402 
	C1.000000,117.643097 1.000000,113.286201 1.000000,108.464645 
	C1.751328,108.002304 2.502655,108.004616 3.630827,108.259430 
	C4.009519,108.682266 4.011367,108.852600 4.229580,109.311508 
	C4.968814,111.397369 5.491683,113.194664 5.978221,115.335648 
	C5.631244,116.773560 5.320600,117.867775 4.756600,119.139893 
	C4.313713,119.899940 4.124182,120.482094 3.934652,121.064240 
z"/>
<path fill="#F9E734" opacity="1.000000" stroke="none" 
	d="
M1.000000,78.333328 
	C3.536667,76.867371 6.101116,75.792076 8.600001,74.581467 
	C10.202923,73.804916 11.709219,72.828918 13.635276,72.307022 
	C13.701040,75.148064 13.390356,77.624428 13.236376,78.851753 
	C9.928808,78.862114 7.459575,77.701950 4.667606,80.004066 
	C3.573286,80.342773 2.794134,80.653381 2.014983,80.963997 
	C2.014983,80.963997 1.995157,80.993553 1.897854,80.767296 
	C1.586460,80.282646 1.319610,80.102295 1.000000,80.000000 
	C1.000000,79.555557 1.000000,79.111107 1.000000,78.333328 
z"/>
<path fill="#FDF4DD" opacity="1.000000" stroke="none" 
	d="
M9.002539,215.991104 
	C8.501419,220.396362 4.196780,221.340561 1.371490,223.984482 
	C1.000000,222.611115 1.000000,221.222214 1.325880,219.385010 
	C2.126083,218.312469 2.600407,217.688232 3.406495,217.058090 
	C4.476643,216.701981 5.215027,216.351776 5.970582,215.675842 
	C5.975093,214.915375 5.962432,214.480667 6.308736,214.035583 
	C7.445980,214.680527 8.224259,215.335815 9.002539,215.991104 
z"/>
<path fill="#F8D80B" opacity="1.000000" stroke="none" 
	d="
M1.000000,92.449982 
	C2.485570,90.792145 3.971140,89.584290 5.726850,88.557671 
	C5.255301,91.571358 4.588239,94.426453 3.739051,97.226295 
	C3.340886,98.539078 2.591525,99.745338 2.001323,100.999878 
	C1.834435,100.999916 1.667548,100.999962 1.250330,101.000000 
	C1.000000,98.299988 1.000000,95.599968 1.000000,92.449982 
z"/>
<path fill="#FBEAC5" opacity="1.000000" stroke="none" 
	d="
M5.949772,214.045944 
	C5.962432,214.480667 5.975093,214.915375 5.607775,215.651215 
	C4.124961,216.297714 3.022126,216.643097 1.689111,216.962875 
	C1.458931,216.937271 1.000000,217.000000 1.000000,217.000000 
	C1.000000,215.611115 1.000000,214.222214 0.999999,212.416656 
	C2.416367,211.665482 3.832737,211.330978 5.507624,211.300537 
	C5.827352,212.418396 5.888562,213.232162 5.949772,214.045944 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M246.710815,4.021288 
	C245.191147,3.416556 243.789566,2.469264 242.193985,1.260986 
	C243.066666,1.000000 244.133347,1.000000 245.600006,1.000000 
	C246.276291,1.892910 246.552597,2.785819 246.710815,4.021288 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M233.666672,1.000001 
	C233.272324,2.042123 232.544647,3.084244 231.512115,4.263511 
	C231.380142,3.487213 231.552979,2.573770 231.862915,1.330163 
	C232.444443,1.000000 232.888901,1.000000 233.666672,1.000001 
z"/>
<path fill="#F4C674" opacity="1.000000" stroke="none" 
	d="
M1.000000,217.333328 
	C1.000000,217.000000 1.458931,216.937271 1.979062,216.971497 
	C2.691038,217.025131 2.882883,217.044571 3.074729,217.063995 
	C2.600407,217.688232 2.126083,218.312469 1.325880,218.968353 
	C1.000000,218.555557 1.000000,218.111099 1.000000,217.333328 
z"/>
<path fill="#F8D80B" opacity="1.000000" stroke="none" 
	d="
M1.000000,80.250000 
	C1.319610,80.102295 1.586460,80.282646 1.911479,80.758240 
	C1.852038,80.980843 1.681668,80.986229 1.255649,80.995811 
	C1.000000,81.000000 1.000000,80.500000 1.000000,80.250000 
z"/>
<path fill="#2A186B" opacity="1.000000" stroke="none" 
	d="
M272.043365,143.130875 
	C272.997223,144.749496 273.566986,146.924438 274.969727,147.892944 
	C282.027344,152.765839 285.118134,159.220856 284.040375,167.751587 
	C283.505554,171.984955 284.039398,176.537720 282.670044,180.448914 
	C277.170319,196.157425 267.569458,208.538086 245.221817,206.152161 
	C245.348999,204.351883 245.674530,203.252579 246.429626,202.074799 
	C249.775162,201.449585 252.691116,200.902832 255.607056,200.356110 
	C259.007629,198.128937 263.239655,196.564835 265.647186,193.545776 
	C271.958862,185.630936 275.815887,176.725143 273.107758,166.077408 
	C271.457245,159.588074 267.492035,156.058029 261.122620,154.579071 
	C249.744110,151.936981 242.589584,156.123566 237.309937,165.418686 
	C236.941208,166.067841 236.853073,167.121460 237.092911,167.832306 
	C238.951523,173.341171 235.890549,172.314987 232.234283,170.713318 
	C231.054901,169.340546 230.359528,168.456436 229.523575,167.283783 
	C228.944000,164.322769 228.504990,161.650269 228.374741,158.808868 
	C229.203537,157.075455 229.723557,155.510925 230.243591,153.946396 
	C234.901184,147.823685 240.594574,143.460464 248.627426,142.931442 
	C249.331818,142.885071 249.993881,142.195557 250.972961,141.194061 
	C252.518311,140.114761 253.766312,139.646179 255.345810,139.117386 
	C256.124359,139.084595 256.571381,139.112015 257.075378,139.447601 
	C260.532867,141.389603 259.678802,138.589905 259.996002,137.003159 
	C259.997559,136.997192 260.009888,136.994171 260.347198,136.969727 
	C262.388214,135.189331 264.023834,133.222137 266.917603,135.307266 
	C267.640717,136.774414 268.298981,137.891510 269.066284,139.262482 
	C269.746704,139.951492 270.318146,140.386597 271.153381,141.032639 
	C271.625916,141.872665 271.834656,142.501770 272.043365,143.130875 
z"/>
<path fill="#29186C" opacity="1.000000" stroke="none" 
	d="
M324.036682,193.099701 
	C321.758911,195.066208 319.711975,198.261200 317.154968,198.741653 
	C311.945496,199.720520 309.536987,195.422119 307.537354,191.163254 
	C306.557037,192.069611 305.467438,192.870239 304.636688,193.883148 
	C301.950562,197.158234 299.176575,199.183105 294.542664,197.127853 
	C290.128723,195.170181 289.357697,191.258606 289.127960,187.564133 
	C288.511536,177.651718 291.622803,168.953033 300.039001,162.091827 
	C303.920135,161.971878 307.024872,161.963669 310.129578,161.955475 
	C310.519653,161.966721 310.909729,161.977951 312.018127,161.986206 
	C314.437897,161.992462 316.139343,162.001709 317.840820,162.010956 
	C320.914337,164.156097 322.252808,171.669052 320.351501,175.138168 
	C317.650421,180.066574 317.908600,185.507141 318.190063,190.858994 
	C318.213806,191.310379 320.816559,192.309647 321.533600,191.887253 
	C322.727386,191.183975 323.364105,189.535095 324.861572,187.948486 
	C331.506897,183.202606 331.506897,183.202606 328.378265,178.432220 
	C329.748901,173.368866 331.069641,168.525085 332.318268,163.662781 
	C332.347687,163.548248 331.568726,163.226120 331.166840,163.000824 
	C330.968506,162.377335 330.770203,161.753845 330.096069,160.760315 
	C329.346802,159.892044 329.073334,159.393814 328.799866,158.895569 
	C329.865997,158.597855 330.932159,158.300140 332.052979,158.140167 
	C332.193237,158.326981 332.278778,158.376022 332.364349,158.425079 
	C332.243713,158.282761 332.123077,158.140457 332.114838,157.727936 
	C332.470612,156.928848 332.714020,156.399963 332.957428,155.871094 
	C332.957428,155.871094 333.004639,155.421982 333.415283,155.146332 
	C334.908386,153.394333 335.721924,151.538437 337.138977,150.534286 
	C339.067444,149.167786 339.519806,148.816360 338.229889,147.353714 
	C337.542297,146.574051 336.747375,145.889008 336.000916,145.161255 
	C336.761261,145.108994 337.521576,145.056717 338.892029,145.319519 
	C340.375671,145.738342 341.249207,145.842087 342.122711,145.945831 
	C342.122711,145.945831 342.586761,145.998306 342.929993,146.271515 
	C343.495209,146.771454 343.763794,146.903580 344.078918,146.941147 
	C344.078918,146.941147 344.003601,147.000870 344.014221,147.419922 
	C344.034912,150.273743 344.044952,152.708481 344.054993,155.143234 
	C344.054993,155.143234 344.080444,155.549347 343.774841,155.765198 
	C342.758148,156.420914 342.047028,156.860764 341.335907,157.300598 
	C341.926483,158.212036 342.517059,159.123474 343.107635,160.034912 
	C342.740601,161.124130 342.373596,162.213333 341.632324,163.693970 
	C341.210205,164.740402 341.162354,165.395386 341.114502,166.050385 
	C341.070679,166.492996 341.026886,166.935608 340.689819,167.897369 
	C340.288544,168.979477 340.180542,169.542435 340.072540,170.105392 
	C340.072540,170.105392 340.023895,170.016891 339.710205,170.053391 
	C338.352081,170.639236 337.307587,171.188583 335.231873,172.280319 
	C337.347076,172.696518 338.236847,172.871582 339.126617,173.046661 
	C339.077301,173.490219 339.028015,173.933792 338.685455,174.898529 
	C338.286072,175.982758 338.179962,176.545807 338.073883,177.108856 
	C338.073883,177.108856 338.026917,177.019257 337.695251,177.031296 
	C336.807373,177.860626 336.055206,178.609467 335.740234,179.511047 
	C335.253204,180.905182 335.419586,182.631546 334.666138,183.804016 
	C333.563507,185.519882 331.892334,186.870438 330.540894,188.295044 
	C332.700226,189.797089 334.268219,190.887787 335.836243,191.978500 
	C336.340332,192.322769 336.844391,192.667053 337.708954,193.405823 
	C338.379364,194.817368 338.689301,195.834427 338.999207,196.851486 
	C338.999207,196.851486 338.577698,197.000473 337.965881,196.971283 
	C334.192596,196.310883 331.031189,195.679688 327.869751,195.048492 
	C327.431183,194.398209 326.992645,193.747910 326.095703,192.769745 
	C325.103790,192.661133 324.570221,192.880417 324.036682,193.099701 
M308.135559,168.594177 
	C307.069977,168.495316 305.160797,168.083084 305.063843,168.344040 
	C303.249847,173.227341 301.506592,178.150864 300.126068,183.169800 
	C299.842468,184.200745 301.083038,185.650925 301.621063,186.907852 
	C302.866241,186.308273 304.235229,185.879257 305.335938,185.080566 
	C311.192810,180.830872 309.039459,174.812729 308.135559,168.594177 
z"/>
<path fill="#29186C" opacity="1.000000" stroke="none" 
	d="
M384.955017,151.853439 
	C388.570251,149.221725 392.192993,146.600220 395.790741,143.944809 
	C396.217316,143.629959 396.507080,143.129730 396.860229,142.715424 
	C396.331421,142.392242 395.802612,142.069077 395.347717,141.130112 
	C396.650665,140.331253 397.879761,140.148178 399.308044,139.973999 
	C399.507263,139.982880 399.904480,140.019089 399.966736,140.321838 
	C400.719696,140.667419 401.410400,140.710251 402.310608,140.681183 
	C402.708405,140.380905 402.909058,140.163879 403.433228,139.984161 
	C404.487885,139.743637 405.231293,139.477203 406.234863,139.127014 
	C406.676636,139.028198 406.858215,139.013107 407.282837,139.018646 
	C407.525879,139.039276 408.011566,138.993637 408.258575,139.001099 
	C408.505585,139.008545 408.965149,139.190475 409.133026,139.454742 
	C411.227661,140.181534 413.154449,140.644028 415.081207,141.106537 
	C415.830536,141.668488 416.572754,142.706268 417.330261,142.717529 
	C423.724640,142.812546 427.427307,147.575775 432.017120,150.855103 
	C438.755829,155.669769 437.587646,162.519241 436.420837,168.984344 
	C434.602509,179.059250 426.230377,181.714935 417.419617,183.655518 
	C416.991364,182.484253 416.969971,181.672684 416.948608,180.861130 
	C425.419525,175.048096 427.608582,170.271072 425.025757,162.986267 
	C423.137390,157.660233 418.804657,155.500458 413.681061,154.256821 
	C405.626343,152.301666 394.780304,156.711853 391.977448,163.430939 
	C391.257324,165.157257 391.318787,167.209610 390.798828,169.709610 
	C390.367981,170.912933 390.159515,171.518829 389.951050,172.124725 
	C389.511108,172.085587 389.071136,172.046463 388.179626,171.727081 
	C387.496094,171.218628 387.218414,171.086624 386.894989,171.050766 
	C386.894989,171.050766 386.974213,171.023788 386.924805,170.702927 
	C385.883759,169.941895 384.892181,169.501740 383.900574,169.061569 
	C383.900574,169.061569 383.977631,169.031860 383.866882,168.825348 
	C383.465668,168.428360 383.175201,168.237854 382.884735,168.047348 
	C382.884735,168.047348 382.963989,168.033920 382.893188,167.843994 
	C382.680206,167.355377 382.459106,167.136261 382.159119,166.996735 
	C382.159119,166.996735 382.003418,166.577957 381.994232,165.897003 
	C381.976501,162.769119 381.967926,160.322174 381.959381,157.875244 
	C381.959381,157.875244 382.005707,157.434113 382.429077,157.140686 
	C383.212921,155.530136 383.573395,154.213043 383.933899,152.895950 
	C383.933899,152.895950 383.963196,152.974091 384.172119,152.859650 
	C384.572388,152.447952 384.763702,152.150696 384.955017,151.853439 
z"/>
<path fill="#29176D" opacity="1.000000" stroke="none" 
	d="
M344.957886,179.863556 
	C344.957916,179.863556 345.002625,179.404694 345.317261,178.955109 
	C345.732788,177.636551 345.833649,176.767578 345.934570,175.898605 
	C345.934570,175.898605 345.974762,175.982101 346.317596,175.912415 
	C347.093842,174.188492 347.527252,172.534271 347.960693,170.880035 
	C347.960663,170.880035 348.021057,170.422791 348.291595,170.066040 
	C348.787415,169.466705 348.916840,169.180725 348.950409,168.851364 
	C348.950409,168.851364 348.984131,168.411377 349.264526,168.074432 
	C349.779541,167.503143 349.912994,167.220947 349.945343,166.890900 
	C349.945343,166.890900 349.965668,166.968719 350.173553,166.864685 
	C350.573792,166.472076 350.766113,166.183472 350.958466,165.894882 
	C350.958466,165.894882 350.969238,165.971878 351.176758,165.865646 
	C351.575378,165.470200 351.766479,165.181000 351.957550,164.891785 
	C351.957550,164.891785 351.969421,164.968918 352.176605,164.863892 
	C352.574554,164.469406 352.765320,164.179947 352.956085,163.890488 
	C352.956085,163.890488 352.965668,163.968414 353.173096,163.863449 
	C353.572815,163.469879 353.765137,163.181259 353.957428,162.892639 
	C353.957428,162.892639 353.968506,162.970001 354.175476,162.864319 
	C354.574158,162.470169 354.765869,162.181717 354.957611,161.893265 
	C354.957611,161.893265 354.972076,161.970795 355.159882,161.897400 
	C355.642212,161.681488 355.858429,161.461884 355.996368,161.165192 
	C356.425140,161.103912 356.853882,161.042633 357.819458,161.217529 
	C359.949646,161.286575 361.542999,161.119431 363.136383,160.952271 
	C364.785095,160.975739 366.462616,161.207443 368.077179,160.983978 
	C373.101318,160.288559 377.918610,165.575180 376.763367,170.618027 
	C375.505371,176.109467 374.025269,181.571030 373.199463,187.128464 
	C372.931000,188.935287 374.416656,191.002747 375.755859,193.135712 
	C376.267517,194.828720 376.123230,196.337006 375.978943,197.845306 
	C375.978943,197.845306 375.575867,198.004776 374.911865,198.010483 
	C372.455627,198.028091 370.663422,198.040009 368.871216,198.051910 
	C366.839264,195.811096 364.807343,193.570282 362.211609,191.285797 
	C361.448334,191.534943 361.248871,191.827759 361.049408,192.120575 
	C361.049408,192.120575 361.036316,192.038818 360.714355,192.082825 
	C359.949890,193.117737 359.507324,194.108643 359.064758,195.099548 
	C359.064758,195.099548 359.018982,195.013138 358.691772,195.023056 
	C357.597229,195.726013 356.829865,196.419067 356.062531,197.112122 
	C356.062531,197.112122 356.021942,197.028061 355.619080,197.024963 
	C353.434601,197.027603 351.653046,197.033340 349.871460,197.039078 
	C349.871460,197.039078 349.425507,196.993271 349.082153,196.723206 
	C348.507050,196.219803 348.227783,196.084473 347.901001,196.047134 
	C347.901001,196.047134 347.976898,196.028503 347.868317,195.820694 
	C347.469147,195.423599 347.178589,195.234314 346.888000,195.045044 
	C346.888000,195.045044 346.966827,195.032227 346.895630,194.841400 
	C346.681610,194.351654 346.460175,194.131912 346.160156,193.991364 
	C345.391205,192.677231 344.622253,191.363098 343.251404,190.035950 
	C342.649506,190.022934 342.160583,190.001968 342.160583,190.001968 
	C342.776062,187.908554 343.391571,185.815155 344.324829,183.112457 
	C344.747711,181.623306 344.852814,180.743423 344.957886,179.863556 
M358.608215,169.244720 
	C358.423309,169.538788 358.238373,169.832840 358.037842,170.045044 
	C358.037842,170.045044 358.118530,170.065765 357.349152,170.155548 
	C356.911560,171.808884 356.473969,173.462219 355.985291,175.560593 
	C355.985291,175.560593 355.832214,175.981567 355.096436,176.187317 
	C355.010315,179.117676 354.924194,182.048050 354.996887,185.415970 
	C354.996887,185.415970 355.041199,185.879349 355.252747,186.373749 
	C355.545837,186.567947 355.838928,186.762146 356.571289,187.008057 
	C356.571289,187.008057 356.983185,187.169144 357.094177,187.750443 
	C357.708557,187.463455 358.322937,187.176468 358.964355,186.968430 
	C358.964355,186.968430 358.882935,186.950165 359.376038,186.756180 
	C359.570801,186.469986 359.765564,186.183807 359.974518,185.973465 
	C359.974518,185.973465 359.898499,185.960175 360.385773,185.758850 
	C360.577728,185.472641 360.769684,185.186432 360.983429,184.977554 
	C360.983429,184.977554 360.909698,184.945618 361.685547,184.965286 
	C365.463867,184.375656 364.889191,181.450256 364.994812,178.412247 
	C364.994812,178.412247 365.162079,177.985123 365.441925,177.745193 
	C365.255768,177.509598 365.069641,177.274002 364.172150,176.614822 
	C364.106628,174.526505 364.415314,172.330902 363.857086,170.383850 
	C363.410889,168.827606 363.319244,165.102234 359.453674,168.258835 
	C359.220703,168.490417 359.084656,168.769287 359.022583,169.021317 
	C359.022583,169.021317 359.098389,169.037888 358.608215,169.244720 
z"/>
<path fill="#341B51" opacity="1.000000" stroke="none" 
	d="
M354.028442,113.831650 
	C354.783173,115.458565 355.516785,116.704208 356.218353,117.895470 
	C358.124023,115.076729 359.971558,112.463249 361.694031,109.769814 
	C364.672028,105.113098 369.279236,105.332008 373.618042,106.329407 
	C376.925232,107.089653 378.642609,113.090958 378.132996,118.043625 
	C377.924500,120.069786 378.988159,122.203621 378.929688,124.273117 
	C378.795471,129.021301 378.950836,133.929016 377.730804,138.431091 
	C377.124023,140.670166 373.957794,142.623184 371.508942,143.738617 
	C370.629791,144.139069 368.337463,141.831909 366.919983,140.513336 
	C366.010773,139.667557 365.521576,138.370239 364.494476,136.710312 
	C362.358185,140.406891 360.438293,145.187897 355.602905,142.830780 
	C350.813141,140.495895 347.142090,140.206131 342.101898,142.684311 
	C338.369324,144.519562 333.336578,137.711319 333.753326,133.274323 
	C334.249084,127.995911 333.970917,122.642776 333.987701,117.321899 
	C334.009552,110.401428 336.203857,107.351234 342.242004,105.944427 
	C346.357117,104.985672 351.784119,108.447952 354.028442,113.831650 
M375.003082,124.993820 
	C375.003082,124.993820 375.015503,124.974686 375.064148,124.133965 
	C374.772919,122.033485 374.481689,119.932999 374.178986,117.440109 
	C374.178986,117.440109 374.174011,117.047569 374.746613,116.601555 
	C374.373627,115.107544 374.413696,113.295914 373.553162,112.176804 
	C370.508850,108.217728 367.346588,109.077728 362.290314,115.181099 
	C361.565491,117.450417 360.840698,119.719727 359.775452,122.581673 
	C358.226562,123.344284 356.677673,124.106903 354.812378,124.232430 
	C353.554474,121.805420 352.296600,119.378410 350.965546,116.449112 
	C350.912201,116.288536 350.858856,116.127968 350.978394,115.301132 
	C350.646515,114.587250 350.314636,113.873367 349.351501,112.745102 
	C345.606171,108.681854 343.049683,108.776237 337.248810,113.459473 
	C337.454803,118.291199 337.660797,123.122925 337.229889,128.449768 
	C337.811829,131.576462 338.123993,134.795410 339.142120,137.772949 
	C339.442780,138.652298 341.984650,139.541824 343.046417,139.161606 
	C344.067230,138.796051 344.968384,136.836136 345.092194,135.499863 
	C345.319550,133.046204 344.937073,130.536041 345.012207,127.375854 
	C346.888916,124.253815 347.739655,127.168297 348.975250,128.662460 
	C349.301331,129.124573 349.627441,129.586685 349.968384,129.998856 
	C349.968384,129.998856 349.938385,130.048218 349.999756,130.815643 
	C350.371429,132.232773 350.731323,133.653152 351.117340,135.066376 
	C351.858032,137.777969 357.323242,141.029343 358.530884,139.372406 
	C360.359070,136.864075 361.425629,133.800644 362.977325,130.541397 
	C362.977325,130.541397 363.029053,130.083405 363.504028,129.892029 
	C363.589722,129.587921 363.675415,129.283813 363.963989,128.574371 
	C363.963989,128.574371 364.043701,128.128143 364.531036,127.931496 
	C364.617157,127.615906 364.703278,127.300316 364.991882,126.401634 
	C365.025635,125.992599 365.059357,125.583565 365.482697,125.116005 
	C365.482697,125.116005 365.876648,125.113037 366.065002,125.598366 
	C366.367188,125.750130 366.669373,125.901901 366.993652,125.997208 
	C366.993652,125.997208 366.947754,126.036995 366.951660,126.924393 
	C367.707123,130.534393 368.268494,134.203857 369.344543,137.715607 
	C369.640381,138.681000 371.751160,139.965775 372.535187,139.691589 
	C373.591949,139.322067 374.730957,137.637939 374.844788,136.425079 
	C375.137115,133.310165 374.883545,130.143997 374.987244,126.567406 
	C374.987244,126.567406 375.009521,126.115135 375.432373,125.923073 
	C375.520844,125.797188 375.703369,125.580391 375.683197,125.559509 
	C375.483063,125.352432 375.249420,125.177750 375.003082,124.993820 
z"/>
<path fill="#29186C" opacity="1.000000" stroke="none" 
	d="
M453.077942,144.108917 
	C453.079987,144.439392 452.940796,144.930954 453.105804,145.075592 
	C454.928223,146.673477 458.376434,148.235916 458.358459,149.772583 
	C458.304779,154.361542 456.909149,158.936905 456.016632,163.513504 
	C454.116577,173.256638 452.203247,182.997177 450.815186,193.261642 
	C451.549469,195.135040 451.763947,196.485596 451.978424,197.836136 
	C451.978424,197.836136 451.560791,197.987946 450.902679,197.997940 
	C448.787170,198.020523 447.329803,198.033112 445.872406,198.045685 
	C439.877930,197.672073 440.010986,192.276947 440.257507,189.047607 
	C440.867126,181.060913 442.773712,173.159439 444.373383,165.267151 
	C445.362457,160.387299 447.009521,155.630508 447.812836,150.728607 
	C448.114716,148.886536 446.867310,146.790558 446.226440,144.063965 
	C449.336426,142.735809 452.546234,142.153992 455.756073,141.572189 
	C455.787018,141.892990 455.817993,142.213791 455.848969,142.534592 
	C454.925293,143.059372 454.001587,143.584152 453.077942,144.108917 
z"/>
<path fill="#29196B" opacity="1.000000" stroke="none" 
	d="
M416.533417,180.911499 
	C416.969971,181.672684 416.991364,182.484253 417.023102,183.701599 
	C413.304688,185.291565 409.575897,186.475739 404.699036,188.024490 
	C404.214752,191.976822 403.465027,196.901581 403.049255,201.854385 
	C402.696289,206.058334 401.278992,206.998520 397.442047,205.094223 
	C393.242523,203.009995 391.556305,198.115356 393.166290,191.697998 
	C395.480408,182.473953 398.062958,173.317276 400.376801,164.093170 
	C400.908539,161.973511 400.813629,159.696655 401.021057,157.333054 
	C409.629944,156.135193 413.073944,159.797760 410.982758,168.925400 
	C410.480957,171.115646 409.686127,173.239700 409.006256,175.387939 
	C408.377014,177.376328 407.721191,179.356323 406.770599,182.283661 
	C410.221405,181.795685 413.169830,181.378769 416.533417,180.911499 
z"/>
<path fill="#FDFEFC" opacity="1.000000" stroke="none" 
	d="
M273.004395,83.026794 
	C272.403046,85.456856 272.320740,88.201332 271.082520,90.245621 
	C269.066345,93.574287 265.862732,93.443169 263.257263,90.434052 
	C261.466919,88.366341 259.742767,86.241325 257.586487,83.767639 
	C257.258789,83.208588 257.333679,83.023827 257.826294,82.895699 
	C259.333466,82.790207 260.422943,82.628067 261.512421,82.465935 
	C261.498749,82.123672 261.485107,81.781403 261.471466,81.439140 
	C260.317963,81.292961 259.164490,81.146782 257.959076,80.675316 
	C257.242371,79.921707 256.577545,79.493393 255.949188,79.030014 
	C255.985657,78.994942 255.931290,79.079842 255.957794,78.695869 
	C255.062637,77.197060 254.140961,76.082230 253.129181,74.755341 
	C253.039093,74.543289 252.958694,74.089577 252.897217,73.845482 
	C252.612671,73.354332 252.389572,73.107285 252.049667,72.682381 
	C251.747971,72.275551 251.513428,72.122810 251.126038,71.825058 
	C251.022827,71.603806 250.944305,71.123161 250.886658,70.867958 
	C250.592468,70.362465 250.355942,70.112175 250.010284,69.681190 
	C249.739212,69.255432 249.519180,69.085663 249.149078,68.765511 
	C249.057098,68.539833 248.980331,68.058525 248.907959,67.816666 
	C248.571106,67.379486 248.306610,67.184158 248.019989,66.995445 
	C247.997864,67.002060 248.013016,66.958412 247.951538,66.755585 
	C247.751358,66.292770 247.546967,66.103844 247.170593,65.775841 
	C247.064316,65.565697 246.953751,65.107849 246.936493,64.729401 
	C245.590256,62.917290 244.261246,61.483620 242.961014,60.023483 
	C242.989777,59.997025 242.956421,60.067528 242.885925,59.822998 
	C242.547684,59.384457 242.279922,59.190445 241.772049,58.896568 
	C241.362061,58.503101 241.192169,58.209492 240.930084,57.696373 
	C240.642502,57.202175 240.378265,57.038975 239.793976,56.916279 
	C239.363922,56.560734 239.185043,56.276165 239.020172,55.605530 
	C238.225006,50.558250 236.077805,47.250828 230.526978,47.054726 
	C229.860748,46.932339 229.482361,46.851208 229.011719,46.518524 
	C228.621231,45.827049 228.322983,45.387123 228.017853,44.582748 
	C227.026367,43.469872 226.041748,42.721451 224.834442,41.863670 
	C224.333496,41.556427 224.132431,41.298401 223.996216,40.578384 
	C221.632248,37.128174 218.383392,37.119446 215.914993,40.408829 
	C215.192612,41.451378 214.626190,42.240364 213.782104,43.008743 
	C213.318817,42.989277 213.133194,42.990425 212.947556,42.991577 
	C210.607864,40.716919 210.533997,35.250889 212.998138,34.214664 
	C216.949722,32.552944 218.521088,30.259377 216.354431,26.087011 
	C216.019699,25.442406 216.848236,24.193724 217.478027,22.092733 
	C218.607849,24.241919 219.169495,25.310316 219.904083,27.004766 
	C220.656830,28.098698 221.236664,28.566578 221.995422,29.223022 
	C222.453674,29.606705 222.733002,29.801823 223.014160,30.355976 
	C225.027451,32.773575 227.038910,34.832142 229.061859,37.257927 
	C230.397858,38.409145 231.722336,39.193150 233.295135,40.087730 
	C234.008377,40.472572 234.473312,40.746841 234.992340,41.289028 
	C235.343033,41.740372 235.639633,41.923798 235.959152,42.057423 
	C235.982086,42.007626 235.904526,42.085140 236.036682,42.373581 
	C237.107224,43.455570 238.045609,44.249115 238.985138,45.415504 
	C240.732712,48.751511 241.547501,52.610455 246.223389,53.124443 
	C246.998886,53.821049 247.495514,54.418240 247.983978,55.402863 
	C248.972168,56.866501 249.968536,57.942703 250.984726,59.011009 
	C251.004547,59.003113 250.983582,59.040432 251.035553,59.239357 
	C251.210144,59.698616 251.404205,59.884293 251.799408,60.206417 
	C251.929138,60.417522 252.059525,60.895618 252.037933,61.262047 
	C252.700653,62.399353 253.384949,63.170227 254.045380,63.974373 
	C254.021515,64.007645 254.051544,63.933079 254.126404,64.178284 
	C254.478119,64.609123 254.754944,64.794762 255.014847,64.989159 
	C254.997925,64.997925 255.023026,64.968239 255.039734,65.324783 
	C255.978973,67.173637 256.901550,68.665932 257.886902,70.498810 
	C258.670654,71.532074 259.391602,72.224754 260.077209,72.965996 
	C260.041870,73.014557 260.090485,72.906860 260.062012,73.272949 
	C260.720703,74.403656 261.407928,75.168282 262.056580,75.967079 
	C262.018066,76.001259 262.064178,75.909218 262.096680,76.135544 
	C262.249908,76.660995 262.458466,76.874062 262.862793,77.216896 
	C262.970673,77.432701 263.035889,77.910805 263.072723,78.135666 
	C263.231049,78.657875 263.439484,78.869728 263.843811,79.214493 
	C263.952820,79.432922 264.047943,79.911812 264.120880,80.158485 
	C264.466064,80.600677 264.738312,80.796188 265.005280,80.995850 
	C265.000000,81.000000 265.008453,80.989349 265.099548,81.224319 
	C265.467560,81.635559 265.744476,81.811821 266.010529,81.994255 
	C265.999664,82.000427 266.011627,81.978447 266.110748,82.219101 
	C266.505829,82.623062 266.801788,82.786369 267.337585,82.985809 
	C267.577393,83.021942 268.029846,83.196846 268.221680,83.414093 
	C269.265289,83.479820 270.117065,83.328308 271.203339,83.097816 
	C271.437805,83.018837 271.928619,82.955856 272.197021,82.981873 
	C272.645050,83.014198 272.824738,83.020500 273.004395,83.026794 
z"/>
<path fill="#29196B" opacity="1.000000" stroke="none" 
	d="
M246.000046,202.153275 
	C245.674530,203.252579 245.348999,204.351883 244.985596,205.796051 
	C242.924789,205.222870 240.744949,204.543259 238.911758,203.336853 
	C235.157761,200.866425 234.582504,194.004013 238.409134,191.340073 
	C241.737335,189.023117 242.852203,186.043839 243.723801,182.601074 
	C245.824234,174.304443 247.899734,166.001495 250.006699,157.615906 
	C258.873444,158.871246 260.186554,160.398407 258.426178,169.963364 
	C257.361664,175.747330 255.534485,181.390915 254.007874,187.853027 
	C253.658112,189.778580 253.350418,190.948685 253.042725,192.118790 
	C253.042740,192.118790 252.996536,192.590103 252.666809,193.097336 
	C252.220444,195.679474 252.103836,197.754379 251.646942,200.003616 
	C245.944016,202.938461 244.224411,197.973267 241.276871,195.055710 
	C240.450943,199.408310 242.001526,201.635254 246.000046,202.153275 
z"/>
<path fill="#311B57" opacity="1.000000" stroke="none" 
	d="
M415.458252,141.065262 
	C413.154449,140.644028 411.227661,140.181534 409.497650,139.369385 
	C414.923370,138.819229 417.847504,136.013550 418.873901,130.550018 
	C418.910065,128.415985 418.864807,126.702621 418.885040,124.564392 
	C418.906738,122.421928 418.862915,120.704323 418.883789,118.575516 
	C418.959106,117.501328 419.222351,116.691071 418.936554,116.200905 
	C417.709717,114.096573 416.321411,112.086395 414.991913,110.041916 
	C413.689209,111.680252 412.232361,113.225502 411.159302,115.002510 
	C410.727692,115.717285 411.137970,116.940445 411.075806,118.344246 
	C410.279816,122.797058 409.573120,126.837845 408.565552,130.888367 
	C407.863464,130.899261 407.462219,130.900421 406.883789,130.633911 
	C405.495544,130.629532 404.284515,130.892807 402.686188,131.076447 
	C401.246796,130.930634 400.194702,130.864487 399.119324,130.459274 
	C398.432007,127.062698 397.768005,124.005196 397.057495,120.500153 
	C397.011108,117.904915 397.313507,115.693596 396.880585,113.637054 
	C396.672272,112.647552 395.076874,111.950058 393.778687,111.046059 
	C393.003784,111.037605 392.559753,111.103424 391.719360,111.263596 
	C391.182098,113.256836 391.041260,115.155708 390.646729,117.216187 
	C390.637817,118.303970 390.882599,119.230164 391.057739,120.495071 
	C390.969208,121.527611 390.950378,122.221428 390.604736,123.127151 
	C390.247681,124.893028 390.217377,126.447006 390.117859,128.393250 
	C389.757263,135.195709 395.104675,137.036102 399.108826,139.965118 
	C397.879761,140.148178 396.650665,140.331253 395.186462,140.804825 
	C388.098633,137.521072 386.050415,131.736938 386.949860,124.371490 
	C387.310303,121.419952 386.964722,118.386414 387.010223,115.391258 
	C387.086639,110.358391 391.180206,105.617958 395.068634,105.994522 
	C398.818146,106.357651 402.470795,112.069344 402.041260,116.521118 
	C401.803802,118.982132 401.757965,121.532677 402.162659,123.953110 
	C402.350464,125.076630 403.818573,125.986107 404.706268,126.992622 
	C405.657959,125.977875 407.286560,125.053574 407.436432,123.931686 
	C407.915741,120.344322 407.505432,116.629463 408.071838,113.064423 
	C408.863220,108.083389 413.165710,104.945152 416.966339,106.023560 
	C420.175140,106.934029 423.026062,113.268867 422.731506,116.836250 
	C422.360626,121.328423 423.110657,125.902534 422.938690,130.424606 
	C422.754547,135.265228 420.633636,139.121201 415.458252,141.065262 
z"/>
<path fill="#FDFEFC" opacity="1.000000" stroke="none" 
	d="
M239.147980,18.927628 
	C242.341415,21.413303 245.534851,23.898972 248.889740,27.021677 
	C249.704498,28.104195 250.357803,28.549675 251.011093,28.995155 
	C251.934540,29.787748 252.857986,30.580343 253.884048,32.038116 
	C254.809830,36.583538 257.152954,39.007908 260.983185,40.007904 
	C261.255219,40.196320 261.527222,40.384743 261.881653,41.198288 
	C261.844391,44.649395 262.960144,46.267990 266.013275,45.990238 
	C266.000000,46.000305 266.020203,45.973942 266.004944,46.334160 
	C266.679443,47.449100 267.369232,48.203831 268.033508,48.981686 
	C268.007965,49.004814 268.044434,48.947350 268.026123,49.315224 
	C268.722961,50.427349 269.438110,51.171604 270.356110,51.936600 
	C270.558960,51.957336 270.966034,51.982670 270.980865,52.226635 
	C270.995697,52.470600 271.018616,52.958015 271.097748,53.201363 
	C271.454437,53.628166 271.731995,53.811623 272.004761,53.997620 
	C272.000000,54.000153 272.005371,53.990467 272.053528,54.201557 
	C272.229370,54.688431 272.434265,54.882938 272.840179,55.206230 
	C272.964050,55.416290 273.039825,55.898117 273.106323,56.152882 
	C273.452698,56.603107 273.732574,56.798569 274.006226,56.997093 
	C274.000000,57.000153 274.007385,56.987957 273.994629,57.398758 
	C274.480164,59.241669 272.823761,62.145134 276.470551,61.489769 
	C276.483002,61.543835 276.577271,61.602322 276.424316,61.940628 
	C276.873169,62.833355 277.474945,63.387775 278.037872,63.966690 
	C277.999023,63.991184 278.059998,63.922310 278.033752,64.286896 
	C278.693054,65.417091 279.378571,66.182701 280.038849,66.977203 
	C280.013611,67.006081 280.046600,66.937813 280.097900,67.164963 
	C280.297363,67.684029 280.525726,67.884819 280.977692,68.241394 
	C281.436188,68.956757 281.751221,69.425217 282.036682,70.281433 
	C282.705597,71.749390 283.404083,72.829582 284.102844,73.931732 
	C284.103149,73.953674 284.070374,73.925064 284.038330,74.268555 
	C284.005615,75.070961 284.004913,75.529869 284.004181,75.988785 
	C283.334229,77.276230 282.864044,78.731972 281.951569,79.815140 
	C280.197876,81.896973 279.302521,80.894524 278.852051,78.019241 
	C277.767303,76.252106 276.836304,75.163445 275.936401,74.032120 
	C275.967499,73.989471 275.929749,74.086830 275.894196,73.858246 
	C275.728333,73.331161 275.512848,73.119339 275.116882,72.783211 
	C275.021606,72.572227 274.953064,72.114311 274.878296,71.859444 
	C274.517029,71.412712 274.230591,71.220840 273.972412,71.015961 
	C274.000671,71.002945 273.964661,71.053818 273.979675,70.655518 
	C273.336182,68.831703 272.677612,67.406197 271.966888,65.656281 
	C271.246460,64.909836 270.578217,64.487816 269.936371,64.026909 
	C269.962769,63.988033 269.928253,64.073143 269.956696,63.672897 
	C269.172424,62.376129 268.359680,61.479607 267.526825,60.526291 
	C267.506683,60.469490 267.413452,60.393089 267.572510,60.054592 
	C267.109344,59.178131 266.487091,58.640167 265.904419,58.043449 
	C265.944061,57.984695 265.889069,58.114780 265.930756,57.744328 
	C265.276611,56.600166 264.580780,55.826454 263.629791,54.928688 
	C262.909393,54.220589 262.444183,53.636543 261.995056,52.666695 
	C260.347595,50.184261 258.684021,48.087635 256.741089,45.819344 
	C254.831223,44.289829 253.200699,42.931988 251.521866,41.573898 
	C251.473541,41.573647 251.392456,41.521027 251.324066,41.289349 
	C251.060699,40.764557 250.787201,40.615120 250.444595,40.621620 
	C250.454056,40.633888 250.458023,40.590706 250.422760,40.295265 
	C249.944366,39.632046 249.501236,39.264267 248.996674,38.669727 
	C248.783813,38.141186 248.543152,37.954269 248.105408,37.594604 
	C247.344208,36.847290 246.690872,36.387596 245.916504,35.639534 
	C244.895554,34.550503 243.995667,33.749840 243.038971,32.636841 
	C242.330505,31.865309 241.678833,31.406109 240.940460,30.686783 
	C240.563293,29.964233 240.272812,29.501814 239.990814,28.668072 
	C237.985703,26.871140 235.972137,25.445526 233.711136,24.016617 
	C233.463684,24.013321 232.973618,24.082590 232.830750,23.814642 
	C232.129196,23.354910 231.570526,23.163128 231.009003,22.747766 
	C231.006180,22.524189 230.960892,22.079296 230.983704,21.736397 
	C231.001984,20.935837 230.997437,20.478174 230.992889,20.020515 
	C231.504822,19.117603 232.016739,18.214687 233.143311,17.182537 
	C235.554657,17.678078 237.351318,18.302851 239.147980,18.927628 
z"/>
<path fill="#F8EA23" opacity="1.000000" stroke="none" 
	d="
M148.041748,27.958992 
	C148.041748,27.958992 148.426605,28.141909 148.704529,28.552357 
	C150.007629,29.624846 151.032822,30.286886 152.058014,30.948929 
	C152.058014,30.948929 152.001572,30.998158 151.989319,31.355179 
	C152.775986,32.808720 153.348831,34.264664 154.415466,34.935509 
	C157.845886,37.093040 161.458527,38.960804 164.886169,41.230091 
	C164.503860,42.563210 164.236298,43.606560 163.726639,45.593929 
	C161.418701,43.378117 159.467422,41.504726 156.660187,38.809555 
	C154.133667,42.830196 151.560928,46.924393 148.689224,51.134247 
	C147.260284,50.593616 146.130325,49.937328 145.007294,49.285080 
	C144.154617,50.059116 143.085495,51.029625 141.699280,52.161236 
	C139.561630,54.241722 137.741104,56.161106 135.553772,58.045879 
	C130.777908,54.984615 126.368828,51.957966 121.965317,48.598030 
	C123.317757,47.519249 124.664627,46.773746 126.364441,45.964394 
	C129.317902,44.554802 131.918411,43.209068 134.923080,41.654179 
	C134.375778,40.895027 133.695358,39.951225 133.466614,39.006577 
	C135.564072,39.007004 137.216782,38.911144 138.854233,39.028492 
	C143.086594,39.331818 143.866135,37.538296 142.332031,33.885799 
	C143.770752,31.811987 144.895737,29.887417 146.358368,27.972670 
	C147.144577,27.974659 147.593155,27.966825 148.041748,27.958992 
M146.519241,46.413780 
	C146.519241,46.413780 146.584641,46.525448 146.519241,46.413780 
z"/>
<path fill="#351B50" opacity="1.000000" stroke="none" 
	d="
M513.262451,131.618744 
	C516.825439,137.402451 516.766785,139.774338 513.279236,141.972122 
	C507.609009,145.545380 502.799011,143.842606 498.932129,137.405518 
	C495.030090,139.078568 491.143890,141.395752 486.950958,142.317245 
	C484.082428,142.947678 479.747345,142.664337 477.984192,140.887726 
	C476.338531,139.229553 476.317169,134.777908 477.170319,132.053650 
	C479.310638,125.219353 481.959137,118.466408 485.219696,112.098579 
	C486.544891,109.510536 489.772919,106.803261 492.518433,106.317810 
	C495.816284,105.734703 500.469788,103.917809 502.781738,109.182640 
	C506.046173,116.616547 509.608368,123.919708 513.262451,131.618744 
M502.978516,135.990417 
	C502.978516,135.990417 502.895203,136.076569 502.931580,136.743927 
	C503.326874,137.411316 503.722137,138.078690 504.714386,138.930649 
	C505.129395,138.965836 505.544403,139.001022 506.285706,139.594452 
	C510.653137,140.877670 511.871521,139.970062 511.116913,135.282944 
	C510.033875,133.850998 508.950836,132.419067 507.983215,130.212448 
	C507.024872,128.122955 506.066528,126.033455 505.023163,123.195366 
	C504.309967,121.131554 503.596771,119.067734 502.798035,116.279556 
	C500.246826,109.768295 495.982239,108.304100 489.245392,112.205673 
	C487.820282,116.127464 486.395142,120.049248 484.973999,123.908836 
	C484.973999,123.908836 484.913116,123.895439 484.409058,124.098732 
	C484.327271,124.426918 484.245514,124.755096 483.992676,125.469597 
	C483.992676,125.469597 483.898041,125.881348 483.253479,126.213852 
	C482.135468,129.026062 480.807587,131.779648 479.997955,134.677994 
	C479.651733,135.917511 480.056763,138.463760 480.691071,138.671158 
	C482.473480,139.254044 484.631470,139.265732 486.483093,138.830063 
	C487.497437,138.591385 488.191925,136.993332 488.995758,135.997238 
	C488.995758,135.997238 489.002777,136.033173 489.521271,135.901291 
	C489.707001,135.608505 489.892731,135.315704 490.525055,134.940140 
	C490.525055,134.940140 490.951721,134.784256 491.456757,134.867264 
	C491.662415,134.615433 491.868073,134.363602 492.677673,134.076660 
	C493.080566,134.093185 493.483490,134.109711 493.939270,134.614594 
	C494.328766,134.742172 494.718292,134.869751 495.493835,135.041016 
	C495.493835,135.041016 495.881226,135.011597 496.090332,135.414398 
	C496.320526,135.237946 496.550690,135.061478 497.122101,134.554153 
	C497.345947,134.286667 497.630615,134.176788 498.427460,134.757767 
	C499.591034,134.878662 500.754639,134.999573 502.001526,135.029068 
	C502.001526,135.029068 501.895630,135.092896 502.085876,135.557892 
	C502.361206,135.734955 502.636536,135.912003 502.978516,135.990417 
z"/>
<path fill="#331B54" opacity="1.000000" stroke="none" 
	d="
M294.811462,142.999329 
	C286.792542,142.797470 284.033813,138.147507 287.281860,131.260468 
	C289.938019,125.628365 292.782898,120.037613 294.818787,114.176575 
	C296.297607,109.919304 298.365723,106.859566 302.859894,106.023102 
	C308.410431,104.990021 313.038269,106.458351 315.426880,111.974304 
	C318.766418,119.686028 322.114807,127.412109 324.969543,135.309128 
	C326.859955,140.538528 321.811096,145.579498 316.635468,143.736923 
	C313.895630,142.761520 311.569153,140.625076 309.056061,139.012772 
	C309.108826,138.518524 309.161591,138.024277 309.214325,137.530029 
	C304.553925,139.352997 299.893494,141.175949 294.811462,142.999329 
M313.734344,116.294350 
	C311.959839,112.342644 309.292725,108.863037 304.724731,109.215454 
	C300.644470,109.530243 298.572937,112.883255 297.869690,117.588806 
	C296.966797,120.054443 296.063904,122.520073 294.912354,125.503738 
	C294.573303,125.957726 294.234222,126.411713 293.321899,127.231071 
	C291.869476,130.698578 290.417084,134.166077 288.974915,137.609146 
	C293.195312,140.653183 296.340546,140.835297 299.179779,136.739609 
	C299.942474,135.639389 301.517517,134.442688 302.724091,134.438019 
	C305.880554,134.425781 309.039429,135.047745 312.537476,135.456635 
	C312.643402,135.815689 312.640869,136.742416 313.021332,136.943085 
	C315.337646,138.164841 317.952240,140.435120 320.069092,140.066818 
	C323.844818,139.409943 321.610718,135.951691 321.129181,133.851974 
	C320.717316,132.056015 319.196014,130.514511 318.290924,128.316162 
	C317.790802,127.213173 317.290649,126.110191 316.889526,124.268845 
	C315.866425,121.842827 314.843292,119.416809 313.734344,116.294350 
z"/>
<path fill="#311C55" opacity="1.000000" stroke="none" 
	d="
M272.420502,143.071198 
	C271.834656,142.501770 271.625916,141.872665 271.164612,140.737518 
	C270.960663,139.838821 271.009369,139.446182 271.415314,138.897324 
	C273.008026,134.588165 276.160980,130.741928 273.883057,125.677887 
	C273.904205,124.886772 273.850342,124.432823 273.866638,123.530930 
	C273.950714,120.935753 274.375488,118.685684 273.870514,116.668327 
	C273.289581,114.347443 272.410553,111.006783 270.729187,110.287643 
	C267.412903,108.869225 263.940033,110.122093 262.083832,113.801842 
	C261.059265,115.832939 260.120087,117.907082 259.071899,120.237122 
	C259.039856,120.697945 259.078308,120.883461 258.937744,121.363098 
	C256.672119,124.699295 254.449158,124.544640 252.111511,121.736977 
	C252.101379,121.533501 252.052094,121.128639 252.285583,121.033539 
	C252.356766,120.601616 252.194458,120.264809 252.002151,119.700974 
	C251.972153,119.473946 251.776550,119.059822 251.819977,118.796616 
	C251.592026,118.324455 251.320648,118.115494 251.032196,117.538834 
	C248.442627,109.385361 241.994415,107.885628 237.626266,112.272491 
	C236.903580,117.911583 236.567825,123.451317 236.157974,129.414368 
	C236.108185,131.842346 236.132492,133.847015 236.107605,136.276215 
	C236.028778,137.840698 235.999130,138.980652 235.969482,140.120605 
	C233.044449,138.198227 231.872787,136.231415 232.724701,132.123978 
	C233.814896,126.867653 232.861206,121.211037 233.033676,115.734634 
	C233.239807,109.189491 239.726028,103.995308 244.995132,106.010727 
	C248.884598,107.498436 252.194870,109.666351 253.502914,113.960350 
	C253.817383,114.992691 254.227661,115.995834 255.052002,118.284180 
	C256.517700,116.019142 257.403717,114.553047 258.384521,113.153381 
	C260.781616,109.732674 262.600159,105.759781 267.936707,105.940987 
	C274.424042,106.161263 278.423981,110.690163 278.010834,117.188812 
	C277.663116,122.657661 278.205261,128.180420 277.921234,133.656906 
	C277.725891,137.423538 277.509338,141.551254 272.420502,143.071198 
z"/>
<path fill="#311B57" opacity="1.000000" stroke="none" 
	d="
M453.497314,144.070770 
	C454.001587,143.584152 454.925293,143.059372 455.848969,142.534592 
	C455.817993,142.213791 455.787018,141.892990 455.756073,141.572189 
	C452.546234,142.153992 449.336426,142.735809 446.033264,143.715179 
	C442.277252,143.559189 438.457153,143.457565 435.018799,142.259979 
	C433.318695,141.667831 431.270813,139.105972 431.186554,137.348694 
	C430.788208,129.044846 430.792084,120.702484 431.114319,112.391754 
	C431.267456,108.442307 438.419342,104.843002 442.308929,106.007889 
	C444.437164,106.645264 446.861847,107.237900 448.968048,106.849228 
	C461.995880,104.445129 471.853699,114.624123 468.773041,127.527863 
	C468.510071,128.629379 468.099670,129.726425 468.039307,130.840775 
	C467.514923,140.525253 463.900726,143.926834 453.497314,144.070770 
M464.949036,129.081146 
	C464.641144,126.050842 464.333252,123.020546 463.951599,119.187790 
	C464.304413,114.380814 461.875549,111.633965 457.307831,111.075378 
	C451.316345,110.342697 445.281311,109.791458 439.254120,109.658974 
	C437.896637,109.629128 435.747711,111.396690 435.274109,112.784302 
	C434.400696,115.343353 434.498566,118.233917 434.034821,121.864693 
	C434.080963,124.243935 434.127075,126.623169 434.019592,129.872620 
	C434.017212,131.360596 434.001892,132.848679 434.015045,134.336533 
	C434.046570,137.904083 435.855011,139.889008 439.415924,139.951248 
	C444.870087,140.046616 450.338989,140.201340 455.780090,139.917465 
	C462.863464,139.547913 464.868500,137.134491 464.949036,129.081146 
z"/>
<path fill="#F9EE05" opacity="1.000000" stroke="none" 
	d="
M146.020737,27.962845 
	C144.895737,29.887417 143.770752,31.811987 142.132294,33.721985 
	C139.857986,36.284809 137.600067,35.825191 135.374634,34.576115 
	C132.204590,32.796841 129.119354,30.866480 125.793388,28.768663 
	C123.731110,30.020971 121.873322,31.505278 119.688820,33.019588 
	C118.232689,33.018330 117.103279,32.987064 116.010406,32.610779 
	C117.057907,30.110041 116.766716,28.753717 114.002487,28.534798 
	C114.478554,24.097347 115.631783,20.494719 120.316650,18.824511 
	C122.799980,17.739386 124.964706,16.822788 127.392868,16.046570 
	C128.470901,16.742456 129.285492,17.297962 130.076111,18.223925 
	C130.247955,20.865656 131.008453,21.831272 133.359802,19.921246 
	C134.130997,19.966272 134.571976,19.972122 135.012939,19.977970 
	C135.202652,20.250101 135.422409,20.495325 135.827698,21.240349 
	C136.364365,22.520058 136.745483,23.273056 137.305481,24.270161 
	C140.329803,25.663795 143.175278,26.813320 146.020737,27.962845 
z"/>
<path fill="#29186C" opacity="1.000000" stroke="none" 
	d="
M461.850403,198.043976 
	C461.850403,198.043961 461.418335,198.005325 461.080688,197.730820 
	C460.506195,197.221161 460.221619,197.088104 459.889282,197.057144 
	C459.889282,197.057144 459.967773,197.036224 459.862793,196.828369 
	C459.468353,196.428955 459.178864,196.237411 458.889374,196.045868 
	C458.889374,196.045868 458.967346,196.034286 458.860168,195.824890 
	C458.459930,195.423355 458.166840,195.231201 457.873749,195.039047 
	C457.458771,194.743866 457.043823,194.448700 456.085754,193.894150 
	C455.345917,192.710754 455.149200,191.786758 454.952484,190.862762 
	C454.952484,190.862762 454.995331,190.408035 455.270264,190.062637 
	C455.773163,189.485046 455.907288,189.207520 455.947601,188.884644 
	C457.071381,182.680374 458.233276,176.482666 459.300598,170.268692 
	C459.740051,167.710098 459.976013,165.116547 460.306305,162.525040 
	C466.752380,161.443008 470.736359,163.975525 469.932983,168.846909 
	C469.312439,172.609772 468.300964,176.306778 467.524811,180.045822 
	C466.967377,182.731369 466.160522,185.435669 466.142731,188.136047 
	C466.132202,189.736786 467.438110,191.346191 468.564331,193.333725 
	C469.003448,194.841553 469.030365,195.967346 469.057281,197.093155 
	C469.057281,197.093155 469.026611,197.017517 468.821899,197.129547 
	C468.424835,197.527405 468.232513,197.813217 468.040161,198.099030 
	C468.040161,198.099030 468.015289,198.021805 467.607056,198.020691 
	C465.416046,198.027710 463.633209,198.035843 461.850403,198.043976 
z"/>
<path fill="#29186C" opacity="1.000000" stroke="none" 
	d="
M471.004456,190.841232 
	C473.011230,183.166626 475.017975,175.492020 477.378479,167.231415 
	C478.489288,165.149506 479.246307,163.653580 480.003326,162.157639 
	C480.452271,162.108429 480.901215,162.059204 481.818115,162.285004 
	C482.524902,162.787537 482.808197,162.919876 483.135956,162.957047 
	C483.135956,162.957047 483.586456,162.992172 483.928711,163.271210 
	C484.504791,163.779739 484.784576,163.911728 485.110382,163.946198 
	C485.110382,163.946198 485.032898,163.966370 485.137695,164.172852 
	C485.530457,164.571396 485.818481,164.763443 486.106476,164.955475 
	C486.106476,164.955475 486.027924,164.968689 486.102234,165.154526 
	C486.316101,165.632889 486.532990,165.847198 486.827179,165.983276 
	C486.161591,170.203461 485.495972,174.423660 484.191895,179.027832 
	C483.381989,180.316742 483.210541,181.221664 483.039062,182.126602 
	C483.039062,182.126602 482.981659,182.592392 482.662811,183.075302 
	C482.251678,184.740356 482.159393,185.922485 482.067108,187.104630 
	C482.067108,187.104630 482.050598,187.025787 481.791687,187.231522 
	C481.733246,188.945358 481.933655,190.453461 482.134094,191.961563 
	C482.134094,191.961563 482.044525,191.968384 482.146576,192.170456 
	C482.535828,192.565338 482.823059,192.758148 483.110291,192.950958 
	C483.110291,192.950958 483.029663,192.975143 483.027710,193.363647 
	C483.715210,198.040268 480.974243,198.251984 477.898987,198.066483 
	C477.898987,198.066483 477.983093,198.021988 477.945435,197.707794 
	C476.657837,196.592224 475.407928,195.790848 474.158020,194.989456 
	C474.158020,194.989456 473.988373,194.582733 473.869965,194.113586 
	C473.460205,193.447662 473.168854,193.250885 472.877533,193.054108 
	C472.877533,193.054108 472.498108,193.035507 472.036560,192.864838 
	C471.384796,192.076523 471.194611,191.458878 471.004456,190.841232 
z"/>
<path fill="#F9CC38" opacity="1.000000" stroke="none" 
	d="
M229.760971,126.103706 
	C229.863541,131.418747 229.966095,136.733795 229.757263,142.755280 
	C229.171188,145.040543 228.896484,146.619385 228.587280,148.396576 
	C226.031738,147.772659 223.209274,146.785065 222.533447,150.983887 
	C219.738693,150.997772 217.383713,151.008774 214.796356,150.744476 
	C213.385361,150.322220 212.206757,150.175262 211.025085,149.690216 
	C211.376053,148.343201 211.730072,147.334259 212.428940,145.342545 
	C208.843094,144.871033 205.430298,144.422256 202.006546,143.560196 
	C202.001892,141.766891 202.008209,140.386887 202.331329,139.126282 
	C205.434082,139.824036 208.220016,140.402405 211.148987,141.212631 
	C211.869171,141.287537 212.446335,141.130615 213.262482,140.970230 
	C213.501465,140.966782 213.979431,140.973633 214.171448,141.244720 
	C216.586075,141.338989 218.808716,141.162155 221.381470,140.936859 
	C222.738358,139.295853 223.745117,137.703339 224.890930,135.890839 
	C225.729218,135.757202 227.066299,135.544037 228.614548,135.297211 
	C225.654373,130.305115 225.654373,130.305115 229.760971,126.103706 
z"/>
<path fill="#FDE5AD" opacity="1.000000" stroke="none" 
	d="
M65.963272,263.998444 
	C65.140152,263.815216 64.317024,263.631958 63.566185,262.957275 
	C64.365463,261.387024 65.092445,260.308258 66.711754,257.905365 
	C64.436775,259.412506 63.352512,260.130798 62.268253,260.849091 
	C62.160500,260.057617 62.052746,259.266113 62.299622,257.950439 
	C64.880264,256.331085 67.106277,255.235916 69.417358,254.098907 
	C67.835808,253.426590 65.054718,253.133377 64.380775,251.786102 
	C62.578117,248.182404 59.959209,248.487610 56.698589,249.035385 
	C56.263809,249.069138 56.090775,249.092682 55.917740,249.116241 
	C55.917740,249.116241 55.976234,249.100525 55.985023,248.886978 
	C55.993809,248.673431 56.045204,248.260818 56.079445,247.883240 
	C56.075897,247.003540 56.038113,246.501434 56.123596,245.728775 
	C56.827053,244.667557 57.407238,243.876892 58.346882,243.074493 
	C60.238251,244.349808 61.140148,247.402130 64.401733,245.004944 
	C65.859406,245.125351 66.920502,245.194702 67.995819,245.600632 
	C68.719894,246.606277 69.429756,247.275375 70.374718,248.043503 
	C71.055763,248.353912 71.501709,248.565277 72.292458,248.696838 
	C76.466644,248.258850 78.968781,245.914062 81.113998,242.748138 
	C81.151543,242.517426 81.245903,242.059525 81.597244,242.044586 
	C83.911903,243.060410 85.875237,244.091187 87.896393,245.482132 
	C87.275078,247.530304 86.595932,249.218292 85.643967,251.016846 
	C84.544037,251.099640 83.716919,251.071884 82.857292,250.829285 
	C82.507057,250.712250 82.189331,250.810043 81.667984,251.008148 
	C81.206314,251.284348 81.021667,251.518188 80.566467,251.806915 
	C75.588150,249.619843 75.705948,253.326645 74.671005,256.040894 
	C72.455795,256.618225 70.571808,257.169739 67.662956,258.021301 
	C69.731346,258.510162 70.903992,258.787323 71.903908,259.375397 
	C69.808540,261.123688 67.885910,262.561066 65.963272,263.998444 
M69.567436,249.619919 
	C68.871803,249.610458 68.176170,249.601013 67.480537,249.591553 
	C67.646210,250.139999 67.811890,250.688461 67.977562,251.236908 
	C68.551270,250.893494 69.124985,250.550079 69.567436,249.619919 
z"/>
<path fill="#F6E314" opacity="1.000000" stroke="none" 
	d="
M185.130493,51.811058 
	C186.847076,52.870590 188.563660,53.930122 190.457062,55.294907 
	C189.464325,56.699909 188.294769,57.799664 186.909149,59.076378 
	C187.268112,60.540394 187.843124,61.827454 188.625336,63.578297 
	C185.684875,63.357456 183.349197,63.182034 180.843536,62.770771 
	C179.770950,62.692570 178.868362,62.850208 177.668243,62.859741 
	C175.833252,62.516304 174.048782,61.763908 172.836304,62.301529 
	C171.862411,62.733353 171.581558,64.728149 171.111832,66.248047 
	C171.443436,66.735260 171.711197,66.927467 172.027863,67.278389 
	C172.021957,67.513435 172.007553,67.983444 171.631775,67.990067 
	C170.504623,67.996727 169.753220,67.996765 169.000702,67.593689 
	C169.152222,63.138321 170.324356,58.533718 164.207001,57.714958 
	C165.288483,57.052601 166.272232,56.184639 167.045105,56.343365 
	C172.067108,57.374748 173.316589,54.816471 172.624329,50.727989 
	C174.270035,51.565407 175.695023,52.290508 177.365158,53.074886 
	C177.961426,51.792641 178.312546,50.451118 178.744888,48.799294 
	C180.769073,49.629398 182.395493,50.296379 184.021896,50.963356 
	C184.021896,50.963356 184.010071,51.000336 184.146576,51.195641 
	C184.496475,51.670277 184.778961,51.810314 185.130493,51.811058 
z"/>
<path fill="#F9EE32" opacity="1.000000" stroke="none" 
	d="
M46.814476,50.809383 
	C47.280285,50.484882 47.746094,50.160385 48.712784,49.721809 
	C49.440498,49.359539 49.667324,49.111347 49.894150,48.863152 
	C57.677750,43.904594 65.461349,38.946041 73.611008,34.195419 
	C73.989197,34.608524 74.001328,34.813694 74.035881,35.361713 
	C74.706352,37.137184 75.354401,38.569813 75.938599,40.352844 
	C74.572182,43.139523 73.269600,45.575794 71.956657,48.239342 
	C71.946297,48.466618 71.910439,48.920666 71.535385,48.916985 
	C68.981560,48.088699 66.815071,46.667370 64.620262,46.622234 
	C62.756542,46.583904 60.860153,48.133770 58.604500,48.982597 
	C56.286053,49.781368 54.295677,50.484894 52.410763,51.404564 
	C50.848789,52.166672 49.411152,53.183624 47.642914,53.852974 
	C47.183090,52.681984 46.998783,51.745682 46.814476,50.809383 
z"/>
<path fill="#FBE5B7" opacity="1.000000" stroke="none" 
	d="
M54.215729,249.190201 
	C54.043190,250.973587 53.870651,252.756973 53.698112,254.540344 
	C52.171284,253.931198 50.593582,253.424072 49.127430,252.693176 
	C45.180183,250.725494 41.280537,248.662308 37.485939,246.093903 
	C39.362156,244.261856 41.222183,243.093262 42.835033,241.648666 
	C44.316830,240.321487 45.277489,237.874664 46.915844,237.366837 
	C50.893841,236.133865 55.154701,235.842697 59.250393,234.939804 
	C60.280693,234.712677 61.118137,233.610718 62.317337,233.077515 
	C64.359390,236.322922 63.241871,238.132309 59.668411,239.014893 
	C57.459270,240.033768 55.628735,241.065918 53.765251,241.912506 
	C53.732304,241.726929 53.814625,241.359070 53.822819,241.142242 
	C53.555630,240.962051 53.280251,240.998672 52.635368,241.060806 
	C45.670906,243.413849 44.802124,245.121140 46.919823,248.239883 
	C49.545559,248.203430 50.758408,248.186584 52.185982,248.401031 
	C53.005714,248.818268 53.610722,249.004227 54.215729,249.190201 
z"/>
<path fill="#E69D5C" opacity="1.000000" stroke="none" 
	d="
M95.210442,274.936127 
	C95.210228,274.124023 95.210014,273.311951 95.130333,271.866913 
	C95.006523,270.199554 94.962196,269.165131 95.116776,267.833923 
	C96.404495,266.665833 97.493301,265.794556 98.790047,264.756897 
	C101.074768,268.847748 104.364738,269.017761 108.141159,268.157410 
	C108.818069,268.224609 109.372536,268.103088 110.214539,268.109619 
	C111.341057,269.156464 112.180038,270.075195 113.010551,271.323395 
	C113.039162,272.298920 113.076241,272.945038 112.986359,273.912323 
	C111.904213,275.486969 110.949020,276.740448 109.630119,278.034180 
	C107.159973,278.053009 105.053535,278.031586 102.653641,277.870789 
	C99.976936,276.799652 97.593689,275.867889 95.210442,274.936127 
z"/>
<path fill="#FDF908" opacity="1.000000" stroke="none" 
	d="
M95.712357,20.486477 
	C95.712357,20.486477 95.742386,20.155453 96.081741,20.004745 
	C96.631096,19.590708 96.841103,19.327377 97.051109,19.064047 
	C97.445396,18.921103 97.839684,18.778156 98.636215,18.818501 
	C99.322289,18.623724 99.606117,18.245657 99.889954,17.867592 
	C99.889954,17.867594 100.002701,17.899406 100.289062,17.920080 
	C100.995773,17.482742 101.416122,17.024731 101.836479,16.566719 
	C102.313026,16.244352 102.789574,15.921988 103.628204,15.963039 
	C108.367935,23.001846 107.500092,24.966282 99.763306,26.058025 
	C98.814003,26.191982 97.914459,26.678541 96.594864,27.002815 
	C93.014687,26.644129 92.855560,28.699846 93.002472,31.250484 
	C93.000366,31.499264 92.997139,31.996832 92.604866,32.004662 
	C89.898247,33.318737 87.583900,34.624981 84.603035,36.307419 
	C84.657257,36.351345 83.796402,35.654068 82.895340,34.626572 
	C82.212318,33.164310 81.569504,32.032276 80.926697,30.900240 
	C80.926697,30.900240 80.951866,31.007717 81.286530,30.988316 
	C83.821129,30.223854 86.021072,29.478798 88.798218,28.538258 
	C88.798218,27.550964 88.798218,26.158941 88.798218,24.766918 
	C88.798218,24.766918 88.943092,24.982805 89.210396,24.958927 
	C90.260376,24.577244 91.043060,24.219440 91.851021,23.816765 
	C91.876297,23.771894 91.975243,23.743269 92.201691,23.899570 
	C92.949066,23.702904 93.470001,23.349936 93.995163,22.998241 
	C93.999390,22.999512 93.997459,22.991146 94.356033,22.938519 
	C95.047195,22.086084 95.379776,21.286282 95.712357,20.486477 
z"/>
<path fill="#FAE406" opacity="1.000000" stroke="none" 
	d="
M211.971939,68.834274 
	C212.363007,68.936333 212.754059,69.038383 213.455353,69.520622 
	C213.765579,69.900803 213.959259,70.353706 214.085022,70.505592 
	C214.405548,70.844566 214.633331,70.973312 215.124939,71.129753 
	C215.661850,71.359177 215.905609,71.573479 216.071655,72.237457 
	C217.694717,73.726585 219.333130,74.836952 221.238800,76.168793 
	C221.926697,81.876060 217.525909,83.597260 213.599548,86.001984 
	C212.126892,85.996201 211.055664,85.990753 209.843323,85.656693 
	C210.122192,82.531265 210.542175,79.734451 210.941315,76.586327 
	C209.953217,75.809586 208.985977,75.384155 207.969513,74.642220 
	C207.278549,73.885361 206.636795,73.445015 205.994537,72.615570 
	C206.656509,70.488182 207.318970,68.749893 208.250320,67.128082 
	C209.005630,67.564583 209.492050,67.884598 210.114716,68.376953 
	C210.455826,68.771782 210.703857,68.923828 210.997543,69.002716 
	C211.000000,69.000000 210.994522,69.004776 211.061798,69.170357 
	C211.427872,69.330276 211.726685,69.324623 212.059525,69.192551 
	C212.093552,69.066132 211.971939,68.834274 211.971939,68.834274 
z"/>
<path fill="#FBF1D4" opacity="1.000000" stroke="none" 
	d="
M179.036926,255.010132 
	C176.040115,257.353302 173.043304,259.696503 169.641464,262.356354 
	C168.841766,260.556366 168.299347,259.335510 167.731522,258.057465 
	C164.907074,258.066589 162.788956,258.831573 162.651459,262.607422 
	C161.012161,262.554077 159.724121,262.161530 158.326904,261.427795 
	C156.743393,260.063629 155.269104,259.040619 153.957458,257.691193 
	C158.316711,255.154678 162.513321,252.944595 167.041718,250.826050 
	C167.920471,251.280258 168.467468,251.642944 169.005920,252.339462 
	C169.394638,257.554749 172.658859,254.976959 175.213074,254.874298 
	C175.990021,254.526794 176.493713,254.262314 177.260040,254.108337 
	C178.027435,254.482620 178.532181,254.746384 179.036926,255.010132 
M161.533997,257.614349 
	C161.478638,257.834412 161.423294,258.054474 161.367935,258.274536 
	C161.505615,258.133057 161.643280,257.991577 161.533997,257.614349 
z"/>
<path fill="#F59C1A" opacity="1.000000" stroke="none" 
	d="
M274.953064,72.114304 
	C274.953064,72.114311 275.021606,72.572227 275.088074,73.036819 
	C275.412933,73.696548 275.671326,73.891685 275.929749,74.086830 
	C275.929749,74.086830 275.967499,73.989471 275.917786,74.429626 
	C275.980774,75.923470 276.093475,76.977142 276.206177,78.030823 
	C275.647186,78.960304 275.088226,79.889786 274.010193,80.996262 
	C273.315491,81.440224 273.139832,81.707184 272.964172,81.974136 
	C272.964172,81.974136 272.989136,82.001793 272.729156,82.047707 
	C272.289001,82.381035 272.108795,82.668449 271.928619,82.955856 
	C271.928619,82.955856 271.437805,83.018837 270.840912,83.014481 
	C269.505951,83.072365 268.767914,83.134605 268.029846,83.196846 
	C268.029846,83.196846 267.577393,83.021942 267.240234,82.742516 
	C266.605896,82.301544 266.308777,82.139992 266.011627,81.978447 
	C266.011627,81.978447 265.999664,82.000427 265.944427,81.742836 
	C265.595612,81.319946 265.302032,81.154648 265.008453,80.989349 
	C265.008453,80.989349 265.000000,81.000000 264.945038,80.740097 
	C264.609375,80.290726 264.328644,80.101265 264.047943,79.911812 
	C264.047943,79.911812 263.952820,79.432922 263.872009,78.967293 
	C263.649567,78.163895 263.397827,77.966942 263.035889,77.910805 
	C263.035889,77.910805 262.970673,77.432701 262.889313,76.968575 
	C262.685059,76.149818 262.437134,75.951408 262.064178,75.909218 
	C262.064178,75.909218 262.018066,76.001259 262.041870,75.643539 
	C261.407257,74.492828 260.748871,73.699844 260.090485,72.906860 
	C260.090485,72.906860 260.041870,73.014557 260.107910,72.725876 
	C260.173981,72.437187 260.310730,71.972214 260.672485,71.972076 
	C265.025208,71.637344 269.016174,71.302750 273.245422,71.001862 
	C273.483704,71.035576 273.964661,71.053818 273.964661,71.053818 
	C273.964661,71.053818 274.000671,71.002945 274.053101,71.266464 
	C274.388062,71.724754 274.670563,71.919533 274.953064,72.114304 
z"/>
<path fill="#F9E734" opacity="1.000000" stroke="none" 
	d="
M14.865121,71.512794 
	C20.295179,67.969315 25.725241,64.425835 31.470276,61.163765 
	C31.208076,64.940872 30.630899,68.436569 29.712379,71.955917 
	C28.916164,71.983612 28.461294,71.987663 27.950581,71.852928 
	C27.806898,71.665726 27.719055,71.617310 27.631214,71.568893 
	C27.751322,71.714806 27.871428,71.860725 27.989101,72.330284 
	C27.988503,73.085464 27.990335,73.516991 27.588470,73.960602 
	C24.775425,74.633629 22.366081,75.294571 19.613598,75.923492 
	C17.802010,74.431900 16.333565,72.972351 14.865121,71.512794 
M27.483673,69.596237 
	C27.483673,69.596237 27.411400,69.479706 27.483673,69.596237 
z"/>
<path fill="#3A194A" opacity="1.000000" stroke="none" 
	d="
M236.112045,140.452118 
	C235.999130,138.980652 236.028778,137.840698 236.389999,136.439896 
	C237.837830,137.096100 238.945374,138.735596 240.072525,138.749191 
	C241.514099,138.766586 244.096405,137.604965 244.200150,136.718811 
	C244.457565,134.519989 243.586349,132.189026 243.130493,129.524048 
	C243.606537,128.138657 243.898544,126.660027 244.702316,126.288422 
	C245.390610,125.970222 246.922287,126.665977 247.618927,127.370270 
	C248.609238,128.371475 249.176285,129.791336 249.947983,131.397308 
	C251.652634,134.234055 253.333466,136.705826 255.014313,139.177597 
	C253.766312,139.646179 252.518311,140.114761 251.091995,140.830994 
	C249.155075,139.714569 247.593033,139.260681 245.584503,141.016556 
	C242.559326,143.661163 239.293137,143.727921 236.112045,140.452118 
z"/>
<path fill="#FAC913" opacity="1.000000" stroke="none" 
	d="
M295.012543,60.976158 
	C295.045502,61.320419 295.190033,61.608719 295.684021,62.270119 
	C295.971252,63.122509 296.020691,63.545834 295.706421,63.942604 
	C289.891602,60.812775 284.442627,64.908852 278.759094,63.911461 
	C278.526031,63.902054 278.059998,63.922310 278.059998,63.922310 
	C278.059998,63.922310 277.999023,63.991184 278.023315,63.660522 
	C277.557495,62.754017 277.067383,62.178165 276.577271,61.602318 
	C276.577271,61.602322 276.483002,61.543835 276.399414,61.175346 
	C275.868866,59.538353 275.421875,58.269848 275.304260,56.859356 
	C276.702698,56.652229 277.977814,56.175159 278.806824,56.589874 
	C283.614441,58.994934 286.601440,56.777580 289.333191,53.068405 
	C290.715271,54.539516 291.821991,55.836857 292.959045,57.352974 
	C292.989380,57.571751 293.195526,57.962452 293.174927,58.219368 
	C293.413879,58.675575 293.673431,58.874870 293.932983,59.074158 
	C293.932983,59.074154 293.979675,58.996113 293.960846,59.323936 
	C294.298859,60.093220 294.655701,60.534687 295.012543,60.976158 
z"/>
<path fill="#E8AF22" opacity="1.000000" stroke="none" 
	d="
M229.832855,125.807304 
	C225.654373,130.305115 225.654373,130.305115 228.614548,135.297211 
	C227.066299,135.544037 225.729218,135.757202 224.890930,135.890839 
	C223.745117,137.703339 222.738358,139.295853 221.352600,140.633179 
	C220.947525,138.273193 220.921432,136.168411 220.894394,133.691040 
	C219.247696,131.541870 217.601929,129.765274 216.280060,127.950409 
	C219.414505,125.248283 222.225067,122.584427 225.318390,120.074509 
	C226.996872,121.789673 228.392593,123.350906 229.788315,124.912140 
	C229.827103,125.111725 229.865906,125.311310 229.832855,125.807304 
z"/>
<path fill="#FCE7BF" opacity="1.000000" stroke="none" 
	d="
M8.191948,227.285339 
	C11.150384,226.873062 13.848394,226.173996 15.133131,222.123871 
	C17.928865,218.344955 19.917107,220.813049 22.245998,222.300125 
	C24.900906,222.364120 27.300276,222.155212 29.824965,222.288025 
	C31.644936,223.750809 33.339588,224.871872 35.081551,226.341522 
	C35.204956,227.152695 35.281055,227.615265 34.956421,228.075363 
	C33.640194,228.753586 32.724693,229.434311 31.626869,230.215057 
	C31.230429,230.507721 31.087471,230.743576 30.734852,231.154434 
	C29.876547,232.068192 29.299059,232.850098 27.933556,234.698975 
	C27.679510,231.873611 27.642048,230.481430 27.407843,229.123184 
	C27.167622,227.730103 26.944950,225.312729 26.347187,225.194031 
	C24.553539,224.837891 22.429775,224.996933 20.710894,225.665115 
	C17.703688,226.834076 14.902199,228.532242 12.014506,230.008667 
	C10.781703,229.202347 9.548903,228.396011 8.191948,227.285339 
z"/>
<path fill="#FCF1CD" opacity="1.000000" stroke="none" 
	d="
M12.147869,230.310669 
	C14.902199,228.532242 17.703688,226.834076 20.710894,225.665115 
	C22.429775,224.996933 24.553539,224.837891 26.347187,225.194031 
	C26.944950,225.312729 27.167622,227.730103 27.407843,229.123184 
	C27.642048,230.481430 27.679510,231.873611 27.933556,234.698975 
	C29.299059,232.850098 29.876547,232.068192 30.757812,231.465424 
	C31.411091,232.131317 31.760593,232.618042 31.923697,233.388977 
	C30.130310,235.799255 28.523323,237.925339 26.916340,240.051422 
	C26.270227,238.875427 25.624113,237.699432 25.422235,237.332001 
	C22.655828,236.405472 20.343847,235.631149 18.294598,234.427979 
	C19.509134,233.767822 20.468019,233.561859 21.410887,233.298538 
	C22.505009,232.992950 23.585070,232.637024 24.671230,232.302948 
	C24.060730,231.383728 23.609919,229.906113 22.802568,229.675232 
	C21.569408,229.322556 19.712624,229.366806 18.814926,230.096619 
	C17.796234,230.924789 17.542425,232.693832 16.956078,234.053818 
	C15.397793,232.906769 13.839511,231.759705 12.147869,230.310669 
M26.474527,233.604706 
	C26.474527,233.604706 26.404375,233.491623 26.474527,233.604706 
z"/>
<path fill="#F9EE05" opacity="1.000000" stroke="none" 
	d="
M96.991852,27.001698 
	C97.914459,26.678541 98.814003,26.191982 99.763306,26.058025 
	C107.500092,24.966282 108.367935,23.001846 103.922089,15.955708 
	C104.724533,14.811377 105.595184,14.037797 107.085190,13.156203 
	C109.125443,12.353711 110.546333,11.659232 112.214890,10.991875 
	C112.462547,11.018995 112.960327,11.022579 112.974236,11.011248 
	C112.988144,10.999915 112.977760,11.032833 112.970703,11.279298 
	C112.963654,11.525763 112.982346,12.018836 113.073578,12.189735 
	C113.457016,12.258055 113.749207,12.155476 114.366455,12.040402 
	C115.124893,12.013600 115.558281,11.999291 116.124680,12.300727 
	C116.714005,15.850526 115.927742,18.108202 112.337173,19.151630 
	C107.481636,20.562662 107.236565,21.661816 109.706680,26.098803 
	C108.240814,26.843206 107.082687,27.443399 105.566750,28.197515 
	C102.346992,28.649820 99.164566,30.899279 96.991852,27.001698 
z"/>
<path fill="#F6E6D7" opacity="1.000000" stroke="none" 
	d="
M128.000519,288.989685 
	C124.866615,290.986023 121.727676,292.974548 118.600258,294.981018 
	C115.762032,296.801941 114.324562,295.875885 114.172989,291.931396 
	C114.901573,287.521912 115.473747,283.860382 116.264862,280.124695 
	C116.483803,280.050568 116.940407,279.978149 117.210060,279.998077 
	C117.659119,280.034760 117.838516,280.051483 118.060013,280.425110 
	C119.563179,282.782074 121.024246,284.782104 123.041573,287.543640 
	C122.948631,287.615051 123.712578,287.085205 124.409393,286.478241 
	C125.307350,285.696075 126.151543,284.852173 126.993530,284.338257 
	C127.312279,286.091003 127.656403,287.540314 128.000519,288.989685 
z"/>
<path fill="#FCF1CD" opacity="1.000000" stroke="none" 
	d="
M62.044205,232.910797 
	C61.118137,233.610718 60.280693,234.712677 59.250393,234.939804 
	C55.154701,235.842697 50.893841,236.133865 46.915844,237.366837 
	C45.277489,237.874664 44.316830,240.321487 42.835033,241.648666 
	C41.222183,243.093262 39.362156,244.261856 37.340858,245.766052 
	C36.450367,245.438171 35.828907,244.895035 35.248512,243.799698 
	C35.852573,241.839371 36.415569,240.431213 37.252747,238.801910 
	C38.067028,236.982895 38.607124,235.385025 39.441723,233.972046 
	C42.409702,235.279724 45.362499,237.265900 46.328613,232.228653 
	C48.433010,232.560486 50.219151,232.790909 52.405296,233.075134 
	C55.884933,233.056213 58.964569,232.983505 62.044205,232.910797 
z"/>
<path fill="#F9C515" opacity="1.000000" stroke="none" 
	d="
M239.006149,55.991592 
	C239.185043,56.276165 239.363922,56.560734 239.863037,57.154125 
	C240.378113,57.771072 240.657776,57.922050 241.022278,57.915878 
	C241.192169,58.209492 241.362061,58.503101 241.822174,59.152260 
	C242.393738,59.694382 242.675079,59.880955 242.956421,60.067528 
	C242.956421,60.067528 242.989777,59.997025 242.967224,60.365936 
	C243.661209,62.102879 244.377716,63.470905 245.024048,65.201019 
	C241.963318,66.046783 238.972794,66.530457 235.982254,67.014137 
	C235.982254,67.014137 236.000000,66.999741 235.985992,66.704910 
	C235.637924,65.945984 235.303848,65.481888 234.984253,65.016380 
	C234.998718,65.014961 234.982559,65.035606 234.951843,64.708054 
	C234.270767,63.927170 233.620392,63.473835 232.705231,62.925205 
	C231.961426,62.549812 231.482391,62.269711 231.003372,61.989609 
	C230.829956,61.712597 230.656525,61.435585 230.168182,60.833389 
	C229.572067,60.330143 229.290863,60.152077 229.009674,59.974007 
	C228.977173,59.619774 228.814056,59.342354 228.202789,58.811764 
	C227.591476,58.316147 227.297668,58.150513 227.003876,57.984879 
	C227.003876,57.984879 226.944427,57.508705 227.340652,57.257706 
	C231.071442,56.966423 235.062637,59.189480 237.259186,54.394051 
	C238.016754,54.975651 238.511444,55.483624 239.006149,55.991592 
z"/>
<path fill="#FAE7CE" opacity="1.000000" stroke="none" 
	d="
M86.008362,273.049103 
	C85.528999,273.894196 85.049644,274.739288 84.306053,276.050201 
	C79.943848,273.812836 75.700523,271.636444 71.617264,269.159363 
	C72.577553,267.892029 73.377785,266.925323 74.511475,265.961975 
	C77.487961,264.755157 80.130981,263.545013 81.455666,262.938477 
	C83.461266,263.848450 84.732117,264.425049 85.960327,265.313690 
	C85.947906,268.100189 85.978134,270.574646 86.008362,273.049103 
z"/>
<path fill="#F8E19E" opacity="1.000000" stroke="none" 
	d="
M205.246307,237.182114 
	C204.276718,238.482071 203.430893,239.904678 202.300476,241.045258 
	C201.300705,242.054016 199.654114,242.514343 198.866165,243.623810 
	C195.956375,247.720963 192.679642,248.443954 188.079346,245.055817 
	C186.959641,244.533493 186.427170,244.267822 185.532318,244.013977 
	C184.442596,244.021088 183.715271,244.016388 183.190521,243.718689 
	C183.945892,241.942093 184.498688,240.458481 185.467499,238.990326 
	C188.274246,239.335938 190.664978,239.666077 193.047806,240.346313 
	C193.375351,241.588882 193.710785,242.481400 194.507828,244.602081 
	C194.928650,242.090012 195.111847,240.996429 195.295029,239.902863 
	C195.636734,240.249283 195.978439,240.595703 196.320145,240.942139 
	C198.176666,239.531570 200.129700,238.228699 201.860443,236.677750 
	C203.352646,235.340591 204.493317,234.421265 205.246307,237.182114 
z"/>
<path fill="#FAF222" opacity="1.000000" stroke="none" 
	d="
M214.059753,43.029350 
	C214.626190,42.240364 215.192612,41.451378 216.324890,40.416458 
	C219.263367,40.440434 221.635941,40.710335 224.008530,40.980240 
	C224.132431,41.298401 224.333496,41.556427 224.904587,42.169479 
	C226.139877,43.372162 227.082306,44.159683 228.024734,44.947201 
	C228.322983,45.387123 228.621231,45.827049 228.808258,46.835152 
	C226.132507,48.811146 223.567963,50.218967 220.765228,51.719215 
	C220.361984,51.853790 220.196945,51.895943 220.031891,51.938095 
	C219.165405,51.048046 218.298920,50.157993 217.099030,48.941265 
	C216.586182,48.306236 216.331543,48.220066 216.001724,48.356071 
	C215.725067,48.058525 215.448410,47.760979 214.979065,46.980152 
	C214.547928,46.280716 214.309464,46.064556 214.070999,45.848400 
	C214.070999,45.848400 214.014206,45.391193 214.009369,44.871964 
	C214.022949,43.911606 214.041351,43.470474 214.059753,43.029350 
z"/>
<path fill="#F7B615" opacity="1.000000" stroke="none" 
	d="
M278.992126,63.920868 
	C284.442627,64.908852 289.891602,60.812775 295.961609,64.096069 
	C297.021942,64.902603 297.463409,65.529114 297.904877,66.155624 
	C297.904877,66.155624 297.959625,66.594803 297.990967,67.088486 
	C298.335052,68.059746 298.647797,68.537315 298.960541,69.014893 
	C298.965820,69.450951 298.971069,69.886993 298.732117,70.656029 
	C298.487915,70.989014 298.048370,71.021545 297.733307,70.793625 
	C295.980255,70.045517 294.535248,69.073364 293.105682,69.095589 
	C289.724609,69.148148 286.350861,69.673340 282.747894,69.973289 
	C282.521637,69.939140 282.066284,69.893669 282.066284,69.893677 
	C281.751221,69.425217 281.436188,68.956757 280.995728,67.983665 
	C280.595734,67.298622 280.321167,67.118217 280.046600,66.937805 
	C280.046600,66.937813 280.013611,67.006081 280.012207,66.653328 
	C279.671234,65.507339 279.331665,64.714104 278.992126,63.920868 
z"/>
<path fill="#F9DD0F" opacity="1.000000" stroke="none" 
	d="
M221.003433,51.626785 
	C223.567963,50.218967 226.132507,48.811146 228.900513,47.086700 
	C229.482361,46.851208 229.860748,46.932339 230.660583,47.365982 
	C233.053467,49.919140 235.024887,52.119778 236.996307,54.320419 
	C235.062637,59.189480 231.071442,56.966423 227.321930,57.018505 
	C226.906967,57.030304 226.433792,57.034443 226.132355,56.779629 
	C225.551468,56.356560 225.272034,56.188301 224.992584,56.020042 
	C224.825256,55.735245 224.657928,55.450447 224.185669,54.827240 
	C223.589676,54.323559 223.298599,54.158291 223.007538,53.993023 
	C223.007538,53.993023 223.000000,54.000000 222.918411,53.691856 
	C222.225693,52.798069 221.614563,52.212429 221.003433,51.626785 
z"/>
<path fill="#F9DF3B" opacity="1.000000" stroke="none" 
	d="
M229.901917,124.466064 
	C228.392593,123.350906 226.996872,121.789673 225.302078,119.711571 
	C222.717499,117.145668 220.431992,115.096626 217.184753,112.185371 
	C221.693024,111.291100 225.171936,110.601013 227.325073,110.173912 
	C228.369766,106.352005 229.107651,103.652512 229.917587,101.219086 
	C229.976456,101.845673 229.963272,102.206207 229.950089,102.566742 
	C229.971893,109.717827 229.993698,116.868904 229.901917,124.466064 
z"/>
<path fill="#FDF8E4" opacity="1.000000" stroke="none" 
	d="
M220.028152,228.049728 
	C217.527924,230.330124 215.135086,232.746643 212.485077,234.837189 
	C211.017960,235.994583 209.120499,236.606506 207.235443,236.891693 
	C207.265381,235.161758 207.476913,234.004425 207.764771,232.429535 
	C204.665115,233.041580 202.251770,233.518097 199.879120,233.641113 
	C198.644592,231.514328 197.369370,229.741074 196.233612,227.651031 
	C198.575333,227.266907 200.777557,227.199570 202.984375,227.494370 
	C203.245651,228.838593 203.502335,229.820663 203.849518,231.148941 
	C205.499664,229.899628 206.663498,229.018494 207.253906,228.571503 
	C209.913895,229.126282 211.947113,229.550354 214.302490,229.943054 
	C215.084091,229.279984 215.543518,228.648254 216.286957,227.893631 
	C217.711243,227.836960 218.851547,227.903183 219.999222,227.990143 
	C220.006607,228.010864 220.028152,228.049728 220.028152,228.049728 
z"/>
<path fill="#F2BC69" opacity="1.000000" stroke="none" 
	d="
M230.117416,197.992447 
	C230.111816,199.050247 230.106216,200.108047 230.076904,202.022461 
	C229.989655,207.547363 229.926117,212.215652 229.862579,216.883942 
	C229.862579,216.883942 230.012436,216.945953 229.601501,216.904022 
	C227.796234,217.546188 226.401886,218.230255 224.886200,218.643402 
	C224.220032,217.558731 223.675217,216.745010 223.504761,215.939224 
	C229.454163,214.324051 230.094894,212.941925 228.033157,206.558685 
	C226.642151,203.392502 225.264877,200.624466 223.887604,197.856430 
	C223.783386,196.371155 225.101822,194.327957 226.737564,192.582077 
	C229.386780,189.754486 229.666962,187.011215 227.402893,183.992065 
	C228.425034,182.602081 229.114426,181.285614 229.908905,180.412918 
	C230.117691,183.593063 230.221405,186.329422 230.325119,189.065796 
	C230.216934,190.438263 230.108765,191.810730 229.985748,193.989166 
	C230.019730,195.860916 230.068573,196.926682 230.117416,197.992447 
z"/>
<path fill="#FDFEFC" opacity="1.000000" stroke="none" 
	d="
M270.994324,27.095819 
	C266.078583,22.681341 261.359558,17.947132 256.308289,12.862362 
	C255.975998,12.511799 255.888885,12.032352 256.105316,11.925289 
	C256.202728,11.216912 256.083740,10.615601 255.964722,10.014289 
	C260.215424,7.514646 263.106354,10.847270 264.125854,13.471628 
	C266.458923,19.477341 270.863953,22.667891 276.159607,25.299191 
	C276.352448,25.395016 276.363190,25.857376 276.569214,26.485550 
	C274.717468,26.585587 272.954254,26.680840 270.994324,27.095819 
z"/>
<path fill="#F6A61A" opacity="1.000000" stroke="none" 
	d="
M282.974152,70.007439 
	C286.350861,69.673340 289.724609,69.148148 293.105682,69.095589 
	C294.535248,69.073364 295.980255,70.045517 297.693848,71.152016 
	C297.925140,72.456245 297.880798,73.174164 297.836456,73.892075 
	C297.293213,74.406647 296.749939,74.921219 295.834442,75.758575 
	C295.267914,76.365349 295.073608,76.649315 294.879333,76.933289 
	C294.879333,76.933289 294.415375,77.007866 293.892303,77.022438 
	C292.860504,77.310226 292.351776,77.583443 291.843048,77.856659 
	C291.843048,77.856659 291.396637,77.892067 290.894287,77.773277 
	C289.277740,77.414703 288.163483,77.174911 287.049255,76.935127 
	C286.963104,76.613312 286.793152,76.344482 286.216888,75.755447 
	C285.286377,74.896515 284.678375,74.410789 284.070374,73.925064 
	C284.070374,73.925064 284.103149,73.953674 284.081299,73.588646 
	C283.697693,72.151558 283.335907,71.079498 282.974152,70.007439 
z"/>
<path fill="#FAF222" opacity="1.000000" stroke="none" 
	d="
M270.966034,51.982670 
	C270.966034,51.982670 270.558960,51.957336 270.305634,51.621094 
	C269.383026,50.505684 268.713745,49.726513 268.044434,48.947350 
	C268.044434,48.947350 268.007965,49.004814 267.999084,48.664017 
	C267.333527,47.540127 266.676880,46.757030 266.020203,45.973938 
	C266.020203,45.973942 266.000000,46.000305 265.950195,45.716076 
	C265.656952,44.938610 265.413544,44.445374 265.170105,43.952141 
	C270.712158,43.917110 276.254181,43.882084 282.508179,43.878876 
	C283.796295,44.305492 284.372437,44.700291 284.948578,45.095093 
	C285.179047,45.624378 285.409515,46.153664 285.864197,47.057571 
	C286.088440,47.432194 286.077240,47.913345 286.031830,47.968056 
	C285.986420,48.022762 285.897827,48.134010 285.539062,48.096745 
	C280.891541,48.747692 276.591858,49.378849 272.327484,50.194622 
	C271.779694,50.299412 271.415344,51.363155 270.966034,51.982670 
z"/>
<path fill="#F8EA23" opacity="1.000000" stroke="none" 
	d="
M214.000946,86.002312 
	C217.525909,83.597260 221.926697,81.876060 221.249115,75.890182 
	C220.986145,75.017769 220.980103,74.645439 220.974060,74.273117 
	C223.615692,75.903816 226.257294,77.534515 228.895264,79.716599 
	C228.623871,80.513672 228.356110,80.759361 227.807037,81.196556 
	C226.473129,82.272148 225.362274,83.097557 224.389999,84.062576 
	C223.817474,84.630836 223.464828,85.420639 223.009644,86.420250 
	C223.004135,87.142967 223.002228,87.556236 222.600311,87.976501 
	C221.133469,87.988106 220.066620,87.992691 218.999893,87.998787 
	C219.000000,88.000290 219.003113,88.001938 218.898254,87.775879 
	C218.587814,87.270782 218.317978,87.085152 217.620331,87.034302 
	C215.506866,87.720474 213.756927,88.365273 211.898956,89.227295 
	C211.593628,89.719711 211.330643,89.898094 211.007965,89.625153 
	C212.009644,88.181206 213.005295,87.091759 214.000946,86.002312 
z"/>
<path fill="#F9C388" opacity="1.000000" stroke="none" 
	d="
M94.985641,275.188354 
	C97.593689,275.867889 99.976936,276.799652 102.661392,278.246063 
	C101.916794,281.212311 100.870987,283.663879 99.825172,286.115479 
	C95.858261,283.415497 91.891350,280.715515 87.924438,278.015533 
	C88.188164,277.454163 88.451889,276.892822 88.715614,276.331451 
	C90.730690,276.034515 92.745773,275.737549 94.985641,275.188354 
z"/>
<path fill="#F28B21" opacity="1.000000" stroke="none" 
	d="
M257.408569,82.839058 
	C257.333679,83.023827 257.258789,83.208588 257.215210,83.690605 
	C257.188324,84.188148 257.118011,84.384087 256.679688,84.869385 
	C254.211807,87.020340 252.134613,89.022224 249.068634,86.904358 
	C248.425964,86.112099 247.783310,85.319824 247.001434,84.002220 
	C246.592178,83.288025 246.322144,83.099167 246.052109,82.910309 
	C246.052109,82.910309 245.940826,82.437950 245.857513,81.979858 
	C245.617416,81.197540 245.359467,81.021690 245.000351,80.994225 
	C245.000351,80.994225 244.999390,81.000206 244.929886,80.685188 
	C244.535629,79.586800 244.210876,78.803429 244.227722,78.014359 
	C245.024628,77.995811 245.479950,77.982948 246.266449,78.118179 
	C248.412628,77.837303 250.227646,77.408340 252.369232,76.985519 
	C253.130981,76.984833 253.566177,76.977997 254.012939,76.978455 
	C254.024521,76.985764 254.039413,77.008736 254.095459,77.208160 
	C254.286331,77.663040 254.487595,77.845024 254.891647,78.202774 
	C255.329086,78.661285 255.630188,78.870560 255.931290,79.079842 
	C255.931290,79.079842 255.985657,78.994942 255.982635,79.356201 
	C256.469910,80.671600 256.960175,81.625732 257.447876,82.645950 
	C257.445312,82.712021 257.408569,82.839058 257.408569,82.839058 
z"/>
<path fill="#F3BF79" opacity="1.000000" stroke="none" 
	d="
M158.436096,261.768982 
	C159.724121,262.161530 161.012161,262.554077 162.633270,262.999634 
	C159.442352,266.215942 155.918350,269.379242 151.713806,272.736755 
	C147.258621,273.297028 146.792740,270.972168 147.343262,267.814545 
	C148.841888,267.058716 150.106827,266.622620 151.263626,265.989075 
	C152.224930,265.462585 153.059036,264.703888 154.309021,264.015259 
	C155.924393,263.244659 157.180237,262.506805 158.436096,261.768982 
z"/>
<path fill="#FBF1D4" opacity="1.000000" stroke="none" 
	d="
M128.353119,288.952087 
	C127.656403,287.540314 127.312279,286.091003 127.349152,284.324371 
	C132.071747,283.389252 128.376343,279.694183 130.309692,277.920715 
	C132.699860,277.376770 135.842300,278.927734 136.271255,274.962891 
	C136.507690,275.006653 136.977661,275.108643 137.128098,275.400391 
	C138.050049,276.886353 138.821564,278.080597 139.437744,279.034393 
	C141.025513,278.699677 142.538483,278.380707 144.051453,278.061768 
	C143.539154,278.686371 143.079575,279.798706 142.506454,279.860626 
	C136.326599,280.528625 133.487289,286.196228 128.353119,288.952087 
z"/>
<path fill="#F6CDA3" opacity="1.000000" stroke="none" 
	d="
M144.348022,277.890991 
	C142.538483,278.380707 141.025513,278.699677 139.437744,279.034393 
	C138.821564,278.080597 138.050049,276.886353 137.143066,274.997345 
	C137.043869,273.501099 137.080154,272.699615 137.447540,271.889343 
	C138.835007,271.557098 139.891388,271.233612 141.318573,270.926544 
	C143.115753,269.951050 144.542084,268.959167 145.984207,267.983643 
	C146.000000,268.000000 145.966766,267.968506 146.241028,267.984924 
	C146.697784,268.017151 146.880280,268.032959 147.062759,268.048798 
	C146.792740,270.972168 147.258621,273.297028 151.407379,272.911133 
	C149.402542,274.500916 147.023575,276.110596 144.348022,277.890991 
z"/>
<path fill="#F5D216" opacity="1.000000" stroke="none" 
	d="
M14.624538,71.574326 
	C16.333565,72.972351 17.802010,74.431900 19.769297,76.171852 
	C20.831644,76.965889 21.395147,77.479530 21.671478,78.022240 
	C20.929550,78.361572 20.474792,78.671837 19.799168,79.091743 
	C19.301092,79.400208 19.112949,79.662819 18.884392,80.286758 
	C18.497015,81.398499 18.239115,82.212723 17.761028,82.939941 
	C17.257629,82.683990 17.059608,82.444580 16.887508,81.939926 
	C16.567984,81.873344 16.307732,82.001534 16.056900,82.438416 
	C16.059748,83.158508 16.053177,83.569916 16.046608,83.981323 
	C15.281590,83.986244 14.516572,83.991165 13.368521,83.995605 
	C11.248151,83.339645 9.510816,82.684166 7.129058,81.902344 
	C5.984016,81.175980 5.483397,80.575981 4.982777,79.975975 
	C7.459575,77.701950 9.928808,78.862114 13.236376,78.851753 
	C13.390356,77.624428 13.701040,75.148064 13.996354,72.298935 
	C13.980985,71.926163 14.383955,71.635857 14.624538,71.574326 
z"/>
<path fill="#FDF4DD" opacity="1.000000" stroke="none" 
	d="
M225.007538,218.914337 
	C226.401886,218.230255 227.796234,217.546188 229.532074,216.861374 
	C229.765442,219.457306 230.273788,222.284180 229.409531,224.598480 
	C227.993546,228.390228 224.262527,228.175797 220.450500,228.030731 
	C220.028152,228.049728 220.006607,228.010864 220.156616,227.650421 
	C220.648315,225.585175 220.989990,223.880341 221.331665,222.175522 
	C221.642227,222.319443 221.952774,222.463364 222.263321,222.607300 
	C223.178055,221.376312 224.092804,220.145325 225.007538,218.914337 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M478.099365,198.414307 
	C480.974243,198.251984 483.715210,198.040268 483.043030,193.324432 
	C489.615631,190.654160 489.809723,184.576767 491.615417,179.072083 
	C492.142944,180.118790 492.332001,181.360321 492.810120,182.846115 
	C491.618317,186.989914 490.588196,191.149292 488.531586,194.716919 
	C486.417175,198.384872 482.693237,199.891174 478.099365,198.414307 
z"/>
<path fill="#F6A92B" opacity="1.000000" stroke="none" 
	d="
M245.935272,77.970093 
	C245.479950,77.982948 245.024628,77.995811 243.973999,77.920769 
	C242.937363,77.538170 242.496017,77.243477 242.054672,76.948784 
	C242.054672,76.948784 242.001221,76.999695 241.998108,76.654480 
	C241.344635,75.524414 240.694260,74.739555 240.043884,73.954697 
	C240.043884,73.954697 240.003937,74.002182 240.000610,73.659584 
	C239.348541,72.524841 238.699799,71.732697 238.051056,70.940559 
	C238.051056,70.940559 238.009323,71.005081 238.004272,70.659088 
	C237.342682,69.522766 236.686127,68.732430 236.029556,67.942093 
	C236.029556,67.942101 235.975800,67.479607 235.979034,67.246872 
	C238.972794,66.530457 241.963318,66.046783 245.331085,65.261482 
	C246.123474,65.009186 246.538605,65.058517 246.953751,65.107849 
	C246.953751,65.107849 247.064316,65.565697 247.158905,66.014275 
	C247.398773,66.793373 247.651947,66.958557 248.013016,66.958412 
	C248.013016,66.958412 247.997864,67.002060 248.081024,67.244186 
	C248.436249,67.677055 248.708282,67.867790 248.980331,68.058525 
	C248.980331,68.058525 249.057098,68.539833 249.056030,69.017303 
	C249.021408,69.670410 248.987869,69.846039 248.527588,70.019218 
	C246.110107,70.162193 244.119370,70.307602 241.148956,70.524582 
	C243.146210,73.631477 244.540741,75.800781 245.935272,77.970093 
z"/>
<path fill="#FAE7CE" opacity="1.000000" stroke="none" 
	d="
M199.838425,233.994629 
	C202.251770,233.518097 204.665115,233.041580 207.764771,232.429535 
	C207.476913,234.004425 207.265381,235.161758 207.025574,236.651184 
	C206.605301,237.017899 206.213318,237.052505 205.533813,237.134613 
	C204.493317,234.421265 203.352646,235.340591 201.860443,236.677750 
	C200.129700,238.228699 198.176666,239.531570 196.320145,240.942139 
	C195.978439,240.595703 195.636734,240.249283 195.295029,239.902863 
	C195.111847,240.996429 194.928650,242.090012 194.507828,244.602081 
	C193.710785,242.481400 193.375351,241.588882 193.262146,240.219086 
	C193.645447,239.455475 193.806473,239.169159 194.111069,238.572906 
	C194.834213,236.839157 195.413773,235.415329 195.996658,233.995697 
	C195.999969,233.999893 196.007919,234.006088 196.196960,234.254211 
	C197.536804,234.333084 198.687622,234.163849 199.838425,233.994629 
z"/>
<path fill="#FAE1A8" opacity="1.000000" stroke="none" 
	d="
M56.960335,249.025162 
	C59.959209,248.487610 62.578117,248.182404 64.380775,251.786102 
	C65.054718,253.133377 67.835808,253.426590 69.417358,254.098907 
	C67.106277,255.235916 64.880264,256.331085 62.408764,257.663452 
	C60.449474,256.169983 58.735672,254.439331 57.013599,251.973404 
	C56.990334,250.500488 56.975334,249.762817 56.960335,249.025162 
z"/>
<path fill="#FCD9BA" opacity="1.000000" stroke="none" 
	d="
M86.420166,273.037933 
	C85.978134,270.574646 85.947906,268.100189 86.330215,265.307861 
	C87.482307,264.991150 88.221870,264.992310 89.271805,265.135376 
	C91.360748,266.228424 93.139305,267.179565 94.917870,268.130707 
	C94.962196,269.165131 95.006523,270.199554 95.015198,271.620850 
	C92.263687,272.347412 89.547829,272.687103 86.420166,273.037933 
z"/>
<path fill="#29196B" opacity="1.000000" stroke="none" 
	d="
M330.989685,163.347656 
	C331.568726,163.226120 332.347687,163.548248 332.318268,163.662781 
	C331.069641,168.525085 329.748901,173.368866 328.378265,178.432220 
	C331.506897,183.202606 331.506897,183.202606 325.181763,187.789612 
	C326.854706,179.875961 328.833618,171.785217 330.989685,163.347656 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M376.326416,197.831726 
	C376.123230,196.337006 376.267517,194.828720 376.149048,193.127289 
	C377.843658,189.145065 379.764069,185.336075 381.784424,181.580872 
	C382.288788,180.643463 383.115448,179.879440 383.793976,179.035736 
	C383.919037,180.201889 384.508942,181.578445 384.092133,182.499344 
	C381.753601,187.666351 379.172333,192.723511 376.326416,197.831726 
z"/>
<path fill="#FAF586" opacity="1.000000" stroke="none" 
	d="
M111.967232,10.964754 
	C110.546333,11.659232 109.125443,12.353711 107.345871,13.015956 
	C111.552040,7.727371 116.027466,6.695336 121.494835,10.020372 
	C125.109413,12.218616 128.474167,14.827637 131.685165,17.621840 
	C130.979736,17.945110 130.539902,17.899290 130.100082,17.853470 
	C129.285492,17.297962 128.470901,16.742456 127.147324,15.777752 
	C124.743271,14.918333 122.848198,14.468113 120.973007,13.671328 
	C120.356087,12.529970 119.719284,11.735179 118.895035,10.676215 
	C116.811020,10.311090 114.914444,10.210135 112.785049,10.168944 
	C112.252213,10.390561 112.057213,10.635910 111.967232,10.964754 
z"/>
<path fill="#FAF586" opacity="1.000000" stroke="none" 
	d="
M46.458298,50.836552 
	C46.998783,51.745682 47.183090,52.681984 47.454914,54.130932 
	C44.383072,56.956818 42.749844,60.012733 42.652935,63.933235 
	C41.873680,62.889652 41.438599,61.896618 41.169640,60.824692 
	C41.227489,60.467800 41.119225,60.189804 41.014954,59.615944 
	C40.729599,58.819557 40.440250,58.319031 39.925888,57.572453 
	C39.069248,57.110203 38.437622,56.894005 37.805992,56.677807 
	C40.571365,54.739777 43.336742,52.801746 46.458298,50.836552 
z"/>
<path fill="#EC9542" opacity="1.000000" stroke="none" 
	d="
M99.841629,286.128113 
	C100.870987,283.663879 101.916794,281.212311 102.954849,278.385437 
	C105.053535,278.031586 107.159973,278.053009 109.630157,278.436707 
	C109.989525,279.872986 109.985138,280.947021 109.646759,282.051208 
	C108.030060,282.250214 106.747345,282.419037 106.624252,282.435242 
	C104.820198,284.692749 103.545700,286.287628 102.271202,287.882446 
	C101.936981,287.750427 101.602760,287.618408 101.003250,287.053802 
	C100.538849,286.314240 100.256310,286.162598 99.890350,286.166290 
	C99.890350,286.166290 99.858078,286.140778 99.841629,286.128113 
z"/>
<path fill="#FAF586" opacity="1.000000" stroke="none" 
	d="
M187.125198,58.899418 
	C188.294769,57.799664 189.464325,56.699909 190.803253,55.344471 
	C191.530319,54.911568 192.316910,54.403435 192.611237,54.607010 
	C196.715683,57.445827 200.752808,60.381973 204.853546,63.700722 
	C204.900742,64.107048 204.732162,64.286652 204.366806,64.226959 
	C202.331497,63.834538 200.661545,63.501808 198.801147,62.858200 
	C194.661011,61.712967 191.945190,56.993679 187.125198,58.899418 
z"/>
<path fill="#FAF586" opacity="1.000000" stroke="none" 
	d="
M165.000900,40.940323 
	C161.458527,38.960804 157.845886,37.093040 154.415466,34.935509 
	C153.348831,34.264664 152.775986,32.808720 152.018631,31.329365 
	C157.027817,33.667515 161.995453,36.388500 166.998962,39.536736 
	C167.034836,39.963982 166.828262,40.211243 166.626892,40.260464 
	C166.178589,40.459156 165.993759,40.664558 165.653259,40.914959 
	C165.435486,40.904030 165.000900,40.940323 165.000900,40.940323 
z"/>
<path fill="#F2D299" opacity="1.000000" stroke="none" 
	d="
M36.978565,239.023071 
	C36.415569,240.431213 35.852573,241.839371 35.152565,243.498047 
	C34.572708,243.815643 34.129864,243.882706 33.234245,243.741806 
	C32.564301,243.281921 32.290592,243.136063 31.960339,243.096237 
	C31.960339,243.096237 31.928005,243.074753 31.916187,242.716736 
	C31.459032,239.058884 29.392021,239.182541 26.983271,240.027634 
	C26.983271,240.027634 26.948761,240.028717 26.932549,240.040070 
	C28.523323,237.925339 30.130310,235.799255 32.246750,233.415268 
	C34.223671,235.069382 31.785339,239.792969 36.978565,239.023071 
z"/>
<path fill="#F3B259" opacity="1.000000" stroke="none" 
	d="
M102.433296,288.160339 
	C103.545700,286.287628 104.820198,284.692749 106.624252,282.435242 
	C106.747345,282.419037 108.030060,282.250214 109.936195,282.067749 
	C110.752487,282.063843 110.945366,282.073547 111.080948,282.431580 
	C110.761734,283.879913 110.499809,284.979889 110.224091,286.405212 
	C110.274574,287.159332 110.338867,287.588104 110.290894,288.346283 
	C109.445541,289.802246 108.712456,290.928802 107.979378,292.055359 
	C106.184715,290.849640 104.390053,289.643921 102.433296,288.160339 
z"/>
<path fill="#FAF586" opacity="1.000000" stroke="none" 
	d="
M183.928513,50.633686 
	C182.395493,50.296379 180.769073,49.629398 178.744888,48.799294 
	C178.312546,50.451118 177.961426,51.792641 177.360306,52.670933 
	C176.509674,50.638855 176.321960,48.639252 175.225220,47.587936 
	C172.690353,45.158028 169.714996,43.187637 166.912888,40.824089 
	C166.907501,40.617302 166.828262,40.211243 166.828262,40.211243 
	C166.828262,40.211243 167.034836,39.963982 167.138565,39.838554 
	C172.773239,43.243423 178.304184,46.773720 183.928513,50.633686 
z"/>
<path fill="#F3BF79" opacity="1.000000" stroke="none" 
	d="
M74.178017,265.958649 
	C73.377785,266.925323 72.577553,267.892029 71.434959,268.869781 
	C69.460663,267.462372 67.828735,266.043854 66.080032,264.311890 
	C67.885910,262.561066 69.808540,261.123688 72.153465,259.459106 
	C73.037827,259.488281 73.499901,259.744720 73.945724,260.361755 
	C73.907150,261.444000 73.884827,262.165649 73.615097,262.940155 
	C73.552231,263.678864 73.736763,264.364685 73.966263,265.282990 
	C74.011223,265.515442 74.178017,265.958649 74.178017,265.958649 
z"/>
<path fill="#FBE5B7" opacity="1.000000" stroke="none" 
	d="
M108.055939,292.361603 
	C108.712456,290.928802 109.445541,289.802246 110.533310,288.502686 
	C111.623108,288.461334 112.358208,288.592987 113.093307,288.724670 
	C113.056053,287.980591 113.283249,286.800201 112.926178,286.583771 
	C112.196381,286.141418 111.152443,286.217346 110.237885,286.079834 
	C110.499809,284.979889 110.761734,283.879913 111.358208,282.203064 
	C113.143822,281.150391 114.594872,280.674591 116.045921,280.198822 
	C115.473747,283.860382 114.901573,287.521912 114.178421,291.548401 
	C112.062469,292.164825 110.097481,292.416321 108.055939,292.361603 
z"/>
<path fill="#F0C81B" opacity="1.000000" stroke="none" 
	d="
M37.535583,56.770050 
	C38.437622,56.894005 39.069248,57.110203 39.746593,57.893494 
	C36.546116,62.951141 33.299919,67.441704 30.053722,71.932259 
	C30.630899,68.436569 31.208076,64.940872 31.823961,61.100365 
	C32.778759,59.982861 33.694851,59.210167 35.097969,58.222855 
	C35.899361,57.871288 36.109379,57.639706 36.215050,57.313488 
	C36.565090,57.163090 36.915131,57.012691 37.535583,56.770050 
z"/>
<path fill="#F6D371" opacity="1.000000" stroke="none" 
	d="
M222.973236,150.980988 
	C223.209274,146.785065 226.031738,147.772659 228.587280,148.396576 
	C228.896484,146.619385 229.171188,145.040543 229.721741,143.220428 
	C230.032120,146.347687 230.066620,149.716217 230.172363,153.515579 
	C229.723557,155.510925 229.203537,157.075455 228.336349,158.734192 
	C227.989197,158.828400 227.874161,158.705460 227.919525,158.318451 
	C228.953613,153.709610 226.556976,151.919022 222.973236,150.980988 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M253.434998,192.070526 
	C253.350418,190.948685 253.658112,189.778580 254.013763,188.245422 
	C257.266785,188.319092 260.471832,188.755814 264.808563,189.346741 
	C263.043274,191.279877 261.743164,192.703598 260.748474,193.792847 
	C258.103943,193.116318 255.965607,192.569290 253.434998,192.070526 
z"/>
<path fill="#29196B" opacity="1.000000" stroke="none" 
	d="
M335.914490,191.550659 
	C334.268219,190.887787 332.700226,189.797089 330.540894,188.295044 
	C331.892334,186.870438 333.563507,185.519882 334.666138,183.804016 
	C335.419586,182.631546 335.253204,180.905182 335.740234,179.511047 
	C336.055206,178.609467 336.807373,177.860626 337.738922,177.056854 
	C337.407074,181.754517 336.699890,186.438675 335.914490,191.550659 
z"/>
<path fill="#29196B" opacity="1.000000" stroke="none" 
	d="
M332.510834,155.922485 
	C332.714020,156.399963 332.470612,156.928848 332.113617,157.728867 
	C332.000000,158.000000 331.998291,158.002441 331.998291,158.002441 
	C330.932159,158.300140 329.865997,158.597855 328.799866,158.895569 
	C329.073334,159.393814 329.346802,159.892044 329.796112,160.615173 
	C321.999817,161.271530 321.999817,161.271530 322.195465,155.973877 
	C325.493469,155.973877 328.778839,155.973877 332.510834,155.922485 
z"/>
<path fill="#FDF4DD" opacity="1.000000" stroke="none" 
	d="
M230.666977,188.941284 
	C230.221405,186.329422 230.117691,183.593063 229.957169,180.042923 
	C229.867706,178.490952 229.835052,177.752747 229.872192,176.561676 
	C229.849350,173.263306 229.756760,170.417816 229.664154,167.572311 
	C230.359528,168.456436 231.054901,169.340546 231.886169,170.553406 
	C231.684326,176.860367 231.346588,182.838562 230.666977,188.941284 
z"/>
<path fill="#FDFEFC" opacity="1.000000" stroke="none" 
	d="
M293.195526,57.962452 
	C293.195526,57.962452 292.989380,57.571751 292.988342,56.973480 
	C292.654022,54.917191 292.320709,53.459167 291.987366,52.001144 
	C294.441528,52.001148 296.895691,52.001148 299.349884,52.001148 
	C299.640930,52.548321 299.931976,53.095493 300.223022,53.642662 
	C298.383606,54.994370 296.544159,56.346073 294.166290,57.833241 
	C293.627838,57.968704 293.195526,57.962452 293.195526,57.962452 
z"/>
<path fill="#29196B" opacity="1.000000" stroke="none" 
	d="
M335.584167,145.095032 
	C336.747375,145.889008 337.542297,146.574051 338.229889,147.353714 
	C339.519806,148.816360 339.067444,149.167786 337.138977,150.534286 
	C335.721924,151.538437 334.908386,153.394333 333.495605,154.935303 
	C333.832672,151.676239 334.500031,148.352524 335.584167,145.095032 
z"/>
<path fill="#FDFAFE" opacity="1.000000" stroke="none" 
	d="
M246.429626,202.074799 
	C242.001526,201.635254 240.450943,199.408310 241.276871,195.055710 
	C244.224411,197.973267 245.944016,202.938461 251.961441,200.078857 
	C253.046616,199.975266 253.477036,199.970764 253.907455,199.966248 
	C254.194839,200.012711 254.482224,200.059189 255.188324,200.230865 
	C252.691116,200.902832 249.775162,201.449585 246.429626,202.074799 
z"/>
<path fill="#FDF4DD" opacity="1.000000" stroke="none" 
	d="
M185.894714,244.002151 
	C186.427170,244.267822 186.959641,244.533493 187.759613,244.937347 
	C186.442612,246.853836 184.858093,248.632156 182.792999,250.678711 
	C181.199402,250.301498 180.086380,249.656036 178.992218,248.734589 
	C180.348267,247.282837 181.685471,246.107040 183.347626,244.917236 
	C184.413284,244.602890 185.154007,244.302521 185.894714,244.002151 
z"/>
<path fill="#FCE7BF" opacity="1.000000" stroke="none" 
	d="
M17.176500,234.078064 
	C17.542425,232.693832 17.796234,230.924789 18.814926,230.096619 
	C19.712624,229.366806 21.569408,229.322556 22.802568,229.675232 
	C23.609919,229.906113 24.060730,231.383728 24.671230,232.302948 
	C23.585070,232.637024 22.505009,232.992950 21.410887,233.298538 
	C20.468019,233.561859 19.509134,233.767822 18.197334,234.076874 
	C17.837339,234.154617 17.396923,234.102325 17.176500,234.078064 
z"/>
<path fill="#FDF4DD" opacity="1.000000" stroke="none" 
	d="
M230.261414,216.853058 
	C229.926117,212.215652 229.989655,207.547363 230.137726,202.415283 
	C233.172913,206.524002 231.586121,211.493729 231.444092,216.331390 
	C231.439056,216.502502 230.932846,216.658905 230.261414,216.853058 
z"/>
<path fill="#F9DF3B" opacity="1.000000" stroke="none" 
	d="
M228.088348,81.005051 
	C228.356110,80.759361 228.623871,80.513672 229.018036,80.020233 
	C229.336487,79.940506 229.528534,80.108505 229.910736,80.816879 
	C230.048706,81.789146 229.996490,82.221046 229.944290,82.652954 
	C229.993652,83.260017 230.043015,83.867073 230.080475,85.348709 
	C229.976059,90.547607 229.883545,94.871948 229.507736,99.132446 
	C228.837585,99.042480 228.450745,99.016357 228.081879,98.658295 
	C228.121017,96.567589 228.142166,94.808830 228.354675,92.916504 
	C228.786713,92.557816 228.916641,92.280724 228.938812,91.638550 
	C228.937119,90.907959 228.932404,90.490479 228.855804,89.838615 
	C228.611862,89.308243 228.363617,89.105583 228.071106,88.622406 
	C228.098145,85.834053 228.093246,83.419548 228.088348,81.005051 
z"/>
<path fill="#FAF586" opacity="1.000000" stroke="none" 
	d="
M146.358368,27.972668 
	C143.175278,26.813320 140.329803,25.663795 137.540741,24.075455 
	C137.776230,23.051920 137.955338,22.467199 138.134445,21.882481 
	C141.396942,23.685541 144.659439,25.488604 147.981842,27.625328 
	C147.593155,27.966825 147.144577,27.974659 146.358368,27.972668 
z"/>
<path fill="#29196B" opacity="1.000000" stroke="none" 
	d="
M343.464661,159.863983 
	C342.517059,159.123474 341.926483,158.212036 341.335907,157.300598 
	C342.047028,156.860764 342.758148,156.420914 343.807983,155.965927 
	C346.067719,156.507660 347.988708,157.064514 351.474304,158.074905 
	C348.002411,158.809036 345.912048,159.251053 343.464661,159.863983 
z"/>
<path fill="#F9F32B" opacity="1.000000" stroke="none" 
	d="
M230.960892,22.079296 
	C230.960892,22.079296 231.006180,22.524189 230.753769,23.037781 
	C225.732529,23.059526 223.884476,25.961283 223.012329,29.996944 
	C222.733002,29.801823 222.453674,29.606705 222.074371,28.879169 
	C221.918930,27.882967 221.863464,27.419184 221.807983,26.955400 
	C221.807983,26.955400 222.032272,26.582081 222.319778,26.176004 
	C223.400742,24.510908 224.194183,23.251890 224.987625,21.992870 
	C224.987625,21.992868 225.000610,22.000195 225.424194,21.995468 
	C227.552139,22.020260 229.256516,22.049778 230.960892,22.079296 
z"/>
<path fill="#F9E734" opacity="1.000000" stroke="none" 
	d="
M88.501289,24.765678 
	C88.798218,26.158941 88.798218,27.550964 88.798218,28.538258 
	C86.021072,29.478798 83.821129,30.223854 81.260101,30.939659 
	C82.173698,29.802591 83.448372,28.694775 85.111435,27.193369 
	C85.769325,26.606359 85.926674,26.345310 85.971870,26.016630 
	C85.971870,26.016630 85.984840,25.981316 86.239464,25.936455 
	C86.799469,25.737267 86.997108,25.495026 87.087006,25.164871 
	C87.459457,25.031393 87.831909,24.897917 88.501289,24.765678 
z"/>
<path fill="#29196B" opacity="1.000000" stroke="none" 
	d="
M327.925781,195.439926 
	C331.031189,195.679688 334.192596,196.310883 337.743713,196.996277 
	C334.337189,200.077942 331.277802,196.969498 327.925781,195.439926 
z"/>
<path fill="#FBF1D4" opacity="1.000000" stroke="none" 
	d="
M178.973358,249.010574 
	C180.086380,249.656036 181.199402,250.301498 182.634888,250.978348 
	C182.197662,251.981750 181.437988,252.953766 180.287262,253.614853 
	C178.936676,252.869904 177.977127,252.435867 176.759247,251.839417 
	C175.998398,250.793762 175.495880,249.910507 175.322052,248.996582 
	C176.090515,248.949265 176.530304,248.932617 177.106277,249.158875 
	C177.819443,249.271378 178.396393,249.140976 178.973358,249.010574 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M255.658661,9.858638 
	C256.083740,10.615601 256.202728,11.216912 255.792603,11.938065 
	C254.848831,12.013348 254.434219,11.968791 254.019592,11.924233 
	C252.805801,10.745900 251.592026,9.567566 250.061111,7.943841 
	C249.447250,7.059954 249.150497,6.621457 248.932922,6.093282 
	C249.012070,6.003604 249.176941,5.830254 249.176941,5.830253 
	C251.235489,7.121163 253.294052,8.412075 255.658661,9.858638 
z"/>
<path fill="#29196B" opacity="1.000000" stroke="none" 
	d="
M342.010620,190.306824 
	C342.160583,190.001968 342.649506,190.022934 342.893677,190.038651 
	C341.991882,192.306473 340.845947,194.558578 339.349609,196.831085 
	C338.689301,195.834427 338.379364,194.817368 338.035400,193.480316 
	C339.287781,192.310760 340.574219,191.461212 342.010620,190.306824 
z"/>
<path fill="#F8EA23" opacity="1.000000" stroke="none" 
	d="
M229.791016,99.196281 
	C229.883545,94.871948 229.976059,90.547607 230.003937,85.800507 
	C230.073700,90.908287 230.208099,96.438835 230.146301,102.268066 
	C229.963272,102.206207 229.976456,101.845673 229.962662,100.924744 
	C229.887466,99.974991 229.839249,99.585640 229.791016,99.196281 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M454.646149,191.001801 
	C455.149200,191.786758 455.345917,192.710754 455.827332,193.853302 
	C454.951569,195.332581 453.791107,196.593307 452.304535,197.845093 
	C451.763947,196.485596 451.549469,195.135040 451.183594,193.473068 
	C452.134735,192.488037 453.237274,191.814438 454.646149,191.001801 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M239.049286,18.602379 
	C237.351318,18.302851 235.554657,17.678078 233.404434,17.014458 
	C232.762756,16.093073 232.474625,15.210533 232.313232,13.986696 
	C234.610153,15.189311 236.780350,16.733223 239.049286,18.602379 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M249.052704,87.284172 
	C252.134613,89.022224 254.211807,87.020340 256.639618,85.021530 
	C257.384979,88.739281 254.152435,88.754158 251.853027,89.395523 
	C251.131409,89.596794 249.987427,88.283844 249.052704,87.284172 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M230.420990,8.008347 
	C231.237106,9.600346 231.711594,11.241079 232.278076,13.225248 
	C231.027649,12.893028 229.443039,12.443007 228.482330,11.411759 
	C228.171967,11.078613 229.500504,9.218626 230.420990,8.008347 
z"/>
<path fill="#F6DB2C" opacity="1.000000" stroke="none" 
	d="
M220.813293,73.997223 
	C220.980103,74.645439 220.986145,75.017769 220.981873,75.668701 
	C219.333130,74.836952 217.694717,73.726585 216.392517,72.275085 
	C217.158844,71.948807 217.588974,71.963669 218.019089,71.978531 
	C218.896896,72.559464 219.774719,73.140396 220.813293,73.997223 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M479.617920,162.082428 
	C479.246307,163.653580 478.489288,165.149506 477.448730,166.808990 
	C476.632355,165.369797 476.099518,163.767044 475.514435,162.007233 
	C476.680237,162.007233 477.956390,162.007233 479.617920,162.082428 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M309.951294,161.611740 
	C307.024872,161.963669 303.920135,161.971878 300.350677,161.967880 
	C303.043732,159.743896 306.318481,159.213867 309.951294,161.611740 
z"/>
<path fill="#F9EE32" opacity="1.000000" stroke="none" 
	d="
M80.595123,30.828758 
	C81.569504,32.032276 82.212318,33.164310 82.531906,34.636742 
	C80.803223,34.616680 79.397758,34.256214 78.018311,33.598099 
	C78.352325,32.574173 78.660316,31.847895 78.968307,31.121614 
	C79.400055,31.000168 79.831810,30.878721 80.595123,30.828758 
z"/>
<path fill="#FCE7BF" opacity="1.000000" stroke="none" 
	d="
M27.124460,240.336456 
	C29.392021,239.182541 31.459032,239.058884 31.897100,242.713226 
	C30.348438,242.260239 28.807043,241.452759 27.124460,240.336456 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M291.798889,51.748817 
	C292.320709,53.459167 292.654022,54.917191 292.958008,56.754707 
	C291.821991,55.836857 290.715271,54.539516 289.327942,52.770462 
	C288.989227,51.906052 288.931183,51.513363 288.953705,51.044464 
	C289.034302,50.968258 289.197418,50.817982 289.197418,50.817978 
	C290.001740,51.044147 290.806091,51.270317 291.798889,51.748817 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M470.727539,190.955719 
	C471.194611,191.458878 471.384796,192.076523 471.847321,192.881317 
	C471.305359,194.325455 470.491028,195.582443 469.367004,196.966293 
	C469.030365,195.967346 469.003448,194.841553 468.959839,193.294281 
	C469.445618,192.271957 469.948151,191.671097 470.727539,190.955719 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M230.983704,21.736397 
	C229.256516,22.049778 227.552139,22.020260 225.420746,21.988710 
	C226.720657,21.327400 228.447601,20.668123 230.583710,20.014679 
	C230.997437,20.478174 231.001984,20.935837 230.983704,21.736397 
z"/>
<path fill="#F9E734" opacity="1.000000" stroke="none" 
	d="
M78.626274,31.095863 
	C78.660316,31.847895 78.352325,32.574173 77.669243,33.629570 
	C76.200592,34.312080 75.107025,34.665474 74.013458,35.018867 
	C74.001328,34.813694 73.989197,34.608524 73.950653,34.095642 
	C75.377579,32.881992 76.830910,31.976051 78.626274,31.095863 
z"/>
<path fill="#FAF586" opacity="1.000000" stroke="none" 
	d="
M88.975197,23.983757 
	C90.465981,23.075420 91.956764,22.167086 93.732269,21.759903 
	C94.010483,22.504419 94.003967,22.747782 93.997459,22.991146 
	C93.997459,22.991146 93.999390,22.999512 93.706787,22.994686 
	C92.934540,23.240997 92.454895,23.492132 91.975243,23.743267 
	C91.975243,23.743269 91.876297,23.771894 91.496094,23.802145 
	C90.402328,23.882851 89.688759,23.933304 88.975197,23.983757 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M284.975830,44.694626 
	C284.372437,44.700291 283.796295,44.305492 282.987061,43.798531 
	C282.510193,43.292603 282.266449,42.898838 281.909943,42.011139 
	C281.797180,41.517197 281.915466,41.041382 281.915466,41.041382 
	C282.944672,42.125641 283.973877,43.209904 284.975830,44.694626 
z"/>
<path fill="#29196B" opacity="1.000000" stroke="none" 
	d="
M339.433655,172.897705 
	C338.236847,172.871582 337.347076,172.696518 335.231873,172.280319 
	C337.307587,171.188583 338.352081,170.639236 339.753632,170.079208 
	C339.987366,170.961929 339.864014,171.855347 339.433655,172.897705 
z"/>
<path fill="#FAE1A8" opacity="1.000000" stroke="none" 
	d="
M62.202866,261.165009 
	C63.352512,260.130798 64.436775,259.412506 66.711754,257.905365 
	C65.092445,260.308258 64.365463,261.387024 63.418186,262.674500 
	C62.844425,262.415802 62.490952,261.948364 62.202866,261.165009 
z"/>
<path fill="#F8EA23" opacity="1.000000" stroke="none" 
	d="
M130.076111,18.223927 
	C130.539902,17.899290 130.979736,17.945110 131.750092,17.953575 
	C132.080627,17.916222 132.435150,18.116993 132.637970,18.486826 
	C132.903702,19.198462 132.966629,19.540264 133.029556,19.882067 
	C131.008453,21.831272 130.247955,20.865656 130.076111,18.223927 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M287.074463,77.271278 
	C288.163483,77.174911 289.277740,77.414703 290.682434,77.845764 
	C289.289917,81.427338 288.270721,78.832748 287.074463,77.271278 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M276.553345,78.028778 
	C276.093475,76.977142 275.980774,75.923470 275.886719,74.472282 
	C276.836304,75.163445 277.767303,76.252106 278.840149,77.654144 
	C278.288177,77.987251 277.594360,78.006989 276.553345,78.028778 
z"/>
<path fill="#29196B" opacity="1.000000" stroke="none" 
	d="
M344.406494,154.925110 
	C344.044952,152.708481 344.034912,150.273743 344.046326,147.384354 
	C344.297852,149.522141 344.527924,152.114548 344.406494,154.925110 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M253.815369,199.583099 
	C253.477036,199.970764 253.046616,199.975266 252.301697,199.904541 
	C252.103836,197.754379 252.220444,195.679474 252.586731,193.320175 
	C253.132019,195.090515 253.427643,197.145233 253.815369,199.583099 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M362.957977,160.615662 
	C361.542999,161.119431 359.949646,161.286575 358.142944,161.173981 
	C359.546295,160.689163 361.162933,160.484085 362.957977,160.615662 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M474.168701,195.324219 
	C475.407928,195.790848 476.657837,196.592224 477.922791,197.749649 
	C476.684998,197.290115 475.432159,196.474548 474.168701,195.324219 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M381.597504,158.080750 
	C381.967926,160.322174 381.976501,162.769119 381.971497,165.674774 
	C381.717163,163.517746 381.476410,160.902008 381.597504,158.080750 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M298.179871,73.900345 
	C297.880798,73.174164 297.925140,72.456245 298.008911,71.379944 
	C298.048370,71.021545 298.487915,70.989014 298.708191,70.982277 
	C299.487549,71.400772 300.046661,71.826004 300.605804,72.251228 
	C299.911621,72.803688 299.217438,73.356148 298.179871,73.900345 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M347.633118,171.038818 
	C347.527252,172.534271 347.093842,174.188492 346.276306,175.891342 
	C346.363312,174.359177 346.834442,172.778381 347.633118,171.038818 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M242.032532,77.341248 
	C242.496017,77.243477 242.937363,77.538170 243.632416,77.926453 
	C244.210876,78.803429 244.535629,79.586800 244.932617,80.684906 
	C244.006683,79.911003 243.008530,78.822357 242.032532,77.341248 
z"/>
<path fill="#FDF4DD" opacity="1.000000" stroke="none" 
	d="
M177.017578,252.001846 
	C177.977127,252.435867 178.936676,252.869904 179.947784,253.646301 
	C179.909927,254.313278 179.732529,254.579224 179.252060,254.898315 
	C178.532181,254.746384 178.027435,254.482620 177.269516,253.775772 
	C177.016769,252.889069 177.017166,252.445465 177.017578,252.001846 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M390.091492,172.431183 
	C390.159515,171.518829 390.367981,170.912933 390.848694,170.125580 
	C391.688812,170.793549 392.256653,171.642944 392.988525,172.737656 
	C391.910034,172.737656 391.070984,172.737656 390.091492,172.431183 
z"/>
<path fill="#FAF586" opacity="1.000000" stroke="none" 
	d="
M152.047302,30.582329 
	C151.032822,30.286886 150.007629,29.624846 148.874268,28.681110 
	C149.856277,29.004854 150.946426,29.610292 152.047302,30.582329 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M224.598770,21.993727 
	C224.194183,23.251890 223.400742,24.510908 222.346344,25.959873 
	C222.793579,24.764742 223.501740,23.379662 224.598770,21.993727 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M492.594452,172.059448 
	C492.858215,173.704544 492.763306,175.547241 492.414734,177.681061 
	C492.185974,176.067139 492.210876,174.162094 492.594452,172.059448 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M350.062317,197.386597 
	C351.653046,197.033340 353.434601,197.027603 355.661316,197.044800 
	C354.155365,197.289856 352.204254,197.511978 350.062317,197.386597 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M383.619049,153.046600 
	C383.573395,154.213043 383.212921,155.530136 382.501038,156.930908 
	C382.534515,155.742126 382.919373,154.469696 383.619049,153.046600 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M297.945984,65.763130 
	C297.463409,65.529114 297.021942,64.902603 296.325287,64.122627 
	C296.020691,63.545834 295.971252,63.122509 295.904602,62.379807 
	C296.587311,63.163830 297.287231,64.267235 297.945984,65.763130 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M369.068909,198.395889 
	C370.663422,198.040009 372.455627,198.028091 374.696106,198.030899 
	C373.185089,198.277039 371.225830,198.508453 369.068909,198.395889 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M462.042786,198.392029 
	C463.633209,198.035843 465.416046,198.027710 467.644165,198.037109 
	C466.138031,198.283142 464.186584,198.511612 462.042786,198.392029 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M317.763031,161.627106 
	C316.139343,162.001709 314.437897,161.992462 312.310425,161.970947 
	C313.818054,161.720215 315.751648,161.481735 317.763031,161.627106 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M231.018188,62.327240 
	C231.482391,62.269711 231.961426,62.549812 232.801147,63.185074 
	C233.462784,64.001091 233.763748,64.461952 234.015015,64.980400 
	C233.965317,65.037994 233.865723,65.153000 233.865723,65.153000 
	C232.921478,64.323624 231.977249,63.494247 231.018188,62.327240 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M446.054749,198.383606 
	C447.329803,198.033112 448.787170,198.020523 450.682068,198.024384 
	C449.492065,198.267731 447.864594,198.494614 446.054749,198.383606 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M238.032745,71.302299 
	C238.699799,71.732697 239.348541,72.524841 240.024139,73.637932 
	C239.372131,73.193932 238.693283,72.428986 238.032745,71.302299 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M240.025482,74.315544 
	C240.694260,74.739555 241.344635,75.524414 242.025833,76.628693 
	C241.373474,76.190872 240.690277,75.433640 240.025482,74.315544 
z"/>
<path fill="#FAF586" opacity="1.000000" stroke="none" 
	d="
M217.965881,71.653397 
	C217.588974,71.963669 217.158844,71.948807 216.407867,71.896317 
	C215.905609,71.573479 215.661850,71.359177 215.112366,70.933571 
	C214.869003,70.651367 214.737137,70.281425 214.835663,70.174431 
	C214.934204,70.067436 215.129669,69.852005 215.129669,69.852005 
	C216.057327,70.344086 216.985001,70.836174 217.965881,71.653397 
z"/>
<path fill="#FAF586" opacity="1.000000" stroke="none" 
	d="
M204.985168,64.017372 
	C205.992599,64.387871 206.915588,64.848045 207.872864,65.703064 
	C207.907135,66.097900 207.741302,66.271469 207.447021,66.195404 
	C206.430115,65.763908 205.707474,65.408478 204.926956,64.859695 
	C204.869080,64.666359 204.732162,64.286652 204.732162,64.286652 
	C204.732162,64.286652 204.900742,64.107048 204.985168,64.017372 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M236.016022,68.304810 
	C236.686127,68.732430 237.342682,69.522766 238.032745,70.632751 
	C237.378342,70.190773 236.690430,69.429146 236.016022,68.304810 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M220.765228,51.719215 
	C221.614563,52.212429 222.225693,52.798069 222.921570,53.687561 
	C222.033966,53.542381 221.061600,53.093353 220.060577,52.291206 
	C220.196945,51.895943 220.361984,51.853790 220.765228,51.719215 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M356.387909,197.066315 
	C356.829865,196.419067 357.597229,195.726013 358.732849,195.049591 
	C358.305176,195.717621 357.509247,196.369064 356.387909,197.066315 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M359.372955,194.982681 
	C359.507324,194.108643 359.949890,193.117737 360.753784,192.095520 
	C360.637146,192.998077 360.159149,193.931946 359.372955,194.982681 
z"/>
<path fill="#F8EA23" opacity="1.000000" stroke="none" 
	d="
M137.879059,21.737793 
	C137.955338,22.467199 137.776230,23.051920 137.361877,23.831348 
	C136.745483,23.273056 136.364365,22.520058 135.987946,21.429173 
	C136.536316,21.258560 137.079987,21.425835 137.879059,21.737793 
z"/>
<path fill="#FAF586" opacity="1.000000" stroke="none" 
	d="
M209.978470,68.204620 
	C209.492050,67.884598 209.005630,67.564583 208.203766,66.938660 
	C207.888336,66.632767 207.741302,66.271469 207.741302,66.271469 
	C207.741302,66.271469 207.907135,66.097900 207.990387,66.010925 
	C209.014648,66.367523 209.955688,66.811096 210.932068,67.646233 
	C210.967407,68.037796 210.802689,68.204819 210.596649,68.174393 
	C210.390594,68.143959 209.978470,68.204620 209.978470,68.204620 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M384.023651,169.367584 
	C384.892181,169.501740 385.883759,169.941895 386.909912,170.742035 
	C386.011902,170.625870 385.079315,170.149734 384.023651,169.367584 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M482.078430,191.552948 
	C481.933655,190.453461 481.733246,188.945358 481.831970,187.231842 
	C482.095032,188.399078 482.058899,189.771713 482.078430,191.552948 
z"/>
<path fill="#FAE1A8" opacity="1.000000" stroke="none" 
	d="
M54.499496,249.175171 
	C53.610722,249.004227 53.005714,248.818268 52.593723,248.369751 
	C53.872894,248.158417 54.959049,248.209625 56.045204,248.260834 
	C56.045204,248.260818 55.993809,248.673431 55.956249,248.878647 
	C55.540215,249.109283 55.161739,249.134720 54.499496,249.175171 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M482.400696,186.928528 
	C482.159393,185.922485 482.251678,184.740356 482.583679,183.296234 
	C482.793701,184.273636 482.764008,185.513031 482.400696,186.928528 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M287.350647,49.056206 
	C287.906189,49.466030 288.461700,49.875854 289.107330,50.551830 
	C289.197418,50.817982 289.034302,50.968258 288.644501,50.932091 
	C287.722870,50.215492 287.191010,49.535065 286.828705,48.916416 
	C286.998260,48.978195 287.350647,49.056210 287.350647,49.056206 
z"/>
<path fill="#FDF4DD" opacity="1.000000" stroke="none" 
	d="
M230.358307,197.773376 
	C230.068573,196.926682 230.019730,195.860916 230.041611,194.399994 
	C230.274612,195.187988 230.436890,196.371155 230.358307,197.773376 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M247.956619,6.438551 
	C247.573685,5.953971 247.190750,5.469392 246.726807,4.692161 
	C247.409515,4.664091 248.173218,4.928674 249.056946,5.511756 
	C249.176941,5.830254 249.012070,6.003604 248.699463,6.124060 
	C248.386841,6.244515 247.956619,6.438553 247.956619,6.438551 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M221.507812,26.955402 
	C221.863464,27.419184 221.918930,27.882967 221.895447,28.690605 
	C221.236664,28.566578 220.656830,28.098698 220.041870,27.324280 
	C220.407028,26.996964 220.807327,26.976185 221.507812,26.955402 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M229.955383,66.593674 
	C229.718353,66.204544 229.720291,65.817451 229.750320,65.432533 
	C229.751160,65.421646 230.051071,65.434074 230.211533,65.435631 
	C230.207108,65.822418 230.202698,66.209213 229.955383,66.593674 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M299.195221,68.861694 
	C298.647797,68.537315 298.335052,68.059746 298.095581,67.283478 
	C298.589233,67.559357 299.009552,68.133926 299.195221,68.861694 
z"/>
<path fill="#29196B" opacity="1.000000" stroke="none" 
	d="
M341.408142,165.933868 
	C341.162354,165.395386 341.210205,164.740402 341.544434,164.042664 
	C341.787781,164.605743 341.744781,165.211563 341.408142,165.933868 
z"/>
<path fill="#FAF586" opacity="1.000000" stroke="none" 
	d="
M133.359802,19.921246 
	C132.966629,19.540264 132.903702,19.198462 132.808167,18.598759 
	C133.502609,18.665806 134.229645,18.990751 134.984802,19.646833 
	C134.571976,19.972122 134.130997,19.966272 133.359802,19.921246 
z"/>
<path fill="#F9E734" opacity="1.000000" stroke="none" 
	d="
M88.883728,24.174681 
	C89.688759,23.933304 90.402328,23.882851 91.470818,23.847015 
	C91.043060,24.219440 90.260376,24.577244 89.124039,24.861744 
	C88.770378,24.788441 88.792259,24.365602 88.883728,24.174681 
z"/>
<path fill="#FBE5B7" opacity="1.000000" stroke="none" 
	d="
M56.698589,249.035385 
	C56.975334,249.762817 56.990334,250.500488 57.007507,251.606903 
	C56.664509,251.237656 56.319340,250.499649 55.945953,249.438934 
	C56.090775,249.092682 56.263809,249.069138 56.698589,249.035385 
z"/>
<path fill="#F8EA23" opacity="1.000000" stroke="none" 
	d="
M230.103653,82.541931 
	C229.996490,82.221046 230.048706,81.789146 230.079559,81.160362 
	C230.126465,81.452629 230.194733,81.941765 230.103653,82.541931 
z"/>
<path fill="#F9E734" opacity="1.000000" stroke="none" 
	d="
M94.356033,22.938519 
	C94.003967,22.747782 94.010483,22.504419 94.021561,21.894409 
	C94.430595,21.238098 94.835075,20.948431 95.475952,20.572620 
	C95.379776,21.286282 95.047195,22.086084 94.356033,22.938519 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M284.038330,74.268555 
	C284.678375,74.410789 285.286377,74.896515 285.993256,75.664558 
	C285.627960,75.963928 285.163788,75.980995 284.351898,75.993416 
	C284.004913,75.529869 284.005615,75.070961 284.038330,74.268555 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M483.359283,181.970520 
	C483.210541,181.221664 483.381989,180.316742 483.851807,179.225052 
	C483.993286,179.963684 483.836395,180.889053 483.359283,181.970520 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M344.634918,180.022385 
	C344.852814,180.743423 344.747711,181.623306 344.406372,182.741272 
	C344.217377,182.046661 344.264679,181.113937 344.634918,180.022385 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M345.614746,176.051865 
	C345.833649,176.767578 345.732788,177.636551 345.396790,178.738770 
	C345.206116,178.049698 345.250519,177.127411 345.614746,176.051865 
z"/>
<path fill="#29196B" opacity="1.000000" stroke="none" 
	d="
M341.963196,145.627747 
	C341.249207,145.842087 340.375671,145.738342 339.266296,145.399597 
	C339.954865,145.212936 340.879303,145.261292 341.963196,145.627747 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M292.141602,77.899864 
	C292.351776,77.583443 292.860504,77.310226 293.673767,77.108734 
	C293.465607,77.434669 292.952850,77.688866 292.141602,77.899864 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M295.004578,60.670799 
	C294.655701,60.534687 294.298859,60.093220 293.923431,59.349777 
	C294.268768,59.487011 294.632721,59.926228 295.004578,60.670799 
z"/>
<path fill="#F6A92B" opacity="1.000000" stroke="none" 
	d="
M234.969772,65.017799 
	C235.303848,65.481888 235.637924,65.945984 235.978714,66.713127 
	C235.688416,66.830803 235.391388,66.645424 235.012909,65.991821 
	C234.944214,65.355011 234.957001,65.186401 234.969772,65.017799 
z"/>
<path fill="#29196B" opacity="1.000000" stroke="none" 
	d="
M340.366638,169.979462 
	C340.180542,169.542435 340.288544,168.979477 340.615417,168.223053 
	C340.776428,168.637573 340.718567,169.245560 340.366638,169.979462 
z"/>
<path fill="#FCF1CD" opacity="1.000000" stroke="none" 
	d="
M7.249319,226.977570 
	C7.164706,226.541382 7.333562,226.115280 7.502417,225.689194 
	C7.659530,225.952835 7.816645,226.216476 7.991892,226.728592 
	C7.840946,226.980591 7.671868,226.984131 7.249319,226.977570 
z"/>
<path fill="#F9E734" opacity="1.000000" stroke="none" 
	d="
M99.663376,17.865784 
	C99.606117,18.245657 99.322289,18.623724 98.944122,18.753607 
	C98.921593,18.178278 99.117256,17.964462 99.663376,17.865784 
z"/>
<path fill="#F9E734" opacity="1.000000" stroke="none" 
	d="
M101.524033,16.618336 
	C101.416122,17.024731 100.995773,17.482742 100.231415,17.916744 
	C100.328804,17.485142 100.770195,17.077545 101.524033,16.618336 
z"/>
<path fill="#29196B" opacity="1.000000" stroke="none" 
	d="
M324.361206,193.062973 
	C324.570221,192.880417 325.103790,192.661133 325.805298,192.643570 
	C325.544098,192.905594 325.114929,192.965927 324.361206,193.062973 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M213.782089,43.008739 
	C214.041351,43.470474 214.022949,43.911606 213.901520,44.668404 
	C213.529144,44.510773 213.259781,44.037468 212.968979,43.277870 
	C213.133194,42.990425 213.318817,42.989277 213.782089,43.008739 
z"/>
<path fill="#FAF222" opacity="1.000000" stroke="none" 
	d="
M279.196228,38.621292 
	C278.825470,38.503250 278.427521,38.077721 278.008667,37.339401 
	C278.381531,37.455673 278.775299,37.884739 279.196228,38.621292 
z"/>
<path fill="#29196B" opacity="1.000000" stroke="none" 
	d="
M338.368225,176.982529 
	C338.179962,176.545807 338.286072,175.982758 338.609619,175.224915 
	C338.772217,175.638809 338.717407,176.247513 338.368225,176.982529 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M229.091644,60.215057 
	C229.290863,60.152077 229.572067,60.330143 229.929596,60.753212 
	C229.728485,60.817509 229.451065,60.636810 229.091644,60.215057 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M234.984253,65.016380 
	C234.957001,65.186401 234.944214,65.355011 234.861267,65.767548 
	C234.502701,65.926895 234.271820,65.761871 233.982086,65.334702 
	C233.865723,65.153000 233.965317,65.037994 234.244965,65.003952 
	C234.524628,64.969917 234.982559,65.035606 234.982559,65.035606 
	C234.982559,65.035606 234.998718,65.014961 234.984253,65.016380 
z"/>
<path fill="#F9C388" opacity="1.000000" stroke="none" 
	d="
M99.963318,286.402527 
	C100.256310,286.162598 100.538849,286.314240 100.809738,286.860168 
	C100.526802,287.079590 100.245071,286.926147 99.963318,286.402527 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M286.077240,47.913345 
	C286.077240,47.913345 286.088440,47.432194 286.125183,47.194244 
	C286.471954,47.053204 286.709778,47.241367 286.710266,47.739998 
	C286.545105,47.959206 286.077240,47.913345 286.077240,47.913345 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M214.098038,46.087509 
	C214.309464,46.064556 214.547928,46.280716 214.781860,46.747780 
	C214.462708,46.869026 214.245285,46.645004 214.098038,46.087509 
z"/>
<path fill="#FAF222" opacity="1.000000" stroke="none" 
	d="
M281.683411,41.026955 
	C281.915466,41.041382 281.797180,41.517197 281.693390,41.739594 
	C281.357941,41.834892 281.188263,41.650654 281.033813,41.223202 
	C280.987061,41.037121 281.451385,41.012527 281.683411,41.026955 
z"/>
<path fill="#FAF222" opacity="1.000000" stroke="none" 
	d="
M275.935303,34.745136 
	C275.723480,34.797943 275.448395,34.593815 275.106323,34.134186 
	C275.316925,34.081863 275.594482,34.285034 275.935303,34.745136 
z"/>
<path fill="#FAF222" opacity="1.000000" stroke="none" 
	d="
M274.479523,32.461617 
	C274.179230,32.565994 273.760712,32.339119 273.277039,31.741337 
	C273.595032,31.623739 273.978149,31.877050 274.479523,32.461617 
z"/>
<path fill="#FAF586" opacity="1.000000" stroke="none" 
	d="
M85.728622,26.076050 
	C85.926674,26.345310 85.769325,26.606359 85.289444,26.900509 
	C85.214500,26.712646 85.349945,26.424057 85.728622,26.076050 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M216.099304,48.517632 
	C216.331543,48.220066 216.586182,48.306236 216.806244,48.831635 
	C216.590515,48.995277 216.373856,48.872112 216.099304,48.517632 
z"/>
<path fill="#FAF586" opacity="1.000000" stroke="none" 
	d="
M86.843391,25.194054 
	C86.997108,25.495026 86.799469,25.737267 86.258270,25.936079 
	C86.092056,25.634468 86.284500,25.382025 86.843391,25.194054 
z"/>
<path fill="#FAF586" opacity="1.000000" stroke="none" 
	d="
M96.788551,19.070368 
	C96.841103,19.327377 96.631096,19.590708 96.157761,19.856966 
	C96.104958,19.598825 96.315475,19.337757 96.788551,19.070368 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M354.700012,161.977020 
	C354.765869,162.181717 354.574158,162.470169 354.136780,162.858917 
	C354.074890,162.659744 354.258667,162.360275 354.700012,161.977020 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M225.074646,56.252411 
	C225.272034,56.188301 225.551468,56.356560 225.895981,56.769196 
	C225.615891,56.954456 225.347778,56.778191 225.074646,56.252411 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M293.174927,58.219368 
	C293.195526,57.962452 293.627838,57.968704 293.844574,57.981911 
	C294.045532,58.175499 294.029785,58.355881 293.973511,58.805214 
	C293.673431,58.874870 293.413879,58.675575 293.174927,58.219368 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M227.096130,58.225349 
	C227.297668,58.150513 227.591476,58.316147 227.968460,58.730568 
	C227.763901,58.808178 227.476135,58.637001 227.096130,58.225349 
z"/>
<path fill="#FAF586" opacity="1.000000" stroke="none" 
	d="
M35.951180,57.294693 
	C36.109379,57.639706 35.899361,57.871288 35.348953,58.050850 
	C35.304375,57.820938 35.495846,57.548416 35.951180,57.294693 
z"/>
<path fill="#FCE7BF" opacity="1.000000" stroke="none" 
	d="
M32.067108,243.311646 
	C32.290592,243.136063 32.564301,243.281921 32.898212,243.739548 
	C32.672741,243.930206 32.392380,243.790817 32.067108,243.311646 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M223.092712,54.230663 
	C223.298599,54.158291 223.589676,54.323559 223.945969,54.740868 
	C223.733429,54.818035 223.455673,54.643169 223.092712,54.230663 
z"/>
<path fill="#FAF586" opacity="1.000000" stroke="none" 
	d="
M185.039841,51.568665 
	C184.778961,51.810314 184.496475,51.670277 184.160767,51.179924 
	C184.342056,51.088028 184.645615,51.207153 185.039841,51.568665 
z"/>
<path fill="#FAF586" opacity="1.000000" stroke="none" 
	d="
M49.656048,48.894089 
	C49.667324,49.111347 49.440498,49.359539 48.951355,49.565754 
	C48.859074,49.235420 49.102039,49.035839 49.656048,48.894089 
z"/>
<path fill="#FAF222" opacity="1.000000" stroke="none" 
	d="
M286.031830,47.968056 
	C286.077240,47.913345 286.545105,47.959206 286.780212,47.961128 
	C287.042023,48.154057 287.068695,48.345058 287.223022,48.796135 
	C287.350647,49.056210 286.998260,48.978195 286.665527,48.841473 
	C286.150726,48.542747 286.005737,48.352501 285.897827,48.134010 
	C285.897827,48.134010 285.986420,48.022762 286.031830,47.968056 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M493.318359,183.035812 
	C493.255920,182.833038 493.450165,182.541367 493.893585,182.150192 
	C493.953522,182.349411 493.764282,182.648163 493.318359,183.035812 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M352.697205,163.973145 
	C352.765320,164.179947 352.574554,164.469406 352.137848,164.859283 
	C352.074036,164.658417 352.256195,164.357117 352.697205,163.973145 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M382.114502,167.264832 
	C382.459106,167.136261 382.680206,167.355377 382.884613,167.883270 
	C382.654510,167.919296 382.362183,167.726120 382.114502,167.264832 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M349.688751,166.977188 
	C349.912994,167.220947 349.779541,167.503143 349.347046,167.869812 
	C349.243469,167.689240 349.337830,167.376358 349.688751,166.977188 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M350.699036,165.974915 
	C350.766113,166.183472 350.573792,166.472076 350.133911,166.857910 
	C350.070801,166.655075 350.255219,166.355011 350.699036,165.974915 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M486.870697,165.721588 
	C486.532990,165.847198 486.316101,165.632889 486.113342,165.116302 
	C486.338196,165.081451 486.626221,165.270676 486.870697,165.721588 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M351.701752,164.978500 
	C351.766479,165.181000 351.575378,165.470200 351.138367,165.859787 
	C351.076935,165.661850 351.261444,165.363541 351.701752,164.978500 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M486.028168,164.694626 
	C485.818481,164.763443 485.530457,164.571396 485.143372,164.133240 
	C485.346130,164.069351 485.648010,164.251572 486.028168,164.694626 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M485.020264,163.695770 
	C484.784576,163.911728 484.504791,163.779739 484.138367,163.355484 
	C484.313873,163.255600 484.622009,163.350464 485.020264,163.695770 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M353.700256,162.976868 
	C353.765137,163.181259 353.572815,163.469879 353.134186,163.858261 
	C353.072937,163.659042 353.257996,163.360062 353.700256,162.976868 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M483.036469,162.708832 
	C482.808197,162.919876 482.524902,162.787537 482.149353,162.365326 
	C482.320740,162.267273 482.628876,162.363937 483.036469,162.708832 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M355.730377,161.122253 
	C355.858429,161.461884 355.642212,161.681488 355.121521,161.888306 
	C355.085022,161.661499 355.274719,161.370407 355.730377,161.122253 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M384.698547,151.950180 
	C384.763702,152.150696 384.572388,152.447952 384.131409,152.850494 
	C384.068512,152.652832 384.255280,152.349884 384.698547,151.950180 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M382.971924,168.303787 
	C383.175201,168.237854 383.465668,168.428360 383.856750,168.865387 
	C383.657928,168.928024 383.358521,168.744125 382.971924,168.303787 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M348.695251,168.945404 
	C348.916840,169.180725 348.787415,169.466705 348.368042,169.848465 
	C348.262665,169.671585 348.351379,169.355530 348.695251,168.945404 
z"/>
<path fill="#29196B" opacity="1.000000" stroke="none" 
	d="
M344.001068,146.692505 
	C343.763794,146.903580 343.495209,146.771454 343.148468,146.353882 
	C343.323578,146.256653 343.623383,146.350266 344.001068,146.692505 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M386.982056,171.301956 
	C387.218414,171.086624 387.496094,171.218628 387.859528,171.640533 
	C387.683716,171.740570 387.376434,171.646851 386.982056,171.301956 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M295.133057,76.862793 
	C295.073608,76.649315 295.267914,76.365349 295.718323,76.016174 
	C295.778534,76.231422 295.582672,76.511864 295.133057,76.862793 
z"/>
<path fill="#FAF586" opacity="1.000000" stroke="none" 
	d="
M213.959259,70.353706 
	C213.959259,70.353706 213.765579,69.900803 213.727066,69.658585 
	C214.037537,69.431847 214.386536,69.447319 214.932587,69.657394 
	C215.129669,69.852005 214.934204,70.067436 214.638290,70.154610 
	C214.342377,70.241783 213.959259,70.353706 213.959259,70.353706 
z"/>
<path fill="#FAF586" opacity="1.000000" stroke="none" 
	d="
M211.050735,67.955261 
	C211.397202,68.012001 211.625092,68.195145 211.894836,68.628212 
	C211.971939,68.834274 212.093552,69.066132 211.807999,69.093185 
	C211.346466,69.081749 211.170502,69.043266 210.994522,69.004776 
	C210.994522,69.004776 211.000000,69.000000 210.966919,68.798340 
	C210.933838,68.596687 210.802689,68.204819 210.802689,68.204819 
	C210.802689,68.204819 210.967407,68.037796 211.050735,67.955261 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M455.696747,188.972351 
	C455.907288,189.207520 455.773163,189.485046 455.351074,189.849030 
	C455.253235,189.673904 455.349579,189.366974 455.696747,188.972351 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M361.311340,192.035370 
	C361.248871,191.827759 361.448334,191.534943 361.901093,191.144684 
	C361.960663,191.348190 361.766968,191.649185 361.311340,192.035370 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M483.023621,192.696365 
	C482.823059,192.758148 482.535828,192.565338 482.151611,192.125839 
	C482.348694,192.066681 482.642822,192.254227 483.023621,192.696365 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M472.968811,193.310181 
	C473.168854,193.250885 473.460205,193.447662 473.852905,193.893890 
	C473.656189,193.950989 473.358124,193.758621 472.968811,193.310181 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M346.122131,194.258987 
	C346.460175,194.131912 346.681610,194.351654 346.886536,194.880280 
	C346.660492,194.915527 346.372314,194.721069 346.122131,194.258987 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M346.972046,195.302368 
	C347.178589,195.234314 347.469147,195.423599 347.860443,195.858963 
	C347.659454,195.923264 347.357758,195.741486 346.972046,195.302368 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M457.962738,195.297180 
	C458.166840,195.231201 458.459930,195.423355 458.855103,195.863968 
	C458.655334,195.926727 458.353546,195.741013 457.962738,195.297180 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M347.979797,196.308258 
	C348.227783,196.084473 348.507050,196.219803 348.870453,196.649567 
	C348.687592,196.753799 348.373077,196.661606 347.979797,196.308258 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M458.973022,196.303284 
	C459.178864,196.237411 459.468353,196.428955 459.855286,196.868286 
	C459.654053,196.930939 459.355377,196.745819 458.973022,196.303284 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M459.973785,197.317352 
	C460.221619,197.088104 460.506195,197.221161 460.877563,197.654663 
	C460.694183,197.761185 460.376251,197.669373 459.973785,197.317352 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M468.297058,198.016754 
	C468.232513,197.813217 468.424835,197.527405 468.861023,197.141174 
	C468.921204,197.338669 468.737549,197.636581 468.297058,198.016754 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M246.103531,83.139290 
	C246.322144,83.099167 246.592178,83.288025 246.847900,83.734482 
	C246.528442,83.870003 246.302231,83.662064 246.103531,83.139290 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M272.197021,82.981873 
	C272.108795,82.668449 272.289001,82.381035 272.723694,82.033020 
	C272.988708,82.147720 272.999207,82.323029 273.007050,82.762566 
	C272.824738,83.020500 272.645050,83.014198 272.197021,82.981873 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M273.183716,81.916977 
	C273.139832,81.707184 273.315491,81.440224 273.741577,81.182816 
	C273.881866,81.490807 273.685608,81.713287 273.183716,81.916977 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M245.048584,81.206985 
	C245.359467,81.021690 245.617416,81.197540 245.746887,81.763901 
	C245.434647,81.892776 245.227051,81.697357 245.048584,81.206985 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M260.893494,39.679695 
	C257.152954,39.007908 254.809830,36.583538 254.009903,32.319092 
	C256.290039,34.407089 258.546936,36.879288 260.893494,39.679695 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M265.102600,43.949654 
	C265.413544,44.445374 265.656952,44.938610 265.963470,45.706009 
	C262.960144,46.267990 261.844391,44.649395 261.971069,41.435047 
	C262.613647,41.563969 263.249207,42.081249 264.129364,43.013943 
	C264.518250,43.701950 264.744476,43.829899 265.052643,43.813206 
	C265.052643,43.813206 265.035126,43.947163 265.102600,43.949654 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M250.968933,28.681911 
	C250.357803,28.549675 249.704498,28.104195 249.024231,27.332443 
	C249.640427,27.460339 250.283600,27.914503 250.968933,28.681911 
z"/>
<path fill="#FAF222" opacity="1.000000" stroke="none" 
	d="
M248.020798,6.704866 
	C247.956619,6.438553 248.386841,6.244515 248.620300,6.213738 
	C249.150497,6.621457 249.447250,7.059954 249.799454,7.757913 
	C249.264938,7.668643 248.674957,7.319911 248.020798,6.704866 
z"/>
<path fill="#FAF222" opacity="1.000000" stroke="none" 
	d="
M254.160828,12.195731 
	C254.434219,11.968791 254.848831,12.013348 255.576172,12.045129 
	C255.888885,12.032352 255.975998,12.511799 255.986176,12.755211 
	C255.431580,12.821491 254.866837,12.644361 254.160828,12.195731 
z"/>
<path fill="#FAF222" opacity="1.000000" stroke="none" 
	d="
M264.976501,43.648365 
	C264.744476,43.829899 264.518250,43.701950 264.364807,43.225685 
	C264.585083,43.119385 264.766663,43.273220 264.976501,43.648365 
z"/>
<path fill="#F2C712" opacity="1.000000" stroke="none" 
	d="
M9.991918,106.008308 
	C10.097158,104.964638 9.803840,103.381218 10.370670,102.962662 
	C14.915709,99.606575 18.579473,94.498161 25.821098,95.109116 
	C26.710329,95.420532 26.900898,95.676430 26.978439,96.343079 
	C26.138527,98.998810 22.758266,101.671440 27.899008,103.503174 
	C27.924234,104.918633 27.916948,105.950142 27.505785,106.992615 
	C24.249569,107.002289 21.389738,107.129448 18.546988,106.963692 
	C15.686454,106.796898 12.842908,106.338776 9.991918,106.008308 
z"/>
<path fill="#FBEB18" opacity="1.000000" stroke="none" 
	d="
M9.615334,106.003410 
	C12.842908,106.338776 15.686454,106.796898 18.546988,106.963692 
	C21.389738,107.129448 24.249569,107.002289 27.538200,107.369316 
	C27.975428,108.489967 27.976366,109.244888 27.531887,109.998222 
	C24.404278,110.011787 21.722084,110.026932 18.597935,110.027473 
	C16.098654,110.020622 14.041330,110.028374 11.582150,110.020332 
	C8.791267,109.677338 6.402242,109.350136 4.013216,109.022934 
	C4.011367,108.852600 4.009519,108.682266 4.006482,108.256424 
	C5.749778,107.333450 7.494263,106.665977 9.615334,106.003410 
z"/>
<path fill="#FAF222" opacity="1.000000" stroke="none" 
	d="
M27.016901,95.987862 
	C26.900898,95.676430 26.710329,95.420532 26.231819,95.106773 
	C25.781593,93.228516 25.544744,91.463654 25.175247,88.955444 
	C27.039146,83.559555 30.295336,86.977852 33.348198,86.982780 
	C34.123322,87.005287 34.566658,87.012604 35.006786,87.416229 
	C34.878819,89.049667 34.754059,90.286804 34.629299,91.523933 
	C34.178562,91.180519 33.727821,90.837097 33.277081,90.493683 
	C31.190355,92.325073 29.103628,94.156471 27.016901,95.987862 
z"/>
<path fill="#FBEB18" opacity="1.000000" stroke="none" 
	d="
M2.194971,101.239502 
	C2.591525,99.745338 3.340886,98.539078 3.739051,97.226295 
	C4.588239,94.426453 5.255301,91.571358 5.997272,88.369148 
	C7.119550,88.304672 8.241546,88.609947 9.672791,88.956421 
	C7.911681,92.706390 8.080162,93.388359 11.211687,95.153008 
	C8.349631,97.205093 5.369125,99.342102 2.194971,101.239502 
z"/>
<path fill="#F9F32B" opacity="1.000000" stroke="none" 
	d="
M16.946779,82.134697 
	C17.059608,82.444580 17.257629,82.683990 18.063789,82.916138 
	C19.060078,82.651588 19.533421,82.323860 20.302719,81.975296 
	C21.064564,81.636909 21.530451,81.319336 21.998116,81.000885 
	C21.999891,81.000000 22.002846,80.997253 22.377686,80.994293 
	C23.875008,81.343224 24.997490,81.695107 25.964142,82.329224 
	C24.862581,83.734474 23.916849,84.857498 22.971119,85.980515 
	C20.776920,84.915398 17.551455,89.672279 16.043301,84.367050 
	C16.053177,83.569916 16.059748,83.158508 16.281660,82.418648 
	C16.497004,82.090195 16.946779,82.134697 16.946779,82.134697 
z"/>
<path fill="#F2D407" opacity="1.000000" stroke="none" 
	d="
M23.132685,86.168625 
	C23.916849,84.857498 24.862581,83.734474 26.267963,82.465034 
	C28.821133,83.220428 30.914650,84.122246 33.009224,85.347855 
	C33.012325,86.103630 33.014370,86.535606 33.016418,86.967590 
	C30.295336,86.977852 27.039146,83.559555 25.020464,88.605034 
	C22.930246,89.085381 21.435518,88.802856 23.132685,86.168625 
z"/>
<path fill="#F6A821" opacity="1.000000" stroke="none" 
	d="
M4.193468,182.247467 
	C7.434142,181.217697 10.909278,180.463440 14.718079,180.174850 
	C15.086214,181.076157 15.120687,181.511780 15.107843,182.351166 
	C15.055780,183.833542 15.051033,184.912201 14.670389,186.032288 
	C12.888760,187.167603 11.483025,188.261459 10.077286,189.355301 
	C10.762207,190.177994 11.960208,191.772278 12.055796,191.708710 
	C13.733749,190.592926 15.339966,189.340973 16.804295,187.952515 
	C17.161945,187.613388 16.958584,186.682571 17.345230,186.016220 
	C18.120323,186.001297 18.563349,185.993942 19.264076,185.851074 
	C19.717913,185.178146 19.914043,184.640762 20.440937,184.041168 
	C21.844778,183.661255 22.917860,183.343536 23.946804,183.411758 
	C23.589294,185.844421 23.275923,187.891159 22.697659,190.157990 
	C19.959433,192.896133 17.486101,195.414215 15.012763,197.932281 
	C13.759050,194.693192 12.181807,195.833817 10.275198,197.798492 
	C9.039068,193.675751 8.211896,189.323074 7.040235,185.065170 
	C6.762167,184.054672 5.329502,183.361908 4.193468,182.247467 
z"/>
<path fill="#FDF8E4" opacity="1.000000" stroke="none" 
	d="
M21.990463,222.027237 
	C19.917107,220.813049 17.928865,218.344955 15.275118,221.791504 
	C13.225725,220.032990 11.454020,218.073212 9.342426,216.052277 
	C8.224259,215.335815 7.445980,214.680527 6.308737,214.035583 
	C5.888562,213.232162 5.827352,212.418396 5.876675,211.343933 
	C7.520121,209.249771 9.053033,207.416321 11.103230,205.389923 
	C12.013968,205.235306 12.407422,205.273590 12.800877,205.311874 
	C12.889736,205.664352 12.978596,206.016815 13.049805,206.991058 
	C13.644674,208.753159 11.250357,211.651688 15.338622,210.973969 
	C16.459658,210.362503 17.233118,209.739929 18.006573,209.117371 
	C18.006571,209.117355 18.427393,208.889008 18.956146,208.954269 
	C20.005806,209.672653 20.526712,210.325760 20.764786,211.218445 
	C20.565636,212.510757 20.263561,214.185043 20.795643,214.515259 
	C24.234608,216.649582 23.341299,219.256561 21.990463,222.027237 
M14.515770,214.410431 
	C14.515770,214.410431 14.580584,214.520676 14.515770,214.410431 
z"/>
<path fill="#F4C674" opacity="1.000000" stroke="none" 
	d="
M21.047623,210.978867 
	C20.526712,210.325760 20.005806,209.672653 19.178560,208.865723 
	C18.911180,208.327438 18.950136,207.942978 19.234257,207.010498 
	C21.302078,206.813019 23.122057,207.400986 24.948444,207.421616 
	C25.979370,207.433258 27.020844,206.511246 28.253651,206.012985 
	C28.449736,206.016174 28.842752,206.036148 28.908693,206.250793 
	C28.974634,206.465424 29.044943,206.908951 29.118481,207.150452 
	C29.451864,207.594208 29.711708,207.796448 30.262424,208.009872 
	C30.739389,207.966019 30.925478,207.910995 31.286182,207.929108 
	C31.460798,208.002258 31.837975,208.035538 31.908237,208.395309 
	C32.947231,209.136520 33.915966,209.517975 35.204659,210.012436 
	C36.999969,210.996582 38.838428,211.718643 37.042908,214.271423 
	C35.932152,215.862839 34.859528,217.099686 33.408386,218.772995 
	C36.252842,219.438202 39.144306,220.114395 41.748852,220.723511 
	C39.184422,222.735992 37.109333,224.364456 35.034241,225.992920 
	C33.339588,224.871872 31.644936,223.750809 30.010000,222.012268 
	C30.542513,218.062347 31.015306,214.729889 31.590647,210.674652 
	C28.721544,211.142609 26.520021,211.648102 24.294292,211.800842 
	C23.235851,211.873444 22.131290,211.273926 21.047623,210.978867 
z"/>
<path fill="#F8BD44" opacity="1.000000" stroke="none" 
	d="
M28.057568,206.009796 
	C27.020844,206.511246 25.979370,207.433258 24.948444,207.421616 
	C23.122057,207.400986 21.302078,206.813019 19.246014,206.719482 
	C16.219421,206.430511 17.016212,204.911377 18.680656,204.361404 
	C21.853144,203.313110 20.408972,202.033295 19.334518,199.995026 
	C21.258968,196.278580 23.127993,193.142090 25.368872,190.005280 
	C26.484344,190.003601 27.227961,190.002243 27.981335,190.467545 
	C28.013250,195.959396 28.035408,200.984604 28.057568,206.009796 
z"/>
<path fill="#FCCF4D" opacity="1.000000" stroke="none" 
	d="
M24.997013,190.005585 
	C23.127993,193.142090 21.258968,196.278580 19.197458,199.690887 
	C17.573206,199.432281 16.141441,198.897873 14.861221,198.147888 
	C17.486101,195.414215 19.959433,192.896133 23.037086,190.173309 
	C24.093275,189.980911 24.545143,189.993256 24.997013,190.005585 
z"/>
<path fill="#F4C674" opacity="1.000000" stroke="none" 
	d="
M14.991045,210.962875 
	C11.250357,211.651688 13.644674,208.753159 13.133705,207.251007 
	C14.062944,207.338242 14.890633,207.787292 15.780586,208.783539 
	C15.558917,209.874786 15.274981,210.418839 14.991045,210.962875 
z"/>
<path fill="#FBF1D4" opacity="1.000000" stroke="none" 
	d="
M15.338622,210.973969 
	C15.274981,210.418839 15.558917,209.874786 15.936460,209.033203 
	C16.474773,208.794998 16.919479,208.854340 17.685381,209.015533 
	C17.233118,209.739929 16.459658,210.362503 15.338622,210.973969 
z"/>
<path fill="#F6CB58" opacity="1.000000" stroke="none" 
	d="
M4.800663,207.617844 
	C5.218891,207.318970 5.539176,207.243347 5.859464,207.167709 
	C5.733313,207.613876 5.607160,208.060043 5.481007,208.506210 
	C5.221578,208.284500 4.962151,208.062790 4.800663,207.617844 
z"/>
<path fill="#F6CB58" opacity="1.000000" stroke="none" 
	d="
M12.841946,204.985718 
	C12.407422,205.273590 12.013968,205.235306 11.328348,205.143768 
	C11.280084,204.545181 11.523987,203.999802 11.767889,203.454437 
	C12.139597,203.856155 12.511306,204.257858 12.841946,204.985718 
z"/>
<path fill="#F7B615" opacity="1.000000" stroke="none" 
	d="
M8.373317,141.037231 
	C8.207981,140.428787 8.373891,139.879166 8.782448,139.161224 
	C10.348570,137.569717 11.672042,136.146545 13.323735,134.221466 
	C15.431415,133.821777 17.210873,133.923981 18.963322,134.368347 
	C19.338022,135.470276 19.654476,136.812424 20.156651,136.885956 
	C22.027927,137.159912 23.960173,137.017410 25.920242,137.345490 
	C26.286217,138.440414 26.601488,139.221039 26.565041,140.044769 
	C24.118204,140.714310 23.282682,144.042175 19.930508,142.259186 
	C15.926216,140.129333 13.044725,142.509430 10.951584,145.959717 
	C10.202577,144.338486 9.453569,142.717270 8.373317,141.037231 
z"/>
<path fill="#FBD233" opacity="1.000000" stroke="none" 
	d="
M11.297159,146.006470 
	C13.044725,142.509430 15.926216,140.129333 19.930508,142.259186 
	C23.282682,144.042175 24.118204,140.714310 26.602140,140.046631 
	C26.990963,140.005402 27.063519,140.004028 27.075291,140.345764 
	C27.364380,143.454300 27.641699,146.221069 27.484610,148.991730 
	C23.796398,148.795105 20.542597,148.594589 17.129087,148.384232 
	C16.877384,148.967133 16.434267,149.993332 15.618029,151.016541 
	C14.498857,151.015335 13.752807,151.017120 13.006756,151.018890 
	C13.046371,149.227890 15.108713,146.881256 11.297159,146.006470 
z"/>
<path fill="#FDCC46" opacity="1.000000" stroke="none" 
	d="
M12.977229,151.294159 
	C13.752807,151.017120 14.498857,151.015335 15.590179,151.346283 
	C15.958891,153.117950 15.982330,154.556885 16.000519,156.328995 
	C15.987262,157.106277 15.979253,157.550369 15.689369,158.115540 
	C14.312692,158.236603 13.217890,158.236603 12.000188,158.236603 
	C12.000188,159.471390 12.000188,160.734238 11.745892,161.994553 
	C11.322060,161.993225 11.152525,161.994446 10.743315,161.994110 
	C10.503639,161.992569 10.024310,161.987122 10.024311,161.987122 
	C8.891862,160.294296 7.759410,158.601486 6.156520,156.205444 
	C6.447545,156.004990 7.524219,155.263367 8.801130,154.261414 
	C10.316813,153.190506 11.632257,152.379959 12.977229,151.294159 
z"/>
<path fill="#F8BD44" opacity="1.000000" stroke="none" 
	d="
M10.019083,162.241592 
	C10.024310,161.987122 10.503639,161.992569 10.834151,162.165161 
	C11.443171,162.224197 11.721679,162.110641 12.000187,161.997086 
	C12.000188,160.734238 12.000188,159.471390 12.000188,158.236603 
	C13.217890,158.236603 14.312692,158.236603 15.995481,158.120544 
	C16.906427,158.027939 17.229383,158.051407 17.552338,158.074890 
	C17.056459,158.671982 16.520863,159.241180 16.076160,159.874222 
	C15.652909,160.476746 15.324584,161.145935 14.954525,161.785812 
	C15.643003,161.859665 16.331478,161.933517 17.011242,162.392975 
	C17.997889,164.518356 18.993250,166.258148 20.249977,168.207138 
	C20.208553,173.204086 16.224560,174.770157 12.999111,177.000092 
	C12.414060,176.177444 11.829009,175.354813 11.173758,173.906418 
	C12.617259,172.839203 14.130960,172.397736 16.324034,171.758133 
	C15.726473,170.470306 15.176283,168.050110 14.488282,168.010284 
	C11.067344,167.812210 10.982774,165.097855 10.007172,163.005066 
	C10.009399,162.835403 10.011627,162.665726 10.019083,162.241592 
z"/>
<path fill="#F7B135" opacity="1.000000" stroke="none" 
	d="
M9.635330,163.012741 
	C10.982774,165.097855 11.067344,167.812210 14.488282,168.010284 
	C15.176283,168.050110 15.726473,170.470306 16.324034,171.758133 
	C14.130960,172.397736 12.617259,172.839203 11.051247,173.627655 
	C9.176302,174.812302 7.346814,176.045975 6.922709,172.511002 
	C6.765261,171.198639 5.749393,169.989258 5.062365,168.374268 
	C6.420989,166.350555 7.842238,164.685486 9.635330,163.012741 
z"/>
<path fill="#F0B138" opacity="1.000000" stroke="none" 
	d="
M13.201031,177.270721 
	C16.224560,174.770157 20.208553,173.204086 20.263649,167.848999 
	C20.835442,166.760406 22.234232,165.655197 22.386621,165.806366 
	C24.348928,167.753067 26.133471,169.878967 27.974033,172.299927 
	C27.976202,173.094345 27.967337,173.548340 27.615955,174.040833 
	C25.830584,175.603653 24.387730,177.127991 23.169012,178.415527 
	C22.389572,177.895828 21.706333,177.440262 20.818672,176.735046 
	C19.083658,177.011917 17.553066,177.538452 16.022472,178.064987 
	C15.149300,177.890457 14.276125,177.715912 13.201031,177.270721 
z"/>
<path fill="#FEDD56" opacity="1.000000" stroke="none" 
	d="
M15.995895,178.348404 
	C17.553066,177.538452 19.083658,177.011917 20.767654,177.127945 
	C20.650761,179.881454 20.380466,181.992401 20.110176,184.103363 
	C19.914043,184.640762 19.717913,185.178146 19.263943,185.548889 
	C17.722458,184.237305 16.438808,183.092361 15.155159,181.947418 
	C15.120687,181.511780 15.086214,181.076157 15.059496,180.312286 
	C15.367938,179.533310 15.668629,179.082565 15.995895,178.348404 
z"/>
<path fill="#F7C830" opacity="1.000000" stroke="none" 
	d="
M18.990330,134.026199 
	C17.210873,133.923981 15.431415,133.821777 13.327492,133.861603 
	C11.428882,132.973221 9.776050,132.036499 8.345453,130.835144 
	C8.051208,130.588058 8.586961,129.352600 9.135506,128.113388 
	C10.355659,127.093376 11.180454,126.532684 12.009352,126.350433 
	C14.213025,133.470856 16.793770,127.612030 19.183992,127.294327 
	C18.735735,125.657066 18.374598,124.338013 18.316362,123.143562 
	C19.750509,123.177841 20.881758,123.087517 22.329666,123.000443 
	C23.102680,124.001816 23.559032,124.999939 23.767914,126.187637 
	C24.854446,129.884918 25.120815,132.833160 20.095709,133.165833 
	C19.709955,133.191376 19.357998,133.727448 18.990330,134.026199 
z"/>
<path fill="#FFEB52" opacity="1.000000" stroke="none" 
	d="
M4.053753,121.311714 
	C4.124182,120.482094 4.313713,119.899940 5.175118,119.153519 
	C7.899997,118.645203 9.953001,118.301147 12.330519,117.975113 
	C13.087728,118.018845 13.520423,118.044571 13.987358,118.376907 
	C14.333523,119.443306 14.645447,120.203079 14.580612,121.019753 
	C11.157543,121.698067 8.111232,122.319489 5.064919,122.940918 
	C4.767562,122.480339 4.470208,122.019768 4.053753,121.311714 
z"/>
<path fill="#FDE22D" opacity="1.000000" stroke="none" 
	d="
M5.042399,123.300644 
	C8.111232,122.319489 11.157543,121.698067 14.597155,121.284912 
	C14.994151,121.669968 14.997848,121.846764 14.867117,122.306168 
	C13.823545,123.716522 12.914399,124.844261 12.005251,125.972000 
	C11.180454,126.532684 10.355659,127.093376 9.271677,127.834579 
	C8.607964,125.714203 3.596659,127.637032 5.042399,123.300644 
z"/>
<path fill="#F8D80B" opacity="1.000000" stroke="none" 
	d="
M4.667607,80.004066 
	C5.483397,80.575981 5.984016,81.175980 6.754009,81.906296 
	C6.104263,82.896004 5.185143,83.755402 3.945587,84.914413 
	C3.327130,83.691414 2.796919,82.642929 2.140846,81.279221 
	C2.794134,80.653381 3.573286,80.342773 4.667607,80.004066 
z"/>
<path fill="#F2C712" opacity="1.000000" stroke="none" 
	d="
M4.229580,109.311508 
	C6.402242,109.350136 8.791267,109.677338 11.588806,110.407234 
	C12.011473,112.873199 12.025626,114.936462 11.762444,116.794952 
	C10.821736,115.162148 10.158364,113.734116 9.452477,112.214569 
	C8.098422,113.308464 7.056487,114.150215 6.014553,114.991959 
	C5.491683,113.194664 4.968814,111.397369 4.229580,109.311508 
z"/>
<path fill="#F8D02B" opacity="1.000000" stroke="none" 
	d="
M5.978220,115.335648 
	C7.056487,114.150215 8.098422,113.308464 9.452477,112.214569 
	C10.158364,113.734116 10.821736,115.162148 11.754233,117.034363 
	C12.023358,117.478546 12.006005,117.957100 12.006005,117.957100 
	C9.953001,118.301147 7.899997,118.645203 5.428474,118.975616 
	C5.320600,117.867775 5.631244,116.773560 5.978220,115.335648 
z"/>
<path fill="#E5B368" opacity="1.000000" stroke="none" 
	d="
M3.406494,217.058090 
	C2.882883,217.044571 2.691038,217.025131 2.209242,216.997101 
	C3.022126,216.643097 4.124961,216.297714 5.590604,215.976959 
	C5.215027,216.351776 4.476643,216.701981 3.406494,217.058090 
z"/>
<path fill="#F0B138" opacity="1.000000" stroke="none" 
	d="
M229.523575,167.283783 
	C229.756760,170.417816 229.849350,173.263306 229.576996,176.532104 
	C228.484238,176.647614 227.756439,176.339828 227.066132,175.628021 
	C226.811569,170.541138 226.519516,165.858276 226.215393,160.982101 
	C220.707382,161.428131 217.200607,162.281693 216.576324,167.567535 
	C216.150146,171.176147 214.019089,173.230850 209.880661,171.725967 
	C209.168091,170.972672 208.577484,170.485336 208.139130,169.693817 
	C210.517136,166.931381 212.742889,164.473129 214.982422,161.648972 
	C215.662308,159.522034 216.328400,157.761017 217.382355,156.009277 
	C221.138199,156.914185 224.506180,157.809814 227.874161,158.705460 
	C227.874161,158.705460 227.989197,158.828400 228.027588,158.903091 
	C228.504990,161.650269 228.944000,164.322769 229.523575,167.283783 
z"/>
<path fill="#F68A1A" opacity="1.000000" stroke="none" 
	d="
M255.345810,139.117386 
	C253.333466,136.705826 251.652634,134.234055 250.259888,131.356079 
	C253.767746,130.447479 256.982544,129.896896 260.219604,129.560974 
	C260.416168,129.540573 260.789581,131.224976 261.057159,132.482086 
	C260.692352,134.230072 260.351105,135.612122 260.009888,136.994171 
	C260.009888,136.994171 259.997559,136.997192 259.661926,137.020157 
	C258.557007,137.741882 257.787720,138.440643 257.018433,139.139404 
	C256.571381,139.112015 256.124359,139.084595 255.345810,139.117386 
z"/>
<path fill="#F68A1A" opacity="1.000000" stroke="none" 
	d="
M273.808105,126.015045 
	C276.160980,130.741928 273.008026,134.588165 271.186798,138.795959 
	C270.053101,138.903397 269.505157,138.955994 268.957245,139.008591 
	C268.298981,137.891510 267.640717,136.774414 266.969208,134.870407 
	C267.011017,131.717911 267.066101,129.352325 267.335358,126.995987 
	C267.549561,127.005234 267.978333,127.005577 268.174500,127.252335 
	C270.183167,127.004410 271.995636,126.509735 273.808105,126.015045 
z"/>
<path fill="#391E3B" opacity="1.000000" stroke="none" 
	d="
M267.121155,126.986748 
	C267.066101,129.352325 267.011017,131.717911 266.904327,134.520355 
	C264.023834,133.222137 262.388214,135.189331 260.347198,136.969727 
	C260.351105,135.612122 260.692352,134.230072 261.371582,132.325958 
	C262.489014,129.880859 263.268494,127.957840 264.029907,126.026543 
	C264.011902,126.018272 264.021698,126.053658 264.280701,125.984863 
	C264.735565,125.629143 264.931427,125.342209 265.318909,125.056084 
	C265.510529,125.056885 265.893494,125.071487 265.989532,125.343857 
	C266.430756,126.073074 266.775970,126.529915 267.121155,126.986748 
z"/>
<path fill="#391E3B" opacity="1.000000" stroke="none" 
	d="
M257.075378,139.447586 
	C257.787720,138.440643 258.557007,137.741882 259.660370,137.026123 
	C259.678802,138.589905 260.532867,141.389603 257.075378,139.447586 
z"/>
<path fill="#E48B3C" opacity="1.000000" stroke="none" 
	d="
M269.066284,139.262482 
	C269.505157,138.955994 270.053101,138.903397 270.829529,138.952179 
	C271.009369,139.446182 270.960663,139.838821 270.900757,140.526581 
	C270.318146,140.386597 269.746704,139.951492 269.066284,139.262482 
z"/>
<path fill="#FDFAFE" opacity="1.000000" stroke="none" 
	d="
M308.423645,168.900543 
	C309.039459,174.812729 311.192810,180.830872 305.335938,185.080566 
	C304.235229,185.879257 302.866241,186.308273 301.621063,186.907852 
	C301.083038,185.650925 299.842468,184.200745 300.126068,183.169800 
	C301.506592,178.150864 303.249847,173.227341 305.063843,168.344040 
	C305.160797,168.083084 307.069977,168.495316 308.423645,168.900543 
z"/>
<path fill="#29196B" opacity="1.000000" stroke="none" 
	d="
M332.001221,157.999084 
	C332.123077,158.140457 332.243713,158.282761 332.364349,158.425079 
	C332.278778,158.376022 332.193237,158.326981 332.052979,158.140182 
	C331.998291,158.002441 332.000000,158.000000 332.001221,157.999084 
z"/>
<path fill="#F28B21" opacity="1.000000" stroke="none" 
	d="
M399.308044,139.973999 
	C395.104675,137.036102 389.757263,135.195709 390.460358,128.468903 
	C393.111420,130.651932 395.337402,133.193161 399.142609,130.798340 
	C400.194702,130.864487 401.246796,130.930634 402.913544,131.313538 
	C404.705780,131.387390 405.883392,131.144485 407.060974,130.901581 
	C407.462219,130.900421 407.863464,130.899261 408.890991,130.913452 
	C410.323303,130.614517 411.129333,130.300232 412.247162,130.134384 
	C414.636780,130.512100 416.714600,130.741394 418.792450,130.970688 
	C417.847504,136.013550 414.923370,138.819229 409.329773,139.105103 
	C408.965149,139.190475 408.505585,139.008545 408.183594,138.814636 
	C407.587646,138.746475 407.313721,138.872253 407.039795,138.998016 
	C406.858215,139.013107 406.676636,139.028198 405.908142,139.036774 
	C404.588165,139.339600 403.855103,139.648926 403.122009,139.958252 
	C402.909058,140.163879 402.708405,140.380905 402.282867,140.385971 
	C401.331940,140.114792 400.618225,140.066940 399.904480,140.019104 
	C399.904480,140.019089 399.507263,139.982880 399.308044,139.973999 
z"/>
<path fill="#311B57" opacity="1.000000" stroke="none" 
	d="
M399.966736,140.321838 
	C400.618225,140.066940 401.331940,140.114792 402.073425,140.457855 
	C401.410400,140.710251 400.719696,140.667419 399.966736,140.321838 
z"/>
<path fill="#311B57" opacity="1.000000" stroke="none" 
	d="
M403.433228,139.984161 
	C403.855103,139.648926 404.588165,139.339600 405.647980,139.120529 
	C405.231293,139.477203 404.487885,139.743637 403.433228,139.984161 
z"/>
<path fill="#311B57" opacity="1.000000" stroke="none" 
	d="
M407.282837,139.018646 
	C407.313721,138.872253 407.587646,138.746475 407.936584,138.807175 
	C408.011566,138.993637 407.525879,139.039276 407.282837,139.018646 
z"/>
<path fill="#FDFAFE" opacity="1.000000" stroke="none" 
	d="
M365.162079,177.985123 
	C365.162079,177.985123 364.994812,178.412247 364.665894,178.796967 
	C363.194580,181.103012 362.052124,183.024323 360.909698,184.945618 
	C360.909698,184.945618 360.983429,184.977554 360.715332,185.021927 
	C360.264313,185.364258 360.081390,185.662216 359.898499,185.960175 
	C359.898499,185.960175 359.974518,185.973465 359.711426,186.019974 
	C359.259857,186.361053 359.071411,186.655609 358.882935,186.950165 
	C358.882935,186.950165 358.964355,186.968430 358.624603,186.977325 
	C357.850952,187.047195 357.417084,187.108170 356.983185,187.169144 
	C356.983185,187.169144 356.571289,187.008057 356.256104,186.726974 
	C355.640991,186.257050 355.341095,186.068207 355.041199,185.879364 
	C355.041199,185.879349 354.996887,185.415970 354.989594,184.776642 
	C355.265594,181.418716 355.548889,178.700150 355.832214,175.981567 
	C355.832214,175.981567 355.985291,175.560593 356.335632,175.176575 
	C357.163483,173.216949 357.640991,171.641357 358.118530,170.065765 
	C358.118530,170.065765 358.037842,170.045044 358.300049,169.994110 
	C358.740936,169.641403 358.919647,169.339645 359.098389,169.037888 
	C359.098389,169.037888 359.022583,169.021317 359.290741,168.975006 
	C359.654388,168.617325 359.749878,168.305954 359.845398,167.994583 
	C363.319244,165.102234 363.410889,168.827606 363.857086,170.383850 
	C364.415314,172.330902 364.106628,174.526505 364.590271,177.065414 
	C365.008392,177.516006 365.162079,177.985123 365.162079,177.985123 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M355.464325,176.084442 
	C355.548889,178.700150 355.265594,181.418716 354.910187,184.557861 
	C354.924194,182.048050 355.010315,179.117676 355.464325,176.084442 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M361.297607,184.955444 
	C362.052124,183.024323 363.194580,181.103012 364.646790,179.025543 
	C364.889191,181.450256 365.463867,184.375656 361.297607,184.955444 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M357.733826,170.110657 
	C357.640991,171.641357 357.163483,173.216949 356.361176,174.954056 
	C356.473969,173.462219 356.911560,171.808884 357.733826,170.110657 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M357.038696,187.459793 
	C357.417084,187.108170 357.850952,187.047195 358.611084,186.937836 
	C358.322937,187.176468 357.708557,187.463455 357.038696,187.459793 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M365.302002,177.865158 
	C365.162079,177.985123 365.008392,177.516006 364.945953,177.277206 
	C365.069641,177.274002 365.255768,177.509598 365.302002,177.865158 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M360.142151,185.859512 
	C360.081390,185.662216 360.264313,185.364258 360.704437,184.983261 
	C360.769684,185.186432 360.577728,185.472641 360.142151,185.859512 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M355.146973,186.126556 
	C355.341095,186.068207 355.640991,186.257050 356.036469,186.701111 
	C355.838928,186.762146 355.545837,186.567947 355.146973,186.126556 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M359.129486,186.853180 
	C359.071411,186.655609 359.259857,186.361053 359.704315,185.982056 
	C359.765564,186.183807 359.570801,186.469986 359.129486,186.853180 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M359.649536,168.126709 
	C359.749878,168.305954 359.654388,168.617325 359.302185,169.012085 
	C359.084656,168.769287 359.220703,168.490417 359.649536,168.126709 
z"/>
<path fill="#2B1E5D" opacity="1.000000" stroke="none" 
	d="
M358.853302,169.141296 
	C358.919647,169.339645 358.740936,169.641403 358.307831,170.035034 
	C358.238373,169.832840 358.423309,169.538788 358.853302,169.141296 
z"/>
<path fill="#F6A821" opacity="1.000000" stroke="none" 
	d="
M348.937103,128.042938 
	C347.739655,127.168297 346.888916,124.253815 344.697815,127.903152 
	C341.833588,133.444061 339.993927,128.730484 337.866791,127.954651 
	C337.660797,123.122925 337.454803,118.291199 337.634918,113.705704 
	C337.686127,117.564850 339.176788,120.209122 342.740601,120.873924 
	C346.209351,121.521004 349.749023,121.361351 351.038696,116.951416 
	C352.296600,119.378410 353.554474,121.805420 354.559937,124.599136 
	C352.208954,125.035591 349.697388,124.519981 349.738220,128.004059 
	C349.292908,128.019028 349.114990,128.030991 348.937103,128.042938 
z"/>
<path fill="#FAC90E" opacity="1.000000" stroke="none" 
	d="
M351.002136,116.700264 
	C349.749023,121.361351 346.209351,121.521004 342.740601,120.873924 
	C339.176788,120.209122 337.686127,117.564850 338.026733,113.557465 
	C343.049683,108.776237 345.606171,108.681854 349.667236,113.261436 
	C350.257172,114.507645 350.531342,115.237518 350.805511,115.967392 
	C350.858856,116.127968 350.912201,116.288536 351.002136,116.700264 
z"/>
<path fill="#F99F11" opacity="1.000000" stroke="none" 
	d="
M350.005615,128.001022 
	C349.697388,124.519981 352.208954,125.035591 354.718140,124.917679 
	C356.677673,124.106903 358.226562,123.344284 360.298950,122.295219 
	C362.245972,123.064018 363.669556,124.119270 365.093079,125.174530 
	C365.059357,125.583565 365.025635,125.992599 364.691620,126.827774 
	C364.155914,127.497772 364.040039,127.789185 364.043701,128.128143 
	C364.043701,128.128143 363.963989,128.574371 363.666443,128.904175 
	C363.138458,129.470245 363.025177,129.753387 363.029053,130.083405 
	C363.029053,130.083405 362.977325,130.541397 362.505005,130.762238 
	C358.001282,130.671448 353.969818,130.359833 349.938385,130.048218 
	C349.938385,130.048218 349.968384,129.998856 349.991547,129.683105 
	C350.011688,128.911896 350.008667,128.456467 350.005615,128.001022 
z"/>
<path fill="#FCB60C" opacity="1.000000" stroke="none" 
	d="
M365.287903,125.145264 
	C363.669556,124.119270 362.245972,123.064018 360.469147,121.998901 
	C360.840698,119.719727 361.565491,117.450417 362.636108,115.394287 
	C363.714294,119.247681 366.155579,119.277901 369.006744,118.470154 
	C370.725555,117.983208 372.451355,117.520927 374.174011,117.047569 
	C374.174011,117.047569 374.178986,117.440109 374.111450,118.079056 
	C374.014557,120.817268 373.985168,122.916542 373.583954,125.011261 
	C371.796753,125.351173 370.381378,125.695625 368.629456,126.025452 
	C367.844543,126.019562 367.396149,126.028282 366.947754,126.037003 
	C366.947754,126.036995 366.993652,125.997208 366.904358,125.767166 
	C366.502289,125.395767 366.189453,125.254402 365.876648,125.113037 
	C365.876648,125.113037 365.482697,125.116005 365.287903,125.145264 
z"/>
<path fill="#EF8C29" opacity="1.000000" stroke="none" 
	d="
M349.969055,130.431931 
	C353.969818,130.359833 358.001282,130.671448 362.424255,130.978073 
	C361.425629,133.800644 360.359070,136.864075 358.530884,139.372406 
	C357.323242,141.029343 351.858032,137.777969 351.117340,135.066376 
	C350.731323,133.653152 350.371429,132.232773 349.969055,130.431931 
z"/>
<path fill="#FAC90E" opacity="1.000000" stroke="none" 
	d="
M374.460327,116.824562 
	C372.451355,117.520927 370.725555,117.983208 369.006744,118.470154 
	C366.155579,119.277901 363.714294,119.247681 362.955872,115.216240 
	C367.346588,109.077728 370.508850,108.217728 373.553162,112.176804 
	C374.413696,113.295914 374.373627,115.107544 374.460327,116.824562 
z"/>
<path fill="#EF8C29" opacity="1.000000" stroke="none" 
	d="
M337.548340,128.202209 
	C339.993927,128.730484 341.833588,133.444061 344.596100,128.239929 
	C344.937073,130.536041 345.319550,133.046204 345.092194,135.499863 
	C344.968384,136.836136 344.067230,138.796051 343.046417,139.161606 
	C341.984650,139.541824 339.442780,138.652298 339.142120,137.772949 
	C338.123993,134.795410 337.811829,131.576462 337.548340,128.202209 
z"/>
<path fill="#E59336" opacity="1.000000" stroke="none" 
	d="
M366.949707,126.480698 
	C367.396149,126.028282 367.844543,126.019562 368.790924,126.381447 
	C370.170105,128.808716 371.051300,130.865372 371.932495,132.922028 
	C372.904388,130.947464 373.876282,128.972885 374.848145,126.998322 
	C374.883545,130.143997 375.137115,133.310165 374.844788,136.425079 
	C374.730957,137.637939 373.591949,139.322067 372.535187,139.691589 
	C371.751160,139.965775 369.640381,138.681000 369.344543,137.715607 
	C368.268494,134.203857 367.707123,130.534393 366.949707,126.480698 
z"/>
<path fill="#FA9D0D" opacity="1.000000" stroke="none" 
	d="
M374.917694,126.782867 
	C373.876282,128.972885 372.904388,130.947464 371.932495,132.922028 
	C371.051300,130.865372 370.170105,128.808716 369.127441,126.396057 
	C370.381378,125.695625 371.796753,125.351173 373.849670,125.015839 
	C374.663330,125.008194 374.839417,124.991440 375.015503,124.974686 
	C375.015503,124.974686 375.003082,124.993820 374.970947,125.273582 
	C374.962402,125.740616 374.985962,125.927879 375.009521,126.115135 
	C375.009521,126.115135 374.987244,126.567406 374.917694,126.782867 
z"/>
<path fill="#E59336" opacity="1.000000" stroke="none" 
	d="
M375.039825,124.554321 
	C374.839417,124.991440 374.663330,125.008194 374.221497,125.020378 
	C373.985168,122.916542 374.014557,120.817268 374.117218,118.275253 
	C374.481689,119.932999 374.772919,122.033485 375.039825,124.554321 
z"/>
<path fill="#F6A821" opacity="1.000000" stroke="none" 
	d="
M350.891968,115.634262 
	C350.531342,115.237518 350.257172,114.507645 349.982849,113.468628 
	C350.314636,113.873367 350.646515,114.587250 350.891968,115.634262 
z"/>
<path fill="#EF8C29" opacity="1.000000" stroke="none" 
	d="
M349.738220,128.004059 
	C350.008667,128.456467 350.011688,128.911896 349.984131,129.708069 
	C349.627441,129.586685 349.301331,129.124573 348.956177,128.352692 
	C349.114990,128.030991 349.292908,128.019028 349.738220,128.004059 
z"/>
<path fill="#E59336" opacity="1.000000" stroke="none" 
	d="
M365.970825,125.355698 
	C366.189453,125.254402 366.502289,125.395767 366.893311,125.795403 
	C366.669373,125.901901 366.367188,125.750130 365.970825,125.355698 
z"/>
<path fill="#E59336" opacity="1.000000" stroke="none" 
	d="
M375.220947,126.019104 
	C374.985962,125.927879 374.962402,125.740616 374.982025,125.273636 
	C375.249420,125.177750 375.483063,125.352432 375.683197,125.559509 
	C375.703369,125.580391 375.520844,125.797188 375.220947,126.019104 
z"/>
<path fill="#EF8C29" opacity="1.000000" stroke="none" 
	d="
M364.287354,128.029816 
	C364.040039,127.789185 364.155914,127.497772 364.590393,127.119316 
	C364.703278,127.300316 364.617157,127.615906 364.287354,128.029816 
z"/>
<path fill="#EF8C29" opacity="1.000000" stroke="none" 
	d="
M363.266541,129.987717 
	C363.025177,129.753387 363.138458,129.470245 363.565002,129.106842 
	C363.675415,129.283813 363.589722,129.587921 363.266541,129.987717 
z"/>
<path fill="#FEFD05" opacity="1.000000" stroke="none" 
	d="
M223.014160,30.355976 
	C223.884476,25.961283 225.732529,23.059526 230.756592,23.261360 
	C231.570526,23.163128 232.129196,23.354910 232.904953,24.035341 
	C233.298859,24.802641 233.549103,24.974178 234.153809,25.143021 
	C235.347443,26.027895 236.260071,26.808350 237.224945,27.904388 
	C237.813843,28.497505 238.350494,28.775042 239.161255,29.074804 
	C239.617706,29.077818 239.800034,29.058607 239.982346,29.039394 
	C240.272812,29.501814 240.563293,29.964233 240.956451,31.025459 
	C241.738022,32.065903 242.416901,32.507542 243.095779,32.949181 
	C243.995667,33.749840 244.895554,34.550503 245.934509,35.987339 
	C246.786804,37.043079 247.500031,37.462646 248.213272,37.882217 
	C248.543152,37.954269 248.783813,38.141186 248.991943,39.005783 
	C249.518417,39.909298 249.988220,40.250004 250.458023,40.590706 
	C250.458023,40.590706 250.454056,40.633888 250.408752,40.923813 
	C250.706451,41.316166 251.049454,41.418598 251.392456,41.521027 
	C251.392456,41.521027 251.473541,41.573647 251.333725,41.913490 
	C250.819595,43.175545 250.445282,44.097759 249.687500,45.017765 
	C247.846878,45.360558 246.389755,45.705563 244.548813,46.044266 
	C243.136337,46.010616 242.107651,45.983257 241.043518,45.968399 
	C241.008057,45.980900 241.031616,45.909489 240.941589,45.692318 
	C240.662476,45.202816 240.405136,45.035370 240.045883,44.983414 
	C240.012238,44.994019 240.034348,44.927010 240.023315,44.545464 
	C238.643036,43.470989 237.273788,42.778065 235.904526,42.085140 
	C235.904526,42.085140 235.982086,42.007626 235.893341,41.798161 
	C235.515823,41.399502 235.227036,41.210304 234.938248,41.021111 
	C234.473312,40.746841 234.008377,40.472572 233.205643,39.764580 
	C231.595352,38.517471 230.322861,37.704090 229.050369,36.890709 
	C227.038910,34.832142 225.027451,32.773575 223.014160,30.355976 
z"/>
<path fill="#FAD809" opacity="1.000000" stroke="none" 
	d="
M244.932617,46.050564 
	C246.389755,45.705563 247.846878,45.360558 249.817169,45.309593 
	C251.791473,48.519234 254.308746,48.488392 256.998962,47.996269 
	C257.004913,48.000786 257.007538,48.013977 257.077667,48.239067 
	C257.317261,48.749268 257.561951,48.935574 257.928802,49.016029 
	C257.975739,49.008980 257.982971,49.103626 258.084717,49.307175 
	C258.379639,49.764168 258.632629,49.921349 259.223236,50.168179 
	C259.997131,51.330143 260.493195,52.306183 260.974365,53.655346 
	C258.270325,58.420528 253.892319,56.575207 250.073334,56.967285 
	C250.027557,56.970985 250.028656,56.879131 249.956604,56.709045 
	C249.755829,56.320496 249.578445,56.153812 249.162628,55.815819 
	C248.645966,55.400299 248.319046,55.207867 247.992142,55.015434 
	C247.495514,54.418240 246.998886,53.821049 246.130981,52.835831 
	C245.157883,51.649166 244.556076,50.850529 244.011414,49.708687 
	C244.356583,48.260509 244.644592,47.155537 244.932617,46.050564 
z"/>
<path fill="#F9C617" opacity="1.000000" stroke="none" 
	d="
M250.119141,56.963577 
	C253.892319,56.575207 258.270325,58.420528 261.217621,53.579849 
	C261.643494,53.104984 261.811218,53.078739 261.978943,53.052498 
	C262.444183,53.636543 262.909393,54.220589 263.652283,55.251770 
	C264.582947,56.504200 265.236023,57.309490 265.889069,58.114784 
	C265.889069,58.114780 265.944061,57.984695 265.916077,58.348858 
	C266.396545,59.273045 266.904999,59.833065 267.413452,60.393089 
	C267.413452,60.393089 267.506683,60.469490 267.598297,60.816162 
	C268.436035,62.132935 269.182129,63.103039 269.928253,64.073143 
	C269.928253,64.073143 269.962769,63.988033 269.893250,64.260094 
	C269.823730,64.532158 269.689209,64.987511 269.320465,64.991058 
	C264.635742,62.681644 260.312897,64.211800 255.749344,64.968521 
	C255.507248,64.965546 255.023026,64.968239 255.023026,64.968239 
	C255.023026,64.968239 254.997925,64.997925 254.947983,64.737259 
	C254.615875,64.295425 254.333710,64.114250 254.051544,63.933075 
	C254.051544,63.933079 254.021515,64.007645 254.026184,63.650398 
	C253.373749,62.493973 252.716644,61.694794 252.059540,60.895615 
	C252.059525,60.895618 251.929138,60.417522 251.830566,59.975250 
	C251.579529,59.221497 251.330063,59.057316 250.983582,59.040432 
	C250.983582,59.040432 251.004547,59.003113 250.938324,58.731712 
	C250.621124,57.961399 250.370132,57.462490 250.119141,56.963577 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M237.259186,54.394051 
	C235.024887,52.119778 233.053467,49.919140 230.948425,47.407242 
	C236.077805,47.250828 238.225006,50.558250 239.020172,55.605530 
	C238.511444,55.483624 238.016754,54.975651 237.259186,54.394051 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M243.954254,50.051888 
	C244.556076,50.850529 245.157883,51.649166 245.852097,52.736416 
	C241.547501,52.610455 240.732712,48.751511 239.248459,45.393143 
	C239.685211,44.974293 239.859772,44.950653 240.034348,44.927010 
	C240.034348,44.927010 240.012238,44.994019 240.114014,45.217010 
	C240.405380,45.739582 240.677307,45.896076 241.031616,45.909489 
	C241.031616,45.909489 241.008057,45.980900 241.121567,46.279377 
	C242.141464,47.735867 243.047867,48.893875 243.954254,50.051888 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M223.996216,40.578381 
	C221.635941,40.710335 219.263367,40.440434 216.480865,40.162895 
	C218.383392,37.119446 221.632248,37.128174 223.996216,40.578381 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M246.936493,64.729401 
	C246.538605,65.058517 246.123474,65.009186 245.401276,64.899399 
	C244.377716,63.470905 243.661209,62.102879 242.938477,60.392395 
	C244.261246,61.483620 245.590256,62.917290 246.936493,64.729401 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M255.039734,65.324783 
	C255.023026,64.968239 255.507248,64.965546 255.888214,65.278458 
	C256.897858,67.018036 257.526550,68.444710 258.072449,69.943085 
	C257.989685,70.014793 257.824097,70.158226 257.824097,70.158226 
	C256.901550,68.665932 255.978973,67.173637 255.039734,65.324783 
z"/>
<path fill="#FFFEFE" opacity="1.000000" stroke="none" 
	d="
M257.826294,82.895699 
	C257.408569,82.839058 257.445312,82.712021 257.631226,82.264999 
	C257.881744,81.545509 257.946381,81.273056 258.010986,81.000610 
	C259.164490,81.146782 260.317963,81.292961 261.471466,81.439140 
	C261.485107,81.781403 261.498749,82.123672 261.512421,82.465935 
	C260.422943,82.628067 259.333466,82.790207 257.826294,82.895699 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M229.061859,37.257923 
	C230.322861,37.704090 231.595352,38.517471 232.957336,39.654007 
	C231.722336,39.193150 230.397858,38.409145 229.061859,37.257923 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M250.073349,56.967281 
	C250.370132,57.462490 250.621124,57.961399 250.918518,58.739609 
	C249.968536,57.942703 248.972168,56.866501 247.983978,55.402863 
	C248.319046,55.207867 248.645966,55.400299 249.151764,56.029350 
	C249.471161,56.759270 249.703827,56.896992 250.028656,56.879131 
	C250.028656,56.879131 250.027557,56.970985 250.073349,56.967281 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M255.957794,78.695869 
	C255.630188,78.870560 255.329086,78.661285 254.901581,77.974426 
	C254.621033,77.196877 254.375778,77.034172 254.039413,77.008736 
	C254.039413,77.008736 254.024521,76.985764 253.943481,76.706314 
	C253.648071,75.940369 253.433670,75.453873 253.219269,74.967392 
	C254.140961,76.082230 255.062637,77.197060 255.957794,78.695869 
z"/>
<path fill="#F9F32B" opacity="1.000000" stroke="none" 
	d="
M240.023315,44.545464 
	C239.859772,44.950653 239.685211,44.974293 239.247314,45.020294 
	C238.045609,44.249115 237.107224,43.455570 236.036682,42.373581 
	C237.273788,42.778065 238.643036,43.470989 240.023315,44.545464 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M228.017853,44.582748 
	C227.082306,44.159683 226.139877,43.372162 225.127289,42.278835 
	C226.041748,42.721451 227.026367,43.469872 228.017853,44.582748 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M260.310730,71.972214 
	C260.310730,71.972214 260.173981,72.437187 260.143250,72.677307 
	C259.391602,72.224754 258.670654,71.532074 257.886902,70.498810 
	C257.824097,70.158226 257.989685,70.014793 258.338715,70.089050 
	C259.228729,70.766273 259.769745,71.369247 260.310730,71.972214 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M252.037949,61.262047 
	C252.716644,61.694794 253.373749,62.493973 254.050049,63.617126 
	C253.384949,63.170227 252.700653,62.399353 252.037949,61.262047 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M260.062012,73.272949 
	C260.748871,73.699844 261.407257,74.492828 262.080383,75.609360 
	C261.407928,75.168282 260.720703,74.403656 260.062012,73.272949 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M257.959076,80.675316 
	C257.946381,81.273056 257.881744,81.545509 257.633789,82.198921 
	C256.960175,81.625732 256.469910,80.671600 255.946182,79.391266 
	C256.577545,79.493393 257.242371,79.921707 257.959076,80.675316 
z"/>
<path fill="#F6A92B" opacity="1.000000" stroke="none" 
	d="
M253.129181,74.755341 
	C253.433670,75.453873 253.648071,75.940369 253.931915,76.699005 
	C253.566177,76.977997 253.130981,76.984833 252.352844,76.615829 
	C252.009338,75.499413 252.008789,74.758842 252.246429,74.028687 
	C252.484589,74.039116 252.958694,74.089577 252.958694,74.089577 
	C252.958694,74.089577 253.039093,74.543289 253.129181,74.755341 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M268.221680,83.414093 
	C268.767914,83.134605 269.505951,83.072365 270.606445,83.093460 
	C270.117065,83.328308 269.265289,83.479820 268.221680,83.414093 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M251.035553,59.239357 
	C251.330063,59.057316 251.579529,59.221497 251.700836,59.764145 
	C251.404205,59.884293 251.210144,59.698616 251.035553,59.239357 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M247.951538,66.755585 
	C247.651947,66.958557 247.398773,66.793373 247.265198,66.224419 
	C247.546967,66.103844 247.751358,66.292770 247.951538,66.755585 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M242.885925,59.822998 
	C242.675079,59.880955 242.393738,59.694382 242.062286,59.252121 
	C242.279922,59.190445 242.547684,59.384457 242.885925,59.822998 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M254.126404,64.178276 
	C254.333710,64.114250 254.615875,64.295425 254.964905,64.728500 
	C254.754944,64.794762 254.478119,64.609123 254.126404,64.178276 
z"/>
<path fill="#F9F32B" opacity="1.000000" stroke="none" 
	d="
M234.992340,41.289028 
	C235.227036,41.210304 235.515823,41.399502 235.870422,41.847958 
	C235.639633,41.923798 235.343033,41.740372 234.992340,41.289028 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M240.930084,57.696369 
	C240.657776,57.922050 240.378113,57.771072 240.114197,57.225101 
	C240.378265,57.038975 240.642502,57.202175 240.930084,57.696369 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M251.229248,72.046310 
	C251.513428,72.122810 251.747971,72.275551 251.992203,72.731270 
	C252.051559,72.958015 251.941620,73.057884 251.712112,73.023224 
	C251.482605,72.988564 251.020416,72.943665 250.956406,72.672401 
	C250.814972,72.232224 250.737549,72.063309 250.798676,71.946365 
	C250.937225,71.998337 251.229248,72.046310 251.229248,72.046310 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M252.897217,73.845482 
	C252.958694,74.089577 252.484589,74.039116 252.244049,74.016312 
	C252.003494,73.993515 251.981628,73.986496 251.988007,73.753632 
	C251.994370,73.520775 251.941620,73.057884 251.941620,73.057884 
	C251.941620,73.057884 252.051559,72.958015 252.109024,72.909126 
	C252.389572,73.107285 252.612671,73.354332 252.897217,73.845482 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M262.096680,76.135544 
	C262.437134,75.951408 262.685059,76.149818 262.781433,76.752769 
	C262.458466,76.874062 262.249908,76.660995 262.096680,76.135544 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M263.072723,78.135666 
	C263.397827,77.966942 263.649567,78.163895 263.763000,78.748871 
	C263.439484,78.869728 263.231049,78.657875 263.072723,78.135666 
z"/>
<path fill="#F6A92B" opacity="1.000000" stroke="none" 
	d="
M250.944305,71.123161 
	C250.944305,71.123161 251.022827,71.603806 251.126038,71.825058 
	C251.229248,72.046310 250.937225,71.998337 250.630402,71.866699 
	C250.125595,71.564285 250.011765,71.349007 250.223099,71.085373 
	C250.464081,71.081528 250.944305,71.123161 250.944305,71.123161 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M250.886658,70.867958 
	C250.944305,71.123161 250.464081,71.081528 250.223969,71.049164 
	C249.983871,71.016808 249.911499,71.019531 249.917450,70.792130 
	C249.923386,70.564735 249.888702,70.111107 249.947266,70.049622 
	C250.005829,69.988136 250.119431,69.861877 250.119431,69.861877 
	C250.355942,70.112175 250.592468,70.362465 250.886658,70.867958 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M248.954330,70.021660 
	C248.987869,69.846039 249.021408,69.670410 249.147995,69.242981 
	C249.519180,69.085663 249.739212,69.255432 250.010284,69.681183 
	C250.119431,69.861877 250.005829,69.988136 249.720917,70.026062 
	C249.436020,70.063988 248.980637,70.054817 248.988144,70.036072 
	C248.995651,70.017334 248.954330,70.021660 248.954330,70.021660 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M248.907959,67.816666 
	C248.708282,67.867790 248.436249,67.677055 248.103149,67.237579 
	C248.306610,67.184158 248.571106,67.379486 248.907959,67.816666 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M266.110748,82.219101 
	C266.308777,82.139992 266.605896,82.301544 267.000397,82.706383 
	C266.801788,82.786369 266.505829,82.623062 266.110748,82.219101 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M265.099548,81.224319 
	C265.302032,81.154648 265.595612,81.319946 265.955292,81.736664 
	C265.744476,81.811821 265.467560,81.635559 265.099548,81.224319 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M264.120880,80.158485 
	C264.328644,80.101265 264.609375,80.290726 264.950317,80.735947 
	C264.738312,80.796188 264.466064,80.600677 264.120880,80.158485 
z"/>
<path fill="#F6A61A" opacity="1.000000" stroke="none" 
	d="
M399.119324,130.459259 
	C395.337402,133.193161 393.111420,130.651932 390.529602,128.076660 
	C390.217377,126.447006 390.247681,124.893028 390.847351,123.144325 
	C391.416748,122.949608 391.904022,122.957573 392.258118,123.002060 
	C394.715118,123.941910 396.302368,123.987976 396.298523,120.971619 
	C396.745972,120.963799 396.924988,120.955750 397.104004,120.947693 
	C397.768005,124.005196 398.432007,127.062698 399.119324,130.459259 
z"/>
<path fill="#FAC913" opacity="1.000000" stroke="none" 
	d="
M411.165070,117.932220 
	C411.137970,116.940445 410.727692,115.717285 411.159302,115.002510 
	C412.232361,113.225502 413.689209,111.680252 414.991913,110.041916 
	C416.321411,112.086395 417.709717,114.096573 418.936554,116.200905 
	C419.222351,116.691071 418.959106,117.501328 418.471619,118.577667 
	C416.342438,118.998886 414.690033,119.006760 412.878296,118.779114 
	C412.200958,118.339813 411.683014,118.136017 411.165070,117.932220 
z"/>
<path fill="#FCB60C" opacity="1.000000" stroke="none" 
	d="
M413.037659,119.014633 
	C414.690033,119.006760 416.342438,118.998886 418.406952,118.988861 
	C418.862915,120.704323 418.906738,122.421928 418.500061,124.569115 
	C416.046265,125.327644 414.042999,125.656601 412.030273,125.584869 
	C412.359802,123.127663 412.698730,121.071144 413.037659,119.014633 
z"/>
<path fill="#F99F11" opacity="1.000000" stroke="none" 
	d="
M412.039734,125.985565 
	C414.042999,125.656601 416.046265,125.327644 418.434540,124.993973 
	C418.864807,126.702621 418.910065,128.415985 418.873901,130.550018 
	C416.714600,130.741394 414.636780,130.512100 412.263428,129.734985 
	C411.991852,128.119980 412.015778,127.052773 412.039734,125.985565 
z"/>
<path fill="#FCB60C" opacity="1.000000" stroke="none" 
	d="
M396.030090,120.971397 
	C396.302368,123.987976 394.715118,123.941910 392.273926,122.688232 
	C391.666199,121.605392 391.396790,120.880875 391.127380,120.156357 
	C390.882599,119.230164 390.637817,118.303970 390.930969,117.211662 
	C391.657623,117.034058 391.846283,117.022568 392.430603,117.007607 
	C393.882141,117.003471 394.938019,117.002800 395.996124,117.399483 
	C396.008942,118.855026 396.019501,119.913208 396.030090,120.971397 
z"/>
<path fill="#E99B3B" opacity="1.000000" stroke="none" 
	d="
M412.030304,125.584869 
	C412.015778,127.052773 411.991852,128.119980 411.951660,129.586578 
	C411.129333,130.300232 410.323303,130.614517 409.191833,130.903717 
	C409.573120,126.837845 410.279816,122.797058 411.075806,118.344246 
	C411.683014,118.136017 412.200958,118.339813 412.878296,118.779114 
	C412.698730,121.071144 412.359802,123.127663 412.030304,125.584869 
z"/>
<path fill="#FAC90E" opacity="1.000000" stroke="none" 
	d="
M395.993896,117.002136 
	C394.938019,117.002800 393.882141,117.003471 392.417389,116.589401 
	C392.044250,114.506195 392.079987,112.837715 392.115723,111.169243 
	C392.559753,111.103424 393.003784,111.037605 393.993011,111.324188 
	C395.023438,113.451775 395.508667,115.226959 395.993896,117.002136 
z"/>
<path fill="#F5BE36" opacity="1.000000" stroke="none" 
	d="
M395.996124,117.399490 
	C395.508667,115.226959 395.023438,113.451775 394.323914,111.398460 
	C395.076874,111.950058 396.672272,112.647552 396.880585,113.637054 
	C397.313507,115.693596 397.011108,117.904915 397.057495,120.500153 
	C396.924988,120.955750 396.745972,120.963799 396.298523,120.971619 
	C396.019501,119.913208 396.008942,118.855026 395.996124,117.399490 
z"/>
<path fill="#EAC141" opacity="1.000000" stroke="none" 
	d="
M391.719360,111.263596 
	C392.079987,112.837715 392.044250,114.506195 392.021729,116.592865 
	C391.846283,117.022568 391.657623,117.034058 391.184662,117.050064 
	C391.041260,115.155708 391.182098,113.256836 391.719360,111.263596 
z"/>
<path fill="#E99B3B" opacity="1.000000" stroke="none" 
	d="
M406.883789,130.633911 
	C405.883392,131.144485 404.705780,131.387390 403.300842,131.393188 
	C404.284515,130.892807 405.495544,130.629532 406.883789,130.633911 
z"/>
<path fill="#EAC141" opacity="1.000000" stroke="none" 
	d="
M391.057739,120.495071 
	C391.396790,120.880875 391.666199,121.605392 391.919830,122.643738 
	C391.904022,122.957573 391.416748,122.949608 391.174133,122.932434 
	C390.950378,122.221428 390.969208,121.527611 391.057739,120.495071 
z"/>
<path fill="#F9DD0F" opacity="1.000000" stroke="none" 
	d="
M285.539062,48.096745 
	C286.005737,48.352501 286.150726,48.542747 286.495972,48.779694 
	C287.191010,49.535065 287.722870,50.215492 288.563904,51.008297 
	C288.931183,51.513363 288.989227,51.906052 289.052551,52.596687 
	C286.601440,56.777580 283.614441,58.994934 278.806824,56.589874 
	C277.977814,56.175159 276.702698,56.652229 275.062714,56.835243 
	C274.491791,56.953121 274.007385,56.987957 274.007385,56.987957 
	C274.007385,56.987957 274.000000,57.000153 273.939148,56.742775 
	C273.598816,56.289635 273.319305,56.093876 273.039825,55.898117 
	C273.039825,55.898117 272.964050,55.416290 272.865479,54.962837 
	C272.637939,54.153233 272.384094,53.980259 272.005371,53.990467 
	C272.005371,53.990467 272.000000,54.000153 271.948486,53.740448 
	C271.604218,53.306499 271.311401,53.132259 271.018616,52.958015 
	C271.018616,52.958015 270.995697,52.470600 270.980865,52.226635 
	C271.415344,51.363155 271.779694,50.299412 272.327484,50.194622 
	C276.591858,49.378849 280.891541,48.747692 285.539062,48.096745 
z"/>
<path fill="#F9F32B" opacity="1.000000" stroke="none" 
	d="
M256.992981,47.991756 
	C254.308746,48.488392 251.791473,48.519234 250.200638,45.311806 
	C250.445282,44.097759 250.819595,43.175545 251.382050,41.913738 
	C253.200699,42.931988 254.831223,44.289829 256.723846,46.153130 
	C256.988312,47.102974 256.990662,47.547367 256.992981,47.991756 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M256.998962,47.996269 
	C256.990662,47.547367 256.988312,47.102974 257.003174,46.324799 
	C258.684021,48.087635 260.347595,50.184261 261.995056,52.666695 
	C261.811218,53.078739 261.643494,53.104984 261.232513,53.206726 
	C260.493195,52.306183 259.997131,51.330143 259.164978,49.931053 
	C258.627289,49.205109 258.345337,49.070312 257.982971,49.103626 
	C257.982971,49.103626 257.975739,49.008980 257.868408,48.785149 
	C257.594452,48.262482 257.343292,48.080036 257.007538,48.013977 
	C257.007538,48.013977 257.004913,48.000786 256.998962,47.996269 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M237.172699,27.588806 
	C236.260071,26.808350 235.347443,26.027895 234.182449,24.888824 
	C233.939590,24.360109 233.949081,24.190008 233.958588,24.019913 
	C235.972137,25.445526 237.985703,26.871140 239.990814,28.668074 
	C239.800034,29.058607 239.617706,29.077818 239.061981,28.790865 
	C238.183304,28.186068 237.677994,27.887438 237.172699,27.588806 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M273.979675,70.655518 
	C273.964661,71.053818 273.483704,71.035576 273.202728,70.669708 
	C272.380798,69.098343 271.839874,67.892845 271.479004,66.510719 
	C271.779083,66.216293 271.899078,66.098495 272.019073,65.980690 
	C272.677612,67.406197 273.336182,68.831703 273.979675,70.655518 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M273.994629,57.398758 
	C274.007385,56.987957 274.491791,56.953121 274.733337,56.977234 
	C275.421875,58.269848 275.868866,59.538353 276.386963,61.121277 
	C272.823761,62.145134 274.480164,59.241669 273.994629,57.398758 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M269.956696,63.672897 
	C269.182129,63.103039 268.436035,62.132935 267.618439,60.872963 
	C268.359680,61.479607 269.172424,62.376129 269.956696,63.672897 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M282.747894,69.973289 
	C283.335907,71.079498 283.697693,72.151558 284.080994,73.566696 
	C283.404083,72.829582 282.705597,71.749390 282.036682,70.281433 
	C282.066284,69.893669 282.521637,69.939140 282.747894,69.973289 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M278.759094,63.911461 
	C279.331665,64.714104 279.671234,65.507339 280.037445,66.624451 
	C279.378571,66.182701 278.693054,65.417091 278.033752,64.286896 
	C278.059998,63.922310 278.526031,63.902054 278.759094,63.911461 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M266.004944,46.334156 
	C266.676880,46.757030 267.333527,47.540127 268.024597,48.640892 
	C267.369232,48.203831 266.679443,47.449100 266.004944,46.334156 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M265.930756,57.744331 
	C265.236023,57.309490 264.582947,56.504200 263.907410,55.375824 
	C264.580780,55.826454 265.276611,56.600166 265.930756,57.744331 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M268.026123,49.315220 
	C268.713745,49.726513 269.383026,50.505684 270.102783,51.600357 
	C269.438110,51.171604 268.722961,50.427349 268.026123,49.315220 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M276.424316,61.940620 
	C277.067383,62.178165 277.557495,62.754017 278.062134,63.636032 
	C277.474945,63.387775 276.873169,62.833355 276.424316,61.940620 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M269.689209,64.987511 
	C269.689209,64.987511 269.823730,64.532158 269.866852,64.298973 
	C270.578217,64.487816 271.246460,64.909836 271.966888,65.656281 
	C271.899078,66.098495 271.779083,66.216293 271.273315,66.303268 
	C270.488129,65.844139 270.088654,65.415825 269.689209,64.987511 
z"/>
<path fill="#F9F32B" opacity="1.000000" stroke="none" 
	d="
M243.038971,32.636845 
	C242.416901,32.507542 241.738022,32.065903 241.043152,31.285587 
	C241.678833,31.406109 242.330505,31.865309 243.038971,32.636845 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M267.572510,60.054592 
	C266.904999,59.833065 266.396545,59.273045 265.876465,58.407612 
	C266.487091,58.640167 267.109344,59.178131 267.572510,60.054592 
z"/>
<path fill="#F9F32B" opacity="1.000000" stroke="none" 
	d="
M248.105408,37.594604 
	C247.500031,37.462646 246.786804,37.043079 246.055557,36.275703 
	C246.690872,36.387596 247.344208,36.847290 248.105408,37.594604 
z"/>
<path fill="#F9F32B" opacity="1.000000" stroke="none" 
	d="
M250.422760,40.295265 
	C249.988220,40.250004 249.518417,39.909298 249.053345,39.232544 
	C249.501236,39.264267 249.944366,39.632046 250.422760,40.295265 
z"/>
<path fill="#F9F32B" opacity="1.000000" stroke="none" 
	d="
M251.324066,41.289349 
	C251.049454,41.418598 250.706451,41.316166 250.399292,40.911545 
	C250.787201,40.615120 251.060699,40.764557 251.324066,41.289349 
z"/>
<path fill="#F9F32B" opacity="1.000000" stroke="none" 
	d="
M233.711136,24.016615 
	C233.949081,24.190008 233.939590,24.360109 233.901428,24.784405 
	C233.549103,24.974178 233.298859,24.802641 233.047821,24.303289 
	C232.973618,24.082590 233.463684,24.013321 233.711136,24.016615 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M273.106323,56.152882 
	C273.319305,56.093876 273.598816,56.289635 273.945374,56.739712 
	C273.732574,56.798569 273.452698,56.603107 273.106323,56.152882 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M272.053528,54.201557 
	C272.384094,53.980259 272.637939,54.153233 272.741577,54.752777 
	C272.434265,54.882938 272.229370,54.688431 272.053528,54.201557 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M271.097748,53.201363 
	C271.311401,53.132259 271.604218,53.306499 271.953278,53.737911 
	C271.731995,53.811623 271.454437,53.628166 271.097748,53.201363 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M275.894196,73.858246 
	C275.671326,73.891685 275.412933,73.696548 275.183350,73.247803 
	C275.512848,73.119339 275.728333,73.331161 275.894196,73.858246 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M274.878296,71.859436 
	C274.670563,71.919533 274.388062,71.724754 274.024841,71.279480 
	C274.230591,71.220840 274.517029,71.412712 274.878296,71.859436 
z"/>
<path fill="#FEFDDA" opacity="1.000000" stroke="none" 
	d="
M280.097900,67.164955 
	C280.321167,67.118217 280.595734,67.298622 280.852264,67.736755 
	C280.525726,67.884819 280.297363,67.684029 280.097900,67.164955 
z"/>
<path fill="#2C1863" opacity="1.000000" stroke="none" 
	d="
M31.837975,208.035538 
	C31.837975,208.035538 31.460798,208.002258 31.171240,207.724243 
	C30.685120,207.198608 30.447350,207.000443 30.119125,206.394836 
	C30.023291,180.284927 29.976704,154.631912 29.950626,128.512650 
	C30.054350,118.425758 30.137566,108.805130 30.510439,98.984848 
	C33.227383,96.872322 35.654667,94.959435 38.323921,92.971130 
	C38.745346,92.617889 38.924812,92.340050 39.063358,92.043945 
	C39.022442,92.025681 39.058727,92.107628 39.453827,92.058304 
	C45.483463,91.789024 48.680141,87.813217 52.382275,84.052376 
	C53.821011,83.360313 54.920750,82.709724 56.379967,81.996681 
	C65.460480,76.505325 74.231606,71.154739 82.885056,65.620171 
	C88.707726,61.896107 94.364021,57.911926 100.454521,54.031963 
	C102.868851,52.942871 104.923401,51.865932 107.328995,50.752781 
	C111.511635,48.581146 114.945053,44.634964 119.992798,48.006882 
	C119.997177,48.003857 120.004585,48.004105 120.057693,48.253384 
	C120.405853,48.565125 120.700912,48.627586 121.227303,48.788742 
	C121.625679,48.902065 121.792717,48.916691 121.959747,48.931313 
	C126.368828,51.957966 130.777908,54.984615 135.565979,58.027321 
	C135.944977,58.043377 135.904755,58.058964 135.984756,58.309258 
	C136.363022,58.668636 136.661285,58.777729 136.941254,58.965912 
	C136.922974,59.045006 136.946426,59.205658 137.052704,59.423466 
	C137.393204,59.903660 137.685181,60.016239 138.017654,59.990440 
	C138.000366,60.001877 138.015045,59.963196 138.100906,60.208286 
	C138.484741,60.599831 138.782730,60.746281 139.314377,60.935486 
	C139.548050,60.978237 139.973801,61.189072 140.082336,61.405605 
	C140.420868,61.885784 140.710342,62.005100 141.030304,61.989262 
	C141.001312,61.998440 141.012634,61.938679 141.093964,62.210884 
	C141.497986,62.660851 141.820694,62.838615 142.358917,63.011597 
	C142.574432,63.006809 142.980957,62.863228 142.945618,62.946632 
	C142.910278,63.030037 142.950592,63.207172 143.133331,63.440025 
	C143.884644,63.781185 144.453201,63.889492 145.008545,63.999641 
	C144.995331,64.001480 145.001923,63.975540 145.016937,64.331940 
	C147.078140,65.782532 149.124313,66.876724 151.474960,68.116257 
	C153.501129,69.210915 155.222855,70.160233 157.057999,71.385925 
	C157.741241,71.794556 158.311050,71.926811 159.015808,72.366760 
	C160.768311,73.721130 162.385834,74.767807 164.316223,75.971176 
	C174.738266,82.470406 184.847473,88.812927 195.106903,95.465607 
	C197.829041,97.710022 201.898544,98.393517 201.185516,103.447784 
	C201.148941,108.264191 201.209396,112.623070 201.173248,117.393181 
	C201.105103,119.214340 201.133560,120.624252 201.072571,122.493126 
	C201.046494,135.650391 201.109863,148.348694 201.116135,161.506836 
	C201.145172,167.986237 201.231308,174.005783 201.226059,180.456009 
	C201.167953,182.953568 201.201233,185.020477 201.152313,187.491852 
	C201.322281,190.246017 201.574463,192.595673 201.501648,195.209671 
	C201.107040,197.111282 200.736176,198.803299 201.022003,200.375977 
	C202.043198,205.994446 200.276733,209.285721 194.313553,210.487503 
	C192.675781,210.817566 191.507248,213.476013 189.908173,215.367249 
	C186.105682,217.188141 182.518372,218.707794 178.611511,220.276672 
	C174.835159,222.869049 171.378357,225.412247 167.617310,228.092987 
	C165.744125,229.558258 164.175201,230.886002 162.264557,232.194733 
	C158.544876,234.071533 155.074661,235.823883 151.822510,237.915329 
	C149.767319,239.237000 148.037674,241.064880 145.795029,242.787750 
	C143.589172,244.197769 141.750320,245.485748 139.536469,246.832397 
	C134.370911,248.973663 129.060486,250.196045 125.534279,254.952209 
	C124.103867,255.612930 123.007591,256.245392 121.551865,256.926422 
	C118.143257,257.631226 115.094086,258.287445 111.909027,258.631561 
	C110.182228,257.544006 108.591309,256.768616 106.859238,255.669739 
	C103.152031,253.287720 99.585983,251.229172 95.706161,249.094055 
	C94.636299,248.648697 93.880203,248.279907 93.066513,247.541382 
	C91.285469,246.488403 89.562019,245.805176 87.838570,245.121948 
	C85.875237,244.091187 83.911903,243.060410 81.492630,241.689972 
	C78.653648,239.310608 76.270607,237.270920 73.703873,235.146576 
	C73.520172,235.061890 73.119316,235.007431 72.966751,234.689255 
	C70.524956,232.908615 68.299675,231.330933 65.929054,230.015076 
	C63.401066,228.611832 60.735706,227.456055 57.817017,226.017700 
	C50.696159,221.867722 43.888603,217.892288 37.081047,213.916855 
	C38.838428,211.718643 36.999969,210.996582 35.075470,209.720047 
	C33.696873,208.888275 32.767422,208.461914 31.837975,208.035538 
M60.982666,88.980354 
	C60.982666,88.980354 60.964394,88.997513 60.444649,89.087570 
	C60.262413,89.374405 60.080177,89.661240 59.979080,89.979500 
	C59.979080,89.979500 59.940010,89.901749 59.294781,89.957207 
	C58.489029,90.596092 57.683277,91.234978 56.981136,91.973930 
	C56.981136,91.973930 56.910908,91.848175 56.286194,91.915390 
	C55.814003,92.262169 55.341816,92.608948 54.458668,93.006866 
	C54.458668,93.006866 54.093307,93.201851 53.379814,93.230064 
	C50.538998,94.877174 47.698185,96.524284 44.422604,98.305290 
	C44.166706,98.513138 44.043541,98.781914 44.035763,99.029694 
	C44.035763,99.029694 44.113792,99.060066 43.629795,99.118683 
	C43.302528,99.246521 43.089176,99.478935 42.546600,100.016647 
	C42.546600,100.016647 42.074524,100.134148 41.571442,100.243820 
	C41.392174,100.526642 41.212906,100.809456 41.020832,101.018021 
	C41.020832,101.018021 41.093285,101.038681 40.581512,101.177216 
	C40.391514,101.461853 40.201519,101.746498 40.000126,101.998062 
	C40.000126,101.998062 40.029278,102.017616 39.286434,101.985519 
	C38.548122,102.723572 37.809811,103.461624 37.041752,104.593346 
	C37.041752,104.593346 36.880383,104.953659 36.207363,105.090408 
	C36.144112,109.692970 36.030140,114.295479 36.026020,118.898094 
	C36.002968,144.653366 35.988918,170.408707 36.036438,196.163910 
	C36.039978,198.084015 36.549622,200.003204 37.149666,202.566177 
	C39.722374,204.057846 42.295082,205.549515 45.141960,207.644501 
	C47.729374,209.137405 50.316788,210.630310 53.222427,212.731216 
	C56.071297,214.591019 58.854118,216.563766 61.787529,218.278992 
	C64.742218,220.006638 67.846558,221.478363 71.153618,223.662292 
	C72.084724,224.146469 73.015839,224.630630 74.211418,225.745285 
	C79.122810,228.862503 84.034195,231.979721 89.220566,235.731415 
	C92.803551,237.851364 96.386536,239.971329 100.164413,242.638092 
	C100.774597,243.040039 101.384781,243.441971 102.534164,244.080627 
	C103.025940,244.335022 103.517715,244.589432 104.640869,244.999039 
	C105.399590,245.374207 106.158310,245.749390 107.194199,246.695557 
	C108.477135,247.375458 109.760063,248.055359 111.475899,248.950439 
	C111.475899,248.950439 111.956139,249.005768 112.268204,249.668823 
	C114.454308,249.765579 116.640419,249.862350 119.314186,249.871033 
	C119.636551,249.740723 119.872688,249.518692 120.418915,249.021988 
	C120.418915,249.021988 120.850067,248.953659 121.358910,248.810318 
	C121.564621,248.535736 121.770325,248.261169 121.998169,247.997559 
	C121.998169,247.997559 121.986786,247.975433 122.457008,247.796555 
	C122.628914,247.518112 122.800827,247.239655 122.997612,247.001221 
	C122.997612,247.001221 122.965446,246.964783 123.599037,246.960693 
	C124.396416,246.355164 125.193802,245.749634 126.386734,244.964127 
	C126.386734,244.964127 126.820175,244.932693 127.305870,244.860748 
	C127.631943,244.739792 127.864433,244.519882 128.432724,244.012970 
	C128.432724,244.012970 128.894073,243.929749 129.393692,243.774170 
	C129.587997,243.493759 129.782288,243.213333 129.992828,242.996826 
	C129.992828,242.996826 129.932312,242.970657 130.386047,242.859299 
	C130.682266,242.716461 130.887955,242.491013 131.419800,242.004639 
	C131.419800,242.004639 131.865128,241.920319 132.515213,241.934326 
	C133.321167,241.313950 134.127121,240.693573 135.461548,239.806686 
	C135.906708,239.208435 136.351868,238.610184 137.178177,237.406754 
	C139.798203,235.638184 142.418228,233.869598 145.756729,231.996262 
	C149.518234,229.691376 153.279739,227.386475 157.720779,224.970169 
	C159.169022,224.003418 160.617279,223.036652 162.554871,221.985092 
	C162.554871,221.985092 163.026886,221.830627 163.545242,221.871201 
	C163.725647,221.591934 163.906036,221.312668 164.009628,221.024048 
	C164.009628,221.024048 164.052567,221.088425 164.748764,221.000015 
	C166.505112,219.363922 168.261475,217.727829 170.669922,215.822540 
	C173.160355,213.907394 175.650787,211.992249 178.803650,210.068588 
	C180.832687,209.385666 182.861725,208.702759 185.014053,208.024353 
	C185.014053,208.024353 185.027115,207.901672 185.649628,207.911758 
	C186.392792,207.302551 187.135956,206.693344 188.094666,206.036133 
	C188.094666,206.036133 188.154388,205.823532 188.569519,205.763092 
	C188.847900,205.616577 188.984848,205.385910 189.496841,204.948242 
	C189.655746,204.870361 189.814651,204.792480 190.683823,204.636002 
	C191.379196,202.412094 192.074570,200.188171 192.958466,197.046692 
	C192.891800,191.720520 192.825134,186.394348 192.905655,180.132828 
	C192.944962,170.969543 193.057510,161.805283 192.937912,152.644073 
	C192.921570,151.392181 191.747681,150.155396 191.055115,148.097916 
	C191.643570,142.078949 192.232010,136.059998 192.896896,129.449448 
	C192.554199,128.966751 192.211487,128.484055 192.028397,127.267044 
	C192.026230,123.805550 192.024063,120.344055 192.026901,116.241745 
	C191.968704,114.191788 191.910507,112.141823 191.991898,109.255386 
	C192.982895,104.084732 189.609344,101.397728 185.810425,98.388100 
	C185.571182,98.130707 185.276138,98.011642 184.991364,98.009354 
	C184.991364,98.009354 184.976028,98.077141 184.828049,97.571877 
	C184.530060,97.418129 184.232071,97.264389 183.317352,97.022964 
	C182.819946,96.717270 182.322540,96.411568 181.624390,95.537498 
	C181.057419,95.373756 180.490463,95.210022 179.995285,95.020622 
	C179.995285,95.020622 179.965729,95.090897 179.741409,94.437111 
	C176.460693,92.637970 173.179977,90.838829 169.268906,88.762459 
	C166.173325,86.889442 163.077744,85.016426 159.354706,82.841690 
	C157.586853,81.841759 155.819000,80.841835 153.771606,79.208618 
	C150.495499,77.138748 147.219391,75.068878 143.685257,72.403397 
	C142.398010,71.787674 141.110764,71.171944 139.676666,70.329659 
	C139.676666,70.329659 139.452240,70.179550 139.194778,69.753899 
	C138.791046,69.552368 138.387329,69.350830 137.527054,68.986092 
	C137.527054,68.986092 137.046753,68.920090 136.830353,68.357391 
	C134.578674,67.212448 132.327011,66.067497 129.794128,64.310501 
	C128.573425,63.845200 127.352730,63.379902 125.833611,62.269131 
	C123.161560,60.020138 120.603050,57.607025 117.743660,55.628815 
	C116.954056,55.082542 115.268318,55.831566 113.309074,56.029552 
	C112.418877,56.480640 111.528687,56.931728 110.497414,57.498646 
	C110.497414,57.498646 110.392181,57.647797 109.801208,57.941494 
	C105.836098,60.615417 101.870987,63.289341 97.442871,66.087585 
	C97.135345,66.261902 96.975700,66.525749 96.234779,67.001595 
	C92.230385,69.421783 88.250214,71.883263 84.214783,74.250526 
	C80.191490,76.610664 76.112793,78.876343 71.383629,81.221375 
	C69.596451,82.797493 67.809273,84.373611 65.962708,85.954857 
	C65.962708,85.954857 65.949181,86.012917 65.251602,85.998032 
	C64.181473,86.654526 63.111340,87.311020 61.961723,87.974083 
	C61.961723,87.974083 61.954136,88.053474 61.462330,88.133209 
	C61.132374,88.312096 60.978691,88.588890 60.982666,88.980354 
z"/>
<path fill="#FDF611" opacity="1.000000" stroke="none" 
	d="
M166.918274,41.030876 
	C169.714996,43.187637 172.690353,45.158028 175.225220,47.587936 
	C176.321960,48.639252 176.509674,50.638855 177.115158,52.611652 
	C175.695023,52.290508 174.270035,51.565407 172.624329,50.727989 
	C173.316589,54.816471 172.067108,57.374748 167.045105,56.343365 
	C166.272232,56.184639 165.288483,57.052601 164.202148,57.721661 
	C164.002029,57.997581 163.995728,58.000824 163.662308,57.948986 
	C162.744827,54.466496 162.160782,51.035843 161.593903,47.706123 
	C159.953735,48.701752 158.047226,49.516899 156.608093,50.840298 
	C155.445755,51.909195 154.846970,53.590908 153.630142,54.960899 
	C151.837479,53.620220 150.412827,52.319405 148.988190,51.018593 
	C151.560928,46.924393 154.133667,42.830196 156.660187,38.809555 
	C159.467422,41.504726 161.418701,43.378117 163.726639,45.593929 
	C164.236298,43.606560 164.503860,42.563210 164.886169,41.230091 
	C165.000900,40.940323 165.435486,40.904030 165.914734,40.950001 
	C166.568771,41.007610 166.743530,41.019245 166.918274,41.030876 
z"/>
<path fill="#F1DC08" opacity="1.000000" stroke="none" 
	d="
M120.015541,32.989586 
	C121.873322,31.505278 123.731110,30.020971 125.792671,29.194954 
	C125.548119,35.042053 127.012810,38.764160 133.014938,39.007427 
	C133.695358,39.951225 134.375778,40.895027 134.923080,41.654179 
	C131.918411,43.209068 129.317902,44.554802 126.330856,45.621010 
	C125.496193,42.187252 125.048065,39.033016 124.678589,36.432365 
	C122.578003,38.463379 121.106300,39.861725 119.663994,41.289757 
	C118.743614,42.201035 117.866753,43.156265 116.633957,44.080051 
	C115.501350,43.760258 114.705162,43.452198 114.049469,42.817101 
	C113.791122,40.653770 113.392288,38.817490 112.996658,36.992130 
	C112.999863,37.003056 112.978119,36.995014 113.399971,36.996296 
	C115.888260,36.706154 118.019272,36.639950 120.005920,36.070267 
	C122.952057,35.225449 121.627022,34.031288 120.015541,32.989586 
z"/>
<path fill="#FDF611" opacity="1.000000" stroke="none" 
	d="
M133.466614,39.006577 
	C127.012810,38.764160 125.548119,35.042053 125.997162,29.426949 
	C129.119354,30.866480 132.204590,32.796841 135.374634,34.576115 
	C137.600067,35.825191 139.857986,36.284809 141.818573,33.871231 
	C143.866135,37.538296 143.086594,39.331818 138.854233,39.028492 
	C137.216782,38.911144 135.564072,39.007004 133.466614,39.006577 
z"/>
<path fill="#FCF51E" opacity="1.000000" stroke="none" 
	d="
M142.980957,62.863228 
	C142.980957,62.863228 142.574432,63.006809 142.255341,62.749626 
	C141.628387,62.307858 141.320511,62.123268 141.012634,61.938679 
	C141.012634,61.938679 141.001312,61.998440 140.950195,61.738075 
	C140.590652,61.381496 140.282227,61.285286 139.973801,61.189072 
	C139.973801,61.189072 139.548050,60.978237 139.228394,60.693687 
	C138.610855,60.260490 138.312943,60.111843 138.015045,59.963196 
	C138.015045,59.963196 138.000366,60.001877 137.951401,59.728188 
	C137.583771,59.371548 137.265091,59.288601 136.946426,59.205658 
	C136.946426,59.205658 136.922974,59.045006 136.825531,58.761787 
	C136.508072,58.231956 136.233627,58.092091 135.904755,58.058964 
	C135.904755,58.058964 135.944977,58.043377 135.932770,58.061935 
	C137.741104,56.161106 139.561630,54.241722 141.700195,52.516552 
	C142.382339,53.661880 142.665207,55.392803 143.123444,55.440544 
	C146.736237,55.816986 150.382492,55.872036 154.340485,56.072586 
	C154.911682,56.310032 155.160858,56.500896 155.410049,56.691765 
	C154.731476,57.128876 154.057816,57.573864 153.373459,58.001743 
	C150.106964,60.044113 145.050629,58.260441 142.980957,62.863228 
z"/>
<path fill="#FDF30F" opacity="1.000000" stroke="none" 
	d="
M116.970383,44.091789 
	C117.866753,43.156265 118.743614,42.201035 119.663994,41.289757 
	C121.106300,39.861725 122.578003,38.463379 124.678589,36.432365 
	C125.048065,39.033016 125.496193,42.187252 125.977905,45.684864 
	C124.664627,46.773746 123.317757,47.519249 121.965317,48.598030 
	C121.792717,48.916691 121.625679,48.902065 121.119125,48.603825 
	C120.565987,48.105213 120.307640,47.999847 120.004585,48.004105 
	C120.004585,48.004105 119.997177,48.003857 119.977501,47.681511 
	C118.962013,46.270042 117.966194,45.180916 116.970383,44.091789 
z"/>
<path fill="#F1DC08" opacity="1.000000" stroke="none" 
	d="
M154.018463,56.026001 
	C150.382492,55.872036 146.736237,55.816986 143.123444,55.440544 
	C142.665207,55.392803 142.382339,53.661880 142.017303,52.355450 
	C143.085495,51.029625 144.154617,50.059116 145.007294,49.285080 
	C146.130325,49.937328 147.260284,50.593616 148.689224,51.134247 
	C150.412827,52.319405 151.837479,53.620220 153.632996,55.217247 
	C154.008743,55.684303 154.013596,55.855148 154.018463,56.026001 
z"/>
<path fill="#F1DC08" opacity="1.000000" stroke="none" 
	d="
M146.551941,46.469612 
	C146.584641,46.525448 146.519241,46.413780 146.551941,46.469612 
z"/>
<path fill="#FA9D0D" opacity="1.000000" stroke="none" 
	d="
M490.951721,134.784256 
	C490.951721,134.784256 490.525055,134.940140 490.041992,135.048096 
	C489.373566,135.448441 489.188171,135.740814 489.002777,136.033173 
	C489.002777,136.033173 488.995758,135.997238 488.630493,135.996368 
	C486.438446,135.926239 484.611664,135.856949 482.784851,135.787659 
	C483.061432,133.926392 483.382507,132.070312 483.600159,130.202164 
	C483.767059,128.769775 483.803558,127.322182 483.898041,125.881348 
	C483.898041,125.881348 483.992676,125.469597 484.295837,125.146515 
	C484.703705,124.514099 484.808411,124.204765 484.913116,123.895439 
	C484.913116,123.895439 484.973999,123.908836 485.319672,123.920723 
	C488.496002,124.925743 491.326660,125.918877 492.782593,126.429680 
	C497.480652,125.482216 501.294403,124.713089 505.108154,123.943962 
	C506.066528,126.033455 507.024872,128.122955 507.502411,130.604202 
	C504.271851,131.107376 501.479034,130.969711 498.790253,131.434143 
	C497.423676,131.670197 496.244354,132.990265 494.703461,133.884460 
	C494.246460,134.007828 494.066406,134.067032 493.886383,134.126251 
	C493.483490,134.109711 493.080566,134.093185 492.142517,134.108292 
	C491.294708,134.258865 491.076172,134.473648 490.951721,134.784256 
z"/>
<path fill="#F7B615" opacity="1.000000" stroke="none" 
	d="
M505.065674,123.569672 
	C501.294403,124.713089 497.480652,125.482216 492.782593,126.429680 
	C491.326660,125.918877 488.496002,124.925743 485.317688,123.951820 
	C486.395142,120.049248 487.820282,116.127464 489.604706,112.459900 
	C489.719360,115.375961 490.245453,117.298126 493.589478,117.121223 
	C496.680939,116.957680 499.785034,117.033508 502.883575,117.003922 
	C503.596771,119.067734 504.309967,121.131554 505.065674,123.569672 
z"/>
<path fill="#FAC90E" opacity="1.000000" stroke="none" 
	d="
M502.840820,116.641739 
	C499.785034,117.033508 496.680939,116.957680 493.589478,117.121223 
	C490.245453,117.298126 489.719360,115.375961 489.944946,112.287094 
	C495.982239,108.304100 500.246826,109.768295 502.840820,116.641739 
z"/>
<path fill="#F68A1A" opacity="1.000000" stroke="none" 
	d="
M494.980408,133.820312 
	C496.244354,132.990265 497.423676,131.670197 498.790253,131.434143 
	C501.479034,130.969711 504.271851,131.107376 507.444702,130.991547 
	C508.950836,132.419067 510.033875,133.850998 510.834229,135.822708 
	C509.020844,137.253723 507.490112,138.144974 505.959412,139.036224 
	C505.544403,139.001022 505.129395,138.965836 504.384888,138.521118 
	C503.668671,137.433258 503.281921,136.754913 502.895203,136.076569 
	C502.895203,136.076569 502.978516,135.990417 502.875244,135.789032 
	C502.479828,135.422729 502.187744,135.257812 501.895630,135.092896 
	C501.895630,135.092896 502.001526,135.029068 501.848694,134.754150 
	C500.455963,134.394318 499.216003,134.309418 497.976074,134.224518 
	C497.630615,134.176788 497.345947,134.286667 496.724396,134.736664 
	C496.326691,134.919159 495.881226,135.011597 495.881226,135.011597 
	C495.881226,135.011597 495.493835,135.041016 495.272705,134.724640 
	C495.027863,134.212280 495.004120,134.016296 494.980408,133.820312 
z"/>
<path fill="#E48B3C" opacity="1.000000" stroke="none" 
	d="
M483.575745,126.047600 
	C483.803558,127.322182 483.767059,128.769775 483.600159,130.202164 
	C483.382507,132.070312 483.061432,133.926392 482.784851,135.787659 
	C484.611664,135.856949 486.438446,135.926239 488.647949,136.001892 
	C488.191925,136.993332 487.497437,138.591385 486.483093,138.830063 
	C484.631470,139.265732 482.473480,139.254044 480.691071,138.671158 
	C480.056763,138.463760 479.651733,135.917511 479.997955,134.677994 
	C480.807587,131.779648 482.135468,129.026062 483.575745,126.047600 
z"/>
<path fill="#E48B3C" opacity="1.000000" stroke="none" 
	d="
M506.122559,139.315338 
	C507.490112,138.144974 509.020844,137.253723 510.845764,136.170380 
	C511.871521,139.970062 510.653137,140.877670 506.122559,139.315338 
z"/>
<path fill="#E48B3C" opacity="1.000000" stroke="none" 
	d="
M498.201782,134.491150 
	C499.216003,134.309418 500.455963,134.394318 501.807068,134.799850 
	C500.754639,134.999573 499.591034,134.878662 498.201782,134.491150 
z"/>
<path fill="#E48B3C" opacity="1.000000" stroke="none" 
	d="
M502.913391,136.410248 
	C503.281921,136.754913 503.668671,137.433258 504.086426,138.428833 
	C503.722137,138.078690 503.326874,137.411316 502.913391,136.410248 
z"/>
<path fill="#E48B3C" opacity="1.000000" stroke="none" 
	d="
M494.703461,133.884460 
	C495.004120,134.016296 495.027863,134.212280 495.079712,134.702805 
	C494.718292,134.869751 494.328766,134.742172 493.912842,134.370422 
	C494.066406,134.067032 494.246460,134.007828 494.703461,133.884460 
z"/>
<path fill="#E48B3C" opacity="1.000000" stroke="none" 
	d="
M484.661072,123.997086 
	C484.808411,124.204765 484.703705,124.514099 484.381348,124.953354 
	C484.245514,124.755096 484.327271,124.426918 484.661072,123.997086 
z"/>
<path fill="#E48B3C" opacity="1.000000" stroke="none" 
	d="
M491.204224,134.825760 
	C491.076172,134.473648 491.294708,134.258865 491.840515,134.125854 
	C491.868073,134.363602 491.662415,134.615433 491.204224,134.825760 
z"/>
<path fill="#E48B3C" opacity="1.000000" stroke="none" 
	d="
M489.262024,135.967239 
	C489.188171,135.740814 489.373566,135.448441 489.818726,135.089478 
	C489.892731,135.315704 489.707001,135.608505 489.262024,135.967239 
z"/>
<path fill="#E48B3C" opacity="1.000000" stroke="none" 
	d="
M495.985779,135.212997 
	C495.881226,135.011597 496.326691,134.919159 496.553772,134.902100 
	C496.550690,135.061478 496.320526,135.237946 495.985779,135.212997 
z"/>
<path fill="#E48B3C" opacity="1.000000" stroke="none" 
	d="
M501.990753,135.325394 
	C502.187744,135.257812 502.479828,135.422729 502.841919,135.838348 
	C502.636536,135.912003 502.361206,135.734955 501.990753,135.325394 
z"/>
<path fill="#EF8C29" opacity="1.000000" stroke="none" 
	d="
M318.165436,128.860443 
	C319.196014,130.514511 320.717316,132.056015 321.129181,133.851974 
	C321.610718,135.951691 323.844818,139.409943 320.069092,140.066818 
	C317.952240,140.435120 315.337646,138.164841 313.021332,136.943085 
	C312.640869,136.742416 312.643402,135.815689 312.537476,135.456635 
	C309.039429,135.047745 305.880554,134.425781 302.724091,134.438019 
	C301.517517,134.442688 299.942474,135.639389 299.179779,136.739609 
	C296.340546,140.835297 293.195312,140.653183 288.974915,137.609146 
	C290.417084,134.166077 291.869476,130.698578 293.651306,127.447693 
	C294.500000,129.729904 295.019287,131.795486 295.704468,134.520996 
	C297.650269,132.666962 298.803619,131.568008 299.532623,130.873367 
	C301.399841,131.360779 303.333252,132.530273 304.240204,131.986801 
	C308.104584,129.671265 311.020660,130.828079 313.823761,134.077393 
	C315.384674,132.201813 316.775055,130.531143 318.165436,128.860443 
z"/>
<path fill="#F99F11" opacity="1.000000" stroke="none" 
	d="
M318.228180,128.588318 
	C316.775055,130.531143 315.384674,132.201813 313.823761,134.077393 
	C311.020660,130.828079 308.104584,129.671265 304.240204,131.986801 
	C303.333252,132.530273 301.399841,131.360779 299.532623,130.873367 
	C298.803619,131.568008 297.650269,132.666962 295.704468,134.520996 
	C295.019287,131.795486 294.500000,129.729904 293.937927,127.265007 
	C294.234222,126.411713 294.573303,125.957726 295.454742,125.248077 
	C302.929352,123.461815 309.860138,123.947105 316.790527,125.007210 
	C317.290649,126.110191 317.790802,127.213173 318.228180,128.588318 
z"/>
<path fill="#F7B615" opacity="1.000000" stroke="none" 
	d="
M316.840027,124.638031 
	C309.860138,123.947105 302.929352,123.461815 295.579041,124.989059 
	C296.063904,122.520073 296.966797,120.054443 298.361450,117.339447 
	C303.855591,119.080589 308.847626,119.503334 313.820160,116.990799 
	C314.843292,119.416809 315.866425,121.842827 316.840027,124.638031 
z"/>
<path fill="#FAC90E" opacity="1.000000" stroke="none" 
	d="
M313.777252,116.642578 
	C308.847626,119.503334 303.855591,119.080589 298.507874,117.041878 
	C298.572937,112.883255 300.644470,109.530243 304.724731,109.215454 
	C309.292725,108.863037 311.959839,112.342644 313.777252,116.642578 
z"/>
<path fill="#F4A520" opacity="1.000000" stroke="none" 
	d="
M264.047943,126.034805 
	C263.268494,127.957840 262.489014,129.880859 261.395142,131.960007 
	C260.789581,131.224976 260.416168,129.540573 260.219604,129.560974 
	C256.982544,129.896896 253.767746,130.447479 250.236053,130.991119 
	C249.176285,129.791336 248.609238,128.371475 247.618927,127.370270 
	C246.922287,126.665977 245.390610,125.970222 244.702316,126.288422 
	C243.898544,126.660027 243.606537,128.138657 242.769165,129.583221 
	C240.374588,129.683334 238.303329,129.337189 236.232071,128.991058 
	C236.567825,123.451317 236.903580,117.911583 237.625793,112.689728 
	C238.027069,114.680412 238.041885,116.353218 238.041290,118.459068 
	C238.134583,120.479004 238.243271,122.065895 238.404327,124.417107 
	C243.221573,122.391212 248.342651,127.478752 252.121658,121.940453 
	C254.449158,124.544640 256.672119,124.699295 259.183258,121.574448 
	C261.087891,123.006058 262.567902,124.520432 264.047943,126.034805 
z"/>
<path fill="#F9C617" opacity="1.000000" stroke="none" 
	d="
M259.142395,119.961823 
	C260.120087,117.907082 261.059265,115.832939 262.083832,113.801842 
	C263.940033,110.122093 267.412903,108.869225 270.729187,110.287643 
	C272.410553,111.006783 273.289581,114.347443 273.870514,116.668327 
	C274.375488,118.685684 273.950714,120.935753 273.507202,123.438736 
	C272.463776,122.015305 271.849976,120.236115 271.236084,118.456955 
	C271.091980,118.384056 270.936432,118.230995 270.805603,118.249840 
	C266.916351,118.810043 263.029572,119.387276 259.142395,119.961823 
z"/>
<path fill="#FCB60C" opacity="1.000000" stroke="none" 
	d="
M259.071899,120.237122 
	C263.029572,119.387276 266.916351,118.810043 270.805603,118.249840 
	C270.936432,118.230995 271.091980,118.384056 271.236084,118.456955 
	C271.849976,120.236115 272.463776,122.015305 273.437012,123.886688 
	C273.850342,124.432823 273.904205,124.886772 273.883057,125.677887 
	C271.995636,126.509735 270.183167,127.004410 268.141754,126.919983 
	C267.239716,125.917747 266.566589,125.494614 265.893494,125.071487 
	C265.893494,125.071487 265.510529,125.056885 265.064758,125.132095 
	C264.419922,125.489426 264.220795,125.771538 264.021698,126.053658 
	C264.021698,126.053658 264.011902,126.018272 264.029907,126.026535 
	C262.567902,124.520432 261.087891,123.006058 259.362305,121.280334 
	C259.078308,120.883461 259.039856,120.697945 259.071899,120.237122 
z"/>
<path fill="#FAC913" opacity="1.000000" stroke="none" 
	d="
M238.056702,118.026024 
	C238.041885,116.353218 238.027069,114.680412 238.012726,112.590378 
	C241.994415,107.885628 248.442627,109.385361 250.996429,117.798798 
	C250.960861,118.600487 250.943985,118.774529 250.541351,118.913864 
	C246.122635,118.594780 242.089661,118.310402 238.056702,118.026024 
z"/>
<path fill="#FCB60C" opacity="1.000000" stroke="none" 
	d="
M238.041290,118.459068 
	C242.089661,118.310402 246.122635,118.594780 250.752625,118.955086 
	C251.349640,119.031006 251.776550,119.059822 251.776550,119.059822 
	C251.776550,119.059822 251.972153,119.473946 251.984009,120.001587 
	C252.014603,120.729042 252.033340,120.928841 252.052094,121.128639 
	C252.052094,121.128639 252.101379,121.533501 252.111511,121.736977 
	C248.342651,127.478752 243.221573,122.391212 238.404327,124.417107 
	C238.243271,122.065895 238.134583,120.479004 238.041290,118.459068 
z"/>
<path fill="#EF8C29" opacity="1.000000" stroke="none" 
	d="
M236.157974,129.414368 
	C238.303329,129.337189 240.374588,129.683334 242.807159,129.970306 
	C243.586349,132.189026 244.457565,134.519989 244.200150,136.718811 
	C244.096405,137.604965 241.514099,138.766586 240.072525,138.749191 
	C238.945374,138.735596 237.837830,137.096100 236.439178,136.015350 
	C236.132492,133.847015 236.108185,131.842346 236.157974,129.414368 
z"/>
<path fill="#F4A520" opacity="1.000000" stroke="none" 
	d="
M252.285583,121.033539 
	C252.033340,120.928841 252.014603,120.729042 252.014008,120.228622 
	C252.194458,120.264809 252.356766,120.601616 252.285583,121.033539 
z"/>
<path fill="#F4A520" opacity="1.000000" stroke="none" 
	d="
M251.819977,118.796616 
	C251.776550,119.059822 251.349640,119.031006 251.138367,118.989792 
	C250.943985,118.774529 250.960861,118.600487 251.013504,118.166489 
	C251.320648,118.115494 251.592026,118.324455 251.819977,118.796616 
z"/>
<path fill="#F9C515" opacity="1.000000" stroke="none" 
	d="
M434.191711,120.986343 
	C434.498566,118.233917 434.400696,115.343353 435.274109,112.784302 
	C435.747711,111.396690 437.896637,109.629128 439.254120,109.658974 
	C445.281311,109.791458 451.316345,110.342697 457.307831,111.075378 
	C461.875549,111.633965 464.304413,114.380814 463.572021,119.590256 
	C460.806976,120.342514 458.421570,120.692307 455.731262,120.915726 
	C451.397217,118.465187 447.252380,118.172081 442.760620,120.162399 
	C442.263550,120.434662 442.098419,120.695251 442.030457,121.020157 
	C442.017334,121.014503 442.029053,121.045753 441.680664,121.085480 
	C438.897980,123.860031 436.541809,122.579933 434.191711,120.986343 
z"/>
<path fill="#F68A1A" opacity="1.000000" stroke="none" 
	d="
M464.792664,129.956665 
	C464.868500,137.134491 462.863464,139.547913 455.780090,139.917465 
	C450.338989,140.201340 444.870087,140.046616 439.415924,139.951248 
	C435.855011,139.889008 434.046570,137.904083 434.015045,134.336533 
	C434.001892,132.848679 434.017212,131.360596 434.454163,129.517273 
	C437.892273,129.771667 440.895844,130.381424 444.271698,131.134155 
	C448.412140,132.138947 452.192627,133.431046 456.263733,130.966171 
	C459.372589,130.637146 462.082642,130.296906 464.792664,129.956665 
z"/>
<path fill="#F6A61A" opacity="1.000000" stroke="none" 
	d="
M464.870850,129.518906 
	C462.082642,130.296906 459.372589,130.637146 456.322449,130.569992 
	C456.000275,127.122437 456.018188,124.082268 456.036133,121.042099 
	C458.421570,120.692307 460.806976,120.342514 463.608887,119.991486 
	C464.333252,123.020546 464.641144,126.050842 464.870850,129.518906 
z"/>
<path fill="#F6A61A" opacity="1.000000" stroke="none" 
	d="
M443.899384,130.991180 
	C440.895844,130.381424 437.892273,129.771667 434.530975,129.082169 
	C434.127075,126.623169 434.080963,124.243935 434.113281,121.425522 
	C436.541809,122.579933 438.897980,123.860031 441.669678,121.452866 
	C442.637878,124.850731 443.268616,127.920959 443.899384,130.991180 
z"/>
<path fill="#FDF908" opacity="1.000000" stroke="none" 
	d="
M119.082481,10.940388 
	C119.719284,11.735179 120.356087,12.529970 120.622154,13.728382 
	C120.166954,15.752347 120.082497,17.372692 119.998032,18.993036 
	C115.631783,20.494719 114.478554,24.097347 113.724716,28.342398 
	C112.296852,27.675686 111.155632,26.815142 110.014420,25.954594 
	C107.236565,21.661816 107.481636,20.562662 112.337173,19.151630 
	C115.927742,18.108202 116.714005,15.850526 116.337784,12.034293 
	C117.306091,11.281539 118.194283,11.110963 119.082481,10.940388 
z"/>
<path fill="#EFC907" opacity="1.000000" stroke="none" 
	d="
M109.706680,26.098804 
	C111.155632,26.815142 112.296852,27.675686 113.715851,28.728632 
	C116.766716,28.753717 117.057907,30.110041 115.708801,32.757370 
	C114.079231,34.182213 110.895721,33.906986 112.978119,36.995014 
	C112.978119,36.995014 112.999863,37.003056 112.714104,37.091503 
	C110.260422,34.134499 108.092491,31.089046 105.924561,28.043594 
	C107.082687,27.443399 108.240814,26.843206 109.706680,26.098804 
z"/>
<path fill="#FDF20B" opacity="1.000000" stroke="none" 
	d="
M113.399971,36.996296 
	C110.895721,33.906986 114.079231,34.182213 115.672256,33.102394 
	C117.103279,32.987064 118.232689,33.018330 119.688820,33.019592 
	C121.627022,34.031288 122.952057,35.225449 120.005920,36.070267 
	C118.019272,36.639950 115.888260,36.706154 113.399971,36.996296 
z"/>
<path fill="#F8EA23" opacity="1.000000" stroke="none" 
	d="
M120.316650,18.824511 
	C120.082497,17.372692 120.166954,15.752347 120.602272,14.074948 
	C122.848198,14.468113 124.743271,14.918333 126.883881,15.637371 
	C124.964706,16.822788 122.799980,17.739386 120.316650,18.824511 
z"/>
<path fill="#F9C515" opacity="1.000000" stroke="none" 
	d="
M211.005951,140.980804 
	C208.220016,140.402405 205.434082,139.824036 202.351410,138.754837 
	C202.051788,135.512375 202.048904,132.760742 202.441956,130.006348 
	C206.212494,131.337463 209.587067,132.671341 213.220978,134.012543 
	C213.653183,134.016251 213.826096,134.012619 213.999741,134.004608 
	C214.000473,134.000214 213.990799,134.004105 213.990234,134.363007 
	C213.596771,136.805176 217.571335,138.895584 213.979431,140.973633 
	C213.979431,140.973633 213.501465,140.966782 212.925812,140.964523 
	C211.902084,140.968445 211.454010,140.974625 211.005951,140.980804 
z"/>
<path fill="#FCCF4D" opacity="1.000000" stroke="none" 
	d="
M227.919525,158.318451 
	C224.506180,157.809814 221.138199,156.914185 217.152679,156.006775 
	C216.535141,155.995010 216.075592,155.996643 216.059326,155.627747 
	C215.704941,153.845810 215.366837,152.432800 215.028748,151.019775 
	C217.383713,151.008774 219.738693,150.997772 222.533447,150.983887 
	C226.556976,151.919022 228.953613,153.709610 227.919525,158.318451 
z"/>
<path fill="#FCCF4D" opacity="1.000000" stroke="none" 
	d="
M202.017517,143.973495 
	C205.430298,144.422256 208.843094,144.871033 212.428940,145.342545 
	C211.730072,147.334259 211.376053,148.343201 210.874786,149.972443 
	C209.826126,151.065521 208.924728,151.538284 207.678589,151.975845 
	C205.571182,150.972244 203.808517,150.003860 202.032486,148.614349 
	C202.018570,146.786636 202.018036,145.380066 202.017517,143.973495 
z"/>
<path fill="#F9D23A" opacity="1.000000" stroke="none" 
	d="
M214.171448,141.244720 
	C217.571335,138.895584 213.596771,136.805176 214.425049,134.358673 
	C216.872055,134.018188 218.883698,134.040909 220.895325,134.063629 
	C220.921432,136.168411 220.947525,138.273193 221.002472,140.681671 
	C218.808716,141.162155 216.586075,141.338989 214.171448,141.244720 
z"/>
<path fill="#F2BC69" opacity="1.000000" stroke="none" 
	d="
M202.006531,143.560196 
	C202.018036,145.380066 202.018570,146.786636 202.018677,149.071838 
	C202.024323,153.657867 202.030396,157.365250 201.820435,161.063202 
	C201.604401,161.053757 201.173218,161.046997 201.173218,161.046997 
	C201.109863,148.348694 201.046494,135.650391 201.328369,122.731522 
	C201.795547,123.674210 201.917480,124.837456 202.014313,126.401031 
	C202.008148,127.870613 202.027084,128.939865 202.046021,130.009109 
	C202.048904,132.760742 202.051788,135.512375 202.034607,138.635437 
	C202.008209,140.386887 202.001892,141.766891 202.006531,143.560196 
z"/>
<path fill="#EAB430" opacity="1.000000" stroke="none" 
	d="
M208.023331,152.011047 
	C208.924728,151.538284 209.826126,151.065521 210.877838,150.310516 
	C212.206757,150.175262 213.385361,150.322220 214.796356,150.744476 
	C215.366837,152.432800 215.704941,153.845810 215.715637,155.638229 
	C214.931717,156.018204 214.475204,156.018784 214.009583,156.009918 
	C214.000443,156.000458 213.981735,155.982300 213.981232,155.622650 
	C213.213379,154.480286 212.570145,153.460678 211.646301,152.976685 
	C210.566406,152.410919 209.241791,152.312317 208.023331,152.011047 
z"/>
<path fill="#F9D23A" opacity="1.000000" stroke="none" 
	d="
M211.148987,141.212646 
	C211.454010,140.974625 211.902084,140.968445 212.686813,140.967972 
	C212.446335,141.130615 211.869171,141.287537 211.148987,141.212646 
z"/>
<path fill="#F4B95F" opacity="1.000000" stroke="none" 
	d="
M58.130318,226.192245 
	C60.735706,227.456055 63.401066,228.611832 65.929054,230.015076 
	C68.299675,231.330933 70.524956,232.908615 72.587914,234.700317 
	C69.087898,237.926529 62.259365,237.830322 64.005165,244.953873 
	C61.140148,247.402130 60.238251,244.349808 58.176834,242.882751 
	C58.447227,241.477890 59.247120,240.253036 60.047012,239.028198 
	C63.241871,238.132309 64.359390,236.322922 62.317337,233.077515 
	C58.964569,232.983505 55.884933,233.056213 52.605923,232.789032 
	C53.910835,231.795746 55.762486,231.477493 56.814133,230.387436 
	C57.724373,229.443924 57.726685,227.624496 58.130318,226.192245 
z"/>
<path fill="#F8A638" opacity="1.000000" stroke="none" 
	d="
M64.401733,245.004944 
	C62.259365,237.830322 69.087898,237.926529 72.740479,235.018494 
	C73.119316,235.007431 73.520172,235.061890 73.745155,235.495102 
	C74.336998,237.265457 74.703857,238.602585 74.739746,240.110748 
	C73.588409,243.113388 72.768036,245.945007 71.947655,248.776642 
	C71.501709,248.565277 71.055763,248.353912 70.300812,247.737000 
	C69.321739,246.642334 68.651672,245.953201 67.981606,245.264069 
	C66.920502,245.194702 65.859406,245.125351 64.401733,245.004944 
z"/>
<path fill="#F8BD44" opacity="1.000000" stroke="none" 
	d="
M72.292458,248.696823 
	C72.768036,245.945007 73.588409,243.113388 75.074203,240.159424 
	C77.518562,241.017670 79.297501,241.998260 81.076447,242.978851 
	C78.968781,245.914062 76.466644,248.258850 72.292458,248.696823 
z"/>
<path fill="#F5AC4C" opacity="1.000000" stroke="none" 
	d="
M56.079445,247.883240 
	C54.959049,248.209625 53.872894,248.158417 52.378998,248.138489 
	C50.758408,248.186584 49.545559,248.203430 46.919823,248.239883 
	C44.802124,245.121140 45.670906,243.413849 52.840290,241.135406 
	C53.414711,241.184479 53.814625,241.359070 53.814625,241.359070 
	C53.814625,241.359070 53.732304,241.726929 53.655739,242.194580 
	C54.386219,243.774597 55.193272,244.886963 56.000320,245.999313 
	C56.038113,246.501434 56.075897,247.003540 56.079445,247.883240 
z"/>
<path fill="#F3B259" opacity="1.000000" stroke="none" 
	d="
M75.002235,256.015106 
	C75.705948,253.326645 75.588150,249.619843 80.468903,252.171234 
	C79.624207,257.583984 80.013718,257.996582 85.115440,257.252686 
	C85.866722,257.922821 86.454269,258.366089 86.583641,258.882965 
	C82.409035,259.009705 78.692612,259.062805 74.967621,258.728149 
	C74.973450,257.565277 74.987839,256.790192 75.002235,256.015106 
z"/>
<path fill="#EC9542" opacity="1.000000" stroke="none" 
	d="
M84.951721,257.025848 
	C80.013718,257.996582 79.624207,257.583984 80.812851,252.174316 
	C81.021667,251.518188 81.206314,251.284348 81.922119,251.048889 
	C82.549850,251.007599 82.719833,251.025864 82.889809,251.044128 
	C83.716919,251.071884 84.544037,251.099640 85.703964,251.080566 
	C86.036781,251.033707 86.158104,251.158920 86.097984,251.525421 
	C85.675812,253.603226 85.313766,255.314529 84.951721,257.025848 
z"/>
<path fill="#FAE1A8" opacity="1.000000" stroke="none" 
	d="
M56.123592,245.728775 
	C55.193272,244.886963 54.386219,243.774597 53.688683,242.380157 
	C55.628735,241.065918 57.459270,240.033768 59.668411,239.014893 
	C59.247120,240.253036 58.447227,241.477890 57.817383,242.894470 
	C57.407238,243.876892 56.827053,244.667557 56.123592,245.728775 
z"/>
<path fill="#F4B95F" opacity="1.000000" stroke="none" 
	d="
M81.113998,242.748138 
	C79.297501,241.998260 77.518562,241.017670 75.405167,239.988403 
	C74.703857,238.602585 74.336998,237.265457 73.928848,235.579773 
	C76.270607,237.270920 78.653648,239.310608 81.141296,241.704910 
	C81.245903,242.059525 81.151543,242.517426 81.113998,242.748138 
z"/>
<path fill="#EC9542" opacity="1.000000" stroke="none" 
	d="
M87.896393,245.482132 
	C89.562019,245.805176 91.285469,246.488403 92.712700,247.571167 
	C90.330353,249.033447 88.244225,250.096191 86.158104,251.158920 
	C86.158104,251.158920 86.036781,251.033707 85.976784,250.970001 
	C86.595932,249.218292 87.275078,247.530304 87.896393,245.482132 
z"/>
<path fill="#FAE1A8" opacity="1.000000" stroke="none" 
	d="
M74.671005,256.040894 
	C74.987839,256.790192 74.973450,257.565277 74.863800,258.939270 
	C74.557007,259.792267 74.288147,259.946594 73.961967,260.001129 
	C73.499901,259.744720 73.037827,259.488281 72.326195,259.148193 
	C70.903992,258.787323 69.731346,258.510162 67.662956,258.021301 
	C70.571808,257.169739 72.455795,256.618225 74.671005,256.040894 
z"/>
<path fill="#FAE1A8" opacity="1.000000" stroke="none" 
	d="
M69.633072,249.913300 
	C69.124985,250.550079 68.551270,250.893494 67.977562,251.236908 
	C67.811890,250.688461 67.646210,250.139999 67.480537,249.591553 
	C68.176170,249.601013 68.871803,249.610458 69.633072,249.913300 
z"/>
<path fill="#FAE1A8" opacity="1.000000" stroke="none" 
	d="
M67.995819,245.600632 
	C68.651672,245.953201 69.321739,246.642334 70.065720,247.637970 
	C69.429756,247.275375 68.719894,246.606277 67.995819,245.600632 
z"/>
<path fill="#F3B259" opacity="1.000000" stroke="none" 
	d="
M82.857292,250.829285 
	C82.719833,251.025864 82.549850,251.007599 82.125740,250.948578 
	C82.189331,250.810043 82.507057,250.712250 82.857292,250.829285 
z"/>
<path fill="#FBEB18" opacity="1.000000" stroke="none" 
	d="
M194.956680,95.155441 
	C184.847473,88.812927 174.738266,82.470406 164.384521,75.641449 
	C165.761932,73.783005 167.383881,72.410995 169.333725,71.059021 
	C172.985794,73.314919 176.913971,72.013237 178.999893,67.993240 
	C179.001389,68.001343 178.985153,67.998993 179.357941,67.935379 
	C180.158340,66.250053 180.585938,64.628334 181.013535,63.006615 
	C183.349197,63.182034 185.684875,63.357456 188.625336,63.578297 
	C187.843124,61.827454 187.268112,60.540394 186.909149,59.076378 
	C191.945190,56.993679 194.661011,61.712967 198.795502,63.217861 
	C197.088669,65.326393 194.560333,68.367195 193.419907,67.914162 
	C189.329636,66.289307 187.407150,69.158043 184.761917,70.727798 
	C182.459702,72.094002 180.097916,73.359818 176.961395,75.119385 
	C177.266556,76.821548 177.757278,79.558739 178.329269,82.749313 
	C183.415222,78.588814 185.504196,71.541298 192.859802,72.342415 
	C192.480957,73.751999 192.243164,74.848976 192.001801,76.329315 
	C189.492249,80.830177 189.462891,84.765648 192.733276,88.614922 
	C194.264801,87.685516 195.634415,86.854370 197.112701,86.237343 
	C197.420303,86.717506 197.678635,86.899109 197.990372,87.383385 
	C197.662064,89.528122 197.339737,91.285744 197.010544,93.283478 
	C197.003677,93.523590 196.994949,94.003922 196.997467,94.001961 
	C197.000000,94.000000 197.003967,93.994835 196.690887,94.029716 
	C195.904099,94.428215 195.430389,94.791824 194.956680,95.155441 
z"/>
<path fill="#FDF30F" opacity="1.000000" stroke="none" 
	d="
M158.880859,72.059067 
	C158.311050,71.926811 157.741241,71.794556 157.106812,71.018272 
	C158.691528,66.918755 160.340881,63.463268 161.995117,60.003891 
	C162.000000,60.000000 162.007538,59.990284 162.327026,59.949951 
	C163.096252,59.273354 163.545990,58.637093 163.995728,58.000824 
	C163.995728,58.000824 164.002029,57.997581 164.006897,57.990879 
	C170.324356,58.533718 169.152222,63.138321 168.999786,67.595291 
	C169.000000,68.000000 168.996826,68.001770 168.627014,67.999527 
	C166.663452,67.556564 165.069672,67.115852 163.836975,66.774986 
	C162.097977,68.629051 160.489426,70.344063 158.880859,72.059067 
z"/>
<path fill="#FCF51E" opacity="1.000000" stroke="none" 
	d="
M170.993179,66.029396 
	C171.581558,64.728149 171.862411,62.733353 172.836304,62.301529 
	C174.048782,61.763908 175.833252,62.516304 177.821136,63.196442 
	C178.509415,65.120499 178.747284,66.559746 178.985153,67.998993 
	C178.985153,67.998993 179.001389,68.001343 178.643311,67.999695 
	C176.493744,67.681595 174.702240,67.365135 173.112808,66.885735 
	C172.540985,66.491661 171.767075,66.260529 170.993179,66.029396 
z"/>
<path fill="#F2D407" opacity="1.000000" stroke="none" 
	d="
M172.910736,67.048676 
	C174.702240,67.365135 176.493744,67.681595 178.641815,67.991592 
	C176.913971,72.013237 172.985794,73.314919 169.329559,70.712799 
	C168.998795,69.885429 169.000076,69.424324 169.341156,68.970650 
	C170.456467,68.646530 171.232010,68.314980 172.007553,67.983444 
	C172.007553,67.983444 172.021957,67.513435 172.246933,67.276123 
	C172.471909,67.038803 172.910736,67.048683 172.910736,67.048676 
z"/>
<path fill="#F2D407" opacity="1.000000" stroke="none" 
	d="
M179.357941,67.935379 
	C178.747284,66.559746 178.509415,65.120499 178.118652,63.344551 
	C178.868362,62.850208 179.770950,62.692570 180.843536,62.770771 
	C180.585938,64.628334 180.158340,66.250053 179.357941,67.935379 
z"/>
<path fill="#FBEB18" opacity="1.000000" stroke="none" 
	d="
M173.112808,66.885742 
	C172.910736,67.048683 172.471909,67.038803 172.252838,67.041077 
	C171.711197,66.927467 171.443436,66.735260 171.111832,66.248047 
	C171.767075,66.260529 172.540985,66.491661 173.112808,66.885742 
z"/>
<path fill="#FBEB18" opacity="1.000000" stroke="none" 
	d="
M171.631775,67.990067 
	C171.232010,68.314980 170.456467,68.646530 169.340973,68.730499 
	C169.001007,68.482933 168.996826,68.001770 168.996826,68.001770 
	C168.996826,68.001770 169.000000,68.000000 169.000916,67.998398 
	C169.753220,67.996765 170.504623,67.996727 171.631775,67.990067 
z"/>
<path fill="#F1DC08" opacity="1.000000" stroke="none" 
	d="
M106.977959,50.788994 
	C104.923401,51.865932 102.868851,52.942871 100.013016,54.021626 
	C95.607513,53.936237 92.127205,53.624432 89.264267,56.915661 
	C88.273582,58.054546 85.614792,57.742409 83.257088,58.178253 
	C84.554573,55.544174 85.384254,53.859814 86.906975,50.768471 
	C81.883606,52.138737 78.445183,53.076664 75.110802,53.749908 
	C75.478081,52.982430 75.741318,52.479637 76.283783,51.779186 
	C77.406601,49.643116 78.250191,47.704700 79.093773,45.766281 
	C78.729706,45.504025 78.365639,45.241768 78.001572,44.979507 
	C75.990059,45.990364 73.978539,47.001217 71.967018,48.012070 
	C73.269600,45.575794 74.572182,43.139523 76.362488,40.351551 
	C78.896980,40.665951 80.943741,41.332050 82.745544,42.190689 
	C84.086365,49.055363 96.440796,53.208881 101.776291,48.883297 
	C103.347435,47.609535 104.993103,46.427696 107.058693,44.858860 
	C107.058693,46.497849 107.058693,47.273361 107.035461,48.390385 
	C107.000809,49.417599 106.989388,50.103294 106.977959,50.788994 
z"/>
<path fill="#F6E006" opacity="1.000000" stroke="none" 
	d="
M76.004555,51.976841 
	C75.741318,52.479637 75.478081,52.982430 75.060471,54.080978 
	C72.938072,56.782951 70.970055,58.889172 68.710342,61.028656 
	C67.277786,61.041187 66.136909,61.020451 64.992081,60.636566 
	C63.998333,59.181156 63.008533,58.088886 62.148361,56.676262 
	C62.423531,51.123970 62.139484,50.658077 58.995613,50.639580 
	C58.990242,49.865612 58.984230,49.423260 58.978218,48.980907 
	C60.860153,48.133770 62.756542,46.583904 64.620262,46.622234 
	C66.815071,46.667370 68.981560,48.088699 71.872910,48.970840 
	C73.725174,50.011204 74.864861,50.994022 76.004555,51.976841 
z"/>
<path fill="#F1DC08" opacity="1.000000" stroke="none" 
	d="
M58.604500,48.982597 
	C58.984230,49.423260 58.990242,49.865612 58.731499,50.832779 
	C57.315964,52.908020 56.165188,54.458447 54.821129,55.764946 
	C53.411316,56.338757 52.194775,57.156498 50.657089,58.158516 
	C48.555180,61.228737 46.774414,64.114677 44.575645,67.000381 
	C42.760998,66.994156 41.364349,66.988167 39.861313,66.713799 
	C40.004749,65.884659 40.106186,65.092690 40.538025,64.815529 
	C41.244614,64.362053 42.166206,64.243568 42.997108,63.983780 
	C42.749844,60.012733 44.383072,56.956818 47.730431,54.365623 
	C49.411152,53.183624 50.848789,52.166672 52.410763,51.404564 
	C54.295677,50.484894 56.286053,49.781368 58.604500,48.982597 
z"/>
<path fill="#FEEE0A" opacity="1.000000" stroke="none" 
	d="
M82.990509,41.998146 
	C80.943741,41.332050 78.896980,40.665951 76.426331,40.001148 
	C75.354401,38.569813 74.706352,37.137184 74.035881,35.361713 
	C75.107025,34.665474 76.200592,34.312080 77.643219,33.927216 
	C79.397758,34.256214 80.803223,34.616680 82.572121,34.966969 
	C83.796402,35.654068 84.657257,36.351345 84.603035,36.307419 
	C87.583900,34.624981 89.898247,33.318737 92.605629,32.255272 
	C92.998802,32.665092 92.998940,32.832138 92.716377,33.135887 
	C92.290993,34.519688 92.148308,35.766788 91.991135,37.405941 
	C91.123650,38.528866 90.381264,39.527786 89.394661,39.934929 
	C87.328072,40.787758 85.132889,41.328968 82.990509,41.998146 
z"/>
<path fill="#FDF30F" opacity="1.000000" stroke="none" 
	d="
M76.283783,51.779186 
	C74.864861,50.994022 73.725174,50.011204 72.247955,48.974525 
	C71.910439,48.920666 71.946297,48.466618 71.956657,48.239346 
	C73.978539,47.001217 75.990059,45.990364 78.001572,44.979507 
	C78.365639,45.241768 78.729706,45.504025 79.093773,45.766281 
	C78.250191,47.704700 77.406601,49.643116 76.283783,51.779186 
z"/>
<path fill="#FAE1A8" opacity="1.000000" stroke="none" 
	d="
M53.822819,241.142242 
	C53.814625,241.359070 53.414711,241.184479 53.209789,241.109894 
	C53.280251,240.998672 53.555630,240.962051 53.822819,241.142242 
z"/>
<path fill="#F4A34A" opacity="1.000000" stroke="none" 
	d="
M96.019928,249.170624 
	C99.585983,251.229172 103.152031,253.287720 106.859810,255.996063 
	C107.004463,257.080261 107.007385,257.514709 106.846832,258.263702 
	C103.746071,260.908936 102.522659,263.156158 106.731842,265.525024 
	C107.405754,265.904327 107.601768,267.132782 108.018707,267.968658 
	C104.364738,269.017761 101.074768,268.847748 98.790047,264.756897 
	C97.493301,265.794556 96.404495,266.665833 95.116776,267.833923 
	C93.139305,267.179565 91.360748,266.228424 89.241806,264.801331 
	C89.498421,263.853546 90.095421,263.381744 91.050354,262.955078 
	C92.483772,263.670563 93.559250,264.340912 95.993919,265.858490 
	C95.993919,260.365021 95.993919,255.687408 95.997726,250.703247 
	C96.007668,249.988007 96.013802,249.579300 96.019928,249.170624 
z"/>
<path fill="#F3B259" opacity="1.000000" stroke="none" 
	d="
M112.044922,258.943695 
	C115.094086,258.287445 118.143257,257.631226 121.590683,256.972107 
	C121.988930,256.969238 122.056267,257.068420 122.004837,257.409698 
	C122.189102,259.837646 122.424789,261.924316 122.700256,264.363190 
	C121.793671,263.616760 121.165245,262.774658 120.347664,262.504059 
	C119.188721,262.120453 117.890366,262.158051 116.651596,262.015625 
	C117.210617,263.208191 117.621948,264.505676 118.372353,265.562317 
	C119.051338,266.518341 120.100479,267.211487 120.630341,268.034912 
	C119.197517,269.023651 118.120750,269.998047 116.753860,271.092468 
	C115.315498,271.139618 114.167259,271.066803 113.019020,270.993958 
	C112.180038,270.075195 111.341057,269.156464 110.305412,267.873840 
	C110.358368,267.291595 110.607986,267.073242 111.212311,266.893921 
	C112.257545,266.520020 112.948074,266.107025 113.638596,265.694061 
	C112.800766,265.112762 111.962936,264.531494 111.120102,263.622314 
	C111.130615,262.857574 111.146133,262.420654 111.402283,261.866669 
	C111.746902,260.857147 111.850899,259.964691 111.977081,259.039917 
	C111.999275,259.007538 112.044922,258.943695 112.044922,258.943695 
M115.622047,267.475494 
	C115.834808,267.529755 116.047569,267.584015 116.260338,267.638245 
	C116.123726,267.504639 115.987114,267.371033 115.622047,267.475494 
z"/>
<path fill="#EF8C29" opacity="1.000000" stroke="none" 
	d="
M110.857597,266.854889 
	C110.607986,267.073242 110.358368,267.291595 110.017883,267.745758 
	C109.372536,268.103088 108.818069,268.224609 108.141159,268.157410 
	C107.601768,267.132782 107.405754,265.904327 106.731842,265.525024 
	C102.522659,263.156158 103.746071,260.908936 107.138702,258.308655 
	C108.053734,258.365662 108.513412,258.692230 109.006454,259.345825 
	C109.747093,260.443176 110.454369,261.213470 111.161652,261.983765 
	C111.146133,262.420654 111.130615,262.857574 111.076378,263.986877 
	C110.977631,265.404480 110.917610,266.129669 110.857597,266.854889 
z"/>
<path fill="#E48B3C" opacity="1.000000" stroke="none" 
	d="
M116.264862,280.124695 
	C114.594872,280.674591 113.143822,281.150391 111.415512,281.854706 
	C110.945366,282.073547 110.752487,282.063843 110.270187,282.037598 
	C109.985138,280.947021 109.989525,279.872986 109.993866,278.396423 
	C110.949020,276.740448 111.904213,275.486969 113.240234,274.142609 
	C115.785751,275.057953 117.950424,276.064209 119.814621,277.217102 
	C118.656219,278.235199 117.798309,279.106659 116.940399,279.978149 
	C116.940407,279.978149 116.483803,280.050568 116.264862,280.124695 
z"/>
<path fill="#F9C388" opacity="1.000000" stroke="none" 
	d="
M120.115097,277.070496 
	C117.950424,276.064209 115.785751,275.057953 113.367195,273.821411 
	C113.076241,272.945038 113.039162,272.298920 113.010551,271.323425 
	C114.167259,271.066803 115.315498,271.139618 117.027451,271.222198 
	C118.141251,271.822510 118.691330,272.413086 119.316269,273.267273 
	C119.906532,274.032440 120.421928,274.534058 121.056183,275.278015 
	C120.821724,276.037048 120.468407,276.553772 120.115097,277.070496 
z"/>
<path fill="#FDF20B" opacity="1.000000" stroke="none" 
	d="
M82.745544,42.190685 
	C85.132889,41.328968 87.328072,40.787758 89.394661,39.934929 
	C90.381264,39.527786 91.123650,38.528866 92.303062,37.270027 
	C95.361374,36.031082 99.543770,38.173645 99.922623,32.612804 
	C97.562401,31.821962 95.283485,31.411833 93.004578,31.001705 
	C92.855560,28.699846 93.014687,26.644129 96.594864,27.002815 
	C99.164566,30.899279 102.346992,28.649820 105.566750,28.197517 
	C108.092491,31.089046 110.260422,34.134499 112.710899,37.080578 
	C113.392288,38.817490 113.791122,40.653770 113.675873,42.780724 
	C112.083473,43.748898 110.924812,44.328594 109.948624,45.130428 
	C108.896263,45.994835 108.014740,47.067219 107.058693,48.048866 
	C107.058693,47.273361 107.058693,46.497849 107.058693,44.858860 
	C104.993103,46.427696 103.347435,47.609535 101.776291,48.883297 
	C96.440796,53.208881 84.086365,49.055363 82.745544,42.190685 
z"/>
<path fill="#F9EE05" opacity="1.000000" stroke="none" 
	d="
M93.002472,31.250484 
	C95.283485,31.411833 97.562401,31.821962 99.606781,32.637886 
	C97.247856,33.028854 95.123474,33.014019 92.999084,32.999187 
	C92.998940,32.832138 92.998802,32.665092 92.997902,32.247440 
	C92.997139,31.996832 93.000366,31.499264 93.002472,31.250484 
z"/>
<path fill="#F9E734" opacity="1.000000" stroke="none" 
	d="
M92.201691,23.899569 
	C92.454895,23.492132 92.934540,23.240997 93.702560,22.993416 
	C93.470001,23.349936 92.949066,23.702904 92.201691,23.899569 
z"/>
<path fill="#F5D216" opacity="1.000000" stroke="none" 
	d="
M210.962173,76.937637 
	C210.542175,79.734451 210.122192,82.531265 209.540588,85.817230 
	C208.926407,87.528618 208.473846,88.750862 207.816254,90.233658 
	C206.483322,89.617645 205.185730,88.886658 204.274368,87.824341 
	C203.327606,86.720772 202.754379,85.296745 201.994232,83.718437 
	C201.661514,82.726921 201.352386,82.029823 201.384842,81.159805 
	C203.021881,75.570084 207.485535,77.379494 210.962173,76.937637 
z"/>
<path fill="#FCF51E" opacity="1.000000" stroke="none" 
	d="
M192.005371,75.945946 
	C192.243164,74.848976 192.480957,73.751999 193.075256,72.054123 
	C195.329956,70.631958 197.228745,69.138351 199.126282,69.139938 
	C202.331558,69.142609 204.007996,67.817696 204.984833,65.053040 
	C205.707474,65.408478 206.430115,65.763908 207.447021,66.195404 
	C207.741302,66.271469 207.888336,66.632767 207.934875,66.822189 
	C207.318970,68.749893 206.656509,70.488182 205.997253,72.612900 
	C206.000458,72.999321 206.004593,72.993073 205.748520,73.163376 
	C205.311890,73.910835 205.131317,74.487991 204.680466,74.989182 
	C203.943375,74.603394 203.476562,74.293564 202.997299,73.599052 
	C201.669083,72.523102 199.837189,70.918602 199.136063,71.314857 
	C197.173309,72.424126 195.677811,74.360146 193.665756,75.974213 
	C192.890839,75.967644 192.448090,75.956795 192.005371,75.945946 
z"/>
<path fill="#FAF586" opacity="1.000000" stroke="none" 
	d="
M203.009766,73.983734 
	C203.476562,74.293564 203.943375,74.603394 204.951355,75.012398 
	C205.672867,75.096535 205.853165,75.081490 206.365524,75.085464 
	C207.137970,75.055885 207.578354,75.007301 208.018738,74.958725 
	C208.985977,75.384155 209.953217,75.809586 210.941315,76.586327 
	C207.485535,77.379494 203.021881,75.570084 201.326004,80.776016 
	C201.620316,78.371338 202.315048,76.177536 203.009766,73.983734 
z"/>
<path fill="#FBEB18" opacity="1.000000" stroke="none" 
	d="
M208.021271,89.973114 
	C208.473846,88.750862 208.926407,87.528618 209.681702,86.145844 
	C211.055664,85.990753 212.126892,85.996201 213.599548,86.001984 
	C213.005295,87.091759 212.009644,88.181206 210.635071,89.629501 
	C209.511185,89.983276 208.766235,89.978195 208.021271,89.973114 
z"/>
<path fill="#FCF51E" opacity="1.000000" stroke="none" 
	d="
M207.969513,74.642212 
	C207.578354,75.007301 207.137970,75.055885 206.356689,74.739441 
	C206.012054,73.913963 206.008331,73.453522 206.004593,72.993073 
	C206.004593,72.993073 206.000458,72.999321 205.997742,73.001999 
	C206.636795,73.445015 207.278549,73.885361 207.969513,74.642212 
z"/>
<path fill="#F6DB2C" opacity="1.000000" stroke="none" 
	d="
M214.085022,70.505592 
	C213.959259,70.353706 214.342377,70.241783 214.539764,70.261604 
	C214.737137,70.281425 214.869003,70.651367 214.881561,70.847549 
	C214.633331,70.973312 214.405548,70.844566 214.085022,70.505592 
z"/>
<path fill="#F6DB2C" opacity="1.000000" stroke="none" 
	d="
M211.061798,69.170357 
	C211.170502,69.043266 211.346466,69.081749 211.773972,69.219604 
	C211.726685,69.324623 211.427872,69.330276 211.061798,69.170357 
z"/>
<path fill="#F6DB2C" opacity="1.000000" stroke="none" 
	d="
M210.596649,68.174393 
	C210.802689,68.204819 210.933838,68.596687 210.964462,68.801056 
	C210.703857,68.923828 210.455826,68.771782 210.114716,68.376953 
	C209.978470,68.204620 210.390594,68.143959 210.596649,68.174393 
z"/>
<path fill="#F5AC4C" opacity="1.000000" stroke="none" 
	d="
M166.709930,250.734512 
	C162.513321,252.944595 158.316711,255.154678 153.566132,257.689056 
	C152.237411,258.117249 151.462646,258.221100 150.700699,258.278473 
	C150.713501,258.231964 150.754486,258.144623 150.991547,257.863281 
	C152.121536,255.971848 153.014481,254.361755 154.091827,252.483490 
	C158.092880,250.170242 155.789612,247.497757 155.010544,244.989517 
	C155.003342,244.996765 154.988419,245.012909 155.282715,244.839203 
	C156.378372,243.116089 157.179718,241.566681 157.989075,240.010056 
	C157.997070,240.002823 158.008636,239.988907 158.313034,239.852936 
	C160.099823,237.806931 161.582214,235.896896 163.446960,234.039902 
	C165.624481,234.478836 167.419662,234.864731 169.212112,235.601837 
	C168.887360,238.091934 168.565323,240.230835 167.873718,242.308868 
	C164.324692,242.277573 164.602936,243.824463 165.621185,246.071320 
	C166.267426,247.497314 166.365845,249.171585 166.709930,250.734512 
z"/>
<path fill="#F8E19E" opacity="1.000000" stroke="none" 
	d="
M167.041718,250.826050 
	C166.365845,249.171585 166.267426,247.497314 165.621185,246.071320 
	C164.602936,243.824463 164.324692,242.277573 167.987442,242.590729 
	C169.307510,244.908951 169.275024,248.172211 173.131653,246.210571 
	C173.922821,246.627701 174.525879,246.787033 175.434402,246.994110 
	C176.149933,247.666580 176.560013,248.291275 176.970078,248.915970 
	C176.530304,248.932617 176.090515,248.949265 174.984680,249.040421 
	C173.893417,250.047806 173.468231,250.980667 172.859894,252.169464 
	C171.455978,252.285461 170.235214,252.145538 169.014450,252.005615 
	C168.467468,251.642944 167.920471,251.280258 167.041718,250.826050 
z"/>
<path fill="#E69D5C" opacity="1.000000" stroke="none" 
	d="
M150.687897,258.324982 
	C151.462646,258.221100 152.237411,258.117249 153.403473,258.015503 
	C155.269104,259.040619 156.743393,260.063629 158.326889,261.427795 
	C157.180237,262.506805 155.924393,263.244659 154.154999,263.700989 
	C152.656921,261.721313 151.672409,260.023132 150.687897,258.324982 
z"/>
<path fill="#F2D299" opacity="1.000000" stroke="none" 
	d="
M169.005920,252.339478 
	C170.235214,252.145538 171.455978,252.285461 173.199646,252.242035 
	C174.128311,253.024887 174.534073,253.991104 174.939850,254.957306 
	C172.658859,254.976959 169.394638,257.554749 169.005920,252.339478 
z"/>
<path fill="#FCE7BF" opacity="1.000000" stroke="none" 
	d="
M175.213074,254.874298 
	C174.534073,253.991104 174.128311,253.024887 173.382782,251.986115 
	C173.468231,250.980667 173.893417,250.047806 174.655975,249.071106 
	C175.495880,249.910507 175.998398,250.793762 176.759247,251.839417 
	C177.017166,252.445465 177.016769,252.889069 177.006897,253.665253 
	C176.493713,254.262314 175.990021,254.526794 175.213074,254.874298 
z"/>
<path fill="#FFFEFE" opacity="1.000000" stroke="none" 
	d="
M161.657471,257.732239 
	C161.643280,257.991577 161.505615,258.133057 161.367935,258.274536 
	C161.423294,258.054474 161.478638,257.834412 161.657471,257.732239 
z"/>
<path fill="#F7B615" opacity="1.000000" stroke="none" 
	d="
M269.320465,64.991058 
	C270.088654,65.415825 270.488129,65.844139 271.093262,66.479904 
	C271.839874,67.892845 272.380798,69.098343 272.964417,70.635994 
	C269.016174,71.302750 265.025208,71.637344 260.672485,71.972076 
	C259.769745,71.369247 259.228729,70.766273 258.421509,70.017349 
	C257.526550,68.444710 256.897858,67.018036 256.130310,65.281433 
	C260.312897,64.211800 264.635742,62.681644 269.320465,64.991058 
z"/>
<path fill="#FAF222" opacity="1.000000" stroke="none" 
	d="
M42.652935,63.933235 
	C42.166206,64.243568 41.244614,64.362053 40.538025,64.815529 
	C40.106186,65.092690 40.004749,65.884659 39.584412,66.852516 
	C34.865208,68.824432 33.803154,72.545883 33.499260,76.761223 
	C33.467930,77.195824 33.181072,77.612000 32.677719,78.029022 
	C31.896135,78.026657 31.449614,78.031990 30.715429,77.888229 
	C29.615900,76.475601 28.804035,75.212059 27.992170,73.948524 
	C27.990335,73.516991 27.988503,73.085464 27.993309,72.326965 
	C27.999950,72.000000 28.006422,71.991707 28.006422,71.991714 
	C28.461294,71.987663 28.916164,71.983612 29.712379,71.955917 
	C33.299919,67.441704 36.546116,62.951141 39.971603,58.139542 
	C40.440250,58.319031 40.729599,58.819557 41.023773,59.864479 
	C41.028595,60.408878 41.003521,60.903580 41.003521,60.903580 
	C41.438599,61.896618 41.873680,62.889652 42.652935,63.933235 
z"/>
<path fill="#FEEE0A" opacity="1.000000" stroke="none" 
	d="
M27.588470,73.960602 
	C28.804035,75.212059 29.615900,76.475601 30.770412,78.229202 
	C32.082813,79.147820 33.052567,79.576363 34.017353,80.392311 
	C33.677647,82.194496 33.342907,83.609283 33.008167,85.024063 
	C30.914650,84.122246 28.821133,83.220428 26.423794,82.182800 
	C24.997490,81.695107 23.875008,81.343224 22.376122,80.618835 
	C21.986031,79.495277 21.972342,78.744225 21.958652,77.993172 
	C21.395147,77.479530 20.831644,76.965889 20.112438,76.203888 
	C22.366081,75.294571 24.775425,74.633629 27.588470,73.960602 
z"/>
<path fill="#FAF222" opacity="1.000000" stroke="none" 
	d="
M27.995743,72.003319 
	C27.871428,71.860725 27.751322,71.714806 27.631214,71.568893 
	C27.719055,71.617310 27.806898,71.665726 27.950581,71.852921 
	C28.006422,71.991707 27.999950,72.000000 27.995743,72.003319 
z"/>
<path fill="#F0C81B" opacity="1.000000" stroke="none" 
	d="
M27.447536,69.537971 
	C27.411400,69.479706 27.483673,69.596237 27.447536,69.537971 
z"/>
<path fill="#F6CC2A" opacity="1.000000" stroke="none" 
	d="
M229.507721,99.132446 
	C229.839249,99.585640 229.887466,99.974991 229.890610,100.658684 
	C229.107651,103.652512 228.369766,106.352005 227.325073,110.173912 
	C225.171936,110.601013 221.693024,111.291100 217.184753,112.185371 
	C220.431992,115.096626 222.717499,117.145668 225.019318,119.557640 
	C222.225067,122.584427 219.414505,125.248283 216.064514,127.948158 
	C215.525070,127.984177 215.092819,127.981415 215.065369,127.726685 
	C215.028915,127.301384 215.019913,127.130821 214.959656,126.637756 
	C213.261322,125.541359 211.614227,124.767464 209.834839,123.702377 
	C208.452118,122.296928 207.201691,121.182671 206.254791,119.905518 
	C208.064743,118.230919 209.077164,116.794006 205.998184,115.619263 
	C206.995316,113.514076 207.982574,111.767700 209.037491,110.383316 
	C211.345459,111.653389 213.585785,112.561470 215.826111,113.469559 
	C215.880707,113.089233 215.935303,112.708908 215.989883,112.328583 
	C214.663986,110.551125 213.338074,108.773666 212.009949,106.676170 
	C212.000992,105.929482 211.994278,105.502815 212.247528,105.049484 
	C212.681335,105.011391 212.855194,104.999969 213.269531,104.995178 
	C213.510025,105.001793 213.991196,105.001366 214.080505,105.165649 
	C214.440750,105.222961 214.711670,105.115982 215.365173,105.004013 
	C218.292999,103.710701 220.827896,102.401367 223.387329,101.141891 
	C224.926422,100.384521 226.503754,99.704834 228.063889,98.990227 
	C228.450745,99.016357 228.837585,99.042480 229.507721,99.132446 
z"/>
<path fill="#F7C520" opacity="1.000000" stroke="none" 
	d="
M215.092819,127.981415 
	C215.092819,127.981415 215.525070,127.984177 215.740616,127.986435 
	C217.601929,129.765274 219.247696,131.541870 220.894394,133.691040 
	C218.883698,134.040909 216.872055,134.018188 214.425613,133.999786 
	C213.990799,134.004105 214.000473,134.000214 213.911591,133.831512 
	C213.535675,133.776947 213.248672,133.891083 212.961655,134.005234 
	C209.587067,132.671341 206.212494,131.337463 202.441956,130.006348 
	C202.027084,128.939865 202.008148,127.870613 202.297623,126.363335 
	C203.494980,124.833992 204.383926,123.742676 205.272858,122.651360 
	C205.541397,122.890259 205.809921,123.129158 206.078445,123.368057 
	C205.933685,124.102852 205.788910,124.837654 205.586884,125.863098 
	C207.376572,125.099243 208.671860,124.546410 209.967148,123.993568 
	C211.614227,124.767464 213.261322,125.541359 214.786499,126.740501 
	C214.807343,127.437637 214.950073,127.709526 215.092819,127.981415 
z"/>
<path fill="#F1A03A" opacity="1.000000" stroke="none" 
	d="
M57.817017,226.017700 
	C57.726685,227.624496 57.724373,229.443924 56.814133,230.387436 
	C55.762486,231.477493 53.910835,231.795746 52.205917,232.735229 
	C50.219151,232.790909 48.433010,232.560486 46.330017,231.958984 
	C46.011360,231.408508 46.009552,231.229126 46.404716,231.019745 
	C49.788010,230.150192 52.774326,229.310623 55.760643,228.471039 
	C52.122055,226.700439 48.828392,223.697311 44.667259,227.060669 
	C42.335518,227.950226 40.432793,229.080032 38.376835,229.581192 
	C37.542023,229.784668 36.373714,228.619995 35.357155,228.077850 
	C35.281055,227.615265 35.204956,227.152695 35.081551,226.341522 
	C37.109333,224.364456 39.184422,222.735992 41.748852,220.723511 
	C39.144306,220.114395 36.252842,219.438202 33.408386,218.772995 
	C34.859528,217.099686 35.932152,215.862839 37.042908,214.271423 
	C43.888603,217.892288 50.696159,221.867722 57.817017,226.017700 
z"/>
<path fill="#FBF1D4" opacity="1.000000" stroke="none" 
	d="
M20.764788,211.218445 
	C22.131290,211.273926 23.235851,211.873444 24.294292,211.800842 
	C26.520021,211.648102 28.721544,211.142609 31.590647,210.674652 
	C31.015306,214.729889 30.542513,218.062347 29.884682,221.670547 
	C27.300276,222.155212 24.900906,222.364120 22.245998,222.300125 
	C23.341299,219.256561 24.234608,216.649582 20.795643,214.515259 
	C20.263561,214.185043 20.565636,212.510757 20.764788,211.218445 
z"/>
<path fill="#F4C674" opacity="1.000000" stroke="none" 
	d="
M34.956421,228.075363 
	C36.373714,228.619995 37.542023,229.784668 38.376835,229.581192 
	C40.432793,229.080032 42.335518,227.950226 44.675968,227.406769 
	C45.370632,228.843475 45.689186,229.946609 46.007744,231.049728 
	C46.009552,231.229126 46.011360,231.408508 46.011757,231.857574 
	C45.362499,237.265900 42.409702,235.279724 39.419888,233.614929 
	C38.205814,232.412186 37.394344,231.519547 36.389828,231.145905 
	C34.936245,230.605225 33.342915,230.440262 31.809198,230.115021 
	C32.724693,229.434311 33.640194,228.753586 34.956421,228.075363 
z"/>
<path fill="#FDF4DD" opacity="1.000000" stroke="none" 
	d="
M31.626869,230.215042 
	C33.342915,230.440262 34.936245,230.605225 36.389828,231.145905 
	C37.394344,231.519547 38.205814,232.412186 39.125385,233.430023 
	C38.607124,235.385025 38.067028,236.982895 37.252747,238.801910 
	C31.785339,239.792969 34.223671,235.069382 32.433147,233.131058 
	C31.760593,232.618042 31.411091,232.131317 31.038628,231.333588 
	C31.087471,230.743576 31.230429,230.507721 31.626869,230.215042 
z"/>
<path fill="#FFFEFE" opacity="1.000000" stroke="none" 
	d="
M26.439451,233.548157 
	C26.404375,233.491623 26.474527,233.604706 26.439451,233.548157 
z"/>
<path fill="#F8EA23" opacity="1.000000" stroke="none" 
	d="
M118.895035,10.676214 
	C118.194283,11.110963 117.306091,11.281539 116.204781,11.718550 
	C115.558281,11.999291 115.124893,12.013600 114.355423,12.025328 
	C114.019333,12.022746 113.994881,11.996199 113.888878,11.775873 
	C113.581970,11.277424 113.313599,11.103186 112.977760,11.032833 
	C112.977760,11.032833 112.988144,10.999915 112.992828,10.783081 
	C112.997520,10.566246 113.017876,10.109182 113.017876,10.109182 
	C114.914444,10.210135 116.811020,10.311090 118.895035,10.676214 
z"/>
<path fill="#F8EA23" opacity="1.000000" stroke="none" 
	d="
M114.030365,12.037821 
	C113.749207,12.155476 113.457016,12.258055 113.326836,12.184790 
	C113.657532,12.004697 113.826202,12.000447 113.994881,11.996199 
	C113.994881,11.996199 114.019333,12.022746 114.030365,12.037821 
z"/>
<path fill="#F9E734" opacity="1.000000" stroke="none" 
	d="
M113.888878,11.775873 
	C113.826202,12.000447 113.657532,12.004697 113.235596,12.013891 
	C112.982346,12.018836 112.963654,11.525763 112.970703,11.279298 
	C113.313599,11.103186 113.581970,11.277424 113.888878,11.775873 
z"/>
<path fill="#F9E734" opacity="1.000000" stroke="none" 
	d="
M112.785049,10.168944 
	C113.017876,10.109182 112.997520,10.566246 112.978928,10.794413 
	C112.960327,11.022579 112.462547,11.018995 112.214890,10.991875 
	C112.057213,10.635910 112.252213,10.390561 112.785049,10.168944 
z"/>
<path fill="#E8DAD9" opacity="1.000000" stroke="none" 
	d="
M130.014404,278.041229 
	C128.376343,279.694183 132.071747,283.389252 127.374527,284.020966 
	C126.151543,284.852173 125.307350,285.696075 124.409393,286.478241 
	C123.712578,287.085205 122.948631,287.615051 123.041573,287.543640 
	C121.024246,284.782104 119.563179,282.782074 118.401291,280.405060 
	C121.132439,279.015472 123.564392,278.002869 126.349304,276.993835 
	C127.806313,277.345398 128.910355,277.693298 130.014404,278.041229 
z"/>
<path fill="#FBEAC5" opacity="1.000000" stroke="none" 
	d="
M125.996353,276.990234 
	C123.564392,278.002869 121.132439,279.015472 118.359192,280.048157 
	C117.838516,280.051483 117.659119,280.034760 117.210052,279.998077 
	C117.798309,279.106659 118.656219,278.235199 119.814621,277.217102 
	C120.468407,276.553772 120.821724,276.037048 121.375916,275.223206 
	C121.858131,274.472931 122.139481,274.019806 122.420822,273.566681 
	C121.361015,273.379028 120.301216,273.191345 119.241409,273.003662 
	C118.691330,272.413086 118.141251,271.822510 117.317581,271.102173 
	C118.120750,269.998047 119.197517,269.023651 120.772934,268.288391 
	C121.812233,268.705505 122.352898,268.883392 122.921280,269.283356 
	C122.949013,269.505402 123.044632,269.943176 123.268875,270.241516 
	C124.988396,270.682281 126.483673,270.824768 127.980087,271.323608 
	C127.320915,272.453339 126.660614,273.226654 125.946106,273.861755 
	C125.806496,273.674042 125.721077,273.624603 125.635658,273.575134 
	C125.757004,273.716766 125.878342,273.858398 126.000130,274.373901 
	C125.999168,275.495270 125.997757,276.242767 125.996353,276.990234 
z"/>
<path fill="#F6A92B" opacity="1.000000" stroke="none" 
	d="
M234.951843,64.708054 
	C234.982559,65.035606 234.524628,64.969917 234.294678,64.946365 
	C233.763748,64.461952 233.462784,64.001091 233.065933,63.280365 
	C233.620392,63.473835 234.270767,63.927170 234.951843,64.708054 
z"/>
<path fill="#FDF4DD" opacity="1.000000" stroke="none" 
	d="
M90.692406,262.909912 
	C90.095421,263.381744 89.498421,263.853546 88.931427,264.659424 
	C88.221870,264.992310 87.482307,264.991150 86.372864,264.995819 
	C84.732117,264.425049 83.461266,263.848450 81.455666,262.938477 
	C80.130981,263.545013 77.487961,264.755157 74.511475,265.961975 
	C74.178017,265.958649 74.011223,265.515442 73.997459,264.922089 
	C73.943298,263.848267 73.902901,263.367767 73.862503,262.887299 
	C73.884827,262.165649 73.907150,261.444000 73.945724,260.361755 
	C74.288147,259.946594 74.557007,259.792267 74.872375,259.327026 
	C78.692612,259.062805 82.409035,259.009705 86.830566,258.937103 
	C87.703423,258.933685 87.871162,258.949738 88.206848,259.246033 
	C89.195702,259.779938 90.016609,260.033600 90.850761,260.617798 
	C90.806801,261.602173 90.749603,262.256042 90.692406,262.909912 
z"/>
<path fill="#F2BC69" opacity="1.000000" stroke="none" 
	d="
M185.051514,238.974854 
	C184.498688,240.458481 183.945892,241.942093 183.196686,243.712723 
	C183.000275,243.999741 183.010666,243.989609 182.679260,243.982697 
	C181.501282,243.277176 180.658676,242.573730 179.807190,241.881210 
	C178.709473,240.988434 177.604630,240.104416 176.502838,239.216644 
	C177.821350,238.285248 179.139877,237.353867 180.441513,236.434418 
	C180.103500,236.075241 179.564865,235.502914 179.255585,234.666122 
	C180.313004,232.932693 181.141068,231.463745 182.217545,229.989441 
	C182.465958,229.984085 182.962830,229.991791 182.999908,230.341034 
	C183.656647,232.132660 184.276306,233.575027 184.652954,235.179169 
	C184.286896,235.897751 184.163864,236.454529 184.124878,237.276276 
	C184.489792,238.019119 184.770660,238.496994 185.051514,238.974854 
z"/>
<path fill="#FBEAC5" opacity="1.000000" stroke="none" 
	d="
M185.467514,238.990326 
	C184.770660,238.496994 184.489792,238.019119 184.490326,237.267563 
	C186.182648,236.328232 187.593582,235.662567 189.002289,234.998444 
	C189.000061,235.000000 188.996460,235.003723 189.383881,235.016174 
	C191.170029,236.313370 192.568771,237.598099 193.967499,238.882843 
	C193.806473,239.169159 193.645447,239.455475 193.270050,239.869019 
	C190.664978,239.666077 188.274246,239.335938 185.467514,238.990326 
z"/>
<path fill="#F2BC69" opacity="1.000000" stroke="none" 
	d="
M185.532318,244.013977 
	C185.154007,244.302521 184.413284,244.602890 183.333115,244.682159 
	C182.993652,244.461075 183.010666,243.989609 183.010666,243.989609 
	C183.010666,243.989609 183.000275,243.999741 182.994110,244.005707 
	C183.715271,244.016388 184.442596,244.021088 185.532318,244.013977 
z"/>
<path fill="#FCE7BF" opacity="1.000000" stroke="none" 
	d="
M224.886200,218.643402 
	C224.092804,220.145325 223.178055,221.376312 222.263321,222.607300 
	C221.952774,222.463364 221.642227,222.319443 221.331665,222.175522 
	C220.989990,223.880341 220.648315,225.585175 220.149231,227.629700 
	C218.851547,227.903183 217.711243,227.836960 215.966019,227.925537 
	C214.900833,228.711685 214.440582,229.343048 213.980331,229.974411 
	C211.947113,229.550354 209.913895,229.126282 207.253906,228.571503 
	C206.663498,229.018494 205.499664,229.899628 203.849518,231.148941 
	C203.502335,229.820663 203.245651,228.838593 202.996948,227.080017 
	C203.018890,225.209686 203.032852,224.115860 203.349518,223.040985 
	C204.185242,222.486221 204.720032,221.914124 205.250916,221.338409 
	C206.124969,220.390518 206.996490,219.440262 207.869080,218.490997 
	C210.453751,222.046677 212.822189,218.081268 215.372375,218.712524 
	C215.142151,217.134720 214.975433,215.992203 214.849670,214.529175 
	C216.270401,213.785477 217.650162,213.362289 219.322067,213.002563 
	C220.006317,213.135880 220.398422,213.205719 220.808014,213.280670 
	C220.825516,213.285782 220.857544,213.302002 220.959778,213.508438 
	C221.268570,213.959259 221.527344,214.119247 221.927979,214.380615 
	C222.017624,214.566406 222.209991,214.931381 222.239746,215.165985 
	C222.436264,215.706573 222.693802,215.866470 223.058075,215.903625 
	C223.074036,215.926941 223.130402,215.931290 223.130402,215.931290 
	C223.675217,216.745010 224.220032,217.558731 224.886200,218.643402 
M219.612640,216.341507 
	C219.503967,216.114380 219.395294,215.887238 219.286621,215.660110 
	C219.077194,215.867081 218.867752,216.074066 218.658325,216.281036 
	C218.880005,216.397278 219.101700,216.513519 219.612640,216.341507 
z"/>
<path fill="#F2D299" opacity="1.000000" stroke="none" 
	d="
M194.111084,238.572906 
	C192.568771,237.598099 191.170029,236.313370 189.359711,234.628876 
	C190.295654,231.481125 191.643158,228.733139 193.257568,226.164795 
	C194.381012,226.885559 195.237579,227.426697 196.094147,227.967819 
	C197.369370,229.741074 198.644592,231.514328 199.879120,233.641113 
	C198.687622,234.163849 197.536804,234.333084 196.331390,234.199066 
	C196.182297,233.927719 196.087814,233.959610 195.993347,233.991501 
	C195.413773,235.415329 194.834213,236.839157 194.111084,238.572906 
z"/>
<path fill="#F8E19E" opacity="1.000000" stroke="none" 
	d="
M196.233612,227.651031 
	C195.237579,227.426697 194.381012,226.885559 193.410690,225.858154 
	C194.525635,223.744858 195.754318,222.117828 197.595428,219.679855 
	C197.475983,224.896912 200.199982,221.902878 202.110260,222.221375 
	C202.498413,222.639221 202.772614,222.830627 203.046814,223.022018 
	C203.032852,224.115860 203.018890,225.209686 202.992371,226.717880 
	C200.777557,227.199570 198.575333,227.266907 196.233612,227.651031 
z"/>
<path fill="#FDF4DD" opacity="1.000000" stroke="none" 
	d="
M214.302490,229.943054 
	C214.440582,229.343048 214.900833,228.711685 215.682007,228.048416 
	C215.543518,228.648254 215.084091,229.279984 214.302490,229.943054 
z"/>
<path fill="#F6B138" opacity="1.000000" stroke="none" 
	d="
M220.790527,213.275543 
	C220.398422,213.205719 220.006317,213.135880 219.322021,212.630371 
	C218.022003,210.147385 217.014160,208.100082 215.649719,206.003204 
	C214.683777,205.704010 213.917145,205.609741 213.491379,205.178741 
	C211.247559,202.907532 209.377319,199.992859 205.376450,201.357437 
	C205.111694,201.447739 204.487946,200.485550 203.918060,199.784973 
	C203.532715,199.361984 203.260468,199.166916 202.973541,198.741577 
	C202.958862,198.511307 202.949158,198.049927 203.029816,197.681732 
	C206.755157,196.218201 210.399872,195.122864 214.026840,194.415985 
	C213.935791,195.839996 213.862473,196.875580 213.556427,197.963165 
	C213.127502,198.225296 212.931320,198.435410 212.735123,198.645523 
	C213.190781,198.743454 213.646454,198.841385 214.452713,198.932083 
	C217.167892,196.312653 219.532455,193.700439 221.925842,190.649628 
	C221.946396,188.164352 221.938126,186.117645 222.232941,183.933472 
	C223.364029,183.528076 224.192047,183.260162 225.286133,183.105499 
	C226.058197,183.501038 226.564163,183.783325 227.070129,184.065598 
	C229.666962,187.011215 229.386780,189.754486 226.737564,192.582077 
	C225.101822,194.327957 223.783386,196.371155 223.887604,197.856430 
	C225.264877,200.624466 226.642151,203.392502 227.642014,206.572754 
	C223.374893,207.299637 219.949753,208.092422 220.790527,213.275543 
z"/>
<path fill="#F1A03A" opacity="1.000000" stroke="none" 
	d="
M220.808014,213.280670 
	C219.949753,208.092422 223.374893,207.299637 227.655746,206.970901 
	C230.094894,212.941925 229.454163,214.324051 223.504761,215.939224 
	C223.130402,215.931290 223.074036,215.926941 222.969147,215.705994 
	C222.704010,215.232147 222.485916,215.047592 222.209991,214.931381 
	C222.209991,214.931381 222.017624,214.566406 221.874390,214.131424 
	C221.439957,213.564972 221.148758,213.433487 220.857544,213.302002 
	C220.857544,213.302002 220.825516,213.285782 220.808014,213.280670 
z"/>
<path fill="#EF9C27" opacity="1.000000" stroke="none" 
	d="
M227.402893,183.992065 
	C226.564163,183.783325 226.058197,183.501038 225.224167,182.755432 
	C222.991776,180.015152 222.460724,175.950882 218.051147,176.656326 
	C223.704010,170.069107 222.980698,179.554535 226.513977,179.681839 
	C226.693527,178.408463 226.861084,177.220261 227.028625,176.032043 
	C227.756439,176.339828 228.484238,176.647614 229.507233,176.984970 
	C229.835052,177.752747 229.867706,178.490952 229.852081,179.599152 
	C229.114426,181.285614 228.425034,182.602081 227.402893,183.992065 
z"/>
<path fill="#F8D80B" opacity="1.000000" stroke="none" 
	d="
M228.081879,98.658279 
	C226.503754,99.704834 224.926422,100.384521 223.387329,101.141891 
	C220.827896,102.401367 218.292999,103.710701 215.117447,105.001884 
	C214.487122,105.004738 213.991196,105.001366 213.991196,105.001366 
	C213.991196,105.001366 213.510025,105.001793 213.236542,104.674110 
	C213.905670,103.206947 214.669540,101.814339 215.835434,100.991096 
	C217.343094,99.926537 219.171738,99.316582 220.862152,98.510880 
	C220.712402,98.303314 220.562653,98.095749 220.412903,97.888176 
	C217.612183,98.262901 214.811462,98.637627 211.928879,98.631844 
	C206.903809,96.515350 201.960617,94.779358 197.017410,93.043373 
	C197.339737,91.285744 197.662064,89.528122 198.266510,87.487808 
	C199.705032,86.141037 200.861420,85.076942 202.017822,84.012848 
	C202.754379,85.296745 203.327606,86.720772 204.274368,87.824341 
	C205.185730,88.886658 206.483322,89.617645 207.816254,90.233658 
	C208.766235,89.978195 209.511185,89.983276 210.629059,89.984009 
	C211.330643,89.898094 211.593628,89.719711 212.157806,89.389153 
	C214.683060,88.888290 216.841415,88.442787 218.999786,87.997284 
	C220.066620,87.992691 221.133469,87.988106 222.873413,87.868729 
	C224.146637,87.365707 224.746765,86.977470 226.086441,86.110794 
	C224.469299,86.110794 223.741287,86.110794 223.013275,86.110794 
	C223.464828,85.420639 223.817474,84.630836 224.389999,84.062576 
	C225.362274,83.097557 226.473129,82.272148 227.807037,81.196556 
	C228.093246,83.419548 228.098145,85.834053 228.161102,88.844147 
	C228.399475,89.713341 228.635635,89.924423 228.927689,90.072998 
	C228.932404,90.490479 228.937119,90.907959 228.745407,91.772095 
	C228.296524,92.438370 228.167953,92.715477 228.163300,93.050072 
	C228.142166,94.808830 228.121017,96.567589 228.081879,98.658279 
M200.483078,91.587181 
	C200.483078,91.587181 200.418854,91.478790 200.483078,91.587181 
z"/>
<path fill="#FBEB18" opacity="1.000000" stroke="none" 
	d="
M218.999893,87.998779 
	C216.841415,88.442787 214.683060,88.888290 212.265839,89.171936 
	C213.756927,88.365273 215.506866,87.720474 217.730835,87.256447 
	C218.408676,87.713478 218.674744,87.901718 219.003113,88.001938 
	C219.003113,88.001938 219.000000,88.000290 218.999893,87.998779 
z"/>
<path fill="#FBEB18" opacity="1.000000" stroke="none" 
	d="
M223.009644,86.420250 
	C223.741287,86.110794 224.469299,86.110794 226.086441,86.110794 
	C224.746765,86.977470 224.146637,87.365707 223.273422,87.861717 
	C223.002228,87.556236 223.004135,87.142967 223.009644,86.420250 
z"/>
<path fill="#F8D80B" opacity="1.000000" stroke="none" 
	d="
M218.898254,87.775879 
	C218.674744,87.901718 218.408676,87.713478 218.094391,87.215065 
	C218.317978,87.085152 218.587814,87.270782 218.898254,87.775879 
z"/>
<path fill="#FA9D0D" opacity="1.000000" stroke="none" 
	d="
M249.983002,71.053009 
	C250.011765,71.349007 250.125595,71.564285 250.491852,71.814728 
	C250.737549,72.063309 250.814972,72.232224 251.037018,72.910156 
	C251.448318,73.608284 251.714966,73.797386 251.981628,73.986496 
	C251.981628,73.986496 252.003494,73.993515 252.005875,74.005890 
	C252.008789,74.758842 252.009338,75.499413 252.026276,76.609680 
	C250.227646,77.408340 248.412628,77.837303 246.266449,78.118179 
	C244.540741,75.800781 243.146210,73.631477 241.148956,70.524582 
	C244.119370,70.307602 246.110107,70.162193 248.527588,70.019218 
	C248.954330,70.021660 248.995651,70.017334 249.073120,70.255829 
	C249.335022,70.769646 249.588669,70.944710 249.911499,71.019531 
	C249.911499,71.019531 249.983871,71.016808 249.983002,71.053009 
z"/>
<path fill="#F6A92B" opacity="1.000000" stroke="none" 
	d="
M254.095459,77.208160 
	C254.375778,77.034172 254.621033,77.196877 254.765244,77.725197 
	C254.487595,77.845024 254.286331,77.663040 254.095459,77.208160 
z"/>
<path fill="#FCD9BA" opacity="1.000000" stroke="none" 
	d="
M150.700699,258.278473 
	C151.672409,260.023132 152.656921,261.721313 153.795456,263.733765 
	C153.059036,264.703888 152.224930,265.462585 151.263626,265.989075 
	C150.106827,266.622620 148.841888,267.058716 147.343262,267.814545 
	C146.880280,268.032959 146.697784,268.017151 146.189499,267.860413 
	C145.786575,267.674347 145.709442,267.629211 145.632309,267.584076 
	C145.744339,267.711823 145.856384,267.839539 145.968414,267.967285 
	C144.542084,268.959167 143.115753,269.951050 141.490906,270.632751 
	C141.939896,268.999634 142.587402,267.676697 142.929001,266.978760 
	C140.004257,266.632904 137.495758,266.336273 135.331558,265.965027 
	C136.842255,264.829224 137.875504,263.544983 139.202515,262.752991 
	C142.021927,261.070404 144.994186,259.643982 147.911926,258.095154 
	C147.920059,258.077759 147.928223,258.078644 148.023712,258.278046 
	C148.475861,258.352783 148.832504,258.228088 149.452637,258.127380 
	C150.062241,258.149078 150.408356,258.146851 150.754486,258.144623 
	C150.754486,258.144623 150.713501,258.231964 150.700699,258.278473 
z"/>
<path fill="#FAE7CE" opacity="1.000000" stroke="none" 
	d="
M126.000305,274.000000 
	C126.660614,273.226654 127.320915,272.453339 127.990845,270.953278 
	C128.000687,269.483948 128.000900,268.741302 128.000549,267.999329 
	C128.000000,268.000000 127.997757,268.000916 128.249390,268.012238 
	C128.668869,268.018250 128.836685,268.012939 129.108566,268.262512 
	C129.475082,268.999817 129.737549,269.482269 130.116226,270.197083 
	C130.528000,270.594055 130.823563,270.758636 131.322357,271.041077 
	C131.775467,271.361481 131.956421,271.613525 132.055908,272.200623 
	C132.050369,272.676697 132.057327,272.867126 132.230057,273.299866 
	C132.963379,273.595276 133.530960,273.648407 134.389832,273.777313 
	C135.132370,274.208435 135.583603,274.563751 136.034821,274.919098 
	C135.842300,278.927734 132.699860,277.376770 130.309692,277.920715 
	C128.910355,277.693298 127.806313,277.345398 126.349304,276.993835 
	C125.997757,276.242767 125.999168,275.495270 126.000290,274.373901 
	C126.000000,274.000000 126.000305,274.000000 126.000305,274.000000 
z"/>
<path fill="#F2BC69" opacity="1.000000" stroke="none" 
	d="
M136.271255,274.962891 
	C135.583603,274.563751 135.132370,274.208435 134.369019,273.616577 
	C134.056885,273.380066 134.031876,273.053192 134.041107,272.791290 
	C134.061798,272.355072 134.073242,272.180756 134.309296,271.884399 
	C134.697754,271.467773 134.861588,271.173126 135.327087,270.917847 
	C136.124649,271.270844 136.620544,271.584473 137.116455,271.898132 
	C137.080154,272.699615 137.043869,273.501099 136.992615,274.705627 
	C136.977661,275.108643 136.507690,275.006653 136.271255,274.962891 
z"/>
<path fill="#E8DAD9" opacity="1.000000" stroke="none" 
	d="
M137.447540,271.889343 
	C136.620544,271.584473 136.124649,271.270844 135.210297,270.635437 
	C134.180359,269.501709 133.568863,268.689758 132.994308,267.585266 
	C133.373642,266.843323 133.716034,266.393921 134.291168,265.963074 
	C134.523895,265.981628 134.987244,266.039642 134.987244,266.039642 
	C137.495758,266.336273 140.004257,266.632904 142.929001,266.978760 
	C142.587402,267.676697 141.939896,268.999634 141.120071,270.616394 
	C139.891388,271.233612 138.835007,271.557098 137.447540,271.889343 
z"/>
<path fill="#F9F32B" opacity="1.000000" stroke="none" 
	d="
M21.671478,78.022240 
	C21.972342,78.744225 21.986031,79.495277 22.001282,80.621796 
	C22.002846,80.997253 21.999891,81.000000 21.740158,80.892563 
	C20.988258,80.521767 20.496092,80.258400 20.002001,79.997520 
	C20.000076,80.000000 20.006044,79.995926 20.009552,79.742470 
	C20.015387,79.320038 20.017710,79.151062 20.020035,78.982101 
	C20.474792,78.671837 20.929550,78.361572 21.671478,78.022240 
z"/>
<path fill="#FAD809" opacity="1.000000" stroke="none" 
	d="
M20.006762,81.996124 
	C19.533421,82.323860 19.060078,82.651588 18.283976,83.003128 
	C18.239115,82.212723 18.497015,81.398499 19.132336,80.290405 
	C19.509758,79.996521 20.006044,79.995926 20.006044,79.995926 
	C20.006044,79.995926 20.000076,80.000000 20.000702,80.331123 
	C20.003138,81.106873 20.004950,81.551498 20.006762,81.996124 
z"/>
<path fill="#FEEE0A" opacity="1.000000" stroke="none" 
	d="
M20.009552,79.742470 
	C20.006044,79.995926 19.509758,79.996521 19.261814,79.992874 
	C19.112949,79.662819 19.301092,79.400208 19.799164,79.091736 
	C20.017710,79.151062 20.015387,79.320038 20.009552,79.742470 
z"/>
<path fill="#FAD809" opacity="1.000000" stroke="none" 
	d="
M16.887508,81.939926 
	C16.946779,82.134697 16.497004,82.090195 16.272243,82.109955 
	C16.307732,82.001534 16.567984,81.873344 16.887508,81.939926 
z"/>
<path fill="#F1DC08" opacity="1.000000" stroke="none" 
	d="
M41.169640,60.824692 
	C41.003521,60.903580 41.028595,60.408878 41.019775,60.160339 
	C41.119225,60.189804 41.227489,60.467800 41.169640,60.824692 
z"/>
<path fill="#F2D407" opacity="1.000000" stroke="none" 
	d="
M204.926956,64.859695 
	C204.007996,67.817696 202.331558,69.142609 199.126282,69.139938 
	C197.228745,69.138351 195.329956,70.631958 193.216278,71.741516 
	C185.504196,71.541298 183.415222,78.588814 178.329269,82.749313 
	C177.757278,79.558739 177.266556,76.821548 176.961395,75.119385 
	C180.097916,73.359818 182.459702,72.094002 184.761917,70.727798 
	C187.407150,69.158043 189.329636,66.289307 193.419907,67.914162 
	C194.560333,68.367195 197.088669,65.326393 198.985947,63.528736 
	C200.661545,63.501808 202.331497,63.834538 204.366806,64.226959 
	C204.732162,64.286652 204.869080,64.666359 204.926956,64.859695 
z"/>
<path fill="#F8EA23" opacity="1.000000" stroke="none" 
	d="
M166.912888,40.824089 
	C166.743530,41.019245 166.568771,41.007610 166.132507,40.960930 
	C165.993759,40.664558 166.178589,40.459156 166.626892,40.260464 
	C166.828262,40.211243 166.907501,40.617302 166.912888,40.824089 
z"/>
<path fill="#F6E6D7" opacity="1.000000" stroke="none" 
	d="
M110.224083,286.405212 
	C111.152443,286.217346 112.196381,286.141418 112.926178,286.583771 
	C113.283249,286.800201 113.056053,287.980591 113.093307,288.724670 
	C112.358208,288.592987 111.623108,288.461334 110.645584,288.173279 
	C110.338867,287.588104 110.274574,287.159332 110.224083,286.405212 
z"/>
<path fill="#FAE1A8" opacity="1.000000" stroke="none" 
	d="
M73.615097,262.940155 
	C73.902901,263.367767 73.943298,263.848267 73.952499,264.689636 
	C73.736763,264.364685 73.552231,263.678864 73.615097,262.940155 
z"/>
<path fill="#FCE7BF" opacity="1.000000" stroke="none" 
	d="
M182.679260,243.982697 
	C183.010666,243.989609 182.993652,244.461075 183.008163,244.696167 
	C181.685471,246.107040 180.348267,247.282837 178.992218,248.734589 
	C178.396393,249.140976 177.819443,249.271378 177.106277,249.158875 
	C176.560013,248.291275 176.149933,247.666580 175.531799,246.718567 
	C175.666870,245.698105 176.010010,245.000931 176.353165,244.303772 
	C176.087173,244.098831 175.821182,243.893906 175.555191,243.688965 
	C174.684631,244.443558 173.814072,245.198151 172.943527,245.952744 
	C169.275024,248.172211 169.307510,244.908951 168.356995,242.651611 
	C168.565323,240.230835 168.887360,238.091934 169.561279,235.557922 
	C170.598297,235.008728 171.283417,234.854660 172.408112,234.743896 
	C174.907211,234.834976 176.966721,234.882782 179.026245,234.930588 
	C179.564865,235.502914 180.103500,236.075241 180.441513,236.434418 
	C179.139877,237.353867 177.821350,238.285248 176.502838,239.216644 
	C177.604630,240.104416 178.709473,240.988434 179.807190,241.881210 
	C180.658676,242.573730 181.501282,243.277176 182.679260,243.982697 
z"/>
<path fill="#F6CC2A" opacity="1.000000" stroke="none" 
	d="
M228.354675,92.916504 
	C228.167953,92.715477 228.296524,92.438370 228.742401,92.085205 
	C228.916641,92.280724 228.786713,92.557816 228.354675,92.916504 
z"/>
<path fill="#F6CC2A" opacity="1.000000" stroke="none" 
	d="
M228.855804,89.838615 
	C228.635635,89.924423 228.399475,89.713341 228.129181,89.218010 
	C228.363617,89.105583 228.611862,89.308243 228.855804,89.838615 
z"/>
<path fill="#F6DB2C" opacity="1.000000" stroke="none" 
	d="
M38.081947,93.046547 
	C35.654667,94.959435 33.227383,96.872322 30.197071,98.921982 
	C29.384089,99.085663 29.174128,99.112572 28.681980,99.352394 
	C28.222027,100.749939 28.044262,101.934578 27.866497,103.119217 
	C22.758266,101.671440 26.138527,98.998810 26.978439,96.343079 
	C29.103628,94.156471 31.190355,92.325073 33.277081,90.493683 
	C33.727821,90.837097 34.178562,91.180519 34.629299,91.523933 
	C34.754059,90.286804 34.878819,89.049667 35.226070,87.138763 
	C37.962257,85.660973 40.475945,84.856956 42.955490,84.397003 
	C41.633808,87.196594 40.346268,89.652115 39.058731,92.107628 
	C39.058727,92.107628 39.022442,92.025681 38.819153,92.034912 
	C38.437893,92.378273 38.259918,92.712410 38.081947,93.046547 
z"/>
<path fill="#F3C455" opacity="1.000000" stroke="none" 
	d="
M27.899010,103.503174 
	C28.044262,101.934578 28.222027,100.749939 28.680958,99.807175 
	C29.031101,109.700218 29.100077,119.351387 29.099150,129.455734 
	C28.959618,133.266968 28.889988,136.625000 28.527172,139.986526 
	C27.843832,139.994675 27.453674,139.999344 27.063519,140.004028 
	C27.063519,140.004028 26.990963,140.005402 26.953861,140.003540 
	C26.601488,139.221039 26.286217,138.440414 26.115353,137.007843 
	C26.828264,133.238861 27.396769,130.121826 27.977770,126.577362 
	C27.971134,124.440681 27.952003,122.731438 27.955996,120.592789 
	C27.961632,118.450890 27.944145,116.738403 27.956980,114.607788 
	C27.983969,112.793045 27.980637,111.396423 27.977303,109.999809 
	C27.976366,109.244888 27.975428,108.489967 27.942074,107.358353 
	C27.916948,105.950142 27.924234,104.918633 27.899010,103.503174 
z"/>
<path fill="#F4C70E" opacity="1.000000" stroke="none" 
	d="
M27.531887,109.998222 
	C27.980637,111.396423 27.983969,112.793045 27.633083,114.586555 
	C24.184507,114.983414 21.090147,114.983376 17.887987,114.706406 
	C18.200089,112.967003 18.619991,111.504539 19.039892,110.042076 
	C21.722084,110.026932 24.404278,110.011787 27.531887,109.998222 
z"/>
<path fill="#F9DD0F" opacity="1.000000" stroke="none" 
	d="
M18.597935,110.027473 
	C18.619991,111.504539 18.200089,112.967003 17.761787,115.005966 
	C17.145302,117.425407 16.442722,119.109146 13.953117,118.070297 
	C13.520423,118.044571 13.087728,118.018845 12.330519,117.975113 
	C12.006005,117.957100 12.023358,117.478546 12.031569,117.239136 
	C12.025626,114.936462 12.011473,112.873199 11.990664,110.423027 
	C14.041330,110.028374 16.098654,110.020622 18.597935,110.027473 
z"/>
<path fill="#F2C712" opacity="1.000000" stroke="none" 
	d="
M42.989632,84.052933 
	C40.475945,84.856956 37.962257,85.660973 35.229282,86.742462 
	C34.566658,87.012604 34.123322,87.005287 33.348198,86.982780 
	C33.014370,86.535606 33.012325,86.103630 33.009224,85.347855 
	C33.342907,83.609283 33.677647,82.194496 34.390694,80.392387 
	C35.517288,79.999008 36.265583,79.992966 37.343857,79.990997 
	C38.113724,79.999451 38.553612,80.003815 39.223648,80.275970 
	C40.975590,81.015259 42.497383,81.486763 43.912430,82.230377 
	C43.533661,83.019302 43.261646,83.536118 42.989632,84.052933 
z"/>
<path fill="#FEEE0A" opacity="1.000000" stroke="none" 
	d="
M20.302719,81.975296 
	C20.004950,81.551498 20.003138,81.106873 20.002625,80.328644 
	C20.496092,80.258400 20.988258,80.521767 21.738380,80.893448 
	C21.530451,81.319336 21.064564,81.636909 20.302719,81.975296 
z"/>
<path fill="#F7B135" opacity="1.000000" stroke="none" 
	d="
M25.368870,190.005280 
	C24.545143,189.993256 24.093275,189.980911 23.301979,189.953232 
	C23.275923,187.891159 23.589294,185.844421 24.146269,183.127563 
	C25.570438,180.968948 26.751005,179.480453 27.960945,178.452759 
	C27.984070,182.609329 27.977823,186.305099 27.971577,190.000885 
	C27.227961,190.002243 26.484344,190.003601 25.368870,190.005280 
z"/>
<path fill="#F8C146" opacity="1.000000" stroke="none" 
	d="
M27.931576,177.991959 
	C26.751005,179.480453 25.570438,180.968948 24.190407,182.741638 
	C22.917860,183.343536 21.844778,183.661255 20.440935,184.041168 
	C20.380466,181.992401 20.650761,179.881454 20.972073,177.377594 
	C21.706333,177.440262 22.389572,177.895828 23.169012,178.415527 
	C24.387730,177.127991 25.830584,175.603653 27.628456,174.439758 
	C27.966175,175.864105 27.948877,176.928040 27.931576,177.991959 
z"/>
<path fill="#FABA2B" opacity="1.000000" stroke="none" 
	d="
M17.013161,186.023804 
	C16.958584,186.682571 17.161945,187.613388 16.804295,187.952515 
	C15.339966,189.340973 13.733749,190.592926 12.055796,191.708710 
	C11.960208,191.772278 10.762207,190.177994 10.077286,189.355301 
	C11.483025,188.261459 12.888760,187.167603 14.997587,186.030060 
	C16.138172,185.998840 16.575666,186.011322 17.013161,186.023804 
z"/>
<path fill="#FDCC46" opacity="1.000000" stroke="none" 
	d="
M17.345230,186.016220 
	C16.575666,186.011322 16.138172,185.998840 15.373482,185.988602 
	C15.051033,184.912201 15.055780,183.833542 15.107843,182.351166 
	C16.438808,183.092361 17.722458,184.237305 19.006241,185.684418 
	C18.563349,185.993942 18.120323,186.001297 17.345230,186.016220 
z"/>
<path fill="#FBF1D4" opacity="1.000000" stroke="none" 
	d="
M14.548177,214.465546 
	C14.580584,214.520676 14.515770,214.410431 14.548177,214.465546 
z"/>
<path fill="#391E3B" opacity="1.000000" stroke="none" 
	d="
M28.820358,139.983047 
	C28.889988,136.625000 28.959618,133.266968 29.289467,129.451508 
	C29.549685,128.994110 29.930117,128.978912 29.930117,128.978912 
	C29.976704,154.631912 30.023291,180.284927 30.048313,206.459106 
	C30.026747,206.980301 29.887468,207.111389 29.675455,207.066528 
	C29.463442,207.021667 29.044943,206.908951 29.044943,206.908951 
	C29.044943,206.908951 28.974634,206.465424 28.988003,205.784653 
	C28.941034,183.396942 28.880695,161.689987 28.820358,139.983047 
z"/>
<path fill="#E5B368" opacity="1.000000" stroke="none" 
	d="
M28.527172,139.986526 
	C28.880695,161.689987 28.941034,183.396942 28.922062,205.570007 
	C28.842752,206.036148 28.449736,206.016174 28.253651,206.012985 
	C28.035408,200.984604 28.013250,195.959396 27.981335,190.467545 
	C27.977823,186.305099 27.984070,182.609329 27.960947,178.452759 
	C27.948877,176.928040 27.966175,175.864105 27.970974,174.401260 
	C27.967337,173.548340 27.976202,173.094345 27.986721,171.846725 
	C27.961542,166.373886 27.934710,161.694672 27.930519,156.585510 
	C27.942366,154.430969 27.931574,152.706390 27.938690,150.649658 
	C27.944071,149.874298 27.931545,149.431076 27.919020,148.987854 
	C27.641699,146.221069 27.364380,143.454300 27.075291,140.345764 
	C27.453674,139.999344 27.843832,139.994675 28.527172,139.986526 
z"/>
<path fill="#391E3B" opacity="1.000000" stroke="none" 
	d="
M31.908236,208.395309 
	C32.767422,208.461914 33.696873,208.888275 34.755512,209.607025 
	C33.915966,209.517975 32.947231,209.136520 31.908236,208.395309 
z"/>
<path fill="#391E3B" opacity="1.000000" stroke="none" 
	d="
M30.097559,206.916016 
	C30.447350,207.000443 30.685120,207.198608 30.996624,207.651093 
	C30.925478,207.910995 30.739389,207.966019 30.254959,207.786835 
	C29.956617,207.552612 29.887468,207.111389 29.887468,207.111389 
	C29.887468,207.111389 30.026747,206.980301 30.097559,206.916016 
z"/>
<path fill="#E5B368" opacity="1.000000" stroke="none" 
	d="
M29.675455,207.066528 
	C29.887468,207.111389 29.956617,207.552612 29.964085,207.775650 
	C29.711708,207.796448 29.451864,207.594208 29.118481,207.150452 
	C29.044943,206.908951 29.463442,207.021667 29.675455,207.066528 
z"/>
<path fill="#F7C520" opacity="1.000000" stroke="none" 
	d="
M27.965275,127.004799 
	C27.396769,130.121826 26.828264,133.238861 26.064650,136.693542 
	C23.960173,137.017410 22.027927,137.159912 20.156651,136.885956 
	C19.654476,136.812424 19.338022,135.470276 18.963322,134.368347 
	C19.357998,133.727448 19.709955,133.191376 20.095709,133.165833 
	C25.120815,132.833160 24.854446,129.884918 24.084780,126.325699 
	C25.754503,126.517715 26.859888,126.761261 27.965275,127.004799 
z"/>
<path fill="#F9CC38" opacity="1.000000" stroke="none" 
	d="
M27.484610,148.991730 
	C27.931545,149.431076 27.944071,149.874298 27.550323,150.662323 
	C23.431292,152.670044 19.718533,154.332932 16.005772,155.995819 
	C15.982330,154.556885 15.958891,153.117950 15.963301,151.349274 
	C16.434267,149.993332 16.877384,148.967133 17.129087,148.384232 
	C20.542597,148.594589 23.796398,148.795105 27.484610,148.991730 
z"/>
<path fill="#F5BE36" opacity="1.000000" stroke="none" 
	d="
M16.000523,156.328995 
	C19.718533,154.332932 23.431292,152.670044 27.532417,150.994476 
	C27.931574,152.706390 27.942366,154.430969 27.570757,156.582565 
	C23.373821,157.810669 21.833515,163.238876 17.019955,162.007370 
	C16.331478,161.933517 15.643003,161.859665 14.954525,161.785812 
	C15.324584,161.145935 15.652909,160.476746 16.076160,159.874222 
	C16.520863,159.241180 17.056459,158.671982 17.552338,158.074890 
	C17.229383,158.051407 16.906427,158.027939 16.277357,157.999481 
	C15.979253,157.550369 15.987262,157.106277 16.000523,156.328995 
z"/>
<path fill="#F7B135" opacity="1.000000" stroke="none" 
	d="
M11.745890,161.994553 
	C11.721679,162.110641 11.443171,162.224197 11.073826,162.166718 
	C11.152525,161.994446 11.322060,161.993225 11.745890,161.994553 
z"/>
<path fill="#F9D047" opacity="1.000000" stroke="none" 
	d="
M17.011242,162.392975 
	C21.833515,163.238876 23.373821,157.810669 27.548119,157.012512 
	C27.934710,161.694672 27.961542,166.373886 27.975687,171.506317 
	C26.133471,169.878967 24.348928,167.753067 22.386621,165.806366 
	C22.234232,165.655197 20.835442,166.760406 20.002281,167.639801 
	C18.993250,166.258148 17.997889,164.518356 17.011242,162.392975 
z"/>
<path fill="#F9D23A" opacity="1.000000" stroke="none" 
	d="
M12.009354,126.350433 
	C12.914399,124.844261 13.823545,123.716522 15.199417,122.302032 
	C16.109203,122.016373 16.552261,122.017448 17.106430,122.238266 
	C17.424406,122.727890 17.689713,122.914879 18.013462,123.018959 
	C18.374598,124.338013 18.735735,125.657066 19.183992,127.294327 
	C16.793770,127.612030 14.213025,133.470856 12.009354,126.350433 
z"/>
<path fill="#FADE1F" opacity="1.000000" stroke="none" 
	d="
M27.977770,126.577362 
	C26.859888,126.761261 25.754503,126.517715 24.332253,126.136116 
	C23.559032,124.999939 23.102680,124.001816 22.292894,122.661087 
	C20.964642,121.544640 19.989822,120.770805 19.251404,119.724030 
	C22.302828,119.974792 25.117851,120.498489 27.932873,121.022186 
	C27.952003,122.731438 27.971134,124.440681 27.977770,126.577362 
z"/>
<path fill="#FDE22D" opacity="1.000000" stroke="none" 
	d="
M19.015003,119.996971 
	C19.989822,120.770805 20.964642,121.544640 21.976233,122.657837 
	C20.881758,123.087517 19.750509,123.177841 18.316362,123.143562 
	C17.689713,122.914879 17.424406,122.727890 17.225586,121.967545 
	C17.827421,120.983727 18.421213,120.490349 19.015003,119.996971 
z"/>
<path fill="#F9F32B" opacity="1.000000" stroke="none" 
	d="
M19.251408,119.724030 
	C18.421213,120.490349 17.827421,120.983727 17.114473,121.747818 
	C16.552261,122.017448 16.109203,122.016373 15.333844,122.019424 
	C14.997848,121.846764 14.994151,121.669968 14.973913,121.228020 
	C14.645447,120.203079 14.333523,119.443306 13.987358,118.376907 
	C16.442722,119.109146 17.145302,117.425407 17.869587,115.282906 
	C21.090147,114.983376 24.184507,114.983414 27.602762,115.004684 
	C27.944145,116.738403 27.961632,118.450890 27.955996,120.592789 
	C25.117851,120.498489 22.302828,119.974792 19.251408,119.724030 
z"/>
<path fill="#F9B022" opacity="1.000000" stroke="none" 
	d="
M227.066132,175.628021 
	C226.861084,177.220261 226.693527,178.408463 226.513977,179.681839 
	C222.980698,179.554535 223.704010,170.069107 218.047699,176.659790 
	C217.996765,177.003250 217.988907,177.011108 217.574402,177.008713 
	C215.160797,177.618240 213.161682,178.230179 210.721695,178.977051 
	C210.934021,179.281128 210.487808,178.642120 210.031891,177.752808 
	C210.016006,177.335587 210.009857,177.168701 210.256897,176.795105 
	C210.340912,175.056229 210.171753,173.524078 210.002594,171.991913 
	C214.019089,173.230850 216.150146,171.176147 216.576324,167.567535 
	C217.200607,162.281693 220.707382,161.428131 226.215393,160.982101 
	C226.519516,165.858276 226.811569,170.541138 227.066132,175.628021 
z"/>
<path fill="#F8C146" opacity="1.000000" stroke="none" 
	d="
M214.018707,156.019379 
	C214.475204,156.018784 214.931717,156.018204 215.731903,156.007141 
	C216.075592,155.996643 216.535141,155.995010 216.764832,155.997498 
	C216.328400,157.761017 215.662308,159.522034 214.587128,161.643143 
	C213.120834,162.004181 212.063614,162.005112 210.794037,161.741211 
	C209.718002,159.862900 208.854355,158.249466 207.990707,156.636017 
	C207.666138,155.905212 207.341583,155.174393 207.017014,154.443573 
	C206.008865,156.291626 205.079315,158.190063 203.946213,159.958054 
	C203.592072,160.510605 202.688507,160.711029 202.036453,161.072647 
	C202.030396,157.365250 202.024323,153.657867 202.032059,149.492981 
	C203.808517,150.003860 205.571182,150.972244 207.678589,151.975845 
	C209.241791,152.312317 210.566406,152.410919 211.646301,152.976685 
	C212.570145,153.460678 213.213379,154.480286 213.697754,155.662018 
	C213.095963,156.373093 212.777145,156.685120 212.458313,156.997162 
	C212.670639,157.179398 212.882950,157.361649 213.095245,157.543915 
	C213.403076,157.035736 213.710907,156.527557 214.018707,156.019379 
z"/>
<path fill="#F8BD44" opacity="1.000000" stroke="none" 
	d="
M209.880646,171.725967 
	C210.171753,173.524078 210.340912,175.056229 209.870850,176.792023 
	C207.166672,177.691681 205.101685,178.387695 203.000702,178.751984 
	C202.681671,175.417709 202.398621,172.415207 202.068405,168.912231 
	C203.829117,169.385971 204.923264,169.680359 206.345642,169.983841 
	C207.111542,169.994614 207.549210,169.996292 207.986862,169.997986 
	C208.577484,170.485336 209.168091,170.972672 209.880646,171.725967 
z"/>
<path fill="#FAC94D" opacity="1.000000" stroke="none" 
	d="
M208.139130,169.693817 
	C207.549210,169.996292 207.111542,169.994614 206.342224,169.612228 
	C207.675827,166.823029 209.341125,164.414551 211.006409,162.006058 
	C212.063614,162.005112 213.120834,162.004181 214.573334,162.009064 
	C212.742889,164.473129 210.517136,166.931381 208.139130,169.693817 
z"/>
<path fill="#F4A520" opacity="1.000000" stroke="none" 
	d="
M265.989532,125.343857 
	C266.566589,125.494614 267.239716,125.917747 267.945557,126.673225 
	C267.978333,127.005577 267.549561,127.005234 267.335358,126.995987 
	C266.775970,126.529915 266.430756,126.073074 265.989532,125.343857 
z"/>
<path fill="#F4A520" opacity="1.000000" stroke="none" 
	d="
M264.280701,125.984863 
	C264.220795,125.771538 264.419922,125.489426 264.873138,125.131294 
	C264.931427,125.342209 264.735565,125.629143 264.280701,125.984863 
z"/>
<path fill="#F9F32B" opacity="1.000000" stroke="none" 
	d="
M244.011414,49.708683 
	C243.047867,48.893875 242.141464,47.735867 241.157028,46.266876 
	C242.107651,45.983257 243.136337,46.010616 244.548813,46.044266 
	C244.644592,47.155537 244.356583,48.260509 244.011414,49.708683 
z"/>
<path fill="#F9F32B" opacity="1.000000" stroke="none" 
	d="
M237.224945,27.904388 
	C237.677994,27.887438 238.183304,28.186068 238.787872,28.768639 
	C238.350494,28.775042 237.813843,28.497505 237.224945,27.904388 
z"/>
<path fill="#F9F32B" opacity="1.000000" stroke="none" 
	d="
M240.941589,45.692318 
	C240.677307,45.896076 240.405380,45.739582 240.147675,45.206406 
	C240.405136,45.035370 240.662476,45.202816 240.941589,45.692318 
z"/>
<path fill="#F9C617" opacity="1.000000" stroke="none" 
	d="
M249.956604,56.709045 
	C249.703827,56.896992 249.471161,56.759270 249.341522,56.252434 
	C249.578445,56.153812 249.755829,56.320496 249.956604,56.709045 
z"/>
<path fill="#F9F32B" opacity="1.000000" stroke="none" 
	d="
M258.084717,49.307175 
	C258.345337,49.070312 258.627289,49.205109 258.887146,49.745132 
	C258.632629,49.921349 258.379639,49.764168 258.084717,49.307175 
z"/>
<path fill="#F9F32B" opacity="1.000000" stroke="none" 
	d="
M257.077667,48.239067 
	C257.343292,48.080036 257.594452,48.262482 257.821472,48.792194 
	C257.561951,48.935574 257.317261,48.749268 257.077667,48.239067 
z"/>
<path fill="#F6A92B" opacity="1.000000" stroke="none" 
	d="
M251.988007,73.753632 
	C251.714966,73.797386 251.448318,73.608284 251.101044,73.181419 
	C251.020416,72.943665 251.482605,72.988564 251.712112,73.023224 
	C251.941620,73.057884 251.994370,73.520775 251.988007,73.753632 
z"/>
<path fill="#F6A92B" opacity="1.000000" stroke="none" 
	d="
M249.917450,70.792130 
	C249.588669,70.944710 249.335022,70.769646 249.065613,70.274574 
	C248.980637,70.054817 249.436020,70.063988 249.662354,70.087547 
	C249.888702,70.111107 249.923386,70.564735 249.917450,70.792130 
z"/>
<path fill="#F9DD0F" opacity="1.000000" stroke="none" 
	d="
M72.058395,81.183441 
	C76.112793,78.876343 80.191490,76.610664 84.214783,74.250526 
	C88.250214,71.883263 92.230385,69.421783 96.644928,67.268097 
	C97.687691,68.010887 98.320312,68.487175 98.968567,69.294975 
	C98.993843,70.068855 99.003487,70.511223 98.696442,71.003983 
	C96.585045,72.700691 94.790352,74.347008 92.763680,75.849426 
	C92.035179,75.461868 91.373451,74.877159 91.066895,75.025650 
	C86.420097,77.276596 87.944359,81.673553 87.045044,85.737785 
	C89.230034,85.207756 90.863899,84.811417 92.497772,84.415077 
	C92.814667,84.865067 93.131561,85.315063 93.448448,85.765053 
	C91.537827,87.382050 89.627197,88.999039 87.994781,90.380585 
	C88.423332,91.540932 88.697800,92.284096 88.575706,93.018448 
	C87.119637,93.006439 86.060120,93.003227 84.658318,93.052612 
	C83.183342,94.089378 82.050659,95.073547 80.956223,96.035797 
	C80.994469,96.013863 80.948715,96.089241 80.899094,95.711960 
	C77.164574,92.090637 74.656227,94.718269 71.968643,97.372597 
	C71.247383,97.816055 70.653824,97.975517 69.869553,97.863251 
	C66.973618,97.156799 65.834396,98.380013 66.023270,101.015884 
	C66.005730,101.003929 66.025276,101.041405 65.679688,101.025902 
	C60.885212,100.674431 56.436329,100.338463 51.988495,99.622322 
	C52.690804,97.228714 53.392056,95.215286 54.093307,93.201851 
	C54.093307,93.201851 54.458668,93.006866 54.989525,92.995468 
	C55.983891,92.605431 56.447399,92.226799 56.910908,91.848175 
	C56.910908,91.848175 56.981136,91.973930 57.297466,91.968018 
	C58.389202,91.275322 59.164604,90.588539 59.940010,89.901749 
	C59.940010,89.901749 59.979080,89.979500 60.189472,89.901077 
	C60.588043,89.547607 60.776218,89.272560 60.964394,88.997513 
	C60.964394,88.997513 60.982666,88.980354 61.226040,88.913498 
	C61.777466,88.671783 61.939037,88.407394 61.954136,88.053474 
	C61.954136,88.053474 61.961723,87.974083 62.373665,88.001663 
	C63.840134,87.357132 64.894653,86.685028 65.949181,86.012917 
	C65.949181,86.012917 65.962708,85.954857 66.352875,85.987747 
	C68.514832,84.408241 70.286613,82.795837 72.058395,81.183441 
z"/>
<path fill="#FAC94D" opacity="1.000000" stroke="none" 
	d="
M191.109741,148.911621 
	C191.747681,150.155396 192.921570,151.392181 192.937912,152.644073 
	C193.057510,161.805283 192.944962,170.969543 192.590851,180.918640 
	C192.164001,185.139175 192.051956,188.573914 191.601715,192.039322 
	C190.179108,192.380402 189.094711,192.690811 188.014648,192.599884 
	C188.816330,189.156296 189.613647,186.114075 190.416855,183.049393 
	C186.138718,184.514725 186.590836,188.566177 185.672180,191.896591 
	C182.471085,189.630936 180.058167,186.549164 175.665909,187.575729 
	C175.013718,187.728149 173.902786,185.917770 172.711517,184.886337 
	C171.287170,184.171310 170.155365,183.585434 169.016052,182.750763 
	C169.008530,182.501999 168.993225,182.004456 168.890015,181.743164 
	C168.518738,180.989014 168.250656,180.496155 168.044067,179.644363 
	C171.146286,177.006683 175.832687,176.359634 176.070206,171.268997 
	C176.074768,171.170990 176.650360,171.099625 177.006516,171.374451 
	C178.502869,177.154861 182.517670,175.657623 186.025879,175.189301 
	C186.025879,173.740097 186.025879,172.369751 186.328186,170.843079 
	C187.897873,167.788086 192.980927,167.148712 191.848114,163.182098 
	C190.255005,163.106079 188.403229,163.298874 188.305817,162.970795 
	C186.889191,158.199448 183.689697,160.612991 180.994659,160.675583 
	C180.993851,159.896790 180.998138,159.451752 181.333237,159.000793 
	C182.311447,158.659012 182.958832,158.323135 183.606232,157.987259 
	C183.419937,157.687973 183.233658,157.388672 183.047363,157.089371 
	C182.031250,157.724838 181.015121,158.360306 179.667145,158.999969 
	C178.892792,159.007004 178.450287,159.009857 177.788971,158.902130 
	C177.294617,158.593582 177.106369,158.332626 177.207718,157.744232 
	C178.958771,156.645798 180.507523,155.811798 182.437714,154.989685 
	C187.557343,156.086334 189.592056,154.627609 190.999115,149.112335 
	C191.047791,149.052658 191.109741,148.911621 191.109741,148.911621 
z"/>
<path fill="#FBB03A" opacity="1.000000" stroke="none" 
	d="
M157.041245,225.081573 
	C153.279739,227.386475 149.518234,229.691376 145.216431,231.764435 
	C142.960953,228.854172 140.446960,229.649811 137.889557,229.770294 
	C137.815689,228.371201 137.886444,227.207214 137.975647,226.024551 
	C137.994080,226.005875 138.030731,225.968323 138.308853,226.076843 
	C139.398224,224.798126 140.209488,223.410858 141.389069,222.016907 
	C143.083618,222.497360 144.409836,222.984512 145.818939,223.502121 
	C146.454163,222.382629 147.384872,220.742416 148.459305,218.848877 
	C146.952454,218.003189 145.518753,217.198563 143.275543,215.939636 
	C143.603424,217.946014 143.755630,218.877426 143.942917,220.023544 
	C142.933670,220.023544 141.959656,220.023544 140.610626,219.973419 
	C136.998932,218.879272 133.942490,217.778305 130.451492,219.829224 
	C129.217529,220.554153 126.849609,219.348923 124.680649,219.039551 
	C123.576294,219.368149 122.790688,219.682068 121.905029,219.734680 
	C122.545525,218.669693 123.286079,217.866028 124.403313,217.047607 
	C126.531380,216.343109 128.282776,215.653397 130.280548,214.793304 
	C132.094879,212.537903 131.310989,211.827271 128.665680,212.006256 
	C127.886597,212.000824 127.441399,211.998505 126.998108,211.998108 
	C127.000000,212.000000 127.003738,212.003510 126.893723,211.779022 
	C126.517792,211.359406 126.251877,211.164261 126.057617,210.613724 
	C128.761703,208.507202 131.394135,206.756058 134.021729,205.398605 
	C133.879089,207.665787 133.741302,209.539276 133.585693,211.655228 
	C135.908997,210.411972 137.897003,209.348129 139.884995,208.284286 
	C139.826401,208.767624 139.767792,209.250977 139.709198,209.734314 
	C142.166351,210.463104 144.623505,211.191910 147.070496,211.928635 
	C147.060349,211.936554 147.077942,211.904114 147.096741,212.258453 
	C148.074539,213.089554 149.033524,213.566330 150.137985,214.360306 
	C151.092209,215.740768 151.748734,217.527740 152.737396,217.735687 
	C156.108521,218.444748 159.596619,218.597672 163.286926,219.148010 
	C163.706696,219.918259 163.879639,220.503342 164.052567,221.088425 
	C164.052567,221.088425 164.009628,221.024048 163.826508,221.094391 
	C163.357559,221.312332 163.152054,221.534302 163.026886,221.830627 
	C163.026886,221.830627 162.554871,221.985092 162.105164,221.770599 
	C159.643799,222.111450 155.894455,220.392441 157.041245,225.081573 
z"/>
<path fill="#F9E90F" opacity="1.000000" stroke="none" 
	d="
M69.002029,60.995388 
	C70.970055,58.889172 72.938072,56.782951 74.956429,54.345665 
	C78.445183,53.076664 81.883606,52.138737 86.906975,50.768471 
	C85.384254,53.859814 84.554573,55.544174 83.257088,58.178253 
	C85.614792,57.742409 88.273582,58.054546 89.264267,56.915661 
	C92.127205,53.624432 95.607513,53.936237 99.653244,54.033779 
	C94.364021,57.911926 88.707726,61.896107 82.885056,65.620171 
	C74.231606,71.154739 65.460480,76.505325 56.332832,81.675934 
	C55.937565,79.931801 55.365871,78.019821 56.077675,77.045937 
	C57.724434,74.792831 59.992649,72.993927 62.365089,71.004654 
	C64.153549,69.998085 65.585777,68.997688 67.336494,67.955734 
	C68.434624,66.946983 69.214264,65.979790 70.327408,64.865143 
	C73.931839,62.762726 79.527710,63.528427 79.111031,57.196774 
	C76.583138,58.200783 74.549355,59.841331 72.383553,61.283661 
	C71.325188,61.988487 69.817680,64.466232 69.002029,60.995388 
z"/>
<path fill="#ED9F31" opacity="1.000000" stroke="none" 
	d="
M173.004089,185.015472 
	C173.902786,185.917770 175.013718,187.728149 175.665909,187.575729 
	C180.058167,186.549164 182.471085,189.630936 185.680939,192.130127 
	C185.988205,192.514175 186.008820,192.982071 185.655426,193.141342 
	C183.550262,193.808182 181.798492,194.315750 180.046707,194.823334 
	C180.096375,195.310440 180.146027,195.797562 180.195694,196.284683 
	C183.764297,197.324387 187.367523,197.613235 191.289505,196.070770 
	C191.934662,196.800674 192.352295,197.382462 192.769943,197.964249 
	C192.074570,200.188171 191.379196,202.412094 190.330124,204.316071 
	C190.270508,201.114349 188.821335,201.516403 186.679932,201.782227 
	C184.828140,202.012115 182.831512,201.075211 180.356857,200.535950 
	C178.748245,203.729752 174.635071,202.701416 170.990082,202.671570 
	C167.837357,197.697342 165.687424,192.786453 167.359253,186.984680 
	C169.464127,186.334808 171.234100,185.675140 173.004089,185.015472 
z"/>
<path fill="#F8E509" opacity="1.000000" stroke="none" 
	d="
M163.662308,57.948990 
	C163.545990,58.637093 163.096252,59.273354 162.270111,59.811344 
	C161.805374,59.664928 161.717056,59.616783 161.628738,59.568642 
	C161.749237,59.715019 161.869736,59.861401 161.990234,60.007782 
	C160.340881,63.463268 158.691528,66.918755 156.993378,70.741898 
	C155.222855,70.160233 153.501129,69.210915 151.323227,67.800804 
	C148.912003,66.218529 146.956955,65.097031 145.001923,63.975540 
	C145.001923,63.975540 144.995331,64.001480 144.899689,63.693504 
	C144.186234,63.326073 143.568420,63.266624 142.950592,63.207172 
	C142.950592,63.207172 142.910278,63.030037 142.945618,62.946632 
	C145.050629,58.260441 150.106964,60.044113 153.373459,58.001743 
	C154.057816,57.573864 154.731476,57.128876 155.410049,56.691765 
	C155.160858,56.500896 154.911682,56.310032 154.340485,56.072586 
	C154.013596,55.855148 154.008743,55.684303 154.001038,55.257111 
	C154.846970,53.590908 155.445755,51.909195 156.608093,50.840298 
	C158.047226,49.516899 159.953735,48.701752 161.593903,47.706123 
	C162.160782,51.035843 162.744827,54.466496 163.662308,57.948990 
z"/>
<path fill="#F0C81B" opacity="1.000000" stroke="none" 
	d="
M159.982178,83.143402 
	C163.077744,85.016426 166.173325,86.889442 169.740845,89.228554 
	C172.266220,93.657776 176.372925,93.909935 179.965729,95.090897 
	C179.965729,95.090897 179.995285,95.020622 180.064453,95.321045 
	C180.697464,95.782928 181.261292,95.944405 181.825119,96.105865 
	C182.322540,96.411568 182.819946,96.717270 183.708542,97.310104 
	C184.391830,97.757210 184.683929,97.917175 184.976028,98.077141 
	C184.976028,98.077141 184.991364,98.009354 185.035156,98.279709 
	C185.403030,98.638626 185.727127,98.727188 186.051224,98.815750 
	C189.609344,101.397728 192.982895,104.084732 191.618637,109.618256 
	C190.499512,109.649475 189.753647,109.317825 188.996078,108.607834 
	C188.330292,104.320976 186.866394,101.646889 182.258499,103.912704 
	C181.673981,101.179108 175.976364,98.524422 173.036743,99.534645 
	C172.969666,99.557709 172.993713,99.845917 172.761902,100.116760 
	C172.285934,100.421928 172.104172,100.678162 171.703094,100.993896 
	C171.003952,100.997543 170.605011,100.999077 170.153549,100.617615 
	C172.105957,96.983871 170.555389,95.647736 167.394989,94.986427 
	C164.572525,94.395851 161.791672,93.606529 159.040527,92.569382 
	C160.039688,91.047104 160.990784,89.860352 162.095322,88.482124 
	C161.545975,87.094223 160.764069,85.118813 159.982178,83.143402 
z"/>
<path fill="#F9E90F" opacity="1.000000" stroke="none" 
	d="
M113.993752,55.986267 
	C115.268318,55.831566 116.954056,55.082542 117.743660,55.628815 
	C120.603050,57.607025 123.161560,60.020138 125.631767,62.620144 
	C124.610001,63.318039 123.790077,63.664921 122.739731,64.010941 
	C122.509308,64.010078 122.048515,64.001938 121.679794,64.060753 
	C120.189346,67.414536 119.067627,70.709511 117.699020,74.113510 
	C116.980530,74.482590 116.508926,74.742630 115.964310,74.645157 
	C111.756096,72.639046 105.695190,74.686882 101.664078,78.988785 
	C100.892021,79.000031 100.450836,79.005363 99.663231,78.953995 
	C98.872986,77.924950 98.429153,76.952599 98.009201,75.687683 
	C98.342354,74.930618 98.651634,74.466133 99.300613,74.001640 
	C100.093231,74.001495 100.546158,74.001358 101.386200,73.999023 
	C103.528542,72.989372 105.283775,71.981926 107.406967,70.972076 
	C110.259171,70.556366 112.157608,69.750198 111.348923,66.570663 
	C110.549423,63.427254 112.645920,63.033722 115.053177,63.138580 
	C115.192589,63.324165 115.277420,63.372990 115.362251,63.421814 
	C115.242126,63.280724 115.121994,63.139637 114.933044,62.633873 
	C113.373550,60.728733 111.882866,59.188263 110.392181,57.647797 
	C110.392181,57.647797 110.497414,57.498646 110.915764,57.642319 
	C112.220665,57.186085 113.107208,56.586178 113.993752,55.986267 
z"/>
<path fill="#F8CF20" opacity="1.000000" stroke="none" 
	d="
M192.021881,116.882561 
	C192.024063,120.344055 192.026230,123.805550 191.625397,127.691582 
	C189.171280,130.760269 187.099182,129.155502 185.156052,128.036041 
	C182.244781,126.358841 180.672333,127.263153 179.419647,130.225159 
	C178.676254,131.982895 177.152145,133.410446 175.945129,134.679138 
	C175.267899,133.603302 174.624649,132.834747 173.991196,131.654907 
	C174.346283,129.149872 174.691559,127.056114 175.337769,125.013596 
	C176.501831,125.590370 177.364990,126.115913 178.621658,126.881035 
	C178.768509,124.582703 178.883026,122.790276 179.363571,120.925964 
	C180.147888,119.262665 180.566193,117.671242 180.991959,115.730545 
	C181.005646,114.915520 181.011871,114.449768 181.354965,113.988235 
	C182.141754,113.992538 182.591675,113.992622 183.112183,114.370132 
	C186.129150,115.459221 189.075516,116.170891 192.021881,116.882561 
z"/>
<path fill="#F6A92B" opacity="1.000000" stroke="none" 
	d="
M170.984741,202.995346 
	C174.635071,202.701416 178.748245,203.729752 180.356857,200.535950 
	C182.831512,201.075211 184.828140,202.012115 186.679932,201.782227 
	C188.821335,201.516403 190.270508,201.114349 189.975006,204.355377 
	C189.814651,204.792480 189.655746,204.870361 189.042084,205.061066 
	C188.322495,205.310120 188.178192,205.526672 188.154388,205.823532 
	C188.154388,205.823532 188.094666,206.036133 187.651337,206.028000 
	C186.481049,206.647141 185.754089,207.274414 185.027130,207.901672 
	C185.027115,207.901672 185.014053,208.024353 184.600891,207.960846 
	C182.820572,207.325058 181.438751,206.279251 180.090576,206.320999 
	C178.876694,206.358582 177.699722,207.588501 176.506470,208.292542 
	C177.051392,208.887390 177.596298,209.482239 178.141205,210.077087 
	C175.650787,211.992249 173.160355,213.907394 170.298660,215.601471 
	C167.729477,212.184906 163.891357,209.766586 165.330780,204.859833 
	C167.430099,204.144806 169.207413,203.570068 170.984741,202.995346 
z"/>
<path fill="#F5AA41" opacity="1.000000" stroke="none" 
	d="
M88.945587,235.096924 
	C84.034195,231.979721 79.122810,228.862503 74.066254,225.074020 
	C73.620346,223.262726 73.319611,222.122681 73.371613,220.960922 
	C76.481407,218.626266 79.238457,216.313354 82.247620,214.000214 
	C82.667801,214.000000 82.835876,214.000000 83.034286,214.303192 
	C84.039055,216.069351 85.013481,217.532318 86.033691,219.325653 
	C86.722298,220.767288 87.365128,221.878571 87.866920,223.322876 
	C87.193367,225.230835 86.660835,226.805740 85.909355,229.028183 
	C88.488785,229.028183 90.226944,229.028183 91.978127,229.357956 
	C92.001320,230.128036 92.011490,230.568329 91.825180,231.278061 
	C90.734322,232.730637 89.839951,233.913773 88.945587,235.096924 
z"/>
<path fill="#F2BC69" opacity="1.000000" stroke="none" 
	d="
M202.949158,198.049927 
	C202.949158,198.049927 202.958862,198.511307 203.081116,198.974518 
	C203.479309,199.629456 203.755234,199.821182 204.031174,200.012909 
	C204.487946,200.485550 205.111694,201.447739 205.376450,201.357437 
	C209.377319,199.992859 211.247559,202.907532 213.491379,205.178741 
	C213.917145,205.609741 214.683777,205.704010 215.615829,206.316666 
	C214.260391,206.987717 212.582245,207.295731 210.904114,207.603745 
	C210.543289,207.099747 210.182480,206.595734 209.821655,206.091721 
	C209.206406,207.770874 208.591141,209.450027 207.594879,211.121552 
	C205.802414,211.366806 204.390945,211.619705 202.599274,211.889252 
	C198.816391,213.271805 195.413712,214.637711 191.765472,215.905334 
	C191.054413,215.560059 190.588913,215.313049 190.123383,215.066040 
	C191.507248,213.476013 192.675781,210.817566 194.313553,210.487503 
	C200.276733,209.285721 202.043198,205.994446 201.022003,200.375977 
	C200.736176,198.803299 201.107040,197.111282 201.573364,195.256546 
	C201.970093,195.039093 202.100418,195.150345 202.066010,195.471222 
	C202.337448,196.544708 202.643311,197.297318 202.949158,198.049927 
z"/>
<path fill="#F0C81B" opacity="1.000000" stroke="none" 
	d="
M197.010544,93.283478 
	C201.960617,94.779358 206.903809,96.515350 211.771149,98.934280 
	C209.960938,100.881584 208.226578,102.145943 206.492218,103.410301 
	C206.867950,103.859398 207.243668,104.308502 207.619400,104.757599 
	C209.075455,104.863785 210.531509,104.969971 211.987564,105.076157 
	C211.994278,105.502815 212.000992,105.929482 211.835541,106.934113 
	C210.765518,108.348503 209.867691,109.184914 208.969849,110.021324 
	C207.982574,111.767700 206.995316,113.514076 205.646759,115.571335 
	C204.343475,113.286972 203.329620,110.714836 202.485672,108.088112 
	C201.953262,106.431023 201.674866,104.692337 201.282532,102.990250 
	C201.898544,98.393517 197.829041,97.710022 195.106903,95.465607 
	C195.430389,94.791824 195.904099,94.428215 196.741882,94.168518 
	C197.190567,94.322144 197.275162,94.371841 197.359772,94.421539 
	C197.238159,94.282333 197.116562,94.143127 196.994949,94.003922 
	C196.994949,94.003922 197.003677,93.523590 197.010544,93.283478 
M204.420395,104.486710 
	C204.420395,104.486710 204.525955,104.424057 204.420395,104.486710 
z"/>
<path fill="#FDF30F" opacity="1.000000" stroke="none" 
	d="
M159.668442,82.992546 
	C160.764069,85.118813 161.545975,87.094223 162.095322,88.482124 
	C160.990784,89.860352 160.039688,91.047104 158.648575,92.638199 
	C157.148270,93.056847 156.087997,93.071144 154.879608,92.766243 
	C151.822144,90.298141 148.912766,88.149231 146.042267,85.634781 
	C146.371323,83.842506 146.661514,82.415779 147.286072,80.843376 
	C149.764023,80.412445 151.907593,80.127174 154.051147,79.841904 
	C155.819000,80.841835 157.586853,81.841759 159.668442,82.992546 
z"/>
<path fill="#F8EA23" opacity="1.000000" stroke="none" 
	d="
M110.096695,57.794647 
	C111.882866,59.188263 113.373550,60.728733 114.932114,62.634598 
	C115.000000,63.000000 114.998589,63.001823 114.998589,63.001823 
	C112.645920,63.033722 110.549423,63.427254 111.348923,66.570663 
	C112.157608,69.750198 110.259171,70.556366 107.174164,70.691345 
	C101.470329,68.056664 101.160049,68.256386 100.999084,74.001221 
	C100.546158,74.001358 100.093231,74.001495 99.315536,73.621231 
	C98.998230,72.478416 99.005676,71.716003 99.013130,70.953590 
	C99.003487,70.511223 98.993843,70.068855 98.952957,68.973190 
	C98.583092,67.534348 98.244484,66.748810 97.905876,65.963264 
	C101.870987,63.289341 105.836098,60.615417 110.096695,57.794647 
z"/>
<path fill="#F19E2E" opacity="1.000000" stroke="none" 
	d="
M70.885323,223.062210 
	C67.846558,221.478363 64.742218,220.006638 61.787529,218.278992 
	C58.854118,216.563766 56.071297,214.591019 53.057919,212.102509 
	C52.595753,209.982117 52.298096,208.490448 52.249420,206.998520 
	C52.498405,206.998260 52.996376,206.997147 53.317570,207.142593 
	C57.115513,209.937927 60.394581,213.908905 65.319260,209.033112 
	C66.086060,209.700134 66.537689,210.322754 66.982117,211.291626 
	C66.977760,212.099304 66.980614,212.560760 66.948990,213.370239 
	C65.595497,216.172424 66.975281,217.112427 69.063538,217.974075 
	C71.367958,218.924911 72.970604,220.407242 70.885323,223.062210 
z"/>
<path fill="#FAE406" opacity="1.000000" stroke="none" 
	d="
M62.008850,71.010834 
	C59.992649,72.993927 57.724434,74.792831 56.077675,77.045937 
	C55.365871,78.019821 55.937565,79.931801 55.973358,81.738396 
	C54.920750,82.709724 53.821011,83.360313 52.016350,84.018112 
	C48.880672,83.336311 46.449924,82.647293 44.019176,81.958275 
	C42.497383,81.486763 40.975590,81.015259 39.475388,79.995972 
	C44.697262,78.284523 50.987988,78.482689 50.285580,71.416817 
	C54.532955,71.269730 58.270905,71.140282 62.008850,71.010834 
z"/>
<path fill="#F4B95F" opacity="1.000000" stroke="none" 
	d="
M135.331558,265.965027 
	C134.987244,266.039642 134.523895,265.981628 134.107697,265.692657 
	C132.843765,265.280609 131.996033,265.157562 130.950058,264.869324 
	C130.416885,263.096710 130.610992,260.679504 129.640228,260.045288 
	C127.414154,258.590912 124.619865,258.006195 122.056267,257.068420 
	C122.056267,257.068420 121.988930,256.969238 121.950119,256.923523 
	C123.007591,256.245392 124.103867,255.612930 125.862610,255.115982 
	C128.339615,255.853607 130.154144,256.455719 132.143158,257.358459 
	C133.925858,257.781738 135.624283,258.294128 137.123779,257.947083 
	C140.387939,257.191589 143.550690,255.997986 146.817413,254.951981 
	C146.879959,254.920517 147.004028,254.985397 147.023544,255.313293 
	C147.329971,256.464966 147.616882,257.288757 147.903793,258.112549 
	C144.994186,259.643982 142.021927,261.070404 139.202515,262.752991 
	C137.875504,263.544983 136.842255,264.829224 135.331558,265.965027 
z"/>
<path fill="#F4B92B" opacity="1.000000" stroke="none" 
	d="
M175.979065,134.986450 
	C177.152145,133.410446 178.676254,131.982895 179.419647,130.225159 
	C180.672333,127.263153 182.244781,126.358841 185.156052,128.036041 
	C187.099182,129.155502 189.171280,130.760269 191.545593,128.058746 
	C192.211487,128.484055 192.554199,128.966751 192.615707,130.033401 
	C192.158157,132.701843 191.981796,134.786331 191.841125,136.449051 
	C189.497406,136.642456 187.252304,136.827713 184.830078,136.760483 
	C184.068466,136.369522 183.483978,136.231064 182.639130,135.902649 
	C180.260239,135.871033 178.141678,136.029358 176.006042,135.887589 
	C175.985657,135.387146 175.982361,135.186798 175.979065,134.986450 
z"/>
<path fill="#391E3B" opacity="1.000000" stroke="none" 
	d="
M36.823990,201.922821 
	C36.549622,200.003204 36.039978,198.084015 36.036438,196.163910 
	C35.988918,170.408707 36.002968,144.653366 36.026020,118.898094 
	C36.030140,114.295479 36.144112,109.692970 36.608215,105.491943 
	C37.106709,118.269646 37.204357,130.645828 37.249840,143.339264 
	C37.207165,144.086227 37.216652,144.515945 37.121250,145.410553 
	C37.063934,153.598083 37.111511,161.320709 37.087372,169.504364 
	C36.951771,180.617859 36.887882,191.270340 36.823990,201.922821 
z"/>
<path fill="#F6CC2A" opacity="1.000000" stroke="none" 
	d="
M53.736561,93.215958 
	C53.392056,95.215286 52.690804,97.228714 51.673134,99.758224 
	C50.347134,100.727592 49.337551,101.180893 48.270248,101.660110 
	C48.526012,102.861153 48.757889,103.949997 48.731018,105.253113 
	C46.675808,106.988724 44.879349,108.510063 43.055145,109.689415 
	C43.733109,106.704178 42.978302,105.076935 39.993439,104.738647 
	C39.976063,104.309769 39.965694,104.138367 39.977005,103.642349 
	C40.008884,102.884361 40.019081,102.450989 40.029278,102.017616 
	C40.029278,102.017616 40.000126,101.998062 40.265160,101.951935 
	C40.717888,101.616768 40.905586,101.327728 41.093285,101.038681 
	C41.093285,101.038681 41.020832,101.018021 41.278625,100.993225 
	C41.715790,100.690338 41.895157,100.412239 42.074524,100.134148 
	C42.074524,100.134148 42.546600,100.016647 43.032692,99.952560 
	C43.717117,99.612343 43.915455,99.336205 44.113792,99.060066 
	C44.113792,99.060066 44.035763,99.029694 44.282112,99.015259 
	C44.870693,98.816383 44.980331,98.539909 44.857368,98.171402 
	C47.698185,96.524284 50.538998,94.877174 53.736561,93.215958 
z"/>
<path fill="#F4A520" opacity="1.000000" stroke="none" 
	d="
M89.083076,235.414169 
	C89.839951,233.913773 90.734322,232.730637 92.170113,231.245377 
	C97.106102,229.421219 98.034668,232.402725 98.477684,235.433426 
	C100.538315,236.017960 102.264160,236.507507 104.102829,237.221191 
	C104.484329,237.637772 104.753021,237.830215 104.995911,238.316193 
	C104.658424,239.075989 104.346725,239.542267 103.649368,240.004822 
	C102.165634,240.697815 101.067566,241.394547 99.969513,242.091278 
	C96.386536,239.971329 92.803551,237.851364 89.083076,235.414169 
z"/>
<path fill="#F7B94A" opacity="1.000000" stroke="none" 
	d="
M210.794037,161.741211 
	C209.341125,164.414551 207.675827,166.823029 206.013977,169.603134 
	C204.923264,169.680359 203.829117,169.385971 202.068405,168.912231 
	C202.398621,172.415207 202.681671,175.417709 202.858551,178.968536 
	C202.274078,179.686356 201.795761,179.855850 201.317444,180.025345 
	C201.231308,174.005783 201.145172,167.986237 201.116135,161.506836 
	C201.173218,161.046997 201.604401,161.053757 201.820435,161.063202 
	C202.688507,160.711029 203.592072,160.510605 203.946213,159.958054 
	C205.079315,158.190063 206.008865,156.291626 207.017014,154.443573 
	C207.341583,155.174393 207.666138,155.905212 207.990707,156.636017 
	C208.854355,158.249466 209.718002,159.862900 210.794037,161.741211 
z"/>
<path fill="#F5AA41" opacity="1.000000" stroke="none" 
	d="
M52.000439,206.998779 
	C52.298096,208.490448 52.595753,209.982117 52.898808,211.798508 
	C50.316788,210.630310 47.729374,209.137405 44.957375,207.072464 
	C45.513779,205.340775 46.254768,204.181122 47.035408,202.651779 
	C47.873703,200.254135 48.672348,198.226151 48.997257,197.401123 
	C52.080517,197.401123 53.977467,197.248962 55.828613,197.473816 
	C56.601131,197.567657 57.276245,198.463409 57.679718,199.150970 
	C54.335857,201.006424 51.139458,202.587952 52.000439,206.998779 
z"/>
<path fill="#F1A03A" opacity="1.000000" stroke="none" 
	d="
M138.034164,230.005402 
	C140.446960,229.649811 142.960953,228.854172 144.857193,231.816803 
	C142.418228,233.869598 139.798203,235.638184 136.626160,237.694290 
	C134.054062,237.320526 132.033966,236.659225 129.697189,235.970245 
	C129.041260,235.242004 128.701996,234.541397 128.362747,233.840790 
	C130.523834,232.876923 132.684921,231.913055 135.042007,231.114548 
	C136.170044,230.855087 137.102112,230.430252 138.034164,230.005402 
z"/>
<path fill="#F59C1A" opacity="1.000000" stroke="none" 
	d="
M201.898376,194.992218 
	C201.574463,192.595673 201.322281,190.246017 201.348389,187.459869 
	C201.626694,187.023407 202.016846,186.949158 202.325897,186.836395 
	C205.591110,185.320923 208.434372,183.302795 211.962952,185.417770 
	C212.383881,189.870514 210.579742,192.422897 206.740952,193.626999 
	C205.187653,194.114197 203.646881,194.641357 202.100418,195.150345 
	C202.100418,195.150345 201.970093,195.039093 201.898376,194.992218 
z"/>
<path fill="#FCD9BA" opacity="1.000000" stroke="none" 
	d="
M157.981079,240.017288 
	C157.179718,241.566681 156.378372,243.116089 155.215668,244.444122 
	C151.956879,243.703720 149.059433,243.184708 146.162018,242.665710 
	C148.037674,241.064880 149.767319,239.237000 151.822510,237.915329 
	C155.074661,235.823883 158.544876,234.071533 162.362396,232.484695 
	C162.889511,233.191422 162.977066,233.589142 163.064606,233.986877 
	C161.582214,235.896896 160.099823,237.806931 158.246460,239.682098 
	C157.750046,239.611725 157.624619,239.576233 157.499191,239.540741 
	C157.659821,239.699585 157.820450,239.858429 157.981079,240.017288 
z"/>
<path fill="#F3B259" opacity="1.000000" stroke="none" 
	d="
M46.995754,203.021454 
	C46.254768,204.181122 45.513779,205.340775 44.820293,206.770813 
	C42.295082,205.549515 39.722374,204.057846 36.986832,202.244507 
	C36.887882,191.270340 36.951771,180.617859 37.320061,169.731766 
	C38.079079,170.666916 38.533699,171.835663 38.835598,173.390320 
	C38.466557,178.531357 38.250237,183.286499 38.068237,188.423615 
	C38.102558,193.133789 38.102558,197.461990 38.102558,201.790207 
	C38.823673,201.829742 39.544788,201.869293 40.265903,201.908829 
	C40.516037,200.328018 40.616932,198.699814 41.095936,197.191696 
	C41.267319,196.652130 42.352562,196.402802 43.165413,196.366180 
	C44.798332,198.663071 42.664433,202.730377 46.995754,203.021454 
z"/>
<path fill="#F3B259" opacity="1.000000" stroke="none" 
	d="
M165.008804,205.000122 
	C163.891357,209.766586 167.729477,212.184906 169.972610,215.736084 
	C168.261475,217.727829 166.505112,219.363922 164.400665,221.044220 
	C163.879639,220.503342 163.706696,219.918259 163.277924,218.766327 
	C163.022079,216.848770 163.022079,215.498062 163.022079,214.043121 
	C158.417313,214.043121 154.204910,214.043121 149.992523,214.043121 
	C149.033524,213.566330 148.074539,213.089554 147.518707,212.283264 
	C153.225967,213.305756 158.500198,213.769409 163.961594,211.891617 
	C163.222672,209.966278 162.675949,208.541763 162.354752,206.893967 
	C163.389771,206.113815 164.199280,205.556961 165.008804,205.000122 
z"/>
<path fill="#F6D371" opacity="1.000000" stroke="none" 
	d="
M189.908188,215.367264 
	C190.588913,215.313049 191.054413,215.560059 191.682434,216.270264 
	C190.919769,218.766296 189.994568,220.799149 189.097809,222.769547 
	C186.871811,222.958755 184.808014,223.134186 182.457855,223.333939 
	C182.652390,225.898788 182.807617,227.945282 182.962830,229.991791 
	C182.962830,229.991791 182.465958,229.984085 181.939301,229.749649 
	C181.219055,228.049103 181.025467,226.582977 180.697388,224.805695 
	C180.018951,223.072159 179.475006,221.649826 178.931061,220.227478 
	C182.518372,218.707794 186.105682,217.188141 189.908188,215.367264 
z"/>
<path fill="#E8AF22" opacity="1.000000" stroke="none" 
	d="
M185.007202,137.012970 
	C187.252304,136.827713 189.497406,136.642456 191.841125,136.449051 
	C191.981796,134.786331 192.158157,132.701843 192.577484,130.329193 
	C192.232010,136.059998 191.643570,142.078949 191.082428,148.504761 
	C191.109741,148.911621 191.047791,149.052658 190.753113,149.218918 
	C187.522537,149.192810 186.959793,147.332474 188.392670,142.548981 
	C186.885834,142.029678 185.377808,141.513687 183.889771,140.624115 
	C183.916245,139.502930 183.922745,138.755310 184.163986,137.908432 
	C184.601562,137.543777 184.804382,137.278381 185.007202,137.012970 
z"/>
<path fill="#EF8C29" opacity="1.000000" stroke="none" 
	d="
M106.917023,246.124573 
	C106.158310,245.749390 105.399590,245.374207 104.379471,244.563675 
	C106.717651,239.510513 111.193832,241.425064 115.142761,241.292786 
	C117.526543,242.388779 119.766205,243.194504 121.773087,244.275482 
	C118.345581,246.035736 115.150856,247.520737 111.956131,249.005768 
	C111.956139,249.005768 111.475899,248.950439 111.249687,248.475372 
	C110.882034,246.809601 110.740585,245.618912 110.535454,243.892120 
	C108.999718,244.839615 107.958374,245.482101 106.917023,246.124573 
z"/>
<path fill="#F5AC4C" opacity="1.000000" stroke="none" 
	d="
M145.795013,242.787750 
	C149.059433,243.184708 151.956879,243.703720 154.921356,244.617828 
	C154.988419,245.012909 155.003342,244.996765 154.626343,245.037323 
	C149.698975,244.489212 150.740356,249.634216 147.824310,250.918106 
	C145.348969,250.181305 143.184830,249.566818 140.892227,248.674408 
	C140.479675,247.855560 140.195557,247.314651 139.911469,246.773743 
	C141.750320,245.485748 143.589172,244.197769 145.795013,242.787750 
z"/>
<path fill="#FDF30F" opacity="1.000000" stroke="none" 
	d="
M122.048515,64.001938 
	C122.048515,64.001938 122.509308,64.010078 122.895691,64.310699 
	C125.408485,67.727364 127.719322,66.817970 130.075333,64.922554 
	C132.327011,66.067497 134.578674,67.212448 136.745392,68.924103 
	C134.990601,70.870285 133.815796,72.864403 130.896255,70.854805 
	C129.737350,70.057091 127.010674,71.537056 124.667984,71.981323 
	C123.898453,71.980186 123.458694,71.977776 122.990044,71.680367 
	C122.631416,70.931053 122.301682,70.476753 121.704987,69.795792 
	C121.641533,67.713409 121.845024,65.857674 122.048515,64.001938 
z"/>
<path fill="#EC9542" opacity="1.000000" stroke="none" 
	d="
M112.112167,249.337280 
	C115.150856,247.520737 118.345581,246.035736 121.780685,243.910736 
	C123.019287,241.841537 124.017502,240.412323 125.346848,238.993301 
	C126.119858,239.001129 126.561722,238.998764 127.074097,239.352493 
	C128.685928,240.149170 130.227264,240.589767 131.828552,241.248962 
	C131.888519,241.467560 131.865128,241.920319 131.865128,241.920319 
	C131.865128,241.920319 131.419800,242.004639 130.954376,242.075958 
	C130.303391,242.421738 130.117844,242.696198 129.932312,242.970657 
	C129.932312,242.970657 129.992828,242.996826 129.731216,243.039062 
	C129.277756,243.364120 129.085907,243.646927 128.894073,243.929749 
	C128.894073,243.929749 128.432724,244.012970 127.956100,244.083496 
	C127.259712,244.413589 127.039948,244.673141 126.820175,244.932693 
	C126.820175,244.932693 126.386734,244.964127 125.865677,245.005219 
	C124.551559,245.685806 123.758499,246.325287 122.965439,246.964783 
	C122.965446,246.964783 122.997612,247.001221 122.738380,247.055283 
	C122.315025,247.398041 122.150909,247.686737 121.986786,247.975433 
	C121.986786,247.975433 121.998169,247.997559 121.730972,248.059189 
	C121.259209,248.398422 121.054634,248.676041 120.850067,248.953659 
	C120.850067,248.953659 120.418915,249.021988 119.957657,249.101410 
	C119.273102,249.440247 119.049812,249.699677 118.826523,249.959106 
	C116.640419,249.862350 114.454308,249.765579 112.112167,249.337280 
z"/>
<path fill="#F9EE32" opacity="1.000000" stroke="none" 
	d="
M43.912430,82.230377 
	C46.449924,82.647293 48.880672,83.336311 51.677345,84.059586 
	C48.680141,87.813217 45.483463,91.789024 39.453827,92.058304 
	C40.346268,89.652115 41.633808,87.196594 42.955486,84.397003 
	C43.261646,83.536118 43.533661,83.019302 43.912430,82.230377 
z"/>
<path fill="#FAE406" opacity="1.000000" stroke="none" 
	d="
M124.997765,71.980049 
	C127.010674,71.537056 129.737350,70.057091 130.896255,70.854805 
	C133.815796,72.864403 134.990601,70.870285 136.853607,69.205444 
	C137.046753,68.920090 137.527054,68.986092 137.810562,69.367676 
	C138.546799,69.892693 138.999512,70.036125 139.452240,70.179550 
	C139.452240,70.179550 139.676666,70.329659 139.539886,70.763931 
	C139.797302,72.540817 140.191498,73.883423 140.823013,76.034210 
	C138.411133,76.034210 136.237228,76.034210 133.743958,75.897118 
	C130.621063,74.844254 127.817528,73.928482 125.010880,72.754532 
	C125.004425,72.324249 125.001091,72.152145 124.997765,71.980049 
z"/>
<path fill="#E98C1E" opacity="1.000000" stroke="none" 
	d="
M88.038902,258.965820 
	C87.871162,258.949738 87.703423,258.933685 87.288757,258.863464 
	C86.454269,258.366089 85.866722,257.922821 85.115448,257.252686 
	C85.313766,255.314529 85.675812,253.603226 86.097984,251.525421 
	C88.244225,250.096191 90.330353,249.033447 92.770294,247.940918 
	C93.880203,248.279907 94.636299,248.648697 95.706161,249.094055 
	C96.013802,249.579300 96.007668,249.988007 95.659775,250.747757 
	C93.525505,253.665024 91.732994,256.231232 89.623779,258.829590 
	C88.884346,258.896423 88.461624,258.931122 88.038902,258.965820 
z"/>
<path fill="#F9E734" opacity="1.000000" stroke="none" 
	d="
M153.911377,79.525261 
	C151.907593,80.127174 149.764023,80.412445 146.972031,80.890877 
	C144.566315,82.045441 142.809036,83.006836 141.070023,83.640808 
	C141.078400,81.886482 141.068497,80.459564 141.307465,78.805557 
	C142.351974,76.718651 143.147629,74.858833 143.943283,72.999016 
	C147.219391,75.068878 150.495499,77.138748 153.911377,79.525261 
M146.582306,77.306999 
	C146.366608,77.017677 146.150894,76.728355 145.935196,76.439026 
	C145.749634,76.615814 145.564072,76.792603 145.378510,76.969398 
	C145.659103,77.196991 145.939697,77.424583 146.582306,77.306999 
M146.479385,79.593231 
	C146.479385,79.593231 146.408936,79.477211 146.479385,79.593231 
z"/>
<path fill="#F6E314" opacity="1.000000" stroke="none" 
	d="
M168.627014,67.999527 
	C168.996826,68.001770 169.001007,68.482933 169.001190,68.723083 
	C169.000076,69.424324 168.998795,69.885429 169.001678,70.692757 
	C167.383881,72.410995 165.761932,73.783005 164.071686,75.484741 
	C162.385834,74.767807 160.768311,73.721130 159.015808,72.366760 
	C160.489426,70.344063 162.097977,68.629051 163.836975,66.774986 
	C165.069672,67.115852 166.663452,67.556564 168.627014,67.999527 
z"/>
<path fill="#F9DF3B" opacity="1.000000" stroke="none" 
	d="
M192.024384,116.562149 
	C189.075516,116.170891 186.129150,115.459221 183.365204,114.192467 
	C184.366638,112.428978 185.185638,111.220573 186.343475,110.006760 
	C187.457474,109.662956 188.232620,109.324570 189.007782,108.986183 
	C189.753647,109.317825 190.499512,109.649475 191.548843,110.036499 
	C191.910507,112.141823 191.968704,114.191788 192.024384,116.562149 
z"/>
<path fill="#F3B259" opacity="1.000000" stroke="none" 
	d="
M178.611511,220.276672 
	C179.475006,221.649826 180.018951,223.072159 180.336853,224.774460 
	C177.406174,226.703064 174.701553,228.351715 171.665192,229.939697 
	C170.196136,229.237839 169.058853,228.596649 167.921555,227.955444 
	C171.378357,225.412247 174.835159,222.869049 178.611511,220.276672 
z"/>
<path fill="#F3C455" opacity="1.000000" stroke="none" 
	d="
M39.955326,103.966965 
	C39.965694,104.138367 39.976063,104.309769 39.719646,104.971497 
	C39.315971,106.647354 39.179077,107.832893 38.737194,109.288925 
	C38.629997,113.038651 38.827789,116.517883 38.978043,120.414001 
	C38.666847,126.532143 38.403191,132.233398 38.109016,138.313812 
	C37.819672,140.135986 37.560837,141.578995 37.302002,143.022003 
	C37.204357,130.645828 37.106709,118.269646 36.944725,105.423569 
	C36.880383,104.953659 37.041752,104.593346 37.416649,104.356529 
	C38.512806,104.068794 39.234066,104.017883 39.955326,103.966965 
z"/>
<path fill="#FBAD36" opacity="1.000000" stroke="none" 
	d="
M114.998627,241.002548 
	C111.193832,241.425064 106.717651,239.510513 104.063782,244.486084 
	C103.517715,244.589432 103.025940,244.335022 102.293915,243.646927 
	C102.714119,242.144989 103.374580,241.076767 104.035034,240.008545 
	C104.346725,239.542267 104.658424,239.075989 105.291565,238.176498 
	C107.410828,237.495514 109.208649,237.247757 111.368240,237.005753 
	C112.819557,238.341858 113.909096,239.672195 114.998627,241.002548 
z"/>
<path fill="#FAAB28" opacity="1.000000" stroke="none" 
	d="
M167.617310,228.092987 
	C169.058853,228.596649 170.196136,229.237839 171.666138,230.334473 
	C171.988754,232.093475 171.978653,233.397049 171.968552,234.700623 
	C171.283417,234.854660 170.598297,235.008728 169.563995,235.206711 
	C167.419662,234.864731 165.624481,234.478836 163.446960,234.039917 
	C162.977066,233.589142 162.889511,233.191422 162.704102,232.503723 
	C164.175201,230.886002 165.744125,229.558258 167.617310,228.092987 
z"/>
<path fill="#EC9542" opacity="1.000000" stroke="none" 
	d="
M139.536469,246.832397 
	C140.195557,247.314651 140.479675,247.855560 140.502686,248.687683 
	C136.888016,250.579742 133.534424,252.180618 130.074310,253.832321 
	C130.832321,255.122986 131.400513,256.090424 131.968689,257.057861 
	C130.154144,256.455719 128.339615,255.853607 126.196747,255.087708 
	C129.060486,250.196045 134.370911,248.973663 139.536469,246.832397 
z"/>
<path fill="#FFEB52" opacity="1.000000" stroke="none" 
	d="
M107.035461,48.390381 
	C108.014740,47.067219 108.896263,45.994835 109.948624,45.130428 
	C110.924812,44.328594 112.083473,43.748898 113.535385,43.107765 
	C114.705162,43.452198 115.501350,43.760258 116.633957,44.080051 
	C117.966194,45.180916 118.962013,46.270042 119.973114,47.684540 
	C114.945053,44.634964 111.511635,48.581146 107.328995,50.752781 
	C106.989388,50.103294 107.000809,49.417599 107.035461,48.390381 
z"/>
<path fill="#F9D23A" opacity="1.000000" stroke="none" 
	d="
M201.185516,103.447784 
	C201.674866,104.692337 201.953262,106.431023 202.485672,108.088112 
	C203.329620,110.714836 204.343475,113.286972 205.636902,115.930138 
	C209.077164,116.794006 208.064743,118.230919 205.907684,119.880608 
	C204.518555,119.367783 203.807159,118.682190 203.032272,118.078056 
	C202.489700,117.655075 201.860397,117.343346 201.269836,116.981941 
	C201.209396,112.623070 201.148941,108.264191 201.185516,103.447784 
z"/>
<path fill="#F2C712" opacity="1.000000" stroke="none" 
	d="
M143.814270,72.701202 
	C143.147629,74.858833 142.351974,76.718651 140.966797,78.661720 
	C139.157684,78.692780 137.803864,78.952293 136.756149,78.501060 
	C135.700256,78.046288 134.948410,76.885605 134.063339,76.034210 
	C136.237228,76.034210 138.411133,76.034210 140.823013,76.034210 
	C140.191498,73.883423 139.797302,72.540817 139.613312,70.877213 
	C141.110764,71.171944 142.398010,71.787674 143.814270,72.701202 
z"/>
<path fill="#F8BD44" opacity="1.000000" stroke="none" 
	d="
M71.019470,223.362244 
	C72.970604,220.407242 71.367958,218.924911 69.063538,217.974075 
	C66.975281,217.112427 65.595497,216.172424 67.281876,213.362610 
	C68.092751,213.002762 68.536270,212.998566 69.037895,213.338058 
	C70.701904,214.457870 72.307808,215.233994 74.042648,216.300110 
	C73.787346,218.054291 73.403107,219.518463 73.018875,220.982651 
	C73.319611,222.122681 73.620346,223.262726 73.934021,224.758774 
	C73.015839,224.630630 72.084724,224.146469 71.019470,223.362244 
z"/>
<path fill="#EAB915" opacity="1.000000" stroke="none" 
	d="
M201.173248,117.393181 
	C201.860397,117.343346 202.489700,117.655075 203.032272,118.078056 
	C203.807159,118.682190 204.518555,119.367783 205.604156,120.043503 
	C207.201691,121.182671 208.452118,122.296928 209.834839,123.702377 
	C208.671860,124.546410 207.376572,125.099243 205.586884,125.863098 
	C205.788910,124.837654 205.933685,124.102852 206.078445,123.368057 
	C205.809921,123.129158 205.541397,122.890259 205.272858,122.651360 
	C204.383926,123.742676 203.494980,124.833992 202.322723,125.962997 
	C201.917480,124.837456 201.795547,123.674210 201.417816,122.272568 
	C201.133560,120.624252 201.105103,119.214340 201.173248,117.393181 
z"/>
<path fill="#F3B259" opacity="1.000000" stroke="none" 
	d="
M131.768600,241.030334 
	C130.227264,240.589767 128.685928,240.149170 127.072189,239.354401 
	C126.999779,239.000229 126.996437,239.004578 127.219681,238.902588 
	C127.718246,238.599594 127.902313,238.335114 128.148560,237.760880 
	C128.872620,237.009064 129.443237,236.503479 130.013855,235.997894 
	C132.033966,236.659225 134.054062,237.320526 136.435608,237.996887 
	C136.351868,238.610184 135.906708,239.208435 134.844315,239.864914 
	C133.407593,240.292206 132.588104,240.661270 131.768600,241.030334 
z"/>
<path fill="#39222F" opacity="1.000000" stroke="none" 
	d="
M29.950626,128.512650 
	C29.930117,128.978912 29.549685,128.994110 29.359367,128.998322 
	C29.100077,119.351387 29.031101,109.700218 28.963146,99.594269 
	C29.174128,99.112572 29.384089,99.085663 29.907413,99.121628 
	C30.137566,108.805130 30.054350,118.425758 29.950626,128.512650 
z"/>
<path fill="#ED9F31" opacity="1.000000" stroke="none" 
	d="
M178.472427,210.072845 
	C177.596298,209.482239 177.051392,208.887390 176.506470,208.292542 
	C177.699722,207.588501 178.876694,206.358582 180.090576,206.320999 
	C181.438751,206.279251 182.820572,207.325058 184.539246,207.958603 
	C182.861725,208.702759 180.832687,209.385666 178.472427,210.072845 
z"/>
<path fill="#FCD258" opacity="1.000000" stroke="none" 
	d="
M202.016846,186.949158 
	C202.016846,186.949158 201.626694,187.023407 201.430603,187.055389 
	C201.201233,185.020477 201.167953,182.953568 201.226044,180.456009 
	C201.795761,179.855850 202.274078,179.686356 202.894562,179.300293 
	C205.101685,178.387695 207.166672,177.691681 209.617676,176.998749 
	C210.009857,177.168701 210.016006,177.335587 209.710907,177.888397 
	C206.054626,180.413528 201.782715,181.763885 202.016846,186.949158 
z"/>
<path fill="#39222F" opacity="1.000000" stroke="none" 
	d="
M179.853577,94.764008 
	C176.372925,93.909935 172.266220,93.657776 170.056015,89.367172 
	C173.179977,90.838829 176.460693,92.637970 179.853577,94.764008 
z"/>
<path fill="#F2D407" opacity="1.000000" stroke="none" 
	d="
M129.934723,64.616531 
	C127.719322,66.817970 125.408485,67.727364 123.126114,64.311562 
	C123.790077,63.664921 124.610001,63.318039 125.780975,62.942879 
	C127.352730,63.379902 128.573425,63.845200 129.934723,64.616531 
z"/>
<path fill="#FCD258" opacity="1.000000" stroke="none" 
	d="
M192.864197,197.505463 
	C192.352295,197.382462 191.934662,196.800674 191.417542,195.758133 
	C191.525345,194.201141 191.732620,193.104904 191.939911,192.008652 
	C192.051956,188.573914 192.164001,185.139175 192.517258,181.386307 
	C192.825134,186.394348 192.891800,191.720520 192.864197,197.505463 
z"/>
<path fill="#F8B644" opacity="1.000000" stroke="none" 
	d="
M157.381012,225.025879 
	C155.894455,220.392441 159.643799,222.111450 161.860474,221.813019 
	C160.617279,223.036652 159.169022,224.003418 157.381012,225.025879 
z"/>
<path fill="#EC9542" opacity="1.000000" stroke="none" 
	d="
M107.055611,246.410065 
	C107.958374,245.482101 108.999718,244.839615 110.535454,243.892120 
	C110.740585,245.618912 110.882034,246.809601 111.033234,248.367767 
	C109.760063,248.055359 108.477135,247.375458 107.055611,246.410065 
z"/>
<path fill="#F6CC2A" opacity="1.000000" stroke="none" 
	d="
M71.721008,81.202408 
	C70.286613,82.795837 68.514832,84.408241 66.382568,85.985184 
	C67.809273,84.373611 69.596451,82.797493 71.721008,81.202408 
z"/>
<path fill="#39222F" opacity="1.000000" stroke="none" 
	d="
M145.016937,64.331940 
	C146.956955,65.097031 148.912003,66.218529 151.018768,67.655472 
	C149.124313,66.876724 147.078140,65.782532 145.016937,64.331940 
z"/>
<path fill="#F39622" opacity="1.000000" stroke="none" 
	d="
M103.649368,240.004822 
	C103.374580,241.076767 102.714119,242.144989 102.024307,243.528564 
	C101.384781,243.441971 100.774597,243.040039 100.066956,242.364685 
	C101.067566,241.394547 102.165634,240.697815 103.649368,240.004822 
z"/>
<path fill="#F3B259" opacity="1.000000" stroke="none" 
	d="
M108.973083,259.018799 
	C108.513412,258.692230 108.053734,258.365662 107.302185,257.994080 
	C107.007385,257.514709 107.004463,257.080261 107.000961,256.319519 
	C108.591309,256.768616 110.182228,257.544006 111.909027,258.631561 
	C112.044922,258.943695 111.999275,259.007538 111.604797,259.019470 
	C110.464577,259.027222 109.718834,259.023010 108.973083,259.018799 
z"/>
<path fill="#F6CC2A" opacity="1.000000" stroke="none" 
	d="
M65.600388,86.005478 
	C64.894653,86.685028 63.840134,87.357132 62.413406,87.998383 
	C63.111340,87.311020 64.181473,86.654526 65.600388,86.005478 
z"/>
<path fill="#F8EA23" opacity="1.000000" stroke="none" 
	d="
M113.651413,56.007912 
	C113.107208,56.586178 112.220665,57.186085 110.986298,57.584404 
	C111.528687,56.931728 112.418877,56.480640 113.651413,56.007912 
z"/>
<path fill="#391E3B" opacity="1.000000" stroke="none" 
	d="
M39.977005,103.642349 
	C39.234066,104.017883 38.512806,104.068794 37.431519,104.159698 
	C37.809811,103.461624 38.548122,102.723572 39.657856,102.001572 
	C40.019081,102.450989 40.008884,102.884361 39.977005,103.642349 
z"/>
<path fill="#ED9F31" opacity="1.000000" stroke="none" 
	d="
M185.338379,207.906723 
	C185.754089,207.274414 186.481049,206.647141 187.543564,206.052002 
	C187.135956,206.693344 186.392792,207.302551 185.338379,207.906723 
z"/>
<path fill="#EFC907" opacity="1.000000" stroke="none" 
	d="
M97.674377,66.025421 
	C98.244484,66.748810 98.583092,67.534348 98.937317,68.641678 
	C98.320312,68.487175 97.687691,68.010887 97.009491,67.206863 
	C96.975700,66.525749 97.135345,66.261902 97.674377,66.025421 
z"/>
<path fill="#3A194A" opacity="1.000000" stroke="none" 
	d="
M131.828552,241.248947 
	C132.588104,240.661270 133.407593,240.292206 134.580078,239.998169 
	C134.127121,240.693573 133.321167,241.313950 132.190170,241.927322 
	C131.865128,241.920319 131.888519,241.467560 131.828552,241.248947 
z"/>
<path fill="#F6CC2A" opacity="1.000000" stroke="none" 
	d="
M59.617393,89.929474 
	C59.164604,90.588539 58.389202,91.275322 57.245659,91.917992 
	C57.683277,91.234978 58.489029,90.596092 59.617393,89.929474 
z"/>
<path fill="#3A194A" opacity="1.000000" stroke="none" 
	d="
M123.282242,246.962738 
	C123.758499,246.325287 124.551559,245.685806 125.667900,245.095215 
	C125.193802,245.749634 124.396416,246.355164 123.282242,246.962738 
z"/>
<path fill="#F2C712" opacity="1.000000" stroke="none" 
	d="
M139.323517,69.966721 
	C138.999512,70.036125 138.546799,69.892693 138.038834,69.449280 
	C138.387329,69.350830 138.791046,69.552368 139.323517,69.966721 
z"/>
<path fill="#F6CC2A" opacity="1.000000" stroke="none" 
	d="
M56.598549,91.881783 
	C56.447399,92.226799 55.983891,92.605431 55.195007,92.969894 
	C55.341816,92.608948 55.814003,92.262169 56.598549,91.881783 
z"/>
<path fill="#F9EE32" opacity="1.000000" stroke="none" 
	d="
M38.323914,92.971130 
	C38.259918,92.712410 38.437893,92.378273 38.860069,92.053177 
	C38.924812,92.340050 38.745346,92.617889 38.323914,92.971130 
z"/>
<path fill="#39222F" opacity="1.000000" stroke="none" 
	d="
M181.724762,95.821686 
	C181.261292,95.944405 180.697464,95.782928 180.028564,95.333870 
	C180.490463,95.210022 181.057419,95.373756 181.724762,95.821686 
z"/>
<path fill="#39222F" opacity="1.000000" stroke="none" 
	d="
M143.133331,63.440025 
	C143.568420,63.266624 144.186234,63.326073 144.912918,63.691666 
	C144.453201,63.889492 143.884644,63.781185 143.133331,63.440025 
z"/>
<path fill="#39222F" opacity="1.000000" stroke="none" 
	d="
M138.100906,60.208286 
	C138.312943,60.111843 138.610855,60.260490 138.994720,60.650932 
	C138.782730,60.746281 138.484741,60.599831 138.100906,60.208286 
z"/>
<path fill="#39222F" opacity="1.000000" stroke="none" 
	d="
M140.082336,61.405605 
	C140.282227,61.285286 140.590652,61.381496 140.979187,61.728897 
	C140.710342,62.005100 140.420868,61.885784 140.082336,61.405605 
z"/>
<path fill="#39222F" opacity="1.000000" stroke="none" 
	d="
M141.093964,62.210884 
	C141.320511,62.123268 141.628387,62.307858 142.039825,62.754414 
	C141.820694,62.838615 141.497986,62.660851 141.093964,62.210884 
z"/>
<path fill="#F8EA23" opacity="1.000000" stroke="none" 
	d="
M120.057693,48.253384 
	C120.307640,47.999847 120.565987,48.105213 120.887787,48.505127 
	C120.700912,48.627586 120.405853,48.565125 120.057693,48.253384 
z"/>
<path fill="#39222F" opacity="1.000000" stroke="none" 
	d="
M137.052704,59.423466 
	C137.265091,59.288601 137.583771,59.371548 137.968689,59.716751 
	C137.685181,60.016239 137.393204,59.903660 137.052704,59.423466 
z"/>
<path fill="#F8EA23" opacity="1.000000" stroke="none" 
	d="
M135.984756,58.309258 
	C136.233627,58.092091 136.508072,58.231956 136.843811,58.682693 
	C136.661285,58.777729 136.363022,58.668636 135.984756,58.309258 
z"/>
<path fill="#3A194A" opacity="1.000000" stroke="none" 
	d="
M130.159180,242.914978 
	C130.117844,242.696198 130.303391,242.421738 130.746033,242.165131 
	C130.887955,242.491013 130.682266,242.716461 130.159180,242.914978 
z"/>
<path fill="#3A194A" opacity="1.000000" stroke="none" 
	d="
M129.143890,243.851959 
	C129.085907,243.646927 129.277756,243.364120 129.723099,243.007111 
	C129.782288,243.213333 129.587997,243.493759 129.143890,243.851959 
z"/>
<path fill="#3A194A" opacity="1.000000" stroke="none" 
	d="
M127.063026,244.896729 
	C127.039948,244.673141 127.259712,244.413589 127.741409,244.177536 
	C127.864433,244.519882 127.631943,244.739792 127.063026,244.896729 
z"/>
<path fill="#3A194A" opacity="1.000000" stroke="none" 
	d="
M122.221893,247.885986 
	C122.150909,247.686737 122.315025,247.398041 122.725945,247.035278 
	C122.800827,247.239655 122.628914,247.518112 122.221893,247.885986 
z"/>
<path fill="#3A194A" opacity="1.000000" stroke="none" 
	d="
M121.104485,248.881989 
	C121.054634,248.676041 121.259209,248.398422 121.719902,248.053711 
	C121.770325,248.261169 121.564621,248.535736 121.104485,248.881989 
z"/>
<path fill="#3A194A" opacity="1.000000" stroke="none" 
	d="
M119.070358,249.915070 
	C119.049812,249.699677 119.273102,249.440247 119.759491,249.192902 
	C119.872688,249.518692 119.636551,249.740723 119.070358,249.915070 
z"/>
<path fill="#391E3B" opacity="1.000000" stroke="none" 
	d="
M40.837399,101.107948 
	C40.905586,101.327728 40.717888,101.616768 40.270859,101.968475 
	C40.201519,101.746498 40.391514,101.461853 40.837399,101.107948 
z"/>
<path fill="#391E3B" opacity="1.000000" stroke="none" 
	d="
M41.822983,100.188980 
	C41.895157,100.412239 41.715790,100.690338 41.285030,101.030350 
	C41.212906,100.809456 41.392174,100.526642 41.822983,100.188980 
z"/>
<path fill="#391E3B" opacity="1.000000" stroke="none" 
	d="
M43.871796,99.089371 
	C43.915455,99.336205 43.717117,99.612343 43.254257,99.852196 
	C43.089176,99.478935 43.302528,99.246521 43.871796,99.089371 
z"/>
<path fill="#39222F" opacity="1.000000" stroke="none" 
	d="
M185.930817,98.601929 
	C185.727127,98.727188 185.403030,98.638626 185.002106,98.290482 
	C185.276138,98.011642 185.571182,98.130707 185.930817,98.601929 
z"/>
<path fill="#ED9F31" opacity="1.000000" stroke="none" 
	d="
M188.361954,205.793304 
	C188.178192,205.526672 188.322495,205.310120 188.783829,205.122498 
	C188.984848,205.385910 188.847900,205.616577 188.361954,205.793304 
z"/>
<path fill="#F3B259" opacity="1.000000" stroke="none" 
	d="
M163.286072,221.850922 
	C163.152054,221.534302 163.357559,221.312332 163.864899,221.099075 
	C163.906036,221.312668 163.725647,221.591934 163.286072,221.850922 
z"/>
<path fill="#F6CC2A" opacity="1.000000" stroke="none" 
	d="
M60.704521,89.042542 
	C60.776218,89.272560 60.588043,89.547607 60.148903,89.885361 
	C60.080177,89.661240 60.262413,89.374405 60.704521,89.042542 
z"/>
<path fill="#391E3B" opacity="1.000000" stroke="none" 
	d="
M44.639984,98.238342 
	C44.980331,98.539909 44.870693,98.816383 44.290779,99.056213 
	C44.043541,98.781914 44.166706,98.513138 44.639984,98.238342 
z"/>
<path fill="#39222F" opacity="1.000000" stroke="none" 
	d="
M184.902039,97.824509 
	C184.683929,97.917175 184.391830,97.757210 184.016907,97.353943 
	C184.232071,97.264389 184.530060,97.418129 184.902039,97.824509 
z"/>
<path fill="#F6CC2A" opacity="1.000000" stroke="none" 
	d="
M61.708233,88.093338 
	C61.939037,88.407394 61.777466,88.671783 61.235352,88.905106 
	C60.978691,88.588890 61.132374,88.312096 61.708233,88.093338 
z"/>
<path fill="#351B50" opacity="1.000000" stroke="none" 
	d="
M444.271698,131.134155 
	C443.268616,127.920959 442.637878,124.850731 442.018066,121.413132 
	C442.029053,121.045753 442.017334,121.014503 442.263977,120.949112 
	C442.833496,120.713348 442.990692,120.445694 442.982208,120.080750 
	C447.252380,118.172081 451.397217,118.465187 455.731262,120.915733 
	C456.018188,124.082268 456.000275,127.122437 455.923615,130.558777 
	C452.192627,133.431046 448.412140,132.138947 444.271698,131.134155 
M450.332611,127.024918 
	C451.111664,127.211632 451.890686,127.398346 452.823669,127.621956 
	C452.823669,126.234596 452.823669,124.813416 452.823669,123.184525 
	C450.586243,122.743103 448.497498,122.331024 446.196594,121.877083 
	C446.196594,124.237038 446.196594,125.993431 446.196594,128.273605 
	C447.526764,127.798645 448.518097,127.444679 450.332611,127.024918 
z"/>
<path fill="#F6A61A" opacity="1.000000" stroke="none" 
	d="
M442.760620,120.162399 
	C442.990692,120.445694 442.833496,120.713348 442.277100,120.954765 
	C442.098419,120.695251 442.263550,120.434662 442.760620,120.162399 
z"/>
<path fill="#F9D23A" opacity="1.000000" stroke="none" 
	d="
M213.220978,134.012543 
	C213.248672,133.891083 213.535675,133.776947 213.910858,133.835907 
	C213.826096,134.012619 213.653183,134.016251 213.220978,134.012543 
z"/>
<path fill="#FADE1F" opacity="1.000000" stroke="none" 
	d="
M201.994232,83.718437 
	C200.861420,85.076942 199.705032,86.141037 198.272491,87.100708 
	C197.678635,86.899109 197.420303,86.717506 197.257874,86.039734 
	C197.647324,85.177826 198.401764,84.533875 198.292221,84.306931 
	C196.933853,81.492676 195.449722,78.739128 193.997955,75.969940 
	C195.677811,74.360146 197.173309,72.424126 199.136063,71.314857 
	C199.837189,70.918602 201.669083,72.523102 202.997299,73.599052 
	C202.315048,76.177536 201.620316,78.371338 200.984436,80.948929 
	C201.352386,82.029823 201.661514,82.726921 201.994232,83.718437 
z"/>
<path fill="#F8D80B" opacity="1.000000" stroke="none" 
	d="
M193.665771,75.974228 
	C195.449722,78.739128 196.933853,81.492676 198.292221,84.306931 
	C198.401764,84.533875 197.647324,85.177826 197.149200,85.825607 
	C195.634415,86.854370 194.264801,87.685516 192.733276,88.614922 
	C189.462891,84.765648 189.492249,80.830177 192.001801,76.329315 
	C192.448090,75.956795 192.890839,75.967644 193.665771,75.974228 
z"/>
<path fill="#F9EE05" opacity="1.000000" stroke="none" 
	d="
M68.710342,61.028656 
	C69.817680,64.466232 71.325188,61.988487 72.383553,61.283661 
	C74.549355,59.841331 76.583138,58.200783 79.111031,57.196774 
	C79.527710,63.528427 73.931839,62.762726 70.007523,64.721603 
	C67.902794,64.817101 66.451462,64.908699 64.626579,65.163338 
	C61.021729,65.139130 56.735317,69.086967 55.425285,62.976170 
	C58.887661,62.321632 61.941849,61.660675 64.996033,60.999710 
	C66.136909,61.020451 67.277786,61.041187 68.710342,61.028656 
z"/>
<path fill="#F6E314" opacity="1.000000" stroke="none" 
	d="
M64.992081,60.636570 
	C61.941849,61.660675 58.887661,62.321632 55.374260,62.649834 
	C53.602779,60.869465 52.290504,59.421852 50.978233,57.974239 
	C52.194775,57.156498 53.411316,56.338757 54.887199,56.125458 
	C57.437275,56.818810 59.728004,56.907711 62.018734,56.996616 
	C63.008533,58.088886 63.998333,59.181156 64.992081,60.636570 
z"/>
<path fill="#FDF611" opacity="1.000000" stroke="none" 
	d="
M62.148361,56.676262 
	C59.728004,56.907711 57.437275,56.818810 55.080475,56.369392 
	C56.165188,54.458447 57.315964,52.908020 58.730862,51.164391 
	C62.139484,50.658077 62.423531,51.123970 62.148361,56.676262 
z"/>
<path fill="#FDF20B" opacity="1.000000" stroke="none" 
	d="
M50.657089,58.158516 
	C52.290504,59.421852 53.602779,60.869465 54.966072,62.643410 
	C56.735317,69.086967 61.021729,65.139130 64.970856,65.216843 
	C66.131790,66.070633 66.574898,67.033966 67.018005,67.997292 
	C65.585777,68.997688 64.153549,69.998085 62.365089,71.004654 
	C58.270905,71.140282 54.532955,71.269730 50.285580,71.416817 
	C50.987988,78.482689 44.697262,78.284523 39.245243,79.728180 
	C38.553612,80.003815 38.113724,79.999451 37.337421,79.741699 
	C37.001007,79.488319 36.987640,78.989426 37.061508,78.627251 
	C37.785351,77.854103 38.386848,77.220360 39.092827,77.066734 
	C45.908173,75.583633 47.450130,72.997208 44.993645,67.000610 
	C46.774414,64.114677 48.555180,61.228737 50.657089,58.158516 
z"/>
<path fill="#F2D407" opacity="1.000000" stroke="none" 
	d="
M44.575645,67.000381 
	C47.450130,72.997208 45.908173,75.583633 39.092827,77.066734 
	C38.386848,77.220360 37.785351,77.854103 36.698898,78.630371 
	C35.179211,78.676025 34.095993,78.356377 33.012779,78.036728 
	C33.181072,77.612000 33.467930,77.195824 33.499260,76.761223 
	C33.803154,72.545883 34.865208,68.824432 39.690804,67.120903 
	C41.364349,66.988167 42.760998,66.994156 44.575645,67.000381 
z"/>
<path fill="#F1DC08" opacity="1.000000" stroke="none" 
	d="
M92.716377,33.135887 
	C95.123474,33.014019 97.247856,33.028854 99.688095,33.018600 
	C99.543770,38.173645 95.361374,36.031082 92.317551,36.877983 
	C92.148308,35.766788 92.290993,34.519688 92.716377,33.135887 
z"/>
<path fill="#F19E2E" opacity="1.000000" stroke="none" 
	d="
M89.940491,258.797455 
	C91.732994,256.231232 93.525505,253.665024 95.655960,251.054306 
	C95.993919,255.687408 95.993919,260.365021 95.993919,265.858490 
	C93.559250,264.340912 92.483772,263.670563 91.050346,262.955078 
	C90.749603,262.256042 90.806801,261.602173 90.817154,260.313293 
	C90.493690,259.384674 90.217094,259.091064 89.940491,258.797455 
z"/>
<path fill="#F8A638" opacity="1.000000" stroke="none" 
	d="
M122.004837,257.409698 
	C124.619865,258.006195 127.414154,258.590912 129.640228,260.045288 
	C130.610992,260.679504 130.416885,263.096710 130.882477,265.195404 
	C130.371201,266.187225 129.729279,266.687805 128.846100,267.224304 
	C128.305435,267.427551 128.103073,267.674438 127.997757,268.000916 
	C127.997757,268.000916 128.000000,268.000000 127.758148,267.925842 
	C126.980377,267.894592 126.444458,267.937469 125.719452,268.091278 
	C125.290825,268.379578 125.104485,268.601562 124.721878,268.893860 
	C124.306404,268.939209 124.140388,268.958923 123.703125,268.985596 
	C123.252441,269.015503 123.072998,269.038391 122.893555,269.061310 
	C122.352898,268.883392 121.812233,268.705505 121.128983,268.274078 
	C120.100479,267.211487 119.051338,266.518341 118.372353,265.562317 
	C117.621948,264.505676 117.210617,263.208191 116.651596,262.015625 
	C117.890366,262.158051 119.188721,262.120453 120.347664,262.504059 
	C121.165245,262.774658 121.793671,263.616760 122.700256,264.363190 
	C122.424789,261.924316 122.189102,259.837646 122.004837,257.409698 
z"/>
<path fill="#F4A34A" opacity="1.000000" stroke="none" 
	d="
M109.006454,259.345825 
	C109.718834,259.023010 110.464577,259.027222 111.582611,259.051849 
	C111.850899,259.964691 111.746902,260.857147 111.402275,261.866669 
	C110.454369,261.213470 109.747093,260.443176 109.006454,259.345825 
z"/>
<path fill="#F4A34A" opacity="1.000000" stroke="none" 
	d="
M111.212311,266.893921 
	C110.917610,266.129669 110.977631,265.404480 111.081375,264.314728 
	C111.962936,264.531494 112.800766,265.112762 113.638596,265.694061 
	C112.948074,266.107025 112.257545,266.520020 111.212311,266.893921 
z"/>
<path fill="#F4A34A" opacity="1.000000" stroke="none" 
	d="
M115.736282,267.356445 
	C115.987114,267.371033 116.123726,267.504639 116.260338,267.638245 
	C116.047569,267.584015 115.834808,267.529755 115.736282,267.356445 
z"/>
<path fill="#FAE7CE" opacity="1.000000" stroke="none" 
	d="
M119.316269,273.267273 
	C120.301216,273.191345 121.361015,273.379028 122.420822,273.566681 
	C122.139481,274.019806 121.858131,274.472931 121.257057,274.980865 
	C120.421928,274.534058 119.906532,274.032440 119.316269,273.267273 
z"/>
<path fill="#FAE406" opacity="1.000000" stroke="none" 
	d="
M205.748520,73.163376 
	C206.008331,73.453522 206.012054,73.913963 206.024628,74.720428 
	C205.853165,75.081490 205.672867,75.096535 205.221649,75.088364 
	C205.131317,74.487991 205.311890,73.910835 205.748520,73.163376 
z"/>
<path fill="#FBE5B7" opacity="1.000000" stroke="none" 
	d="
M148.135483,251.040421 
	C150.740356,249.634216 149.698975,244.489212 154.633545,245.030090 
	C155.789612,247.497757 158.092880,250.170242 153.751846,252.549042 
	C151.153000,253.583633 149.078506,254.284515 147.004028,254.985397 
	C147.004028,254.985397 146.879959,254.920517 146.857574,254.599518 
	C147.268631,253.199158 147.702057,252.119781 148.135483,251.040421 
z"/>
<path fill="#E69D5C" opacity="1.000000" stroke="none" 
	d="
M147.023544,255.313293 
	C149.078506,254.284515 151.153000,253.583633 153.567444,252.817215 
	C153.014481,254.361755 152.121536,255.971848 150.991547,257.863281 
	C150.408356,258.146851 150.062241,258.149078 149.137054,258.106842 
	C148.348083,258.067780 148.138153,258.073212 147.928223,258.078644 
	C147.928223,258.078644 147.920059,258.077759 147.911926,258.095154 
	C147.616882,257.288757 147.329971,256.464966 147.023544,255.313293 
z"/>
<path fill="#F2D299" opacity="1.000000" stroke="none" 
	d="
M173.131653,246.210571 
	C173.814072,245.198151 174.684631,244.443558 175.555191,243.688965 
	C175.821182,243.893906 176.087173,244.098831 176.353165,244.303772 
	C176.010010,245.000931 175.666870,245.698105 175.226334,246.670807 
	C174.525879,246.787033 173.922821,246.627701 173.131653,246.210571 
z"/>
<path fill="#FAE406" opacity="1.000000" stroke="none" 
	d="
M32.677719,78.029022 
	C34.095993,78.356377 35.179211,78.676025 36.625031,78.992554 
	C36.987640,78.989426 37.001007,79.488319 37.007439,79.737625 
	C36.265583,79.992966 35.517288,79.999008 34.395660,80.004982 
	C33.052567,79.576363 32.082813,79.147820 31.058079,78.378296 
	C31.449614,78.031990 31.896135,78.026657 32.677719,78.029022 
z"/>
<path fill="#F9E90F" opacity="1.000000" stroke="none" 
	d="
M212.247528,105.049492 
	C210.531509,104.969971 209.075455,104.863785 207.619400,104.757599 
	C207.243668,104.308502 206.867950,103.859398 206.492218,103.410301 
	C208.226578,102.145943 209.960938,100.881584 211.853012,99.314789 
	C214.811462,98.637627 217.612183,98.262901 220.412903,97.888176 
	C220.562653,98.095749 220.712402,98.303314 220.862152,98.510880 
	C219.171738,99.316582 217.343094,99.926537 215.835434,100.991096 
	C214.669540,101.814339 213.905670,103.206947 212.996063,104.667496 
	C212.855194,104.999969 212.681335,105.011391 212.247528,105.049492 
z"/>
<path fill="#EAB915" opacity="1.000000" stroke="none" 
	d="
M209.037491,110.383316 
	C209.867691,109.184914 210.765518,108.348503 211.837769,107.254143 
	C213.338074,108.773666 214.663986,110.551125 215.989883,112.328583 
	C215.935303,112.708908 215.880707,113.089233 215.826111,113.469559 
	C213.585785,112.561470 211.345459,111.653389 209.037491,110.383316 
z"/>
<path fill="#E8AF22" opacity="1.000000" stroke="none" 
	d="
M215.065369,127.726685 
	C214.950073,127.709526 214.807343,127.437637 214.837738,127.063004 
	C215.019913,127.130821 215.028915,127.301384 215.065369,127.726685 
z"/>
<path fill="#F9E90F" opacity="1.000000" stroke="none" 
	d="
M214.080505,105.165649 
	C213.991196,105.001366 214.487122,105.004738 214.734863,105.006866 
	C214.711670,105.115982 214.440750,105.222961 214.080505,105.165649 
z"/>
<path fill="#F8E19E" opacity="1.000000" stroke="none" 
	d="
M46.404716,231.019745 
	C45.689186,229.946609 45.370632,228.843475 45.043369,227.394257 
	C48.828392,223.697311 52.122055,226.700439 55.760643,228.471039 
	C52.774326,229.310623 49.788010,230.150192 46.404716,231.019745 
z"/>
<path fill="#F8E19E" opacity="1.000000" stroke="none" 
	d="
M123.974380,268.978638 
	C124.140388,268.958923 124.306404,268.939209 124.959412,268.852966 
	C125.749619,268.603333 125.903671,268.334595 125.908546,267.980316 
	C126.444458,267.937469 126.980377,267.894592 127.758698,267.925201 
	C128.000900,268.741302 128.000687,269.483948 127.989716,270.596924 
	C126.483673,270.824768 124.988396,270.682281 123.485916,270.151825 
	C123.735146,269.559692 123.900368,269.297974 123.974380,268.978638 
z"/>
<path fill="#F3B259" opacity="1.000000" stroke="none" 
	d="
M123.703125,268.985596 
	C123.900368,269.297974 123.735146,269.559692 123.261673,269.853516 
	C123.044632,269.943176 122.949013,269.505402 122.921280,269.283356 
	C123.072998,269.038391 123.252441,269.015503 123.703125,268.985596 
z"/>
<path fill="#FAE7CE" opacity="1.000000" stroke="none" 
	d="
M125.999847,274.000000 
	C125.878342,273.858398 125.757004,273.716766 125.635658,273.575134 
	C125.721077,273.624603 125.806496,273.674042 125.946106,273.861755 
	C126.000305,274.000000 126.000000,274.000000 125.999847,274.000000 
z"/>
<path fill="#F3B259" opacity="1.000000" stroke="none" 
	d="
M89.623779,258.829590 
	C90.217094,259.091064 90.493690,259.384674 90.803909,259.982788 
	C90.016609,260.033600 89.195702,259.779938 88.206848,259.246033 
	C88.461624,258.931122 88.884346,258.896423 89.623779,258.829590 
z"/>
<path fill="#FBEAC5" opacity="1.000000" stroke="none" 
	d="
M182.999908,230.341034 
	C182.807617,227.945282 182.652390,225.898788 182.457855,223.333939 
	C184.808014,223.134186 186.871811,222.958755 189.097809,222.769547 
	C189.994568,220.799149 190.919769,218.766296 191.928009,216.368515 
	C195.413712,214.637711 198.816391,213.271805 202.707977,212.203918 
	C202.796692,215.666275 202.396484,218.830612 201.996292,221.994934 
	C200.199982,221.902878 197.475983,224.896912 197.595428,219.679855 
	C195.754318,222.117828 194.525635,223.744858 193.143799,225.678528 
	C191.643158,228.733139 190.295654,231.481125 188.972290,234.616425 
	C188.996460,235.003723 189.000061,235.000000 188.592957,234.987350 
	C187.089233,234.988937 185.992599,235.003174 184.895981,235.017395 
	C184.276306,233.575027 183.656647,232.132660 182.999908,230.341034 
z"/>
<path fill="#F7B135" opacity="1.000000" stroke="none" 
	d="
M172.408112,234.743896 
	C171.978653,233.397049 171.988754,232.093475 171.997894,230.395126 
	C174.701553,228.351715 177.406174,226.703064 180.471344,225.085632 
	C181.025467,226.582977 181.219055,228.049103 181.690887,229.755005 
	C181.141068,231.463745 180.313004,232.932693 179.255585,234.666122 
	C176.966721,234.882782 174.907211,234.834976 172.408112,234.743896 
z"/>
<path fill="#F2D299" opacity="1.000000" stroke="none" 
	d="
M184.652954,235.179169 
	C185.992599,235.003174 187.089233,234.988937 188.595184,234.985809 
	C187.593582,235.662567 186.182648,236.328232 184.406281,237.002594 
	C184.163864,236.454529 184.286896,235.897751 184.652954,235.179169 
z"/>
<path fill="#FAE1A8" opacity="1.000000" stroke="none" 
	d="
M202.110260,222.221375 
	C202.396484,218.830612 202.796692,215.666275 203.088196,212.187286 
	C204.390945,211.619705 205.802414,211.366806 207.818558,211.351395 
	C209.704636,212.659470 210.902145,213.864838 212.306030,214.738617 
	C212.923950,215.123184 213.961624,214.833328 214.808716,214.849670 
	C214.975433,215.992203 215.142151,217.134720 215.372375,218.712524 
	C212.822189,218.081268 210.453751,222.046677 207.869080,218.490997 
	C206.996490,219.440262 206.124969,220.390518 205.250916,221.338409 
	C204.720032,221.914124 204.185242,222.486221 203.349518,223.040985 
	C202.772614,222.830627 202.498413,222.639221 202.110260,222.221375 
z"/>
<path fill="#F4C674" opacity="1.000000" stroke="none" 
	d="
M214.849670,214.529175 
	C213.961624,214.833328 212.923950,215.123184 212.306030,214.738617 
	C210.902145,213.864838 209.704636,212.659470 208.199570,211.359039 
	C208.591141,209.450027 209.206406,207.770874 209.821655,206.091721 
	C210.182480,206.595734 210.543289,207.099747 210.904114,207.603745 
	C212.582245,207.295731 214.260391,206.987717 215.972412,206.366241 
	C217.014160,208.100082 218.022003,210.147385 219.029877,212.566895 
	C217.650162,213.362289 216.270401,213.785477 214.849670,214.529175 
z"/>
<path fill="#F4C674" opacity="1.000000" stroke="none" 
	d="
M219.468018,216.485626 
	C219.101700,216.513519 218.880005,216.397278 218.658325,216.281036 
	C218.867752,216.074066 219.077194,215.867081 219.286621,215.660110 
	C219.395294,215.887238 219.503967,216.114380 219.468018,216.485626 
z"/>
<path fill="#F2BC69" opacity="1.000000" stroke="none" 
	d="
M220.959778,213.508438 
	C221.148758,213.433487 221.439957,213.564972 221.784744,213.945648 
	C221.527344,214.119247 221.268570,213.959259 220.959778,213.508438 
z"/>
<path fill="#F2BC69" opacity="1.000000" stroke="none" 
	d="
M222.239746,215.165985 
	C222.485916,215.047592 222.704010,215.232147 222.953186,215.682678 
	C222.693802,215.866470 222.436264,215.706573 222.239746,215.165985 
z"/>
<path fill="#FAE7CE" opacity="1.000000" stroke="none" 
	d="
M195.996658,233.995697 
	C196.087814,233.959610 196.182297,233.927719 196.142334,233.950958 
	C196.007919,234.006088 195.999969,233.999893 195.996658,233.995697 
z"/>
<path fill="#FCCF4D" opacity="1.000000" stroke="none" 
	d="
M202.066010,195.471222 
	C203.646881,194.641357 205.187653,194.114197 206.740952,193.626999 
	C210.579742,192.422897 212.383881,189.870514 212.286499,185.272324 
	C214.399780,183.481384 216.190887,182.241074 218.319489,181.054291 
	C219.747940,182.095520 220.838913,183.083237 221.929871,184.070953 
	C221.938126,186.117645 221.946396,188.164352 221.546661,190.614349 
	C218.773956,192.020950 216.409271,193.024246 214.044571,194.027527 
	C210.399872,195.122864 206.755157,196.218201 203.029816,197.681732 
	C202.643311,197.297318 202.337448,196.544708 202.066010,195.471222 
z"/>
<path fill="#F8E19E" opacity="1.000000" stroke="none" 
	d="
M214.026840,194.415985 
	C216.409271,193.024246 218.773956,192.020950 221.517822,191.052948 
	C219.532455,193.700439 217.167892,196.312653 214.382492,198.672668 
	C213.904175,198.250702 213.846680,198.080933 213.789154,197.911148 
	C213.862473,196.875580 213.935791,195.839996 214.026840,194.415985 
z"/>
<path fill="#F9B022" opacity="1.000000" stroke="none" 
	d="
M222.232941,183.933472 
	C220.838913,183.083237 219.747940,182.095520 218.328125,180.655151 
	C217.995819,179.138702 217.992355,178.074905 217.988892,177.011108 
	C217.988907,177.011108 217.996765,177.003250 218.000214,176.999786 
	C222.460724,175.950882 222.991776,180.015152 224.958069,182.642166 
	C224.192047,183.260162 223.364029,183.528076 222.232941,183.933472 
z"/>
<path fill="#FCCF4D" opacity="1.000000" stroke="none" 
	d="
M213.556427,197.963165 
	C213.846680,198.080933 213.904175,198.250702 214.031891,198.679901 
	C213.646454,198.841385 213.190781,198.743454 212.735123,198.645523 
	C212.931320,198.435410 213.127502,198.225296 213.556427,197.963165 
z"/>
<path fill="#FCCF4D" opacity="1.000000" stroke="none" 
	d="
M203.918060,199.784973 
	C203.755234,199.821182 203.479309,199.629456 203.095795,199.204788 
	C203.260468,199.166916 203.532715,199.361984 203.918060,199.784973 
z"/>
<path fill="#F0C81B" opacity="1.000000" stroke="none" 
	d="
M200.450958,91.532990 
	C200.418854,91.478790 200.483078,91.587181 200.450958,91.532990 
z"/>
<path fill="#F4B95F" opacity="1.000000" stroke="none" 
	d="
M148.023712,258.278046 
	C148.138153,258.073212 148.348083,258.067780 148.873581,258.082886 
	C148.832504,258.228088 148.475861,258.352783 148.023712,258.278046 
z"/>
<path fill="#E8DAD9" opacity="1.000000" stroke="none" 
	d="
M145.984207,267.983643 
	C145.856384,267.839539 145.744339,267.711823 145.632309,267.584076 
	C145.709442,267.629211 145.786575,267.674347 145.915222,267.843994 
	C145.966766,267.968506 146.000000,268.000000 145.984207,267.983643 
z"/>
<path fill="#FBF1D4" opacity="1.000000" stroke="none" 
	d="
M129.087341,267.188354 
	C129.729279,266.687805 130.371201,266.187225 131.080719,265.360565 
	C131.996033,265.157562 132.843765,265.280609 133.874954,265.674072 
	C133.716034,266.393921 133.373642,266.843323 132.981812,267.858582 
	C132.919159,268.605469 132.905945,268.786499 132.696503,269.094482 
	C132.252563,269.431183 132.093140,269.694366 131.797897,270.043518 
	C131.573807,270.076050 131.122589,270.115082 130.843292,270.067444 
	C130.375992,270.001434 130.188004,269.983063 130.000015,269.964691 
	C129.737549,269.482269 129.475082,268.999817 129.127930,268.057495 
	C129.043243,267.597626 129.087341,267.188354 129.087341,267.188354 
z"/>
<path fill="#F9C388" opacity="1.000000" stroke="none" 
	d="
M132.892731,268.967499 
	C132.905945,268.786499 132.919159,268.605469 132.944855,268.151123 
	C133.568863,268.689758 134.180359,269.501709 134.908646,270.596069 
	C134.861588,271.173126 134.697754,271.467773 133.971313,271.880859 
	C132.961945,271.971191 132.515198,271.943115 132.068436,271.915039 
	C131.956421,271.613525 131.775467,271.361481 131.316742,270.839355 
	C131.107910,270.519806 131.122589,270.115082 131.122589,270.115082 
	C131.122589,270.115082 131.573807,270.076050 132.020599,269.945496 
	C132.739334,269.597778 132.881104,269.315308 132.892731,268.967499 
z"/>
<path fill="#FCD9BA" opacity="1.000000" stroke="none" 
	d="
M132.055908,272.200623 
	C132.515198,271.943115 132.961945,271.971191 133.746704,272.002869 
	C134.073242,272.180756 134.061798,272.355072 133.712494,272.773621 
	C132.937866,273.031128 132.501083,273.044373 132.064301,273.057617 
	C132.057327,272.867126 132.050369,272.676697 132.055908,272.200623 
z"/>
<path fill="#F9C388" opacity="1.000000" stroke="none" 
	d="
M132.230057,273.299896 
	C132.501083,273.044373 132.937866,273.031128 133.703262,273.035522 
	C134.031876,273.053192 134.056885,273.380066 134.077698,273.540802 
	C133.530960,273.648407 132.963379,273.595276 132.230057,273.299896 
z"/>
<path fill="#F8E19E" opacity="1.000000" stroke="none" 
	d="
M128.846100,267.224304 
	C129.087341,267.188354 129.043243,267.597626 129.023880,267.802612 
	C128.836685,268.012939 128.668869,268.018250 128.249390,268.012238 
	C128.103073,267.674438 128.305435,267.427551 128.846100,267.224304 
z"/>
<path fill="#FCD9BA" opacity="1.000000" stroke="none" 
	d="
M130.843292,270.067444 
	C131.122589,270.115082 131.107910,270.519806 131.113525,270.721527 
	C130.823563,270.758636 130.528000,270.594055 130.116226,270.197083 
	C130.188004,269.983063 130.375992,270.001434 130.843292,270.067444 
z"/>
<path fill="#F5BE36" opacity="1.000000" stroke="none" 
	d="
M217.574402,177.008713 
	C217.992355,178.074905 217.995819,179.138702 217.990646,180.601624 
	C216.190887,182.241074 214.399780,183.481384 212.285110,184.867142 
	C208.434372,183.302795 205.591110,185.320923 202.325897,186.836395 
	C201.782715,181.763885 206.054626,180.413528 209.720612,178.138702 
	C210.487808,178.642120 210.934021,179.281128 210.721695,178.977051 
	C213.161682,178.230179 215.160797,177.618240 217.574402,177.008713 
z"/>
<path fill="#EAB430" opacity="1.000000" stroke="none" 
	d="
M214.009583,156.009918 
	C213.710907,156.527557 213.403076,157.035736 213.095245,157.543915 
	C212.882950,157.361649 212.670639,157.179398 212.458313,156.997162 
	C212.777145,156.685120 213.095963,156.373093 213.698257,156.021667 
	C213.981735,155.982300 214.000443,156.000458 214.009583,156.009918 
z"/>
<path fill="#2B1767" opacity="1.000000" stroke="none" 
	d="
M162.129242,207.117264 
	C162.675949,208.541763 163.222672,209.966278 163.961594,211.891617 
	C158.500198,213.769409 153.225967,213.305756 147.499908,211.928925 
	C147.077942,211.904114 147.060349,211.936554 146.914261,211.625916 
	C140.289368,208.257828 139.374771,201.681259 137.052307,195.654144 
	C136.985596,194.889694 136.937790,194.454239 136.970184,193.558136 
	C137.085709,188.765350 137.121033,184.433243 137.480026,179.865479 
	C138.481537,175.753342 139.159378,171.876846 139.910187,167.609650 
	C139.614563,165.501282 139.245987,163.783600 139.036285,161.725555 
	C140.128784,159.593597 141.435684,157.893906 141.905090,155.987976 
	C142.544876,153.390167 142.545975,150.635040 142.963898,147.673248 
	C143.358841,146.261093 143.610825,145.123047 143.949738,143.648331 
	C144.393814,142.250351 144.750977,141.189041 145.421570,139.905334 
	C146.794922,133.260742 150.516479,127.222557 148.575409,118.796944 
	C145.300949,123.943237 142.744400,127.961227 140.053848,132.285156 
	C139.331955,133.731644 138.744080,134.872192 137.986191,136.333618 
	C136.844574,139.090363 135.872971,141.526260 134.593735,144.120728 
	C133.509232,146.167313 132.732361,148.055298 131.631195,150.107254 
	C130.513779,152.023529 129.489090,153.709961 128.968491,155.539810 
	C126.942902,162.659470 125.077286,169.824661 123.087303,177.299866 
	C122.987335,178.069626 122.952217,178.512421 122.592758,179.168747 
	C121.578819,182.257660 120.889229,185.133026 120.137260,188.411194 
	C120.349457,191.117096 120.624023,193.420197 121.040886,196.916885 
	C118.215996,198.047058 114.597237,199.494827 110.589005,200.965408 
	C108.533669,201.361603 106.867805,201.735001 104.932693,202.024445 
	C104.476280,201.922470 104.289124,201.904465 104.036255,201.550262 
	C102.249542,199.121933 100.528534,197.029816 98.877701,194.482697 
	C99.314217,187.031631 99.680557,180.035583 100.294586,172.932007 
	C100.635498,171.858932 100.728745,170.893402 100.899231,169.518799 
	C101.278511,166.090225 101.580544,163.070709 101.964523,159.698044 
	C102.433479,158.243713 102.820503,157.142548 103.507050,155.834900 
	C104.162727,153.756821 104.518890,151.885208 104.941208,149.598236 
	C106.994026,140.554413 108.980682,131.925949 110.967346,123.297501 
	C110.334160,123.075806 109.700974,122.854111 109.067787,122.632416 
	C105.658081,129.397476 102.248375,136.162521 98.638924,143.065216 
	C98.172165,143.421539 98.031372,143.703247 98.008835,144.022736 
	C98.000870,143.997467 98.051094,144.014404 97.776306,144.102249 
	C97.331268,144.524918 97.161026,144.859726 96.975723,145.376587 
	C96.960663,145.558609 96.819633,145.895599 96.524071,146.101196 
	C95.497650,148.557846 94.766785,150.808884 94.021378,153.030609 
	C94.006836,153.001282 94.070305,153.017258 93.798279,153.115143 
	C93.382767,153.557022 93.239296,153.901031 93.083313,154.444595 
	C93.070801,154.644150 92.874359,154.992477 92.657158,155.103760 
	C92.183632,155.446426 92.049065,155.734650 91.993347,156.304840 
	C91.950432,156.529938 91.808411,156.965668 91.577835,157.165894 
	C91.242363,157.952545 91.137466,158.538956 91.029816,159.373917 
	C91.027046,159.622467 91.159698,160.101562 91.084900,160.045273 
	C91.010094,159.988983 90.828232,159.944473 90.629662,160.086197 
	C90.194244,160.473892 90.068939,160.767471 89.939384,161.398239 
	C87.855949,167.105789 85.888313,172.523788 83.941711,177.952423 
	C83.962746,177.963058 83.952377,177.918777 83.647133,178.086609 
	C83.199928,179.165298 83.057976,180.076141 82.951889,180.986053 
	C82.987747,180.985123 82.966393,180.916626 82.734985,181.012482 
	C82.409409,181.400711 82.315231,181.693085 82.036606,182.292877 
	C81.541176,183.715668 81.230209,184.831009 80.667801,186.047058 
	C80.343559,186.478653 80.270767,186.809540 80.115433,187.317413 
	C80.032898,187.494400 79.980186,187.881393 79.667267,188.062546 
	C78.953674,189.824997 78.553001,191.406296 78.060928,193.209885 
	C77.969521,193.432175 77.885139,193.905411 77.583603,194.084183 
	C76.811089,195.820694 76.340096,197.378448 75.915390,198.963486 
	C75.961678,198.990784 75.906960,198.898285 75.631485,199.042496 
	C75.278618,199.779572 75.201218,200.372421 75.045303,201.183289 
	C74.966789,201.401306 74.934837,201.863617 74.608788,201.923538 
	C73.844765,202.617401 73.406792,203.251358 72.608917,203.916916 
	C69.526802,202.632080 66.804581,201.315628 63.994888,199.742783 
	C63.605953,199.307236 63.304493,199.128098 62.878220,198.661926 
	C61.853119,197.878952 60.952839,197.382996 60.030010,196.481201 
	C60.404743,193.393524 60.802029,190.711670 61.509552,187.841736 
	C62.290886,185.778381 62.761986,183.903091 63.539078,181.822418 
	C64.501389,178.085770 65.157715,174.554520 65.875961,170.766266 
	C65.967674,170.335541 65.997475,170.161835 66.384483,169.779053 
	C67.214600,165.070969 67.687500,160.571945 68.440369,155.880371 
	C68.767906,153.496841 68.815468,151.305847 68.927086,148.781494 
	C69.263931,147.621384 69.536720,146.794647 69.886139,145.554718 
	C69.904228,143.738373 69.845680,142.335236 69.866692,140.724030 
	C69.946243,140.515976 69.994568,140.073074 70.366669,139.841125 
	C70.495171,132.378815 70.251564,125.148438 70.014465,117.505669 
	C69.268318,112.592598 69.515427,107.599815 64.073158,105.425705 
	C63.801548,105.317200 64.067070,103.864151 64.357437,102.940201 
	C65.096672,102.242630 65.560974,101.642014 66.025276,101.041405 
	C66.025276,101.041405 66.005730,101.003929 66.351730,100.989456 
	C67.818573,100.028313 68.939415,99.081650 70.060257,98.134979 
	C70.653824,97.975517 71.247383,97.816055 72.373199,97.349907 
	C75.586540,96.725235 78.267632,96.407242 80.948715,96.089249 
	C80.948715,96.089241 80.994469,96.013863 81.079208,96.330917 
	C82.099022,97.123421 83.034103,97.598854 84.120926,98.388145 
	C85.567802,100.472649 86.862946,102.243309 88.080963,104.439728 
	C88.333549,109.584244 88.757011,114.298897 88.964355,119.023026 
	C89.141586,123.060913 89.070839,127.109695 88.914062,131.435822 
	C88.785210,132.969223 88.852074,134.220459 88.918938,135.471710 
	C89.732887,134.968872 90.546837,134.466034 91.548752,133.870361 
	C91.736717,133.777512 91.897446,133.390274 92.148132,133.208023 
	C92.958641,131.393677 93.518463,129.761581 94.074356,128.103653 
	C94.070419,128.077820 94.122665,128.078659 94.361038,127.996811 
	C94.668205,127.614655 94.737007,127.314346 94.890968,126.789680 
	C94.976128,126.565323 95.035034,126.088997 95.288864,126.004578 
	C95.632362,125.604080 95.722038,125.288002 95.906868,124.763794 
	C96.002029,124.555656 96.062424,124.101936 96.307487,124.011963 
	C96.638268,123.611244 96.723976,123.300507 96.901535,122.789749 
	C96.993370,122.589729 97.044037,122.152466 97.355797,121.995148 
	C98.394302,120.230072 99.121048,118.622299 99.919128,116.659256 
	C101.038826,114.226120 102.087173,112.148254 103.455643,109.930443 
	C104.894867,107.861427 105.803116,105.765541 107.172615,104.034584 
	C111.272865,98.852104 115.055580,93.035194 123.402184,93.908920 
	C125.913948,94.681671 128.091263,95.267899 130.184967,96.071846 
	C131.996277,96.767342 133.627151,97.243828 132.683838,100.243622 
	C131.512177,103.969582 131.433044,108.039116 130.563873,112.184067 
	C129.143417,118.184982 127.958183,123.948402 127.001762,129.749557 
	C126.784187,131.069290 127.358757,132.519592 127.568726,133.909805 
	C128.724548,132.887680 130.281479,132.079529 130.962021,130.803833 
	C133.119186,126.760216 134.957855,122.546112 136.901840,118.389587 
	C137.892853,116.270683 138.845779,114.133957 139.893890,111.763123 
	C139.998734,111.353485 140.025589,111.186020 140.364319,110.869308 
	C145.460022,104.841858 150.243866,98.963654 155.027710,93.085449 
	C156.087997,93.071144 157.148270,93.056847 158.600494,92.973724 
	C161.791672,93.606529 164.572525,94.395851 167.394989,94.986427 
	C170.555389,95.647736 172.105957,96.983871 170.068314,100.987732 
	C170.081696,102.490845 170.127808,103.240845 169.989441,104.352966 
	C168.198151,113.124535 166.591354,121.533981 164.651184,130.170929 
	C163.935165,134.940887 163.552536,139.483337 163.091614,144.373016 
	C162.722229,145.825638 162.431152,146.931061 162.077362,148.264938 
	C162.014664,148.493408 162.134430,148.951874 161.953842,149.313339 
	C160.566025,159.112808 159.358795,168.550827 158.085052,178.453186 
	C158.393539,184.935043 158.768570,190.952515 159.067810,197.367630 
	C160.037766,200.882599 161.083496,203.999924 162.129242,207.117264 
z"/>
<path fill="#EFC907" opacity="1.000000" stroke="none" 
	d="
M100.009644,79.010704 
	C100.450836,79.005363 100.892021,79.000031 101.783165,79.279282 
	C101.488884,81.041954 100.744652,82.520027 99.685471,84.139343 
	C97.913788,86.190109 96.457047,88.099648 94.662239,90.059708 
	C93.207390,91.745239 92.090607,93.380241 90.654526,94.972580 
	C89.880905,94.295700 89.426590,93.661484 88.972275,93.027260 
	C88.697800,92.284096 88.423332,91.540932 87.994781,90.380585 
	C89.627197,88.999039 91.537827,87.382050 93.448448,85.765053 
	C93.131561,85.315063 92.814667,84.865067 92.497772,84.415077 
	C90.863899,84.811417 89.230034,85.207756 87.045044,85.737785 
	C87.944359,81.673553 86.420097,77.276596 91.066895,75.025650 
	C91.373451,74.877159 92.035179,75.461868 92.766418,75.853348 
	C93.001129,76.001160 93.007584,76.007584 93.007782,76.388596 
	C94.355103,78.338654 95.635544,79.982071 97.162186,81.350922 
	C97.300613,81.475037 99.022888,79.832718 100.009644,79.010704 
z"/>
<path fill="#FADE1F" opacity="1.000000" stroke="none" 
	d="
M65.679688,101.025902 
	C65.560974,101.642014 65.096672,102.242630 64.014511,102.954330 
	C59.266289,104.381866 55.135925,105.698318 50.963486,106.692459 
	C50.277531,105.926392 49.633648,105.482620 48.989761,105.038849 
	C48.757889,103.949997 48.526012,102.861153 48.270248,101.660110 
	C49.337551,101.180893 50.347134,100.727592 51.672081,100.138397 
	C56.436329,100.338463 60.885212,100.674431 65.679688,101.025902 
z"/>
<path fill="#F6DB2C" opacity="1.000000" stroke="none" 
	d="
M88.575714,93.018448 
	C89.426590,93.661484 89.880905,94.295700 90.663712,95.336823 
	C91.333076,96.828369 91.673943,97.913010 92.003403,99.356186 
	C91.318054,101.149452 90.644104,102.584183 89.668327,104.013062 
	C88.963692,104.009468 88.560890,104.011719 88.158081,104.013962 
	C86.862946,102.243309 85.567802,100.472649 84.161568,98.017044 
	C84.367180,95.888069 84.683891,94.444046 85.000610,93.000015 
	C86.060120,93.003227 87.119637,93.006439 88.575714,93.018448 
z"/>
<path fill="#FADE1F" opacity="1.000000" stroke="none" 
	d="
M80.899094,95.711967 
	C78.267632,96.407242 75.586540,96.725235 72.500893,97.065918 
	C74.656227,94.718269 77.164574,92.090637 80.899094,95.711967 
z"/>
<path fill="#EFC907" opacity="1.000000" stroke="none" 
	d="
M98.696442,71.003983 
	C99.005676,71.716003 98.998230,72.478416 98.975845,73.621239 
	C98.651634,74.466133 98.342354,74.930618 97.594788,75.693962 
	C95.773529,75.997742 94.390556,76.002663 93.007584,76.007584 
	C93.007584,76.007584 93.001129,76.001160 92.998390,75.997238 
	C94.790352,74.347008 96.585045,72.700691 98.696442,71.003983 
z"/>
<path fill="#FADE1F" opacity="1.000000" stroke="none" 
	d="
M69.869553,97.863251 
	C68.939415,99.081650 67.818573,100.028313 66.369270,101.001404 
	C65.834396,98.380013 66.973618,97.156799 69.869553,97.863251 
z"/>
<path fill="#FADE1F" opacity="1.000000" stroke="none" 
	d="
M84.658310,93.052612 
	C84.683891,94.444046 84.367180,95.888069 84.009827,97.703201 
	C83.034103,97.598854 82.099022,97.123421 81.040955,96.352852 
	C82.050659,95.073547 83.183342,94.089378 84.658310,93.052612 
z"/>
<path fill="#FCC131" opacity="1.000000" stroke="none" 
	d="
M178.007782,159.012726 
	C178.450287,159.009857 178.892792,159.007004 179.917999,159.003296 
	C180.667969,159.003860 180.835205,159.005295 181.002441,159.006714 
	C180.998138,159.451752 180.993851,159.896790 181.001984,161.046692 
	C182.446045,163.224472 183.877701,164.697372 185.689041,166.560898 
	C181.276581,166.543198 184.061691,169.192169 183.562286,170.992828 
	C181.068710,171.003433 179.014114,171.009628 176.959518,171.015839 
	C176.650360,171.099625 176.074768,171.170990 176.070206,171.268997 
	C175.832687,176.359634 171.146286,177.006683 167.671555,179.643890 
	C166.491486,180.001785 165.745438,180.001190 164.999695,180.000305 
	C165.000000,180.000000 164.999390,180.000610 164.865524,179.715759 
	C163.816483,178.290756 162.901291,177.150589 162.386765,176.004837 
	C172.114044,173.835495 172.114044,173.835495 171.269150,171.319321 
	C168.838898,170.710846 167.419296,170.355423 166.057861,169.643799 
	C170.332886,166.683731 172.795914,163.167969 172.379822,157.967865 
	C173.502945,157.988251 174.251709,158.001358 175.033722,158.342621 
	C175.633911,159.322433 176.200867,159.974091 176.767822,160.625763 
	C177.181137,160.088089 177.594467,159.550400 178.007782,159.012726 
z"/>
<path fill="#FBAD36" opacity="1.000000" stroke="none" 
	d="
M162.354736,206.893967 
	C161.083496,203.999924 160.037766,200.882599 159.442368,197.363464 
	C161.245209,194.843002 162.597717,192.724350 164.070816,190.416809 
	C162.479279,189.524017 161.385300,188.910355 160.994049,188.690887 
	C161.624100,186.757004 162.031418,185.049118 162.740128,183.477112 
	C163.301498,182.231857 164.232498,181.153229 164.999390,180.000610 
	C164.999390,180.000610 165.000000,180.000000 165.064117,180.358673 
	C166.416550,181.146378 167.704895,181.575409 168.993225,182.004456 
	C168.993225,182.004456 169.008530,182.501999 168.751587,182.854523 
	C167.995422,183.473190 167.496185,183.739334 166.829803,184.093307 
	C166.775925,184.452911 166.889175,184.724686 167.005402,185.326370 
	C167.013733,186.095810 167.019058,186.535339 167.024384,186.974869 
	C165.687424,192.786453 167.837357,197.697342 170.990082,202.671570 
	C169.207413,203.570068 167.430099,204.144806 165.330780,204.859833 
	C164.199280,205.556961 163.389771,206.113815 162.354736,206.893967 
z"/>
<path fill="#F5BE36" opacity="1.000000" stroke="none" 
	d="
M183.869797,140.997681 
	C185.377808,141.513687 186.885834,142.029678 188.392670,142.548981 
	C186.959793,147.332474 187.522537,149.192810 190.704422,149.278595 
	C189.592056,154.627609 187.557343,156.086334 182.306702,154.763718 
	C181.514771,154.325546 181.235245,154.125214 180.975037,153.579987 
	C181.714462,151.781158 182.434570,150.327225 183.269836,148.640747 
	C182.481033,148.419937 181.737442,148.211777 180.866211,147.704254 
	C179.826004,145.940399 178.913452,144.475906 178.337769,143.007324 
	C179.450790,142.677444 180.226959,142.351654 181.313446,142.003128 
	C182.372467,141.652832 183.121124,141.325256 183.869797,140.997681 
M185.239029,144.173645 
	C185.406494,144.117096 185.573944,144.060547 185.741409,144.003998 
	C185.572968,143.949692 185.404510,143.895386 185.239029,144.173645 
z"/>
<path fill="#F8C642" opacity="1.000000" stroke="none" 
	d="
M184.001266,170.988434 
	C184.061691,169.192169 181.276581,166.543198 185.689041,166.560898 
	C183.877701,164.697372 182.446045,163.224472 181.007080,161.380447 
	C183.689697,160.612991 186.889191,158.199448 188.305817,162.970795 
	C188.403229,163.298874 190.255005,163.106079 191.848114,163.182098 
	C192.980927,167.148712 187.897873,167.788086 185.990387,170.842621 
	C184.900604,170.995148 184.450943,170.991791 184.001266,170.988434 
z"/>
<path fill="#FFEB52" opacity="1.000000" stroke="none" 
	d="
M180.955734,153.924866 
	C181.235245,154.125214 181.514771,154.325546 181.925293,154.751862 
	C180.507523,155.811798 178.958771,156.645798 176.873444,157.739014 
	C175.891418,158.003662 175.445953,158.009079 175.000473,158.014481 
	C174.251709,158.001358 173.502945,157.988251 172.418976,157.666077 
	C172.434113,156.527679 172.784454,155.698334 173.134796,154.869003 
	C172.139038,154.844543 171.134918,154.893204 170.149200,154.780716 
	C167.959488,154.530838 165.759003,154.278229 163.637344,153.735703 
	C163.487900,153.697479 163.947662,151.276779 164.124130,149.963928 
	C164.378250,149.997147 164.632370,150.030380 164.886475,150.063614 
	C164.954620,150.699158 165.022751,151.334686 165.152039,152.142853 
	C165.468582,152.207153 165.723984,152.098831 166.402588,151.991699 
	C168.235184,151.958817 169.644547,151.924713 171.303848,152.012161 
	C172.044235,152.385620 172.534668,152.637512 173.426697,152.944107 
	C176.204102,153.307495 178.579926,153.616180 180.955734,153.924866 
z"/>
<path fill="#F8BD44" opacity="1.000000" stroke="none" 
	d="
M191.601715,192.039322 
	C191.732620,193.104904 191.525345,194.201141 191.190018,195.610046 
	C187.367523,197.613235 183.764297,197.324387 180.195694,196.284683 
	C180.146027,195.797562 180.096375,195.310440 180.046707,194.823334 
	C181.798492,194.315750 183.550262,193.808182 185.901550,193.150391 
	C186.501083,193.000168 186.993576,193.015198 187.084320,193.181610 
	C187.449448,193.235321 187.723831,193.122620 187.999115,193.005600 
	C188.000031,193.001266 188.010300,193.001221 188.010300,193.001221 
	C189.094711,192.690811 190.179108,192.380402 191.601715,192.039322 
z"/>
<path fill="#F4B92B" opacity="1.000000" stroke="none" 
	d="
M183.562286,170.992828 
	C184.450943,170.991791 184.900604,170.995148 185.688080,170.998947 
	C186.025879,172.369751 186.025879,173.740097 186.025879,175.189301 
	C182.517670,175.657623 178.502869,177.154861 177.006516,171.374451 
	C179.014114,171.009628 181.068710,171.003433 183.562286,170.992828 
z"/>
<path fill="#FCD258" opacity="1.000000" stroke="none" 
	d="
M186.993576,193.015198 
	C186.993576,193.015198 186.501083,193.000168 186.254944,192.991119 
	C186.008820,192.982071 185.988205,192.514175 185.979431,192.280640 
	C186.590836,188.566177 186.138718,184.514725 190.416855,183.049393 
	C189.613647,186.114075 188.816330,189.156296 188.014648,192.599884 
	C188.010300,193.001221 188.000031,193.001266 187.747971,193.004425 
	C187.328461,193.010132 187.161011,193.012665 186.993576,193.015198 
z"/>
<path fill="#F8B644" opacity="1.000000" stroke="none" 
	d="
M167.359253,186.984680 
	C167.019058,186.535339 167.013733,186.095810 167.004410,185.078644 
	C167.000427,184.501007 166.996948,184.005493 166.996948,184.005493 
	C167.496185,183.739334 167.995422,183.473190 168.759125,183.103287 
	C170.155365,183.585434 171.287170,184.171310 172.711517,184.886337 
	C171.234100,185.675140 169.464127,186.334808 167.359253,186.984680 
z"/>
<path fill="#F8B644" opacity="1.000000" stroke="none" 
	d="
M168.890015,181.743164 
	C167.704895,181.575409 166.416550,181.146378 165.063812,180.358978 
	C165.745438,180.001190 166.491486,180.001785 167.610046,180.002838 
	C168.250656,180.496155 168.518738,180.989014 168.890015,181.743164 
z"/>
<path fill="#F8C642" opacity="1.000000" stroke="none" 
	d="
M175.033722,158.342621 
	C175.445953,158.009079 175.891418,158.003662 176.671173,158.003479 
	C177.106369,158.332626 177.294617,158.593582 177.788986,158.902130 
	C177.594467,159.550400 177.181137,160.088089 176.767822,160.625763 
	C176.200867,159.974091 175.633911,159.322433 175.033722,158.342621 
z"/>
<path fill="#F8C642" opacity="1.000000" stroke="none" 
	d="
M181.333252,159.000793 
	C180.835205,159.005295 180.667969,159.003860 180.249863,158.999115 
	C181.015121,158.360306 182.031250,157.724838 183.047363,157.089371 
	C183.233658,157.388672 183.419937,157.687973 183.606232,157.987259 
	C182.958832,158.323135 182.311447,158.659012 181.333252,159.000793 
z"/>
<path fill="#F7B94A" opacity="1.000000" stroke="none" 
	d="
M110.978477,200.942612 
	C114.597237,199.494827 118.215996,198.047058 121.040886,196.916885 
	C120.624023,193.420197 120.349457,191.117096 120.453735,188.442017 
	C123.548721,188.049179 126.264870,188.028336 129.381073,188.010208 
	C130.847977,188.016541 131.914841,188.020157 132.973541,188.394623 
	C132.465714,189.480530 132.075790,190.320129 131.439911,190.880661 
	C130.546783,191.667938 129.470886,192.247864 128.473160,192.916489 
	C129.174957,193.739151 130.038437,194.477203 130.527267,195.411331 
	C130.916306,196.154770 130.847763,197.137680 130.734924,198.028290 
	C130.227707,198.199585 129.967941,198.356873 129.708191,198.514160 
	C130.140457,198.663834 130.572739,198.813507 131.350769,198.999268 
	C132.800339,199.356445 133.904175,199.677536 135.139069,200.309265 
	C134.855606,202.081573 134.441101,203.543243 134.026581,205.004929 
	C131.394135,206.756058 128.761703,208.507202 125.717270,210.650024 
	C123.875595,212.029587 122.445930,213.017471 120.750626,214.175323 
	C119.829262,215.037094 119.173531,215.728867 118.517792,216.420654 
	C115.795746,213.742706 113.470680,216.371246 110.823677,216.739594 
	C110.090820,216.331650 109.529053,216.167542 108.963837,215.637665 
	C108.939537,214.543106 108.918671,213.814316 108.927246,212.743103 
	C109.319847,211.394318 109.683022,210.387939 110.046188,209.381577 
	C109.397469,210.021805 108.748749,210.662033 108.100037,211.302261 
	C107.750359,209.857117 107.400688,208.411972 107.457733,206.977844 
	C113.932968,203.811218 120.316742,207.170822 126.711563,206.000427 
	C124.896454,204.476471 122.939438,203.589355 120.889542,203.066101 
	C117.618324,202.231064 114.285347,201.637985 110.978477,200.942612 
z"/>
<path fill="#E98C1E" opacity="1.000000" stroke="none" 
	d="
M137.889557,229.770294 
	C137.102112,230.430252 136.170044,230.855087 135.038330,230.782135 
	C134.448532,229.074219 134.058380,227.864105 133.509781,226.162552 
	C130.155685,228.917938 127.078667,231.445724 124.004196,233.576782 
	C124.012680,232.120621 124.018623,231.061172 124.334732,229.858749 
	C125.174919,228.581985 126.181740,227.429169 126.137413,226.318314 
	C126.039970,223.875931 125.411263,221.454758 124.999405,219.024902 
	C126.849609,219.348923 129.217529,220.554153 130.451492,219.829224 
	C133.942490,217.778305 136.998932,218.879272 140.615082,220.306335 
	C141.003296,221.134109 141.012009,221.578857 141.020737,222.023605 
	C140.209488,223.410858 139.398224,224.798126 138.264557,225.752075 
	C137.268951,224.612366 136.595749,223.905975 135.922546,223.199585 
	C135.619324,223.621796 135.316101,224.044006 135.012863,224.466217 
	C135.994324,224.991882 136.975769,225.517548 137.957214,226.043228 
	C137.886444,227.207214 137.815689,228.371201 137.889557,229.770294 
z"/>
<path fill="#E59336" opacity="1.000000" stroke="none" 
	d="
M124.680649,219.039566 
	C125.411263,221.454758 126.039970,223.875931 126.137413,226.318314 
	C126.181740,227.429169 125.174919,228.581985 124.033577,229.996628 
	C122.527802,230.784622 121.633347,231.291748 120.839096,231.742081 
	C119.769295,229.942520 118.888672,228.461197 117.965958,226.618607 
	C117.229645,224.481644 116.535423,222.705917 116.143478,221.703369 
	C118.686890,220.962524 120.345993,220.479263 122.005089,219.996002 
	C122.790688,219.682068 123.576294,219.368149 124.680649,219.039566 
z"/>
<path fill="#E99B3B" opacity="1.000000" stroke="none" 
	d="
M121.905029,219.734680 
	C120.345993,220.479263 118.686890,220.962524 116.143478,221.703369 
	C116.535423,222.705917 117.229645,224.481644 117.584091,226.613800 
	C116.519440,226.452606 115.258705,226.022263 115.157806,225.402939 
	C114.561699,221.744141 111.948837,221.194489 108.991135,220.633575 
	C108.988174,219.505417 108.985405,218.753510 109.283463,217.979370 
	C110.054451,217.632553 110.524605,217.307999 110.994766,216.983429 
	C113.470680,216.371246 115.795746,213.742706 118.517792,216.420654 
	C119.173531,215.728867 119.829262,215.037094 121.032654,214.305634 
	C122.395744,215.198105 123.211189,216.130249 124.026634,217.062378 
	C123.286079,217.866028 122.545525,218.669693 121.905029,219.734680 
z"/>
<path fill="#E9AB4E" opacity="1.000000" stroke="none" 
	d="
M134.021729,205.398605 
	C134.441101,203.543243 134.855606,202.081573 135.271088,200.001892 
	C135.871765,198.250320 136.471481,197.116730 137.071198,195.983154 
	C139.374771,201.681259 140.289368,208.257828 146.924408,211.617981 
	C144.623505,211.191910 142.166351,210.463104 139.709198,209.734314 
	C139.767792,209.250977 139.826401,208.767624 139.884995,208.284286 
	C137.897003,209.348129 135.908997,210.411972 133.585693,211.655228 
	C133.741302,209.539276 133.879089,207.665787 134.021729,205.398605 
z"/>
<path fill="#F8B644" opacity="1.000000" stroke="none" 
	d="
M150.137985,214.360306 
	C154.204910,214.043121 158.417313,214.043121 163.022079,214.043121 
	C163.022079,215.498062 163.022079,216.848770 163.031067,218.581161 
	C159.596619,218.597672 156.108521,218.444748 152.737396,217.735687 
	C151.748734,217.527740 151.092209,215.740768 150.137985,214.360306 
z"/>
<path fill="#EF9C27" opacity="1.000000" stroke="none" 
	d="
M124.403313,217.047607 
	C123.211189,216.130249 122.395744,215.198105 121.298279,214.135651 
	C122.445930,213.017471 123.875595,212.029587 125.645615,211.005402 
	C126.251877,211.164261 126.517792,211.359406 126.592529,211.818329 
	C126.132713,212.729172 125.864075,213.376251 125.595436,214.023331 
	C125.981422,214.117172 126.367401,214.210999 126.753380,214.304840 
	C126.834328,213.535294 126.915268,212.765747 126.996216,211.996201 
	C127.441399,211.998505 127.886597,212.000824 128.672806,212.341888 
	C129.353943,213.441650 129.694046,214.202667 130.034164,214.963669 
	C128.282776,215.653397 126.531380,216.343109 124.403313,217.047607 
z"/>
<path fill="#EF9C27" opacity="1.000000" stroke="none" 
	d="
M141.389069,222.016907 
	C141.012009,221.578857 141.003296,221.134109 140.990112,220.356445 
	C141.959656,220.023544 142.933670,220.023544 143.942917,220.023544 
	C143.755630,218.877426 143.603424,217.946014 143.275543,215.939636 
	C145.518753,217.198563 146.952454,218.003189 148.459305,218.848877 
	C147.384872,220.742416 146.454163,222.382629 145.818939,223.502121 
	C144.409836,222.984512 143.083618,222.497360 141.389069,222.016907 
z"/>
<path fill="#E9AB4E" opacity="1.000000" stroke="none" 
	d="
M130.280548,214.793304 
	C129.694046,214.202667 129.353943,213.441650 129.006683,212.345001 
	C131.310989,211.827271 132.094879,212.537903 130.280548,214.793304 
z"/>
<path fill="#F6E006" opacity="1.000000" stroke="none" 
	d="
M67.336494,67.955734 
	C66.574898,67.033966 66.131790,66.070633 65.344398,65.053802 
	C66.451462,64.908699 67.902794,64.817101 69.674019,64.869049 
	C69.214264,65.979790 68.434624,66.946983 67.336494,67.955734 
z"/>
<path fill="#FDF30F" opacity="1.000000" stroke="none" 
	d="
M161.995117,60.003891 
	C161.869736,59.861401 161.749237,59.715019 161.628738,59.568642 
	C161.717056,59.616783 161.805374,59.664928 161.950623,59.851677 
	C162.007538,59.990284 162.000000,60.000000 161.995117,60.003891 
z"/>
<path fill="#FDE22D" opacity="1.000000" stroke="none" 
	d="
M188.996078,108.607834 
	C188.232620,109.324570 187.457474,109.662956 186.307434,109.662209 
	C185.025223,108.727768 184.117859,108.132469 183.210510,107.537170 
	C182.474487,109.352287 181.738464,111.167404 180.644196,112.916100 
	C179.637634,110.985626 178.059753,108.826889 178.537506,107.319824 
	C179.486526,104.326294 177.915451,104.252243 175.984726,103.607513 
	C174.963501,102.190231 173.969376,101.100334 172.975250,100.010445 
	C172.993713,99.845917 172.969666,99.557709 173.036743,99.534645 
	C175.976364,98.524422 181.673981,101.179108 182.258499,103.912704 
	C186.866394,101.646889 188.330292,104.320976 188.996078,108.607834 
z"/>
<path fill="#F9D412" opacity="1.000000" stroke="none" 
	d="
M172.761902,100.116760 
	C173.969376,101.100334 174.963501,102.190231 176.078247,103.898544 
	C175.544754,105.655533 174.890640,106.794106 174.245819,107.916504 
	C175.001022,108.539345 176.056213,109.409584 177.847580,110.886993 
	C175.753677,111.803101 174.387863,112.400673 172.722580,113.136871 
	C171.791626,113.660759 171.160141,114.046021 170.528656,114.431282 
	C170.152924,114.238739 169.777191,114.046204 169.401459,113.853661 
	C170.257538,110.566948 171.113617,107.280243 171.984344,103.618835 
	C172.000412,102.493347 172.001846,101.742561 172.003281,100.991776 
	C172.104172,100.678162 172.285934,100.421928 172.761902,100.116760 
z"/>
<path fill="#FDE22D" opacity="1.000000" stroke="none" 
	d="
M171.703094,100.993896 
	C172.001846,101.742561 172.000412,102.493347 171.685425,103.619827 
	C170.972534,103.993965 170.573227,103.992401 170.173904,103.990837 
	C170.127808,103.240845 170.081696,102.490845 170.120819,101.370728 
	C170.605011,100.999077 171.003952,100.997543 171.703094,100.993896 
z"/>
<path fill="#FEEE0A" opacity="1.000000" stroke="none" 
	d="
M95.000305,90.009186 
	C96.457047,88.099648 97.913788,86.190109 99.980469,84.267227 
	C102.388901,84.507370 104.187408,84.760849 106.002380,85.252701 
	C106.141754,85.762299 106.264656,86.033516 106.387573,86.304733 
	C106.509003,86.215492 106.741623,86.056236 106.735847,86.047066 
	C106.508690,85.686272 106.259850,85.339134 106.014809,84.649109 
	C105.865685,80.758385 108.973320,77.825417 113.214462,77.107521 
	C114.244667,76.933136 115.101028,75.731796 116.037331,75.002670 
	C116.508926,74.742630 116.980530,74.482590 117.943085,74.380997 
	C119.623062,74.693657 120.812073,74.847878 122.138260,75.319992 
	C122.513412,77.771423 122.751389,79.904945 122.644196,81.975235 
	C120.740570,80.885979 119.812790,80.200035 117.307236,81.284027 
	C112.233185,83.479271 112.096214,86.736099 112.813339,91.278839 
	C107.889343,95.052200 105.142944,89.141640 101.171066,89.193085 
	C100.436859,89.202591 99.709175,89.715637 98.644043,90.049255 
	C97.517471,90.943230 96.725342,91.784233 95.582977,92.997086 
	C95.333481,91.717705 95.166893,90.863441 95.000305,90.009186 
M108.470604,82.370583 
	C108.524292,82.159874 108.577988,81.949158 108.631683,81.738449 
	C108.500198,81.873604 108.368713,82.008766 108.470604,82.370583 
z"/>
<path fill="#F2D407" opacity="1.000000" stroke="none" 
	d="
M115.964310,74.645157 
	C115.101028,75.731796 114.244667,76.933136 113.214462,77.107521 
	C108.973320,77.825417 105.865685,80.758385 106.007500,84.654411 
	C105.999229,85.000801 105.985909,85.014328 105.985909,85.014328 
	C104.187408,84.760849 102.388901,84.507370 100.295410,84.125999 
	C100.744652,82.520027 101.488884,81.041954 102.114029,79.273376 
	C105.695190,74.686882 111.756096,72.639046 115.964310,74.645157 
z"/>
<path fill="#F2D407" opacity="1.000000" stroke="none" 
	d="
M122.001091,75.002090 
	C120.812073,74.847878 119.623062,74.693657 118.189980,74.271957 
	C119.067627,70.709511 120.189346,67.414536 121.679794,64.060753 
	C121.845024,65.857674 121.641533,67.713409 121.619980,70.045334 
	C122.207596,71.006134 122.613266,71.490753 123.018936,71.975372 
	C123.458694,71.977776 123.898453,71.980186 124.667984,71.981323 
	C125.001091,72.152145 125.004425,72.324249 124.725143,72.871933 
	C123.628708,73.832367 122.814896,74.417229 122.001091,75.002090 
z"/>
<path fill="#F9DD0F" opacity="1.000000" stroke="none" 
	d="
M93.007782,76.388596 
	C94.390556,76.002663 95.773529,75.997742 97.570915,75.986542 
	C98.429153,76.952599 98.872986,77.924950 99.663231,78.953995 
	C99.022888,79.832718 97.300613,81.475037 97.162186,81.350922 
	C95.635544,79.982071 94.355103,78.338654 93.007782,76.388596 
z"/>
<path fill="#FDF908" opacity="1.000000" stroke="none" 
	d="
M101.386200,73.999023 
	C101.160049,68.256386 101.470329,68.056664 106.806206,70.693748 
	C105.283775,71.981926 103.528542,72.989372 101.386200,73.999023 
z"/>
<path fill="#F8EA23" opacity="1.000000" stroke="none" 
	d="
M115.000931,62.999275 
	C115.121994,63.139637 115.242126,63.280724 115.362251,63.421814 
	C115.277420,63.372990 115.192589,63.324165 115.053177,63.138580 
	C114.998589,63.001823 115.000000,63.000000 115.000931,62.999275 
z"/>
<path fill="#F9D047" opacity="1.000000" stroke="none" 
	d="
M181.003113,142.025848 
	C180.226959,142.351654 179.450790,142.677444 177.965836,143.003021 
	C175.506561,143.674179 173.756073,144.345566 171.999863,145.002411 
	C171.994125,144.987869 171.979248,144.960388 171.989746,144.599548 
	C172.965866,138.636002 170.828690,136.135162 165.682907,137.992401 
	C166.816391,140.390808 167.920059,142.726120 169.013458,145.403412 
	C168.362030,146.304214 167.320831,147.450104 167.143005,147.328949 
	C165.726212,146.363525 164.473175,145.157806 163.169907,144.025787 
	C163.552536,139.483337 163.935165,134.940887 164.803131,130.405319 
	C166.510193,130.615967 167.731903,130.819748 168.972366,131.262146 
	C168.991104,131.500793 169.005188,131.979324 168.690170,131.983124 
	C167.818710,132.204788 167.262253,132.422668 166.705811,132.640564 
	C167.451431,133.202927 168.197037,133.765274 168.942657,134.327637 
	C169.310120,133.572571 169.677582,132.817505 170.438980,132.056564 
	C171.882401,132.055862 172.931900,132.061035 173.981384,132.066208 
	C174.624649,132.834747 175.267899,133.603302 175.945114,134.679138 
	C175.982361,135.186798 175.985657,135.387146 175.970123,136.213959 
	C175.748444,137.721924 175.545593,138.603424 175.612625,138.312134 
	C177.945435,139.919296 179.474274,140.972580 181.003113,142.025848 
z"/>
<path fill="#F4C70E" opacity="1.000000" stroke="none" 
	d="
M173.022049,112.998245 
	C174.387863,112.400673 175.753677,111.803101 177.847580,110.886993 
	C176.056213,109.409584 175.001022,108.539345 174.245819,107.916504 
	C174.890640,106.794106 175.544754,105.655533 176.105347,104.225937 
	C177.915451,104.252243 179.486526,104.326294 178.537506,107.319824 
	C178.059753,108.826889 179.637634,110.985626 180.646301,113.166603 
	C181.010452,113.650352 181.014267,113.817177 181.018097,113.984009 
	C181.011871,114.449768 181.005646,114.915520 180.699142,115.735115 
	C179.933884,116.394157 179.468887,116.699371 178.666779,116.951157 
	C176.560455,115.597908 174.791260,114.298080 173.022049,112.998245 
z"/>
<path fill="#EAB915" opacity="1.000000" stroke="none" 
	d="
M173.991196,131.654907 
	C172.931900,132.061035 171.882401,132.055862 170.179443,132.026428 
	C169.352371,131.994537 169.178787,131.986938 169.005188,131.979324 
	C169.005188,131.979324 168.991104,131.500793 169.039856,130.915237 
	C170.060455,127.554367 171.032288,124.779053 172.305206,122.131584 
	C173.416489,123.160408 174.226669,124.061386 175.036850,124.962364 
	C174.691559,127.056114 174.346283,129.149872 173.991196,131.654907 
z"/>
<path fill="#F9DF3B" opacity="1.000000" stroke="none" 
	d="
M175.337769,125.013596 
	C174.226669,124.061386 173.416489,123.160408 172.309998,121.746750 
	C174.164291,115.736557 176.670258,120.992363 179.001434,121.001526 
	C179.000000,121.000000 178.997559,120.997841 178.997559,120.997841 
	C178.883026,122.790276 178.768509,124.582703 178.621658,126.881035 
	C177.364990,126.115913 176.501831,125.590370 175.337769,125.013596 
z"/>
<path fill="#F6DB2C" opacity="1.000000" stroke="none" 
	d="
M181.354965,113.988235 
	C181.014267,113.817177 181.010452,113.650352 181.004532,113.233025 
	C181.738464,111.167404 182.474487,109.352287 183.210510,107.537170 
	C184.117859,108.132469 185.025223,108.727768 185.968597,109.667618 
	C185.185638,111.220573 184.366638,112.428978 183.294617,113.815048 
	C182.591675,113.992622 182.141754,113.992538 181.354965,113.988235 
z"/>
<path fill="#F9DF3B" opacity="1.000000" stroke="none" 
	d="
M179.003906,117.004578 
	C179.468887,116.699371 179.933884,116.394157 180.691681,116.084381 
	C180.566193,117.671242 180.147888,119.262665 179.363571,120.925964 
	C178.997559,120.997841 179.000000,121.000000 179.003403,120.601517 
	C179.005844,119.136879 179.004868,118.070732 179.003906,117.004578 
z"/>
<path fill="#EF9C27" opacity="1.000000" stroke="none" 
	d="
M74.934845,201.863617 
	C74.934837,201.863617 74.966789,201.401306 75.267586,201.108063 
	C75.808884,200.602844 75.986107,200.347000 76.468018,200.029419 
	C78.074738,199.338135 79.377113,198.758804 80.539391,197.972397 
	C83.200768,196.171722 84.009453,197.311569 83.713905,200.191833 
	C83.581268,202.450729 83.700081,204.511368 83.828888,206.745300 
	C85.112228,207.136230 86.530251,207.568192 87.820648,208.341675 
	C87.794617,210.451767 87.896225,212.220352 87.995209,214.367310 
	C88.006310,215.502716 88.020027,216.259720 87.975403,217.333008 
	C87.274017,218.097961 86.630959,218.546631 85.987907,218.995300 
	C85.013481,217.532318 84.039055,216.069351 83.032211,214.303192 
	C82.999794,214.000000 83.000191,214.003860 83.171326,213.913849 
	C83.407303,213.713531 83.553383,213.545013 83.523155,213.502853 
	C83.380775,213.304230 83.190056,213.140244 83.010574,212.615265 
	C82.323097,211.177780 81.641624,210.088943 80.917130,208.682159 
	C79.591293,208.243179 78.308464,208.122147 76.756973,207.884003 
	C74.585617,207.073929 72.325203,206.816238 70.849617,205.603561 
	C66.957275,202.404739 65.241325,204.191986 63.720093,207.863190 
	C62.823879,206.993423 61.589733,206.073456 61.738152,205.533539 
	C62.264545,203.618652 63.262852,201.833496 64.082367,199.999191 
	C66.804581,201.315628 69.526802,202.632080 72.953125,203.928894 
	C74.083099,203.227386 74.508972,202.545502 74.934845,201.863617 
z"/>
<path fill="#E59336" opacity="1.000000" stroke="none" 
	d="
M91.965111,229.028183 
	C90.226944,229.028183 88.488785,229.028183 85.909355,229.028183 
	C86.660835,226.805740 87.193367,225.230835 88.236115,223.319702 
	C89.485107,222.974426 90.223892,222.965378 91.292404,222.835480 
	C92.746407,222.480347 93.870667,222.246063 95.031700,222.367188 
	C95.041183,224.808258 95.013885,226.893967 94.609299,228.984283 
	C93.476372,229.002014 92.720741,229.015091 91.965111,229.028183 
z"/>
<path fill="#FAAB28" opacity="1.000000" stroke="none" 
	d="
M91.978127,229.357956 
	C92.720741,229.015091 93.476372,229.002014 94.990143,228.990845 
	C99.168282,230.661514 102.588280,232.330261 106.004250,233.999741 
	C106.000221,234.000473 106.003746,233.991440 105.968414,234.305786 
	C105.285385,235.412445 104.637703,236.204758 103.990021,236.997070 
	C102.264160,236.507507 100.538315,236.017960 98.477684,235.433426 
	C98.034668,232.402725 97.106102,229.421219 92.366592,230.975952 
	C92.011490,230.568329 92.001320,230.128036 91.978127,229.357956 
z"/>
<path fill="#F8C146" opacity="1.000000" stroke="none" 
	d="
M90.962669,222.956329 
	C90.223892,222.965378 89.485107,222.974426 88.377136,222.986664 
	C87.365128,221.878571 86.722298,220.767288 86.033691,219.325653 
	C86.630959,218.546631 87.274017,218.097961 88.325043,217.290619 
	C90.490410,216.290375 92.247810,215.648804 93.966751,215.362701 
	C92.939751,218.130890 91.951210,220.543610 90.962669,222.956329 
z"/>
<path fill="#EF9C27" opacity="1.000000" stroke="none" 
	d="
M73.371613,220.960922 
	C73.403107,219.518463 73.787346,218.054291 74.400017,216.261765 
	C75.751160,214.933792 76.873863,213.934158 78.329025,212.942902 
	C79.104706,212.959122 79.547928,212.966934 80.152977,213.227570 
	C80.875038,213.653732 81.435272,213.827072 81.995506,214.000427 
	C79.238457,216.313354 76.481407,218.626266 73.371613,220.960922 
z"/>
<path fill="#F7B135" opacity="1.000000" stroke="none" 
	d="
M82.247620,214.000214 
	C81.435272,213.827072 80.875038,213.653732 80.153061,212.867035 
	C80.314255,211.169144 80.637199,210.084610 80.960144,209.000092 
	C81.641624,210.088943 82.323097,211.177780 83.004135,212.875427 
	C83.002518,213.657425 83.001358,213.830643 83.000191,214.003860 
	C83.000191,214.003860 82.999794,214.000000 83.001877,214.000000 
	C82.835876,214.000000 82.667801,214.000000 82.247620,214.000214 
z"/>
<path fill="#F9D23A" opacity="1.000000" stroke="none" 
	d="
M204.473175,104.455383 
	C204.525955,104.424057 204.420395,104.486710 204.473175,104.455383 
z"/>
<path fill="#FBEB18" opacity="1.000000" stroke="none" 
	d="
M196.997467,94.001961 
	C197.116562,94.143127 197.238159,94.282333 197.359772,94.421539 
	C197.275162,94.371841 197.190567,94.322144 197.054962,94.133636 
	C197.003967,93.994835 197.000000,94.000000 196.997467,94.001961 
z"/>
<path fill="#F9D412" opacity="1.000000" stroke="none" 
	d="
M154.879608,92.766251 
	C150.243866,98.963654 145.460022,104.841858 140.020172,110.862923 
	C138.905090,111.008553 138.446045,111.011322 137.962555,111.024048 
	C137.938110,111.034004 137.885483,111.030106 137.910202,110.774635 
	C137.943726,110.348282 137.952515,110.177399 138.136414,109.919037 
	C138.378891,109.721306 138.528061,109.547501 138.500031,109.511429 
	C138.348846,109.316902 138.153732,109.156540 137.967926,108.618423 
	C137.963470,107.520409 137.964859,106.788391 137.951736,105.707169 
	C137.268478,103.878464 136.599731,102.398949 135.940857,100.505547 
	C135.826508,97.851593 136.957184,94.923409 132.959625,94.454712 
	C132.507980,93.049896 131.567810,91.203911 131.845596,90.997551 
	C133.031586,90.116577 134.575317,89.244705 135.992233,89.238670 
	C139.738266,89.222725 143.672485,90.251091 146.003403,86.000320 
	C148.912766,88.149231 151.822144,90.298141 154.879608,92.766251 
M137.591721,97.515587 
	C137.591721,97.515587 137.475677,97.585167 137.591721,97.515587 
z"/>
<path fill="#FADE1F" opacity="1.000000" stroke="none" 
	d="
M146.042267,85.634781 
	C143.672485,90.251091 139.738266,89.222725 135.992233,89.238670 
	C134.575317,89.244705 133.031586,90.116577 131.845596,90.997551 
	C131.567810,91.203911 132.507980,93.049896 132.576965,94.479370 
	C130.173309,93.879601 128.080475,92.941986 126.188858,91.727875 
	C127.259445,89.970116 128.128815,88.488869 129.001617,86.592285 
	C130.046829,85.123161 131.045120,83.233627 132.138763,83.176712 
	C135.088043,83.023232 138.077972,83.650688 141.051758,83.968231 
	C142.809036,83.006836 144.566315,82.045441 146.637634,81.036545 
	C146.661514,82.415779 146.371323,83.842506 146.042267,85.634781 
z"/>
<path fill="#FBB03A" opacity="1.000000" stroke="none" 
	d="
M63.994884,199.742783 
	C63.262852,201.833496 62.264545,203.618652 61.738152,205.533539 
	C61.589733,206.073456 62.823879,206.993423 63.934486,207.975708 
	C64.713326,208.412125 64.899666,208.670929 65.004089,208.988708 
	C60.394581,213.908905 57.115513,209.937927 53.552185,206.861740 
	C58.259861,205.997528 60.562325,202.385193 63.003029,198.948944 
	C63.304493,199.128098 63.605953,199.307236 63.994884,199.742783 
z"/>
<path fill="#F59C1A" opacity="1.000000" stroke="none" 
	d="
M65.319260,209.033112 
	C64.899666,208.670929 64.713326,208.412125 64.230682,208.099792 
	C65.241325,204.191986 66.957275,202.404739 70.849617,205.603561 
	C72.325203,206.816238 74.585617,207.073929 76.552704,208.180237 
	C73.407837,209.377487 70.198578,210.161438 66.989319,210.945374 
	C66.537689,210.322754 66.086060,209.700134 65.319260,209.033112 
z"/>
<path fill="#ED9F31" opacity="1.000000" stroke="none" 
	d="
M62.878216,198.661926 
	C60.562325,202.385193 58.259861,205.997528 53.230991,206.716293 
	C52.996376,206.997147 52.498405,206.998260 52.249420,206.998520 
	C51.139458,202.587952 54.335857,201.006424 58.011612,199.106277 
	C59.123814,198.232452 59.588188,197.559738 60.052559,196.887039 
	C60.952839,197.382996 61.853119,197.878952 62.878216,198.661926 
z"/>
<path fill="#F9D047" opacity="1.000000" stroke="none" 
	d="
M66.982117,211.291626 
	C70.198578,210.161438 73.407837,209.377487 76.821365,208.297333 
	C78.308464,208.122147 79.591293,208.243179 80.917130,208.682159 
	C80.637199,210.084610 80.314255,211.169144 79.991226,212.614197 
	C79.547928,212.966934 79.104706,212.959122 78.101028,212.684723 
	C74.686981,212.610214 71.833382,212.802292 68.979790,212.994385 
	C68.536270,212.998566 68.092751,213.002762 67.316345,213.014572 
	C66.980614,212.560760 66.977760,212.099304 66.982117,211.291626 
z"/>
<path fill="#F39622" opacity="1.000000" stroke="none" 
	d="
M147.824310,250.918106 
	C147.702057,252.119781 147.268631,253.199158 146.795044,254.630981 
	C143.550690,255.997986 140.387939,257.191589 137.123779,257.947083 
	C135.624283,258.294128 133.925858,257.781738 132.143158,257.358459 
	C131.400513,256.090424 130.832321,255.122986 130.074310,253.832321 
	C133.534424,252.180618 136.888016,250.579742 140.631134,248.965591 
	C143.184830,249.566818 145.348969,250.181305 147.824310,250.918106 
z"/>
<path fill="#FFEB52" opacity="1.000000" stroke="none" 
	d="
M181.313446,142.003128 
	C179.474274,140.972580 177.945435,139.919296 175.612625,138.312134 
	C175.545593,138.603424 175.748444,137.721924 175.987213,136.514038 
	C178.141678,136.029358 180.260239,135.871033 182.690430,136.183167 
	C183.311142,137.104980 183.620193,137.556335 183.929230,138.007690 
	C183.922745,138.755310 183.916245,139.502930 183.889771,140.624115 
	C183.121124,141.325256 182.372467,141.652832 181.313446,142.003128 
M181.489777,138.594162 
	C181.489777,138.594162 181.415253,138.474304 181.489777,138.594162 
z"/>
<path fill="#F5BE36" opacity="1.000000" stroke="none" 
	d="
M184.164001,137.908432 
	C183.620193,137.556335 183.311142,137.104980 182.950790,136.373108 
	C183.483978,136.231064 184.068466,136.369522 184.830078,136.760468 
	C184.804382,137.278381 184.601562,137.543777 184.164001,137.908432 
z"/>
<path fill="#FBD233" opacity="1.000000" stroke="none" 
	d="
M69.994568,140.073074 
	C69.994568,140.073074 69.946243,140.515976 69.539215,140.805298 
	C68.740845,142.069458 68.349503,143.044327 67.660294,143.884308 
	C67.007858,142.534256 66.642738,141.322037 66.301826,140.103058 
	C65.989296,138.985565 65.785713,136.922180 65.391907,136.887421 
	C62.190983,136.604996 58.954178,136.645340 55.736732,136.783554 
	C55.461067,136.795410 55.246277,138.224426 54.601387,138.997955 
	C52.910355,139.539932 50.988022,139.720993 50.444496,140.681931 
	C48.385994,144.321289 46.774380,148.213409 44.609333,152.055222 
	C42.179852,152.402191 40.136635,152.704910 38.076626,152.582123 
	C37.781937,149.752975 37.504036,147.349319 37.226143,144.945663 
	C37.216652,144.515945 37.207165,144.086227 37.249840,143.339264 
	C37.560837,141.578995 37.819672,140.135986 38.416046,138.332214 
	C39.166824,137.974625 39.580051,137.977768 40.138161,138.281631 
	C43.436951,142.580627 44.924332,140.532150 46.002163,137.002762 
	C46.000000,137.000000 46.005482,137.004578 46.378349,137.005676 
	C48.527000,136.331497 50.302784,135.656235 52.454422,134.936127 
	C57.134430,134.253113 61.438587,133.614960 66.678963,132.837997 
	C66.717857,132.203232 66.842690,130.165955 67.259109,128.090790 
	C67.744896,128.026337 67.939110,127.999733 68.421967,127.970917 
	C69.094849,127.950279 69.479088,127.931854 69.893730,128.379150 
	C69.947617,132.587616 69.971092,136.330338 69.994568,140.073074 
z"/>
<path fill="#F3C455" opacity="1.000000" stroke="none" 
	d="
M37.121246,145.410553 
	C37.504036,147.349319 37.781937,149.752975 38.044876,153.015778 
	C38.029346,155.903564 38.028778,157.932205 38.021122,160.371094 
	C38.457981,165.968079 39.750515,166.744598 45.383759,165.000549 
	C46.492199,166.008759 47.231922,167.003830 47.756248,168.280365 
	C47.017448,170.065399 46.494038,171.568954 45.866493,173.265991 
	C45.582062,173.703506 45.346455,173.868652 44.782623,173.938446 
	C44.327549,173.915405 44.145390,173.908813 43.861267,173.706985 
	C43.571484,173.271271 43.330956,173.108734 42.839081,172.768066 
	C41.423069,172.676117 40.205692,172.840271 38.988319,173.004425 
	C38.533699,171.835663 38.079079,170.666916 37.391769,169.270752 
	C37.111511,161.320709 37.063934,153.598083 37.121246,145.410553 
z"/>
<path fill="#F9EE32" opacity="1.000000" stroke="none" 
	d="
M48.731014,105.253113 
	C49.633648,105.482620 50.277531,105.926392 50.820007,107.008606 
	C48.826679,110.311424 48.025124,114.911072 43.002472,111.677490 
	C43.011818,110.924599 43.010769,110.494530 43.027031,110.054504 
	C43.044342,110.044556 43.082893,110.031410 43.082893,110.031410 
	C44.879349,108.510063 46.675808,106.988724 48.731014,105.253113 
z"/>
<path fill="#F8D02B" opacity="1.000000" stroke="none" 
	d="
M39.042183,109.018433 
	C39.179077,107.832893 39.315971,106.647354 39.726662,105.228973 
	C42.978302,105.076935 43.733109,106.704178 43.055145,109.689415 
	C43.082893,110.031410 43.044342,110.044556 42.678898,110.029396 
	C41.223026,109.682304 40.132603,109.350372 39.042183,109.018433 
z"/>
<path fill="#F39622" opacity="1.000000" stroke="none" 
	d="
M104.102829,237.221191 
	C104.637703,236.204758 105.285385,235.412445 106.321426,234.307129 
	C108.142014,234.996078 109.574242,235.998032 111.006462,237.000000 
	C109.208649,237.247757 107.410828,237.495514 105.317368,237.882965 
	C104.753021,237.830215 104.484329,237.637772 104.102829,237.221191 
z"/>
<path fill="#F7B135" opacity="1.000000" stroke="none" 
	d="
M60.030014,196.481201 
	C59.588188,197.559738 59.123814,198.232452 58.327545,198.949860 
	C57.276245,198.463409 56.601131,197.567657 55.828613,197.473816 
	C53.977467,197.248962 52.080517,197.401123 48.997257,197.401123 
	C48.672348,198.226151 47.873703,200.254135 47.035404,202.651779 
	C42.664433,202.730377 44.798332,198.663071 43.456314,196.234406 
	C44.743530,193.835846 45.884567,191.914276 47.341606,189.851227 
	C49.449108,187.795197 51.240601,185.880630 53.389381,183.976227 
	C56.230885,185.334198 58.715099,186.682007 61.199314,188.029831 
	C60.802029,190.711670 60.404743,193.393524 60.030014,196.481201 
z"/>
<path fill="#F7B94A" opacity="1.000000" stroke="none" 
	d="
M124.001648,233.973511 
	C127.078667,231.445724 130.155685,228.917938 133.509781,226.162552 
	C134.058380,227.864105 134.448532,229.074219 134.842346,230.616760 
	C132.684921,231.913055 130.523834,232.876923 128.362747,233.840790 
	C128.701996,234.541397 129.041260,235.242004 129.697174,235.970245 
	C129.443237,236.503479 128.872620,237.009064 127.907196,237.768219 
	C127.512390,238.021805 127.031136,238.063828 126.919456,237.809296 
	C126.339798,237.287354 125.871819,237.019943 125.403839,236.752533 
	C125.265442,237.172424 125.127060,237.592316 124.713074,237.779816 
	C124.292191,236.356140 124.146919,235.164825 124.001648,233.973511 
z"/>
<path fill="#F5AC4C" opacity="1.000000" stroke="none" 
	d="
M157.989075,240.010056 
	C157.820450,239.858429 157.659821,239.699585 157.499191,239.540741 
	C157.624619,239.576233 157.750046,239.611725 157.942047,239.818054 
	C158.008636,239.988907 157.997070,240.002823 157.989075,240.010056 
z"/>
<path fill="#F7AF2D" opacity="1.000000" stroke="none" 
	d="
M53.032101,183.966080 
	C51.240601,185.880630 49.449108,187.795197 47.298660,189.500031 
	C46.365883,188.247803 45.792057,187.205276 45.583111,186.825668 
	C42.605843,187.305237 40.319881,187.673447 38.033920,188.041656 
	C38.250237,183.286499 38.466557,178.531357 38.835598,173.390320 
	C40.205692,172.840271 41.423069,172.676117 42.838257,172.994843 
	C43.345123,173.619202 43.654179,173.760712 43.963234,173.902222 
	C44.145390,173.908813 44.327549,173.915405 44.717354,174.271698 
	C43.971218,175.290207 43.017433,175.959045 41.466000,177.046982 
	C44.760010,178.474365 47.863773,179.665176 50.800713,181.179535 
	C51.766628,181.677612 52.300610,183.013367 53.032101,183.966080 
z"/>
<path fill="#FAC94D" opacity="1.000000" stroke="none" 
	d="
M38.068237,188.423615 
	C40.319881,187.673447 42.605843,187.305237 45.583111,186.825668 
	C45.792057,187.205276 46.365883,188.247803 46.982658,189.641510 
	C45.884567,191.914276 44.743530,193.835846 43.311592,195.889206 
	C42.352562,196.402802 41.267319,196.652130 41.095936,197.191696 
	C40.616932,198.699814 40.516037,200.328018 40.265903,201.908829 
	C39.544788,201.869293 38.823673,201.829742 38.102558,201.790207 
	C38.102558,197.461990 38.102558,193.133789 38.068237,188.423615 
z"/>
<path fill="#F8A638" opacity="1.000000" stroke="none" 
	d="
M124.004196,233.576782 
	C124.146919,235.164825 124.292191,236.356140 124.718430,238.022522 
	C124.999405,238.497589 125.015724,238.983109 125.015717,238.983109 
	C124.017502,240.412323 123.019287,241.841537 122.013458,243.635498 
	C119.766205,243.194504 117.526543,242.388779 115.142761,241.292786 
	C113.909096,239.672195 112.819557,238.341858 111.368240,237.005753 
	C109.574242,235.998032 108.142014,234.996078 106.356766,233.992783 
	C106.003746,233.991440 106.000221,234.000473 106.008453,233.615509 
	C105.224724,231.192200 109.065994,227.653854 103.999695,226.999695 
	C104.000000,227.000000 104.000610,227.000610 103.789825,226.732071 
	C102.528748,225.460312 101.478462,224.457108 100.428169,223.453888 
	C102.017784,223.230347 104.067711,222.272400 105.108376,222.924667 
	C107.926491,224.691025 108.107460,222.415970 108.991318,221.009842 
	C111.948837,221.194489 114.561699,221.744141 115.157806,225.402939 
	C115.258705,226.022263 116.519440,226.452606 117.626183,226.975052 
	C118.888672,228.461197 119.769295,229.942520 120.839096,231.742081 
	C121.633347,231.291748 122.527802,230.784622 123.723404,230.139618 
	C124.018623,231.061172 124.012680,232.120621 124.004196,233.576782 
z"/>
<path fill="#FAE406" opacity="1.000000" stroke="none" 
	d="
M122.990044,71.680367 
	C122.613266,71.490753 122.207596,71.006134 121.886932,70.271980 
	C122.301682,70.476753 122.631416,70.931053 122.990044,71.680367 
z"/>
<path fill="#F3B259" opacity="1.000000" stroke="none" 
	d="
M125.346855,238.993301 
	C125.015724,238.983109 124.999405,238.497589 124.994041,238.254898 
	C125.127060,237.592316 125.265442,237.172424 125.403839,236.752533 
	C125.871819,237.019943 126.339798,237.287354 126.911385,238.044159 
	C127.014992,238.533539 126.996437,239.004578 126.996437,239.004578 
	C126.996437,239.004578 126.999779,239.000229 127.001686,238.998322 
	C126.561722,238.998764 126.119858,239.001129 125.346855,238.993301 
z"/>
<path fill="#FBEB18" opacity="1.000000" stroke="none" 
	d="
M141.070023,83.640808 
	C138.077972,83.650688 135.088043,83.023232 132.138763,83.176712 
	C131.045120,83.233627 130.046829,85.123161 128.625397,86.590057 
	C127.493248,87.002075 126.740746,87.000977 125.955498,86.658897 
	C124.944962,84.891434 123.967171,83.464951 122.989372,82.038475 
	C122.751389,79.904945 122.513412,77.771423 122.138260,75.319992 
	C122.814896,74.417229 123.628708,73.832367 124.728256,73.130112 
	C127.817528,73.928482 130.621063,74.844254 133.743958,75.897118 
	C134.948410,76.885605 135.700256,78.046288 136.756149,78.501060 
	C137.803864,78.952293 139.157684,78.692780 140.717926,78.888817 
	C141.068497,80.459564 141.078400,81.886482 141.070023,83.640808 
z"/>
<path fill="#FADE1F" opacity="1.000000" stroke="none" 
	d="
M146.401306,77.479584 
	C145.939697,77.424583 145.659103,77.196991 145.378510,76.969391 
	C145.564072,76.792603 145.749634,76.615814 145.935196,76.439026 
	C146.150894,76.728355 146.366608,77.017677 146.401306,77.479584 
z"/>
<path fill="#FADE1F" opacity="1.000000" stroke="none" 
	d="
M146.444153,79.535217 
	C146.408936,79.477211 146.479385,79.593231 146.444153,79.535217 
z"/>
<path fill="#FBD51D" opacity="1.000000" stroke="none" 
	d="
M39.993282,137.980911 
	C39.580051,137.977768 39.166824,137.974625 38.446564,137.953064 
	C38.403191,132.233398 38.666847,126.532143 39.232597,120.304359 
	C40.021313,119.513000 40.507935,119.248169 41.176468,119.251480 
	C42.563457,120.344887 43.768532,121.170143 45.033726,122.340958 
	C46.065594,123.127129 47.037342,123.567734 47.999344,124.399323 
	C47.987312,127.528709 47.985035,130.267105 47.679207,133.164398 
	C46.918930,134.550369 46.462204,135.777481 46.005482,137.004578 
	C46.005482,137.004578 46.000000,137.000000 45.597775,136.998291 
	C43.461460,137.324692 41.727371,137.652802 39.993282,137.980911 
z"/>
<path fill="#F6CC2A" opacity="1.000000" stroke="none" 
	d="
M40.994560,118.983337 
	C40.507935,119.248169 40.021313,119.513000 39.280136,119.887466 
	C38.827789,116.517883 38.629997,113.038651 38.737190,109.288925 
	C40.132603,109.350372 41.223026,109.682304 42.661587,110.039345 
	C43.010769,110.494530 43.011818,110.924599 42.660583,111.734833 
	C41.870384,114.404434 41.432472,116.693886 40.994560,118.983337 
z"/>
<path fill="#F7B135" opacity="1.000000" stroke="none" 
	d="
M69.037895,213.338058 
	C71.833382,212.802292 74.686981,212.610214 77.768570,212.676331 
	C76.873863,213.934158 75.751160,214.933792 74.271088,215.971771 
	C72.307808,215.233994 70.701904,214.457870 69.037895,213.338058 
z"/>
<path fill="#EC9542" opacity="1.000000" stroke="none" 
	d="
M127.219681,238.902588 
	C126.996437,239.004578 127.014992,238.533539 127.023064,238.298676 
	C127.031136,238.063828 127.512390,238.021805 127.753754,238.014465 
	C127.902313,238.335114 127.718246,238.599594 127.219681,238.902588 
z"/>
<path fill="#FDFAFE" opacity="1.000000" stroke="none" 
	d="
M449.921021,127.057816 
	C448.518097,127.444679 447.526764,127.798645 446.196594,128.273605 
	C446.196594,125.993431 446.196594,124.237038 446.196594,121.877083 
	C448.497498,122.331024 450.586243,122.743103 452.823669,123.184525 
	C452.823669,124.813416 452.823669,126.234596 452.823669,127.621956 
	C451.890686,127.398346 451.111664,127.211632 449.921021,127.057816 
z"/>
<path fill="#F3B259" opacity="1.000000" stroke="none" 
	d="
M125.719452,268.091278 
	C125.903671,268.334595 125.749619,268.603333 125.208862,268.827332 
	C125.104485,268.601562 125.290825,268.379578 125.719452,268.091278 
z"/>
<path fill="#FCD9BA" opacity="1.000000" stroke="none" 
	d="
M132.696503,269.094482 
	C132.881104,269.315308 132.739334,269.597778 132.244690,269.912964 
	C132.093140,269.694366 132.252563,269.431183 132.696503,269.094482 
z"/>
<path fill="#F5D216" opacity="1.000000" stroke="none" 
	d="
M125.988235,86.999878 
	C126.740746,87.000977 127.493248,87.002075 128.621979,87.005402 
	C128.128815,88.488869 127.259445,89.970116 125.851089,91.737671 
	C124.551880,92.621613 123.791656,93.219261 123.031433,93.816910 
	C115.055580,93.035194 111.272865,98.852104 107.172615,104.034584 
	C105.803116,105.765541 104.894867,107.861427 103.124268,109.886368 
	C101.647011,109.657249 100.821259,109.332268 99.792221,108.728462 
	C96.580917,106.794357 93.683212,104.422112 90.018745,106.608330 
	C89.996918,105.498428 89.983543,104.758675 89.970154,104.018913 
	C90.644104,102.584183 91.318054,101.149452 92.247818,99.354935 
	C92.503639,98.995140 92.992851,98.993988 93.369415,98.995461 
	C94.499046,98.998566 95.252113,99.000198 96.262009,99.001740 
	C96.690041,99.002373 96.861252,99.003090 97.019913,99.388176 
	C96.958168,102.933060 98.090256,104.075775 100.714142,101.663223 
	C101.740334,100.719681 102.269402,99.235466 103.272346,97.981544 
	C103.781548,97.809845 104.043297,97.654114 104.305046,97.498383 
	C103.879883,97.333176 103.454712,97.167969 103.011459,96.619232 
	C101.663193,95.491203 100.333008,94.746696 99.002502,93.601631 
	C98.994278,92.132805 98.986382,91.064545 98.978477,89.996284 
	C99.709175,89.715637 100.436859,89.202591 101.171066,89.193085 
	C105.142944,89.141640 107.889343,95.052200 113.172821,91.232590 
	C114.871750,89.462791 116.001091,88.018059 116.796997,86.999878 
	C119.971458,86.999878 122.979843,86.999878 125.988235,86.999878 
z"/>
<path fill="#F8C642" opacity="1.000000" stroke="none" 
	d="
M163.091614,144.373016 
	C164.473175,145.157806 165.726212,146.363525 167.143005,147.328949 
	C167.320831,147.450104 168.362030,146.304214 169.382324,145.380890 
	C170.500732,144.997726 171.239990,144.979065 171.979248,144.960388 
	C171.979248,144.960388 171.994125,144.987869 171.845200,145.357956 
	C171.482147,147.782242 171.268036,149.836426 171.053925,151.890625 
	C169.644547,151.924713 168.235184,151.958817 166.180542,151.989197 
	C165.535248,151.985504 165.090897,151.970215 165.090897,151.970215 
	C165.022751,151.334686 164.954620,150.699158 164.886475,150.063614 
	C164.632370,150.030380 164.378250,149.997147 164.124130,149.963928 
	C163.947662,151.276779 163.487900,153.697479 163.637344,153.735703 
	C165.759003,154.278229 167.959488,154.530838 170.149200,154.780716 
	C171.134918,154.893204 172.139038,154.844543 173.134796,154.869003 
	C172.784454,155.698334 172.434113,156.527679 172.044617,157.658798 
	C172.795914,163.167969 170.332886,166.683731 165.914200,169.941193 
	C164.143555,172.400009 162.574753,174.205215 160.872772,176.290085 
	C159.876923,177.042786 159.014236,177.515808 158.151581,177.988831 
	C159.358795,168.550827 160.566025,159.112808 162.102325,149.233093 
	C162.485443,148.693527 162.609512,148.546021 162.581390,148.506332 
	C162.458374,148.332642 162.291214,148.190231 162.140076,148.036469 
	C162.431152,146.931061 162.722229,145.825638 163.091614,144.373016 
z"/>
<path fill="#F8D02B" opacity="1.000000" stroke="none" 
	d="
M41.176472,119.251480 
	C41.432472,116.693886 41.870384,114.404434 42.650185,112.057648 
	C48.025124,114.911072 48.826679,110.311424 50.862083,107.330917 
	C55.135925,105.698318 59.266289,104.381866 63.739574,103.051285 
	C64.067070,103.864151 63.801548,105.317200 64.073158,105.425705 
	C69.515427,107.599815 69.268318,112.592598 69.969337,117.786194 
	C69.890968,118.666458 69.864235,118.853790 69.484261,119.025360 
	C68.416954,119.007195 67.702896,119.004776 66.994110,119.000763 
	C66.999382,118.999168 66.997108,119.011932 67.038605,118.654114 
	C67.080109,116.428513 67.080109,114.560722 67.080109,111.952339 
	C64.807495,113.721802 63.425934,114.797485 62.037617,115.878433 
	C59.071018,111.760849 59.076160,111.764633 56.543583,115.207367 
	C56.356258,115.462006 55.989727,115.576599 55.756554,115.806953 
	C52.606010,118.919487 49.533028,122.242455 43.843788,118.789871 
	C44.446156,120.498901 44.709885,121.247154 44.973610,121.995399 
	C43.768532,121.170143 42.563457,120.344887 41.176472,119.251480 
z"/>
<path fill="#F8BD44" opacity="1.000000" stroke="none" 
	d="
M53.389389,183.976227 
	C52.300610,183.013367 51.766628,181.677612 50.800713,181.179535 
	C47.863773,179.665176 44.760010,178.474365 41.466000,177.046982 
	C43.017433,175.959045 43.971218,175.290207 44.990273,174.288147 
	C45.346455,173.868652 45.582062,173.703506 46.189034,173.272736 
	C47.737938,173.073166 48.860168,173.060287 50.332996,173.032074 
	C51.787170,172.345764 52.890755,171.674805 54.255428,171.006165 
	C54.690495,171.013992 54.864468,171.019485 55.176781,171.328857 
	C56.667648,172.630219 58.020180,173.627716 59.372719,174.625214 
	C59.945194,173.087204 60.517670,171.549194 61.420853,169.950043 
	C62.912548,169.239105 64.073532,168.589325 65.435692,168.141220 
	C65.767006,168.891312 65.897141,169.439713 66.027283,169.988113 
	C65.997475,170.161835 65.967674,170.335541 65.636955,170.882172 
	C64.868912,171.499451 64.401787,171.743790 63.855927,171.825714 
	C63.528194,171.788162 63.279202,171.913025 63.181362,172.389771 
	C63.148979,174.891891 62.897663,177.039490 62.813332,179.193634 
	C62.776615,180.131454 63.082695,181.082672 63.233086,182.027817 
	C62.761986,183.903091 62.290886,185.778381 61.509552,187.841736 
	C58.715099,186.682007 56.230885,185.334198 53.389389,183.976227 
z"/>
<path fill="#F9D23A" opacity="1.000000" stroke="none" 
	d="
M89.668335,104.013062 
	C89.983543,104.758675 89.996918,105.498428 90.009850,106.993652 
	C90.757057,109.042648 91.237053,110.640854 92.300407,111.575050 
	C96.155853,114.962250 97.227921,109.330643 99.995499,109.007286 
	C100.821259,109.332268 101.647011,109.657249 102.804146,110.026314 
	C102.087173,112.148254 101.038826,114.226120 99.575630,116.721779 
	C98.455200,118.810539 97.749626,120.481506 97.044037,122.152466 
	C97.044037,122.152466 96.993370,122.589729 96.701187,122.916702 
	C96.174911,123.481903 96.059387,123.767990 96.062424,124.101936 
	C96.062424,124.101936 96.002029,124.555656 95.701813,124.889610 
	C95.162117,125.462372 95.039932,125.750847 95.035034,126.088997 
	C95.035034,126.088997 94.976128,126.565323 94.697945,126.908546 
	C94.189827,127.480370 94.090790,127.756004 94.122665,128.078659 
	C94.122665,128.078659 94.070419,128.077820 93.686432,128.101013 
	C90.434212,129.239273 90.607445,131.165787 91.897446,133.390274 
	C91.897446,133.390274 91.736717,133.777512 91.333687,133.567276 
	C90.323700,132.622574 89.716743,131.888107 89.109787,131.153656 
	C89.070839,127.109695 89.141586,123.060913 88.964355,119.023026 
	C88.757011,114.298897 88.333549,109.584244 88.080963,104.439728 
	C88.560890,104.011719 88.963692,104.009468 89.668335,104.013062 
z"/>
<path fill="#F4C64C" opacity="1.000000" stroke="none" 
	d="
M98.838669,142.927567 
	C102.248375,136.162521 105.658081,129.397476 109.067787,122.632416 
	C109.700974,122.854111 110.334160,123.075806 110.967346,123.297501 
	C108.980682,131.925949 106.994026,140.554413 104.551498,149.593506 
	C101.366348,150.657898 98.637054,151.311630 95.961334,151.579254 
	C96.283150,149.427292 96.551392,147.661438 96.819633,145.895599 
	C96.819633,145.895599 96.960663,145.558609 97.230591,145.255432 
	C97.684044,144.639633 97.867569,144.327011 98.051094,144.014404 
	C98.051094,144.014404 98.000870,143.997467 98.277115,143.954254 
	C98.648460,143.583206 98.743561,143.255386 98.838669,142.927567 
z"/>
<path fill="#EAC141" opacity="1.000000" stroke="none" 
	d="
M139.815918,112.005287 
	C138.845779,114.133957 137.892853,116.270683 136.901840,118.389587 
	C134.957855,122.546112 133.119186,126.760216 130.962021,130.803833 
	C130.281479,132.079529 128.724548,132.887680 127.568726,133.909805 
	C127.358757,132.519592 126.784187,131.069290 127.001762,129.749557 
	C127.958183,123.948402 129.143417,118.184982 130.837906,112.194122 
	C131.612778,111.987373 131.795471,111.994377 132.036896,112.346954 
	C134.753922,115.072266 134.918137,113.052361 135.421738,111.009460 
	C136.478699,111.017700 137.182083,111.023903 137.885483,111.030106 
	C137.885483,111.030106 137.938110,111.034004 138.109589,111.257767 
	C138.792694,111.656120 139.304306,111.830704 139.815918,112.005287 
z"/>
<path fill="#F0A739" opacity="1.000000" stroke="none" 
	d="
M87.948280,208.000153 
	C86.530251,207.568192 85.112228,207.136230 83.828888,206.745300 
	C83.700081,204.511368 83.581268,202.450729 84.009674,200.193604 
	C85.730171,198.754257 86.903450,197.511383 87.592651,196.781281 
	C86.984421,195.353790 86.487808,194.188263 86.318436,192.879456 
	C90.102356,192.828430 93.559044,192.920700 97.118423,193.262833 
	C97.749916,193.987701 98.278725,194.462708 98.807526,194.937714 
	C100.528534,197.029816 102.249542,199.121933 103.688629,201.563110 
	C102.287590,201.614441 101.168465,201.316681 99.795708,200.739090 
	C95.528381,200.913086 91.514687,201.366898 86.688957,201.912521 
	C87.132057,203.641754 87.565979,205.335175 87.993057,207.271790 
	C87.986221,207.515015 87.948280,208.000153 87.948280,208.000153 
z"/>
<path fill="#F6CB58" opacity="1.000000" stroke="none" 
	d="
M83.920677,177.941772 
	C85.888313,172.523788 87.855949,167.105789 90.189583,161.295044 
	C90.646469,160.583023 90.737358,160.263748 90.828232,159.944473 
	C90.828232,159.944473 91.010094,159.988983 91.341850,160.226654 
	C94.477150,162.023590 94.022346,166.652985 97.963753,167.140137 
	C97.646072,167.767990 97.328384,168.395844 96.702591,169.142212 
	C95.547089,170.068588 94.699692,170.876450 93.852303,171.684311 
	C94.085670,171.954819 94.319031,172.225327 94.552391,172.495834 
	C95.690323,172.010208 96.828247,171.524582 97.970566,171.366119 
	C97.973686,172.129562 97.972404,172.565842 97.630646,173.152817 
	C94.520882,174.530304 91.751602,175.757095 88.609566,176.995087 
	C86.798096,177.318130 85.359390,177.629944 83.920677,177.941772 
z"/>
<path fill="#F7C830" opacity="1.000000" stroke="none" 
	d="
M178.666779,116.951157 
	C179.004868,118.070732 179.005844,119.136879 179.004852,120.603043 
	C176.670258,120.992363 174.164291,115.736557 172.008911,121.618896 
	C171.032288,124.779053 170.060455,127.554367 169.021118,130.676605 
	C167.731903,130.819748 166.510193,130.615967 165.136505,130.177811 
	C166.591354,121.533981 168.198151,113.124535 169.989441,104.352966 
	C170.573227,103.992401 170.972534,103.993965 171.670776,103.994522 
	C171.113617,107.280243 170.257538,110.566948 169.401459,113.853661 
	C169.777191,114.046204 170.152924,114.238739 170.528656,114.431282 
	C171.160141,114.046021 171.791626,113.660759 172.722580,113.136871 
	C174.791260,114.298080 176.560455,115.597908 178.666779,116.951157 
z"/>
<path fill="#FAAB28" opacity="1.000000" stroke="none" 
	d="
M97.015732,193.012970 
	C93.559044,192.920700 90.102356,192.828430 86.018539,192.977539 
	C84.594803,192.578293 83.787689,191.950211 83.004311,191.293793 
	C82.073807,190.514114 81.163727,189.710068 80.183884,188.656433 
	C80.075378,188.224670 80.027786,188.053040 79.980186,187.881393 
	C79.980186,187.881393 80.032898,187.494400 80.363159,187.311356 
	C80.693420,187.128311 81.183502,187.056213 81.545975,187.034683 
	C85.940392,185.665802 89.972343,184.318436 94.404022,182.994644 
	C100.407082,185.272888 97.784851,189.347992 97.015732,193.012970 
z"/>
<path fill="#F4B92B" opacity="1.000000" stroke="none" 
	d="
M142.820923,147.947372 
	C142.545975,150.635040 142.544876,153.390167 141.905090,155.987976 
	C141.435684,157.893906 140.128784,159.593597 138.803223,161.887024 
	C138.250214,162.926025 138.089157,163.463181 137.568695,163.996140 
	C135.824081,163.660706 134.438858,163.329468 133.033646,162.625275 
	C132.344498,161.166931 131.675354,160.081543 131.005768,158.749207 
	C131.005310,158.502258 131.007736,158.008377 131.246857,157.841553 
	C131.634125,157.123657 131.782257,156.572586 132.224411,155.884186 
	C134.055191,153.933380 135.723267,152.212555 137.088394,150.278000 
	C138.239853,148.646255 139.039658,146.766373 140.328552,144.993912 
	C141.106506,144.989975 141.551041,144.988464 141.996948,145.311768 
	C142.272537,146.406830 142.546722,147.177109 142.820923,147.947372 
z"/>
<path fill="#F4B92B" opacity="1.000000" stroke="none" 
	d="
M55.003212,138.995117 
	C55.246277,138.224426 55.461067,136.795410 55.736732,136.783554 
	C58.954178,136.645340 62.190983,136.604996 65.391907,136.887421 
	C65.785713,136.922180 65.989296,138.985565 66.301826,140.103058 
	C66.642738,141.322037 67.007858,142.534256 67.708870,144.186768 
	C68.640045,145.072052 69.224777,145.519989 69.809509,145.967926 
	C69.536720,146.794647 69.263931,147.621384 68.567299,148.743408 
	C66.770302,149.357574 65.397156,149.676437 63.700935,149.859467 
	C62.257050,149.509628 61.136230,149.295639 59.975323,148.713348 
	C59.672337,147.257553 58.945133,146.027954 59.226933,145.107162 
	C60.446327,141.122833 57.930199,139.972092 55.003212,138.995117 
z"/>
<path fill="#F4B95F" opacity="1.000000" stroke="none" 
	d="
M131.930405,156.021530 
	C131.782257,156.572586 131.634125,157.123657 131.065781,157.919067 
	C130.765793,158.440979 130.886017,158.718567 131.006226,158.996140 
	C131.675354,160.081543 132.344498,161.166931 132.977859,162.978607 
	C131.618271,166.119202 130.294449,168.533508 128.629105,170.924698 
	C127.520241,171.807953 126.752617,172.714081 125.985710,173.620819 
	C125.040894,174.737915 124.096649,175.855515 123.152161,176.972900 
	C125.077286,169.824661 126.942902,162.659470 128.968491,155.539810 
	C129.489090,153.709961 130.513779,152.023529 131.651703,150.481293 
	C131.974487,152.468079 131.952438,154.244812 131.930405,156.021530 
z"/>
<path fill="#FDCC46" opacity="1.000000" stroke="none" 
	d="
M64.024002,149.995285 
	C65.397156,149.676437 66.770302,149.357574 68.503242,149.076797 
	C68.815468,151.305847 68.767906,153.496841 68.138809,156.029404 
	C67.021698,158.608231 66.486122,160.845459 65.636635,163.085922 
	C61.562656,166.546173 57.775814,165.307663 55.973053,159.765564 
	C55.989586,159.329239 55.993950,159.154633 56.297028,158.774933 
	C60.489326,156.939636 64.233772,155.180267 64.024002,149.995285 
z"/>
<path fill="#FBAD36" opacity="1.000000" stroke="none" 
	d="
M100.049347,201.018906 
	C101.168465,201.316681 102.287590,201.614441 103.754349,201.899338 
	C104.289124,201.904465 104.476280,201.922470 105.099014,202.314438 
	C106.040054,204.114548 106.545532,205.540680 107.051010,206.966827 
	C107.400688,208.411972 107.750359,209.857117 108.100037,211.302261 
	C108.748749,210.662033 109.397469,210.021805 110.046188,209.381577 
	C109.683022,210.387939 109.319847,211.394318 108.522720,212.716278 
	C104.415497,214.371521 100.742233,215.711166 96.812607,216.880997 
	C96.368599,216.146683 96.180954,215.582199 96.274345,214.877319 
	C96.704460,213.155472 96.853531,211.574005 97.271103,209.802338 
	C99.025871,208.405441 100.512138,207.198715 102.187927,206.225403 
	C102.865379,206.238327 103.353317,206.017838 103.841255,205.797333 
	C103.229301,205.200668 102.617348,204.604004 101.666489,204.005859 
	C100.875824,203.994507 100.424072,203.984634 99.982407,203.605377 
	C100.011444,202.496964 100.030396,201.757935 100.049347,201.018906 
z"/>
<path fill="#F0B138" opacity="1.000000" stroke="none" 
	d="
M123.087311,177.299866 
	C124.096649,175.855515 125.040894,174.737915 125.985710,173.620819 
	C126.752617,172.714081 127.520241,171.807953 128.642151,171.254242 
	C130.329849,173.406036 131.662949,175.205154 132.960007,177.357346 
	C132.032074,179.146454 131.140182,180.582474 129.645096,182.989716 
	C132.320801,182.989716 134.147018,182.989716 135.978882,183.394318 
	C135.673965,185.543900 135.363403,187.288879 134.785553,188.924271 
	C134.006073,188.551056 133.493881,188.287415 132.981689,188.023773 
	C131.914841,188.020157 130.847977,188.016541 129.385162,187.628967 
	C128.001068,184.838287 127.012970,182.431549 126.268066,180.009460 
	C126.786835,179.865784 127.062401,179.737488 127.337967,179.609177 
	C126.886208,179.731430 126.434448,179.853683 125.656570,179.949341 
	C124.525993,179.600250 123.721550,179.277740 122.917099,178.955215 
	C122.952217,178.512421 122.987335,178.069626 123.087311,177.299866 
z"/>
<path fill="#F8B644" opacity="1.000000" stroke="none" 
	d="
M158.085037,178.453186 
	C159.014236,177.515808 159.876923,177.042786 161.117783,176.289078 
	C161.495972,176.008408 161.986099,176.010437 161.986099,176.010437 
	C162.901291,177.150589 163.816483,178.290756 164.865524,179.715759 
	C164.232498,181.153229 163.301498,182.231857 162.740128,183.477112 
	C162.031418,185.049118 161.624100,186.757004 160.994049,188.690887 
	C161.385300,188.910355 162.479279,189.524017 164.070816,190.416809 
	C162.597717,192.724350 161.245209,194.843002 159.518158,196.965820 
	C158.768570,190.952515 158.393539,184.935043 158.085037,178.453186 
z"/>
<path fill="#EAC141" opacity="1.000000" stroke="none" 
	d="
M140.187836,131.979218 
	C142.744400,127.961227 145.300949,123.943237 148.575409,118.796944 
	C150.516479,127.222557 146.794922,133.260742 145.243469,139.647141 
	C144.175354,139.406815 143.598755,139.202271 143.017654,138.571213 
	C142.296417,135.986404 144.949356,132.283981 140.187836,131.979218 
z"/>
<path fill="#F8B644" opacity="1.000000" stroke="none" 
	d="
M94.004295,182.971085 
	C89.972343,184.318436 85.940392,185.665802 81.479843,186.756744 
	C81.007240,186.315689 80.963242,186.131027 80.919250,185.946365 
	C81.230209,184.831009 81.541176,183.715668 82.224693,182.165695 
	C82.812431,181.501358 82.935478,181.229874 82.966393,180.916626 
	C82.966393,180.916626 82.987747,180.985123 83.245560,180.799133 
	C83.653046,179.715027 83.802711,178.816895 83.952377,177.918777 
	C83.952377,177.918777 83.962746,177.963058 83.941711,177.952423 
	C85.359390,177.629944 86.798096,177.318130 88.829514,177.264557 
	C91.481766,178.337326 93.541321,179.151840 95.799171,180.044785 
	C95.021538,181.312607 94.512917,182.141846 94.004295,182.971085 
z"/>
<path fill="#F5BE36" opacity="1.000000" stroke="none" 
	d="
M97.971123,173.002106 
	C97.972404,172.565842 97.973686,172.129562 97.880089,171.100494 
	C97.527046,170.013016 97.268875,169.518356 97.010696,169.023697 
	C97.328384,168.395844 97.646072,167.767990 97.963753,167.140137 
	C94.022346,166.652985 94.477150,162.023590 91.416656,160.282928 
	C91.159698,160.101562 91.027046,159.622467 91.316338,159.222412 
	C91.673225,158.203461 91.740814,157.584564 91.808411,156.965668 
	C91.808411,156.965668 91.950432,156.529938 92.238892,156.214447 
	C92.643028,155.596786 92.758690,155.294632 92.874359,154.992477 
	C92.874359,154.992477 93.070801,154.644150 93.300659,154.387970 
	C93.530525,154.131805 93.962471,154.007462 93.970612,154.378052 
	C94.215164,155.730713 94.451561,156.712799 94.467346,156.778351 
	C97.789703,156.498199 100.498619,156.269775 103.207535,156.041351 
	C102.820503,157.142548 102.433479,158.243713 101.628937,159.643768 
	C100.342148,159.746506 99.472862,159.550354 98.062248,159.232071 
	C99.076157,163.161652 99.949074,166.544754 100.821983,169.927872 
	C100.728745,170.893402 100.635498,171.858932 99.949142,172.919830 
	C98.894386,173.010834 98.432755,173.006470 97.971123,173.002106 
z"/>
<path fill="#F9D047" opacity="1.000000" stroke="none" 
	d="
M137.910202,110.774635 
	C137.182083,111.023903 136.478699,111.017700 135.073242,111.007324 
	C133.573502,111.335899 132.775848,111.668640 131.978180,112.001381 
	C131.795471,111.994377 131.612778,111.987373 131.156067,111.970314 
	C131.433044,108.039116 131.512177,103.969582 132.683838,100.243622 
	C133.627151,97.243828 131.996277,96.767342 130.184967,96.071846 
	C128.091263,95.267899 125.913948,94.681671 123.402176,93.908913 
	C123.791656,93.219261 124.551880,92.621613 125.649872,92.014175 
	C128.080475,92.941986 130.173309,93.879601 132.648804,94.792557 
	C136.957184,94.923409 135.826508,97.851593 135.594238,100.595734 
	C133.346405,104.554192 133.736984,105.263718 137.966248,106.056366 
	C137.964859,106.788391 137.963470,107.520409 137.958023,108.873993 
	C137.956421,109.665878 137.958862,109.836197 137.961319,110.006523 
	C137.952515,110.177399 137.943726,110.348282 137.910202,110.774635 
z"/>
<path fill="#F8C146" opacity="1.000000" stroke="none" 
	d="
M97.630646,173.152817 
	C98.432755,173.006470 98.894386,173.010834 99.701462,173.027374 
	C99.680557,180.035583 99.314217,187.031631 98.877701,194.482697 
	C98.278725,194.462708 97.749916,193.987701 97.118423,193.262833 
	C97.784851,189.347992 100.407082,185.272888 94.404022,182.994644 
	C94.512917,182.141846 95.021538,181.312607 95.799171,180.044785 
	C93.541321,179.151840 91.481766,178.337326 89.202271,177.253357 
	C91.751602,175.757095 94.520882,174.530304 97.630646,173.152817 
z"/>
<path fill="#FCD258" opacity="1.000000" stroke="none" 
	d="
M107.457733,206.977844 
	C106.545532,205.540680 106.040054,204.114548 105.368256,202.398407 
	C106.867805,201.735001 108.533669,201.361603 110.589005,200.965408 
	C114.285347,201.637985 117.618324,202.231064 120.889542,203.066101 
	C122.939438,203.589355 124.896454,204.476471 126.711563,206.000427 
	C120.316742,207.170822 113.932968,203.811218 107.457733,206.977844 
z"/>
<path fill="#F8B644" opacity="1.000000" stroke="none" 
	d="
M80.244789,188.916580 
	C81.163727,189.710068 82.073807,190.514114 83.004311,191.293793 
	C83.787689,191.950211 84.594803,192.578293 85.691307,193.120819 
	C86.487808,194.188263 86.984421,195.353790 87.592651,196.781281 
	C86.903450,197.511383 85.730171,198.754257 84.261124,199.995361 
	C84.009453,197.311569 83.200768,196.171722 80.539391,197.972397 
	C79.377113,198.758804 78.074738,199.338135 76.427559,199.740540 
	C75.981735,199.279129 75.944344,199.088699 75.906960,198.898285 
	C75.906960,198.898285 75.961678,198.990784 76.269333,198.842407 
	C77.013039,197.097809 77.449089,195.501617 77.885139,193.905411 
	C77.885139,193.905411 77.969521,193.432175 78.414772,193.148438 
	C79.321609,191.548676 79.783195,190.232620 80.244789,188.916580 
z"/>
<path fill="#F9D047" opacity="1.000000" stroke="none" 
	d="
M126.024864,180.024826 
	C127.012970,182.431549 128.001068,184.838287 128.985107,187.626251 
	C126.264870,188.028336 123.548721,188.049179 120.516098,188.039215 
	C120.889229,185.133026 121.578819,182.257660 122.592758,179.168747 
	C123.721550,179.277740 124.525993,179.600250 125.666237,179.962219 
	C126.002029,180.001678 126.024864,180.024826 126.024864,180.024826 
z"/>
<path fill="#ED9F31" opacity="1.000000" stroke="none" 
	d="
M132.973541,188.394623 
	C133.493881,188.287415 134.006073,188.551056 134.775299,189.295471 
	C135.044235,190.520096 135.056168,191.263947 134.955688,192.251160 
	C135.233719,193.006393 135.624161,193.518280 136.233582,194.038177 
	C136.452560,194.046188 136.889984,194.018799 136.889984,194.018799 
	C136.937790,194.454239 136.985596,194.889694 137.052307,195.654144 
	C136.471481,197.116730 135.871765,198.250320 135.140015,199.691254 
	C133.904175,199.677536 132.800339,199.356445 131.345734,198.762238 
	C130.994949,198.489136 130.982376,198.014282 130.982361,198.014282 
	C130.847763,197.137680 130.916306,196.154770 130.527267,195.411331 
	C130.038437,194.477203 129.174957,193.739151 128.473160,192.916489 
	C129.470886,192.247864 130.546783,191.667938 131.439911,190.880661 
	C132.075790,190.320129 132.465714,189.480530 132.973541,188.394623 
z"/>
<path fill="#F5BE36" opacity="1.000000" stroke="none" 
	d="
M132.224411,155.884186 
	C131.952438,154.244812 131.974487,152.468079 131.976013,150.317322 
	C132.732361,148.055298 133.509232,146.167313 134.965790,144.166260 
	C137.095367,144.367569 138.545242,144.681961 139.995117,144.996338 
	C139.039658,146.766373 138.239853,148.646255 137.088394,150.278000 
	C135.723267,152.212555 134.055191,153.933380 132.224411,155.884186 
z"/>
<path fill="#F9CC38" opacity="1.000000" stroke="none" 
	d="
M140.328552,144.993912 
	C138.545242,144.681961 137.095367,144.367569 135.273438,144.007660 
	C135.872971,141.526260 136.844574,139.090363 138.282745,136.383911 
	C138.625580,137.405060 138.501831,138.696793 138.321152,140.582794 
	C139.946411,140.034805 141.484283,139.516281 143.022156,138.997742 
	C143.598755,139.202271 144.175354,139.406815 144.930054,139.869537 
	C144.750977,141.189041 144.393814,142.250351 143.668427,143.676422 
	C142.865311,144.356445 142.430435,144.671707 141.995560,144.986954 
	C141.551041,144.988464 141.106506,144.989975 140.328552,144.993912 
z"/>
<path fill="#FCCF4D" opacity="1.000000" stroke="none" 
	d="
M103.507050,155.834885 
	C100.498619,156.269775 97.789703,156.498199 94.467346,156.778351 
	C94.451561,156.712799 94.215164,155.730713 94.002380,154.130737 
	C94.026001,153.512863 94.070305,153.017258 94.070305,153.017258 
	C94.070305,153.017258 94.006836,153.001282 94.293861,152.971191 
	C95.023178,152.615860 95.465469,152.290619 95.907768,151.965378 
	C98.637054,151.311630 101.366348,150.657898 104.485344,150.008881 
	C104.518890,151.885208 104.162727,153.756821 103.507050,155.834885 
M100.591454,153.514816 
	C100.591454,153.514816 100.482300,153.579376 100.591454,153.514816 
z"/>
<path fill="#FBBE2D" opacity="1.000000" stroke="none" 
	d="
M143.017654,138.571228 
	C141.484283,139.516281 139.946411,140.034805 138.321152,140.582794 
	C138.501831,138.696793 138.625580,137.405060 138.452759,136.063049 
	C138.744080,134.872192 139.331955,133.731644 140.053833,132.285156 
	C144.949356,132.283981 142.296417,135.986404 143.017654,138.571228 
z"/>
<path fill="#F8C146" opacity="1.000000" stroke="none" 
	d="
M100.899231,169.518799 
	C99.949074,166.544754 99.076157,163.161652 98.062248,159.232071 
	C99.472862,159.550354 100.342148,159.746506 101.547005,159.996918 
	C101.580544,163.070709 101.278511,166.090225 100.899231,169.518799 
z"/>
<path fill="#F6CB58" opacity="1.000000" stroke="none" 
	d="
M69.837502,119.041115 
	C69.864235,118.853790 69.890968,118.666458 69.962830,118.198593 
	C70.251564,125.148438 70.495171,132.378815 70.366669,139.841125 
	C69.971092,136.330338 69.947617,132.587616 69.932571,127.941742 
	C69.907341,124.668533 69.873688,122.298447 69.867561,119.706474 
	C69.895088,119.484581 69.837502,119.041115 69.837502,119.041115 
z"/>
<path fill="#F4B95F" opacity="1.000000" stroke="none" 
	d="
M65.950539,163.082703 
	C66.486122,160.845459 67.021698,158.608231 67.858841,156.221954 
	C67.687500,160.571945 67.214600,165.070969 66.384491,169.779053 
	C65.897141,169.439713 65.767006,168.891312 65.421722,167.788879 
	C65.454567,165.850784 65.702553,164.466751 65.950539,163.082703 
z"/>
<path fill="#F7B94A" opacity="1.000000" stroke="none" 
	d="
M139.837204,168.000336 
	C139.159378,171.876846 138.481537,175.753342 137.196930,179.836227 
	C136.400330,180.036163 136.210510,180.029724 136.012024,179.683472 
	C135.675766,178.563675 135.348175,177.783691 135.021805,176.751419 
	C135.024689,176.330917 135.026367,176.162735 135.266159,175.829895 
	C135.666489,175.123779 135.828690,174.582321 136.001007,174.024506 
	C136.011139,174.008118 136.038269,173.980820 136.248932,173.881897 
	C136.720123,173.593811 136.899963,173.346054 137.275909,172.784973 
	C137.714676,171.021942 137.876648,169.513657 138.338379,168.003876 
	C139.037842,168.001709 139.437515,168.001022 139.837204,168.000336 
z"/>
<path fill="#F4B95F" opacity="1.000000" stroke="none" 
	d="
M63.539078,181.822418 
	C63.082695,181.082672 62.776615,180.131454 62.813332,179.193634 
	C62.897663,177.039490 63.148979,174.891891 63.407124,172.373627 
	C63.481731,172.005600 63.934666,171.988129 63.934666,171.988129 
	C64.401787,171.743790 64.868912,171.499451 65.575027,171.139191 
	C65.157715,174.554520 64.501389,178.085770 63.539078,181.822418 
z"/>
<path fill="#E9AB4E" opacity="1.000000" stroke="none" 
	d="
M136.020706,180.023300 
	C136.210510,180.029724 136.400330,180.036163 136.873260,180.071869 
	C137.121033,184.433243 137.085709,188.765350 136.970184,193.558136 
	C136.889984,194.018799 136.452560,194.046188 136.216156,193.740662 
	C135.675873,192.959351 135.371994,192.483566 135.068100,192.007797 
	C135.056168,191.263947 135.044235,190.520096 135.042587,189.405045 
	C135.363403,187.288879 135.673965,185.543900 135.989441,183.023560 
	C136.003143,181.506546 136.011932,180.764923 136.020706,180.023300 
z"/>
<path fill="#39222F" opacity="1.000000" stroke="none" 
	d="
M95.961334,151.579254 
	C95.465469,152.290619 95.023178,152.615860 94.308403,153.000519 
	C94.766785,150.808884 95.497650,148.557846 96.524071,146.101196 
	C96.551392,147.661438 96.283150,149.427292 95.961334,151.579254 
z"/>
<path fill="#F8C146" opacity="1.000000" stroke="none" 
	d="
M139.910187,167.609650 
	C139.437515,168.001022 139.037842,168.001709 138.321121,167.603394 
	C137.978760,166.136383 137.953430,165.068375 137.928101,164.000351 
	C138.089157,163.463181 138.250214,162.926025 138.644348,162.227386 
	C139.245987,163.783600 139.614563,165.501282 139.910187,167.609650 
z"/>
<path fill="#F6CB58" opacity="1.000000" stroke="none" 
	d="
M92.148132,133.208023 
	C90.607445,131.165787 90.434212,129.239273 93.690369,128.126862 
	C93.518463,129.761581 92.958641,131.393677 92.148132,133.208023 
z"/>
<path fill="#F6CB58" opacity="1.000000" stroke="none" 
	d="
M88.914062,131.435822 
	C89.716743,131.888107 90.323700,132.622574 91.145721,133.660126 
	C90.546837,134.466034 89.732887,134.968872 88.918938,135.471710 
	C88.852074,134.220459 88.785210,132.969223 88.914062,131.435822 
z"/>
<path fill="#F6CB58" opacity="1.000000" stroke="none" 
	d="
M69.886139,145.554718 
	C69.224777,145.519989 68.640045,145.072052 68.006737,144.321655 
	C68.349503,143.044327 68.740845,142.069458 69.459663,141.013351 
	C69.845680,142.335236 69.904228,143.738373 69.886139,145.554718 
z"/>
<path fill="#F6CB58" opacity="1.000000" stroke="none" 
	d="
M97.355804,121.995148 
	C97.749626,120.481506 98.455200,118.810539 99.504288,117.077057 
	C99.121048,118.622299 98.394302,120.230072 97.355804,121.995148 
z"/>
<path fill="#F5BE36" opacity="1.000000" stroke="none" 
	d="
M141.996948,145.311768 
	C142.430435,144.671707 142.865311,144.356445 143.581512,144.013092 
	C143.610825,145.123047 143.358841,146.261093 142.963898,147.673248 
	C142.546722,147.177109 142.272537,146.406830 141.996948,145.311768 
z"/>
<path fill="#E5B368" opacity="1.000000" stroke="none" 
	d="
M77.583603,194.084183 
	C77.449089,195.501617 77.013039,197.097809 76.223053,198.815094 
	C76.340096,197.378448 76.811089,195.820694 77.583603,194.084183 
z"/>
<path fill="#E5B368" opacity="1.000000" stroke="none" 
	d="
M80.183884,188.656433 
	C79.783195,190.232620 79.321609,191.548676 78.506180,192.926147 
	C78.553001,191.406296 78.953674,189.824997 79.667267,188.062546 
	C80.027786,188.053040 80.075378,188.224670 80.183884,188.656433 
z"/>
<path fill="#E5B368" opacity="1.000000" stroke="none" 
	d="
M74.608795,201.923538 
	C74.508972,202.545502 74.083099,203.227386 73.313026,203.897278 
	C73.406792,203.251358 73.844765,202.617401 74.608795,201.923538 
z"/>
<path fill="#F6CB58" opacity="1.000000" stroke="none" 
	d="
M83.647133,178.086609 
	C83.802711,178.816895 83.653046,179.715027 83.209702,180.800064 
	C83.057976,180.076141 83.199928,179.165298 83.647133,178.086609 
z"/>
<path fill="#E5B368" opacity="1.000000" stroke="none" 
	d="
M75.631485,199.042496 
	C75.944344,199.088699 75.981735,199.279129 76.059586,199.758438 
	C75.986107,200.347000 75.808884,200.602844 75.346100,200.890060 
	C75.201218,200.372421 75.278618,199.779572 75.631485,199.042496 
z"/>
<path fill="#39222F" opacity="1.000000" stroke="none" 
	d="
M91.577835,157.165894 
	C91.740814,157.584564 91.673225,158.203461 91.319107,158.973862 
	C91.137466,158.538956 91.242363,157.952545 91.577835,157.165894 
z"/>
<path fill="#FADE1F" opacity="1.000000" stroke="none" 
	d="
M139.893890,111.763123 
	C139.304306,111.830704 138.792694,111.656120 138.134033,111.247818 
	C138.446045,111.011322 138.905090,111.008553 139.708313,111.012169 
	C140.025589,111.186020 139.998734,111.353485 139.893890,111.763123 
z"/>
<path fill="#F6CB58" opacity="1.000000" stroke="none" 
	d="
M96.307487,124.011963 
	C96.059387,123.767990 96.174911,123.481903 96.609344,123.116714 
	C96.723976,123.300507 96.638268,123.611244 96.307487,124.011963 
z"/>
<path fill="#F6CB58" opacity="1.000000" stroke="none" 
	d="
M95.288864,126.004578 
	C95.039932,125.750847 95.162117,125.462372 95.606659,125.097748 
	C95.722038,125.288002 95.632362,125.604080 95.288864,126.004578 
z"/>
<path fill="#F6CB58" opacity="1.000000" stroke="none" 
	d="
M94.361038,127.996811 
	C94.090790,127.756004 94.189827,127.480370 94.612785,127.132904 
	C94.737007,127.314346 94.668205,127.614655 94.361038,127.996811 
z"/>
<path fill="#F6CB58" opacity="1.000000" stroke="none" 
	d="
M82.734985,181.012482 
	C82.935478,181.229874 82.812431,181.501358 82.409149,181.858276 
	C82.315231,181.693085 82.409409,181.400711 82.734985,181.012482 
z"/>
<path fill="#E5B368" opacity="1.000000" stroke="none" 
	d="
M80.667801,186.047058 
	C80.963242,186.131027 81.007240,186.315689 81.117371,186.778290 
	C81.183502,187.056213 80.693420,187.128311 80.445694,187.134369 
	C80.270767,186.809540 80.343559,186.478653 80.667801,186.047058 
z"/>
<path fill="#39222F" opacity="1.000000" stroke="none" 
	d="
M92.657158,155.103760 
	C92.758690,155.294632 92.643028,155.596786 92.281815,155.989349 
	C92.049065,155.734650 92.183632,155.446426 92.657158,155.103760 
z"/>
<path fill="#39222F" opacity="1.000000" stroke="none" 
	d="
M90.629669,160.086197 
	C90.737358,160.263748 90.646469,160.583023 90.305382,161.005493 
	C90.068939,160.767471 90.194244,160.473892 90.629669,160.086197 
z"/>
<path fill="#39222F" opacity="1.000000" stroke="none" 
	d="
M93.798279,153.115143 
	C94.070305,153.017258 94.026001,153.512863 93.994232,153.760162 
	C93.962471,154.007462 93.530525,154.131805 93.313171,154.188416 
	C93.239296,153.901031 93.382767,153.557022 93.798279,153.115143 
z"/>
<path fill="#F9D047" opacity="1.000000" stroke="none" 
	d="
M162.077362,148.264938 
	C162.291214,148.190231 162.458374,148.332642 162.581390,148.506332 
	C162.609512,148.546021 162.485443,148.693527 162.282928,148.871643 
	C162.134430,148.951874 162.014664,148.493408 162.077362,148.264938 
z"/>
<path fill="#39222F" opacity="1.000000" stroke="none" 
	d="
M97.776306,144.102249 
	C97.867569,144.327011 97.684044,144.639633 97.245651,145.073395 
	C97.161026,144.859726 97.331268,144.524918 97.776306,144.102249 
z"/>
<path fill="#39222F" opacity="1.000000" stroke="none" 
	d="
M98.638916,143.065216 
	C98.743561,143.255386 98.648460,143.583206 98.285080,143.979523 
	C98.031372,143.703247 98.172165,143.421539 98.638916,143.065216 
z"/>
<path fill="#F9DD0F" opacity="1.000000" stroke="none" 
	d="
M92.992851,98.993988 
	C92.992851,98.993988 92.503639,98.995140 92.259216,98.996399 
	C91.673943,97.913010 91.333076,96.828369 90.983017,95.379486 
	C92.090607,93.380241 93.207390,91.745239 94.662231,90.059708 
	C95.166893,90.863441 95.333481,91.717705 95.582977,92.997086 
	C96.725342,91.784233 97.517471,90.943230 98.644043,90.049255 
	C98.986382,91.064545 98.994278,92.132805 98.893311,93.896255 
	C98.200439,96.062233 97.616447,97.533012 97.032455,99.003799 
	C96.861252,99.003090 96.690041,99.002373 96.211601,98.666901 
	C94.933868,98.552765 93.963364,98.773376 92.992851,98.993988 
z"/>
<path fill="#F9D047" opacity="1.000000" stroke="none" 
	d="
M162.386765,176.004837 
	C161.986099,176.010437 161.495972,176.008408 161.250977,176.009415 
	C162.574753,174.205215 164.143555,172.400009 165.856018,170.297394 
	C167.419296,170.355423 168.838898,170.710846 171.269150,171.319321 
	C172.114044,173.835495 172.114044,173.835495 162.386765,176.004837 
z"/>
<path fill="#ED9F31" opacity="1.000000" stroke="none" 
	d="
M166.829803,184.093307 
	C166.996948,184.005493 167.000427,184.501007 167.001434,184.748734 
	C166.889175,184.724686 166.775925,184.452911 166.829803,184.093307 
z"/>
<path fill="#F7C520" opacity="1.000000" stroke="none" 
	d="
M171.303864,152.012177 
	C171.268036,149.836426 171.482147,147.782242 171.850937,145.372498 
	C173.756073,144.345566 175.506561,143.674179 177.628967,143.007111 
	C178.913452,144.475906 179.826004,145.940399 180.685425,147.946762 
	C177.860458,149.547409 173.677917,148.167664 173.025101,152.889404 
	C172.534668,152.637512 172.044235,152.385620 171.303864,152.012177 
z"/>
<path fill="#EAB915" opacity="1.000000" stroke="none" 
	d="
M173.426697,152.944122 
	C173.677917,148.167664 177.860458,149.547409 180.813080,148.246124 
	C181.737442,148.211777 182.481033,148.419937 183.269836,148.640747 
	C182.434570,150.327225 181.714462,151.781158 180.975037,153.579987 
	C178.579926,153.616180 176.204102,153.307495 173.426697,152.944122 
z"/>
<path fill="#E8AF22" opacity="1.000000" stroke="none" 
	d="
M185.237549,144.007355 
	C185.404510,143.895386 185.572968,143.949692 185.741409,144.003998 
	C185.573944,144.060547 185.406494,144.117096 185.237549,144.007355 
z"/>
<path fill="#F9D047" opacity="1.000000" stroke="none" 
	d="
M165.152039,152.142853 
	C165.090897,151.970215 165.535248,151.985504 165.757309,151.988007 
	C165.723984,152.098831 165.468582,152.207153 165.152039,152.142853 
z"/>
<path fill="#FAC94D" opacity="1.000000" stroke="none" 
	d="
M187.084320,193.181610 
	C187.161011,193.012665 187.328461,193.010132 187.747070,193.008759 
	C187.723831,193.122620 187.449448,193.235321 187.084320,193.181610 
z"/>
<path fill="#F39622" opacity="1.000000" stroke="none" 
	d="
M97.068962,217.050812 
	C100.742233,215.711166 104.415497,214.371521 108.493286,213.058685 
	C108.918671,213.814316 108.939537,214.543106 108.744553,215.785583 
	C108.680016,216.866699 108.831322,217.434143 108.982628,218.001602 
	C108.985405,218.753510 108.988174,219.505417 108.991135,220.633575 
	C108.107460,222.415970 107.926491,224.691025 105.108376,222.924667 
	C104.067711,222.272400 102.017784,223.230347 100.428169,223.453888 
	C101.478462,224.457108 102.528748,225.460312 103.433945,226.736786 
	C102.586044,227.861038 101.883255,228.712036 101.180458,229.563034 
	C101.695877,229.865967 102.211304,230.168884 102.726723,230.471817 
	C103.150948,229.314346 103.575165,228.156860 103.999390,226.999390 
	C109.065994,227.653854 105.224724,231.192200 106.012482,233.614777 
	C102.588280,232.330261 99.168282,230.661514 95.367432,228.986221 
	C95.013885,226.893967 95.041183,224.808258 95.199127,222.120392 
	C95.885735,221.342422 96.441681,221.166641 97.169220,220.907227 
	C97.226471,220.551025 97.112129,220.278412 96.997452,219.635651 
	C97.021057,218.527267 97.045013,217.789047 97.068962,217.050812 
z"/>
<path fill="#FBAD36" opacity="1.000000" stroke="none" 
	d="
M109.283463,217.979370 
	C108.831322,217.434143 108.680016,216.866699 108.747993,216.151337 
	C109.529053,216.167542 110.090820,216.331650 110.823669,216.739594 
	C110.524605,217.307999 110.054451,217.632553 109.283463,217.979370 
z"/>
<path fill="#E9AB4E" opacity="1.000000" stroke="none" 
	d="
M130.734924,198.028290 
	C130.982376,198.014282 130.994949,198.489136 130.999969,198.726151 
	C130.572739,198.813507 130.140457,198.663834 129.708191,198.514160 
	C129.967941,198.356873 130.227707,198.199585 130.734924,198.028290 
z"/>
<path fill="#FBB03A" opacity="1.000000" stroke="none" 
	d="
M137.975647,226.024551 
	C136.975769,225.517548 135.994324,224.991882 135.012863,224.466217 
	C135.316101,224.044006 135.619324,223.621796 135.922546,223.199585 
	C136.595749,223.905975 137.268951,224.612366 137.986450,225.643539 
	C138.030731,225.968323 137.994080,226.005875 137.975647,226.024551 
z"/>
<path fill="#FBB03A" opacity="1.000000" stroke="none" 
	d="
M126.998108,211.998108 
	C126.915268,212.765747 126.834328,213.535294 126.753380,214.304840 
	C126.367401,214.210999 125.981422,214.117172 125.595436,214.023331 
	C125.864075,213.376251 126.132713,212.729172 126.702545,212.042801 
	C127.003738,212.003510 127.000000,212.000000 126.998108,211.998108 
z"/>
<path fill="#FADE1F" opacity="1.000000" stroke="none" 
	d="
M125.955498,86.658890 
	C122.979843,86.999878 119.971458,86.999878 116.796997,86.999878 
	C116.001091,88.018059 114.871750,89.462791 113.382919,90.953766 
	C112.096214,86.736099 112.233185,83.479271 117.307236,81.284027 
	C119.812790,80.200035 120.740570,80.885979 122.644196,81.975235 
	C123.967171,83.464951 124.944962,84.891434 125.955498,86.658890 
z"/>
<path fill="#F2D407" opacity="1.000000" stroke="none" 
	d="
M108.353920,82.257256 
	C108.368713,82.008766 108.500198,81.873604 108.631683,81.738449 
	C108.577988,81.949158 108.524292,82.159874 108.353920,82.257256 
z"/>
<path fill="#F2D407" opacity="1.000000" stroke="none" 
	d="
M106.006538,84.995499 
	C106.259850,85.339134 106.508690,85.686272 106.735847,86.047066 
	C106.741623,86.056236 106.509003,86.215492 106.387573,86.304733 
	C106.264656,86.033516 106.141754,85.762299 106.002380,85.252701 
	C105.985909,85.014328 105.999229,85.000801 106.006538,84.995499 
z"/>
<path fill="#FEDD56" opacity="1.000000" stroke="none" 
	d="
M171.989746,144.599548 
	C171.239990,144.979065 170.500732,144.997726 169.392593,145.038910 
	C167.920059,142.726120 166.816391,140.390808 165.682907,137.992401 
	C170.828690,136.135162 172.965866,138.636002 171.989746,144.599548 
z"/>
<path fill="#F7C830" opacity="1.000000" stroke="none" 
	d="
M168.690170,131.983124 
	C169.178787,131.986938 169.352371,131.994537 169.785507,132.032288 
	C169.677582,132.817505 169.310120,133.572571 168.942657,134.327637 
	C168.197037,133.765274 167.451431,133.202927 166.705811,132.640564 
	C167.262253,132.422668 167.818710,132.204788 168.690170,131.983124 
z"/>
<path fill="#FABA2B" opacity="1.000000" stroke="none" 
	d="
M97.002602,209.992538 
	C96.853531,211.574005 96.704460,213.155472 95.943054,214.872910 
	C94.888885,215.008331 94.447052,215.007797 94.005211,215.007263 
	C92.247810,215.648804 90.490410,216.290375 88.383377,216.974335 
	C88.020027,216.259720 88.006310,215.502716 88.153381,214.047577 
	C88.898727,212.372665 89.253929,211.029465 90.113220,210.491501 
	C92.227173,209.168106 94.456001,206.313309 97.002602,209.992538 
z"/>
<path fill="#F9CC38" opacity="1.000000" stroke="none" 
	d="
M97.271103,209.802338 
	C94.456001,206.313309 92.227173,209.168106 90.113220,210.491501 
	C89.253929,211.029465 88.898727,212.372665 88.156006,213.669205 
	C87.896225,212.220352 87.794617,210.451767 87.820648,208.341675 
	C87.948280,208.000153 87.986221,207.515015 88.346909,207.223648 
	C92.462509,205.946442 96.217407,204.960602 99.972313,203.974762 
	C100.424072,203.984634 100.875824,203.994507 101.662537,204.336578 
	C101.997795,205.109848 101.998100,205.550934 101.998398,205.992004 
	C100.512138,207.198715 99.025871,208.405441 97.271103,209.802338 
z"/>
<path fill="#F5AA41" opacity="1.000000" stroke="none" 
	d="
M83.171326,213.913849 
	C83.001358,213.830643 83.002518,213.657425 83.010132,213.224045 
	C83.190056,213.140244 83.380775,213.304230 83.523155,213.502853 
	C83.553383,213.545013 83.407303,213.713531 83.171326,213.913849 
z"/>
<path fill="#F5AA41" opacity="1.000000" stroke="none" 
	d="
M93.966751,215.362717 
	C94.447052,215.007797 94.888885,215.008331 95.662018,215.013290 
	C96.180954,215.582199 96.368599,216.146683 96.812607,216.880997 
	C97.045013,217.789047 97.021057,218.527267 96.997498,219.881882 
	C96.997894,220.498260 96.997627,220.990845 96.997627,220.990845 
	C96.441681,221.166641 95.885735,221.342422 95.162354,221.764999 
	C93.870667,222.246063 92.746407,222.480347 91.292404,222.835480 
	C91.951210,220.543610 92.939751,218.130890 93.966751,215.362717 
z"/>
<path fill="#FADE1F" opacity="1.000000" stroke="none" 
	d="
M137.951736,105.707169 
	C133.736984,105.263718 133.346405,104.554192 135.584381,101.009621 
	C136.599731,102.398949 137.268478,103.878464 137.951736,105.707169 
z"/>
<path fill="#FADE1F" opacity="1.000000" stroke="none" 
	d="
M138.136414,109.919037 
	C137.958862,109.836197 137.956421,109.665878 137.963867,109.239975 
	C138.153732,109.156540 138.348846,109.316902 138.500031,109.511429 
	C138.528061,109.547501 138.378891,109.721306 138.136414,109.919037 
z"/>
<path fill="#FADE1F" opacity="1.000000" stroke="none" 
	d="
M137.533691,97.550377 
	C137.475677,97.585167 137.591721,97.515587 137.533691,97.550377 
z"/>
<path fill="#F5BE36" opacity="1.000000" stroke="none" 
	d="
M181.452515,138.534241 
	C181.415253,138.474304 181.489777,138.594162 181.452515,138.534241 
z"/>
<path fill="#F3C012" opacity="1.000000" stroke="none" 
	d="
M45.033726,122.340958 
	C44.709885,121.247154 44.446156,120.498901 43.843788,118.789871 
	C49.533028,122.242455 52.606010,118.919487 55.756554,115.806953 
	C55.989727,115.576599 56.356258,115.462006 56.543583,115.207367 
	C59.076160,111.764633 59.071018,111.760849 62.037617,115.878433 
	C63.425934,114.797485 64.807495,113.721802 67.080109,111.952339 
	C67.080109,114.560722 67.080109,116.428513 66.683578,118.657043 
	C64.884888,120.097084 63.482731,121.176384 62.081112,122.255272 
	C64.523918,124.563133 66.328621,126.268127 68.133316,127.973129 
	C67.939110,127.999733 67.744896,128.026337 66.890694,128.054108 
	C62.303368,129.289749 57.509705,129.448364 55.125294,126.845009 
	C52.769394,124.272781 50.738544,124.043388 48.009094,124.008347 
	C47.037342,123.567734 46.065594,123.127129 45.033726,122.340958 
z"/>
<path fill="#FDCC46" opacity="1.000000" stroke="none" 
	d="
M54.601387,138.997955 
	C57.930199,139.972092 60.446327,141.122833 59.226933,145.107162 
	C58.945133,146.027954 59.672337,147.257553 60.015144,149.042603 
	C60.665710,150.866440 61.236366,151.992691 61.412704,152.340729 
	C57.792137,153.963318 54.388168,155.488846 50.672211,156.855682 
	C48.572010,155.134995 46.783806,153.572983 44.995598,152.010986 
	C46.774380,148.213409 48.385994,144.321289 50.444496,140.681931 
	C50.988022,139.720993 52.910355,139.539932 54.601387,138.997955 
z"/>
<path fill="#FDE22D" opacity="1.000000" stroke="none" 
	d="
M47.999344,124.399323 
	C50.738544,124.043388 52.769394,124.272781 55.125294,126.845009 
	C57.509705,129.448364 62.303368,129.289749 66.599113,128.091980 
	C66.842690,130.165955 66.717857,132.203232 66.678963,132.837997 
	C61.438587,133.614960 57.134430,134.253113 52.373268,134.578064 
	C50.605091,133.845093 49.293922,133.425308 47.982758,133.005508 
	C47.985035,130.267105 47.987312,127.528709 47.999344,124.399323 
z"/>
<path fill="#F4B92B" opacity="1.000000" stroke="none" 
	d="
M44.609333,152.055222 
	C46.783806,153.572983 48.572010,155.134995 50.915825,156.870605 
	C51.471428,157.044205 51.955841,157.101120 51.957771,157.487488 
	C50.468235,160.948547 46.736130,161.942413 43.744267,160.624329 
	C42.067020,159.885406 39.948120,160.148956 38.028206,159.960831 
	C38.028778,157.932205 38.029346,155.903564 38.061665,153.441284 
	C40.136635,152.704910 42.179852,152.402191 44.609333,152.055222 
z"/>
<path fill="#F8EA23" opacity="1.000000" stroke="none" 
	d="
M68.421967,127.970917 
	C66.328621,126.268127 64.523918,124.563133 62.081112,122.255272 
	C63.482731,121.176384 64.884888,120.097084 66.642075,119.014862 
	C66.997108,119.011932 66.999382,118.999168 67.183708,119.261864 
	C68.192032,119.659157 69.016037,119.793762 69.840034,119.928368 
	C69.873688,122.298447 69.907341,124.668533 69.902161,127.476021 
	C69.479088,127.931854 69.094849,127.950279 68.421967,127.970917 
z"/>
<path fill="#F7C520" opacity="1.000000" stroke="none" 
	d="
M47.679203,133.164398 
	C49.293922,133.425308 50.605091,133.845093 51.997414,134.622925 
	C50.302784,135.656235 48.527000,136.331497 46.378349,137.005676 
	C46.462204,135.777481 46.918930,134.550369 47.679203,133.164398 
z"/>
<path fill="#F7C520" opacity="1.000000" stroke="none" 
	d="
M40.138161,138.281631 
	C41.727371,137.652802 43.461460,137.324692 45.599937,137.001053 
	C44.924332,140.532150 43.436951,142.580627 40.138161,138.281631 
z"/>
<path fill="#FABA2B" opacity="1.000000" stroke="none" 
	d="
M65.636635,163.085922 
	C65.702553,164.466751 65.454567,165.850784 65.220551,167.587189 
	C64.073532,168.589325 62.912548,169.239105 61.030857,169.975479 
	C58.552914,170.383041 56.795677,170.704025 55.038445,171.024994 
	C54.864468,171.019485 54.690495,171.013992 53.855061,171.000671 
	C52.124851,170.976059 51.056103,170.959244 49.984764,170.591522 
	C49.312000,169.493378 48.641819,168.746124 47.971642,167.998886 
	C47.231922,167.003830 46.492199,166.008759 45.637955,164.721878 
	C47.949055,163.247910 50.351299,162.013672 52.811817,160.909286 
	C53.795322,160.467834 54.907352,160.312729 55.960876,160.027283 
	C57.775814,165.307663 61.562656,166.546173 65.636635,163.085922 
z"/>
<path fill="#F9CC38" opacity="1.000000" stroke="none" 
	d="
M55.973053,159.765564 
	C54.907352,160.312729 53.795322,160.467834 52.811817,160.909286 
	C50.351299,162.013672 47.949055,163.247910 45.269238,164.708740 
	C39.750515,166.744598 38.457981,165.968079 38.021126,160.371094 
	C39.948120,160.148956 42.067020,159.885406 43.744267,160.624329 
	C46.736130,161.942413 50.468235,160.948547 52.294350,157.481201 
	C53.752106,157.719040 54.875206,158.349548 55.998310,158.980042 
	C55.993950,159.154633 55.989586,159.329239 55.973053,159.765564 
z"/>
<path fill="#F7AF2D" opacity="1.000000" stroke="none" 
	d="
M47.756248,168.280365 
	C48.641819,168.746124 49.312000,169.493378 49.795456,170.739349 
	C49.733288,171.841187 49.857845,172.444290 49.982403,173.047409 
	C48.860168,173.060287 47.737938,173.073166 46.293167,173.079269 
	C46.494038,171.568954 47.017448,170.065399 47.756248,168.280365 
z"/>
<path fill="#F3B259" opacity="1.000000" stroke="none" 
	d="
M43.861267,173.706985 
	C43.654179,173.760712 43.345123,173.619202 43.036896,173.250931 
	C43.330956,173.108734 43.571484,173.271271 43.861267,173.706985 
z"/>
<path fill="#F8D02B" opacity="1.000000" stroke="none" 
	d="
M99.792221,108.728462 
	C97.227921,109.330643 96.155853,114.962250 92.300407,111.575050 
	C91.237053,110.640854 90.757057,109.042648 90.018295,107.363792 
	C93.683212,104.422112 96.580917,106.794357 99.792221,108.728462 
z"/>
<path fill="#FDF30F" opacity="1.000000" stroke="none" 
	d="
M97.019905,99.388176 
	C97.616447,97.533012 98.200439,96.062233 98.893631,94.296829 
	C100.333008,94.746696 101.663193,95.491203 103.003326,96.867859 
	C103.013275,97.500023 103.024887,97.997505 103.024887,97.997505 
	C102.269402,99.235466 101.740334,100.719681 100.714142,101.663223 
	C98.090256,104.075775 96.958168,102.933060 97.019905,99.388176 
z"/>
<path fill="#F6DB2C" opacity="1.000000" stroke="none" 
	d="
M93.369415,98.995461 
	C93.963364,98.773376 94.933868,98.552765 95.954788,98.666992 
	C95.252113,99.000198 94.499046,98.998566 93.369415,98.995461 
z"/>
<path fill="#F9DD0F" opacity="1.000000" stroke="none" 
	d="
M103.272346,97.981544 
	C103.024887,97.997505 103.013275,97.500023 103.021408,97.251389 
	C103.454712,97.167969 103.879883,97.333176 104.305046,97.498383 
	C104.043297,97.654114 103.781548,97.809845 103.272346,97.981544 
z"/>
<path fill="#F3C012" opacity="1.000000" stroke="none" 
	d="
M69.867561,119.706474 
	C69.016037,119.793762 68.192032,119.659157 67.178436,119.263458 
	C67.702896,119.004776 68.416954,119.007195 69.484261,119.025360 
	C69.837502,119.041115 69.895088,119.484581 69.867561,119.706474 
z"/>
<path fill="#FEDD56" opacity="1.000000" stroke="none" 
	d="
M55.176781,171.328857 
	C56.795677,170.704025 58.552914,170.383041 60.700142,170.036621 
	C60.517670,171.549194 59.945194,173.087204 59.372719,174.625214 
	C58.020180,173.627716 56.667648,172.630219 55.176781,171.328857 
z"/>
<path fill="#FEDD56" opacity="1.000000" stroke="none" 
	d="
M50.332996,173.032074 
	C49.857845,172.444290 49.733288,171.841187 49.798042,171.090240 
	C51.056103,170.959244 52.124851,170.976059 53.593971,170.998352 
	C52.890755,171.674805 51.787170,172.345764 50.332996,173.032074 
z"/>
<path fill="#FEDD56" opacity="1.000000" stroke="none" 
	d="
M63.855927,171.825714 
	C63.934666,171.988129 63.481731,172.005600 63.255970,172.021744 
	C63.279202,171.913025 63.528194,171.788162 63.855927,171.825714 
z"/>
<path fill="#FADE1F" opacity="1.000000" stroke="none" 
	d="
M132.036896,112.346954 
	C132.775848,111.668640 133.573502,111.335899 134.719666,111.005287 
	C134.918137,113.052361 134.753922,115.072266 132.036896,112.346954 
z"/>
<path fill="#EF9C27" opacity="1.000000" stroke="none" 
	d="
M99.982407,203.605377 
	C96.217407,204.960602 92.462509,205.946442 88.353752,206.980438 
	C87.565979,205.335175 87.132057,203.641754 86.688957,201.912521 
	C91.514687,201.366898 95.528381,200.913086 99.795715,200.739090 
	C100.030396,201.757935 100.011444,202.496964 99.982407,203.605377 
z"/>
<path fill="#F8C146" opacity="1.000000" stroke="none" 
	d="
M96.702591,169.142212 
	C97.268875,169.518356 97.527046,170.013016 97.875702,170.773315 
	C96.828247,171.524582 95.690323,172.010208 94.552391,172.495834 
	C94.319031,172.225327 94.085670,171.954819 93.852303,171.684311 
	C94.699692,170.876450 95.547089,170.068588 96.702591,169.142212 
z"/>
<path fill="#FABA2B" opacity="1.000000" stroke="none" 
	d="
M137.568695,163.996140 
	C137.953430,165.068375 137.978760,166.136383 138.021362,167.604889 
	C137.876648,169.513657 137.714676,171.021942 137.060059,172.877380 
	C136.296677,173.410583 136.120285,173.662689 136.038269,173.980820 
	C136.038269,173.980820 136.011139,174.008118 135.755371,174.180542 
	C135.342392,174.900162 135.185211,175.447357 135.028030,175.994537 
	C135.026367,176.162735 135.024689,176.330917 134.684158,176.747650 
	C133.895538,176.998886 133.445801,177.001572 132.996048,177.004272 
	C131.662949,175.205154 130.329849,173.406036 128.983688,171.277374 
	C130.294449,168.533508 131.618271,166.119202 132.997864,163.351562 
	C134.438858,163.329468 135.824081,163.660706 137.568695,163.996140 
z"/>
<path fill="#F5BE36" opacity="1.000000" stroke="none" 
	d="
M131.005768,158.749207 
	C130.886017,158.718567 130.765793,158.440979 130.826660,158.085892 
	C131.007736,158.008377 131.005310,158.502258 131.005768,158.749207 
z"/>
<path fill="#FFEB52" opacity="1.000000" stroke="none" 
	d="
M56.297028,158.774933 
	C54.875206,158.349548 53.752106,157.719040 52.292423,157.094818 
	C51.955841,157.101120 51.471428,157.044205 51.227814,157.029282 
	C54.388168,155.488846 57.792137,153.963318 61.412704,152.340729 
	C61.236366,151.992691 60.665710,150.866440 60.055237,149.410904 
	C61.136230,149.295639 62.257050,149.509628 63.700935,149.859467 
	C64.233772,155.180267 60.489326,156.939636 56.297028,158.774933 
z"/>
<path fill="#F0A739" opacity="1.000000" stroke="none" 
	d="
M102.187927,206.225403 
	C101.998100,205.550934 101.997795,205.109848 102.001442,204.338043 
	C102.617348,204.604004 103.229301,205.200668 103.841255,205.797333 
	C103.353317,206.017838 102.865379,206.238327 102.187927,206.225403 
z"/>
<path fill="#F8C146" opacity="1.000000" stroke="none" 
	d="
M132.960007,177.357330 
	C133.445801,177.001572 133.895538,176.998886 134.682938,176.999969 
	C135.348175,177.783691 135.675766,178.563675 136.012024,179.683472 
	C136.011932,180.764923 136.003143,181.506546 135.983795,182.618942 
	C134.147018,182.989716 132.320801,182.989716 129.645096,182.989716 
	C131.140182,180.582474 132.032074,179.146454 132.960007,177.357330 
z"/>
<path fill="#F9D047" opacity="1.000000" stroke="none" 
	d="
M125.992355,179.988800 
	C126.434448,179.853683 126.886208,179.731430 127.337967,179.609177 
	C127.062401,179.737488 126.786835,179.865784 126.268066,180.009460 
	C126.024864,180.024826 126.002029,180.001678 125.992355,179.988800 
z"/>
<path fill="#F7B94A" opacity="1.000000" stroke="none" 
	d="
M134.955688,192.251160 
	C135.371994,192.483566 135.675873,192.959351 135.997177,193.732635 
	C135.624161,193.518280 135.233719,193.006393 134.955688,192.251160 
z"/>
<path fill="#F5BE36" opacity="1.000000" stroke="none" 
	d="
M100.536880,153.547089 
	C100.482300,153.579376 100.591454,153.514816 100.536880,153.547089 
z"/>
<path fill="#F8C146" opacity="1.000000" stroke="none" 
	d="
M135.266159,175.829895 
	C135.185211,175.447357 135.342392,174.900162 135.745239,174.196930 
	C135.828690,174.582321 135.666489,175.123779 135.266159,175.829895 
z"/>
<path fill="#F8C146" opacity="1.000000" stroke="none" 
	d="
M136.248932,173.881897 
	C136.120285,173.662689 136.296677,173.410583 136.783264,173.132126 
	C136.899963,173.346054 136.720123,173.593811 136.248932,173.881897 
z"/>
<path fill="#F8A638" opacity="1.000000" stroke="none" 
	d="
M103.999695,226.999695 
	C103.575165,228.156860 103.150948,229.314346 102.726723,230.471817 
	C102.211304,230.168884 101.695877,229.865967 101.180458,229.563034 
	C101.883255,228.712036 102.586044,227.861038 103.644730,227.005325 
	C104.000610,227.000610 104.000000,227.000000 103.999695,226.999695 
z"/>
<path fill="#FBAD36" opacity="1.000000" stroke="none" 
	d="
M97.169220,220.907227 
	C96.997627,220.990845 96.997894,220.498260 96.997841,220.252045 
	C97.112129,220.278412 97.226471,220.551025 97.169220,220.907227 
z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
